<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { pick } from 'lodash-es';
import { defineAsyncComponent } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import { IconHawkChevronDown } from '~/common/components/molecules/hawk-icons/icons.js';

// components
import DocumentUploadForm from '~/dms/components/documents/forms/document-upload-form.vue';

const props = defineProps({
  attachment_config: {
    type: Object,
  },
  is_single_file: {
    type: Boolean,
  },
  trigger_classes: {
    type: String,
    default: '',
  },
  trigger_props: {
    type: Object,
    default: () => ({}),
  },
  trigger_text: {
    type: String,
  },
  dropdown_position: {
    type: String,
  },
});

const emits = defineEmits(['addFiles']);

const DocumentBrowsePopup = defineAsyncComponent({
  loader: () => import('~/dms/components/atoms/document-browse-popup.vue'),
  loadingComponent: HawkLoader,
});

const $t = inject('$t');

const document_upload_modal = useModal({
  component: DocumentUploadForm,
});

const document_browse_modal = useModal({
  component: DocumentBrowsePopup,
});

const file_element = { name: 'File', component: 'MultifileElement', auto: false, addClass: { list: 'overflow-y-auto max-h-[50vh] scrollbar' } };

const fields_list = ref([file_element]);
const show_dropdown = ref(false);

function openFileUploadModal() {
  document_upload_modal.patchOptions({
    attrs: {
      fields_list,
      attachment_config: props.attachment_config,
      is_single_file: props.is_single_file,
      submit: async (form) => {
        document_upload_modal.close();
        const files = props.is_single_file ? [form.data.File] : form.data.File;
        emits('addFiles', files.map(file => ({ service: file.service_object, file_name: file.name, file_size: file.size, mime_type: file.type, meta: file.meta, created_at: new Date().toISOString() })));
      },
      onClose() {
        document_upload_modal.close();
      },
    },
  });
  document_upload_modal.open();
}

function openFileImportModal() {
  document_browse_modal.patchOptions({
    attrs: {
      title: props.is_single_file ? $t('Select a document') : $t('Select documents'),
      action_label: $t('Upload'),
      is_single_file: props.is_single_file,
      on_submit: async ({ selected_documents }) => {
        document_browse_modal.close();
        emits('addFiles', selected_documents.map(file => ({
          service: file.service,
          file_name: file.name,
          file_size: file.file_size,
          mime_type: file.file_type,
          meta: file.meta,
          created_at: new Date().toISOString(),
          ...(pick(file, ['thumbnail_small', 'thumbnail_xsmall', 'thumbnail_large', 'thumbnail_small_size', 'thumbnail_xsmall_size', 'thumbnail_large_size'])),
        })));
      },
      onClose() {
        document_browse_modal.close();
      },
    },
  });
  document_browse_modal.open();
}

const hawk_menu_items = [
  {
    label: $t('Upload'),
    uid: 'menu1',
    icon: 'file',
    on_click: () => {
      openFileUploadModal();
    },
  },
  {
    label: $t('Choose existing'),
    uid: 'menu2',
    has_divider: true,
    icon: 'folder',
    on_click: () => {
      openFileImportModal();
    },
  },
];
</script>

<template>
  <hawk-menu
    :items="hawk_menu_items"
    :position="dropdown_position"
    additional_dropdown_classes="w-52"
    @open="show_dropdown = true"
    @close="show_dropdown = false"
  >
    <template #trigger>
      <hawk-button
        type="outlined"
        class="flex justify-between text-gray-700 font-semibold"
        :class="trigger_classes"
        v-bind="trigger_props"
      >
        {{ trigger_text || $t('Add Attachments') }}
        <IconHawkChevronDown
          class="w-[16px] h-[16px] transition-transform"
          :class="{ 'rotate-180 !visible': show_dropdown }"
        />
      </hawk-button>
    </template>
  </hawk-menu>
</template>
