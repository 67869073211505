<script setup>
import { groupBy, map } from 'lodash-es';
import { computed, ref } from 'vue';
import { IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  add_suggestion: {
    type: Function,
    default: () => undefined,
  },
  close: {
    type: Function,
    default: () => undefined,
  },
  nested_suggestions: {
    type: Array,
    default: () => [],
  },
  is_workflow: {
    type: Boolean,
    required: true,
  },
  search_field: {
    type: String,
    default: '',
  },
  has_default_suggestions: {
    type: Boolean,
    default: true,
  },
});

const selected_tree_item = ref(null);

const nested_suggestions_searched = computed(() => {
  let suggestions = props.nested_suggestions;
  if (!props.has_default_suggestions)
    suggestions = (props.nested_suggestions || []).slice(3);

  return suggestions.filter(suggestion => suggestion.label.toLowerCase().includes(props.search_field.toLowerCase()));
});

// Used to format the field options in tree structure
const get_formatted_structure = computed(() => {
  let result = [];

  if (props.is_workflow) {
    const grouped_by_form_id = groupBy(nested_suggestions_searched.value, 'form_block_id');
    result = map(grouped_by_form_id, (items, form_block_id) => {
      const form = {
        label: items[0].form_block_name,
        id: form_block_id,
        uid: form_block_id,
        subRows: [],
      };

      const grouped_by_section_id = groupBy(items, 'section_id');

      form.subRows = map(grouped_by_section_id, (fields, section_id) => {
        return {
          label: fields[0].section_name,
          id: section_id,
          uid: section_id,
          subRows: fields.map(field => ({
            label: field.label,
            id: field.id,
            uid: field.id,
          })),
        };
      });

      return form;
    });
  }
  else {
    const grouped_by_section_id = groupBy(nested_suggestions_searched.value, 'section_id');
    result = map(grouped_by_section_id, (items, section_id) => {
      const section = {
        label: items[0].section_name,
        id: section_id,
        uid: section_id,
        subRows: [],
      };

      section.subRows = items.map(field => ({
        label: field.label,
        id: field.id,
        uid: field.id,
      }));

      return section;
    });
  }

  return result;
});

const get_expanded_uids = computed(() => props.search_field ? nested_suggestions_searched.value.flatMap(suggestion => [suggestion.form_block_id, suggestion.section_id]) : []);

function handleTreeSelectItem({ item }) {
  if (!item.subRows?.length) {
    selected_tree_item.value = item;
    props.add_suggestion({
      id: item.id,
      label: item.label,
    });
    props.close();
  }
}
</script>

<template>
  <HawkTree
    children_key="subRows"
    value_key="label"
    key_field="uid"
    :items="get_formatted_structure"
    :expanded_items="get_expanded_uids"
    :full_row_click="true"
    :selected_item="selected_tree_item"
    :expand_icon_on_right="false"
    @select-item="handleTreeSelectItem"
  >
    <template #item="item_data">
      <span class="text-sm font-medium text-gray-700">
        {{ `${item_data.data.label}` }}
      </span>
    </template>
    <template #collapse-icon>
      <IconHawkChevronRight />
    </template>
  </HawkTree>
</template>
