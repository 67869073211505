<script setup>
import { reactive, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import emailNotificationModal from '~/forms/components/settings/form-settings-email-notification-modal.vue';
import RemindersList from '~/forms/components/settings/form-settings-reminders-list.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';

const { $t, auth_store, common_store, route, router } = useCommonImports();
const form_template_detail_store = useFormTemplateDetailStore();
const notification_data = ref(null);
const finalArr = ref([]);
const current_open_modal_id = ref(null);
const state = reactive({
  reminders_added: 0,
  reminders_deleted: 0,
});
function saveReminders(data, idx) {
  if (idx !== null) {
    finalArr.value[idx] = data;
  }
  else {
    finalArr.value.push(data);
    state.reminders_added += 1;
  }
}
function deleteReminders(idx) {
  finalArr.value.splice(idx, 1);
  state.reminders_deleted += 1;
}
const { open, close } = useModal({
  component: emailNotificationModal,
  attrs: {
    final_arr: finalArr,
    current_open_modal_id,
    onClose() {
      close();
    },
    callback: saveReminders,
    close_callback: () => {
      close();
      return true;
    },
  },
  slots: {
    title_text: 'Add Reminder',
    subtitle_text: $t('send-email-push-notifications-assign-notify'),
  },
});
const save_loading = ref(false);
function formatMemberTeam(obj) {
  const custom = [];
  obj?.forEach((item) => {
    custom.push(item.uid);
  });
  return custom;
}
function return_key() {
  const { steps, owner, template_members, assignees } = notification_data.value;
  return { steps, owner, template_members, assignees };
}
async function customPayload() {
  const reminders_arr = [];
  await finalArr.value.forEach((elem) => {
    const { days, from, count, action, when, custom } = elem;
    const payload = {
      days,
      from,
      count,
      action,
      when,
      to: { ...return_key(), custom: custom ? formatMemberTeam(Object.values(custom)) : null },
    };
    reminders_arr.push(payload);
  });
  return reminders_arr;
}
function submittedData(data) {
  const result = {};
  data.forEach(item => result[item] = true);
  return result;
}
async function notificationPayload() {
  const { submitted_checkbox } = notification_data.value;
  const has_custom = submitted_checkbox.checkboxgroup.find(value => value === 'custom');
  const data = submittedData(submitted_checkbox.checkboxgroup);
  const form_submitted = {
    steps: null,
    ...data,
    custom: has_custom
      ? formatMemberTeam(submitted_checkbox.custom)
      : [],
  };
  return await form_submitted;
}
async function onSave() {
  save_loading.value = true;
  await form_template_detail_store.update_form_details({
    body: {
      reminders: await customPayload(),
      notifications: { form_submitted: await notificationPayload() },
    },

  });
  form_template_detail_store.form_template_track_events('Notifications updated');
  if (state.reminders_added)
    form_template_detail_store.form_template_track_events('Reminders added', { count: state.reminders_added });
  if (state.reminders_deleted)
    form_template_detail_store.form_template_track_events('Reminders removed', { count: state.reminders_deleted });
  save_loading.value = false;
}
function getCustomData(uids) {
  return uids.map((id) => {
    if (common_store.teams_map[id]) {
      const user = common_store.get_team(id);
      return { name: user?.name, uid: user?.uid, value: user?.uid, member: false };
    }
    else {
      const user = common_store.get_user(id);
      return { name: user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.email, uid: user?.uid, value: user?.uid, member: true };
    }
  });
}
onMounted(() => {
  if (form_template_detail_store?.form_template_detail?.reminders) {
    const { reminders } = form_template_detail_store.form_template_detail;
    reminders.length && reminders.forEach((item) => {
      finalArr.value.push({ ...item, custom: getCustomData(item?.to?.custom || []) });
    });
  }
  if (form_template_detail_store?.form_template_detail?.notifications) {
    const { notifications } = form_template_detail_store.form_template_detail;
    const { assignees, template_members, owner, custom } = notifications?.form_submitted ?? {};
    notification_data.value.submitted_checkbox.custom = getCustomData(custom || []);
    notification_data.value.submitted_checkbox.checkboxgroup = [assignees ? 'assignees' : null, 'submitted', template_members ? 'template_members' : false, owner ? 'owner' : null, ...(notification_data.value.submitted_checkbox.custom.length > 0 ? ['custom'] : [])];
  }
});
</script>

<template>
  <div>
    <div class="mb-5">
      <h2 class="mt-6 text-lg font-semibold tracking-tight">
        {{ $t('Email & Notifications') }}
      </h2>
      <span class="text-gray-600 text-xs"> {{ $t('General settings') }}</span>
    </div>
    <Vueform
      v-model="notification_data" :display-errors="false" sync :columns="{
        default: { container: 12, label: 6, wrapper: 6 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }" size="sm"
      :add-classes="{
        CheckboxgroupCheckbox: {
          container: 'mb-2 flex items-center',
          input: '!mt-0',
          text: 'text-sm',
        },
      }"
      @submit="onSave"
    >
      <ObjectElement
        size="sm" class="mb-4" name="submitted_checkbox"
        :label="$t('Send submitted copies to')"
      >
        <CheckboxElement value="submitted" :description="$t('The submitter of form will receive submitted copies by default')" :disabled="true" :default="true" :checked="true">
          {{ $t('Submitted') }}
        </CheckboxElement>
        <CheckboxgroupElement
          name="checkboxgroup" size="lg" class="mail-checkbox-grp-btn" :checked="true" :items="[
            { value: 'owner', label: $t('Creator of the form') },
            { value: 'assignees', label: $t('Assignees') },
            { value: 'template_members', label: $t('Members of the template') },
            { value: 'custom', label: $t('Custom') },
          ]" :disables="['submitted']" :default="['submitted']"
        />
        <hawk-assignee-input
          class="mb-6"
          :add-classes="{
            ElementLabel: {
              container: ['hidden'],
            },
            ElementLayout: {
              innerContainer: ['md:!col-span-5'],
            },
          }"
          :options="{
            name: 'custom',
            conditions: [
              ['submitted_checkbox.checkboxgroup', 'custom'],
            ],
            has_teams: true,
            placeholder: $t('select members and teams'),
            rules: 'required',
          }" :multi="true"
        />
      </ObjectElement>
      <div class="col-span-12">
        <h2 class="mt-3 text-lg font-semibold tracking-tight">
          {{ $t('Reminders') }}
        </h2>
        <span class="text-gray-600 text-xs"> {{ $t('send-email-push-notifications-assign-notify') }}</span>
      </div>
      <div class="col-span-12">
        <RemindersList
          :data="finalArr" :delete_reminders="deleteReminders" :edit_reminders="open" @currrent-id="current_open_modal_id = $event
          "
        />
      </div>
      <div class="flex cursor-pointer col-span-12" @click="current_open_modal_id = null; open()">
        <hawk-button type="text">
          <IconHawkPlus class="h-5 w-5" />
          {{ $t("Add Reminder") }}
        </hawk-button>
      </div>

      <div v-if="finalArr?.length > 0" class="text-sm font-regular col-span-12 mt-2">
        <span class="font-semibold">{{ $t("Note") }}:</span> {{ $t("The configured reminders or any changes made in already configured reminders will apply only to the forms created hereafter") }}
      </div>
      <StaticElement
        name="divider_1_copy"
        content="<hr style='border-color: #d1d5db; margin-top: 8px; padding-bottom: 8px;' />"
      />
      <StaticElement>
        <div class="flex justify-end text-sm font-medium">
          <hawk-button
            type="outlined"
            @click="router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid } })"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <ButtonElement
            button-class="w-full bg-blue-600" class="pl-4" name="submit" :submits="true"
            :loading="save_loading"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </StaticElement>
    </Vueform>
  </div>
</template>

<style lang="scss">
.mail-checkbox-grp-btn {
  .form-bg-selected {
    background-color: transparent !important;
  }
}
</style>
