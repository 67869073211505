<script setup>
import { useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkArrowLeft } from '~/common/components/molecules/hawk-icons/icons.js';

defineProps({
  email: {
    type: String,
    default: 'test@example.com',
  },
});
const router = useRouter();
const auth_store = useAuthStore();

const IconIllustrationPageNotFound = defineAsyncComponent(() => import('~icons/illustration/page-not-found.vue'));
</script>

<template>
  <div class="grid place-content-center" :class="[!auth_store.access_token ? 'h-screen' : 'h-[calc(100vh-65px)]']">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="flex justify-center mb-6">
        <IconIllustrationPageNotFound class="w-44 mb-2" />
      </div>
      <p class="text-center text-2xl font-semibold tracking-tight text-primary-800 mb-3">
        404 error
      </p>
      <p class="text-center text-2xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t('Page not found') }}
      </p>
      <p class="text-gray-500 w-96 mx-auto text-center mb-6">
        Sorry, the page you are looking for doesn't exist. Here are some helpful links:
      </p>
      <div class="flex justify-center gap-3">
        <HawkButton
          class="text-gray-600 text-sm"
          type="outlined"
          @click="router.go(-1)"
        >
          <IconHawkArrowLeft />
          Go back
        </HawkButton>
        <HawkButton
          v-if="auth_store.access_token"
          class="text-gray-600 text-sm"
          @click="$router.push({ name: 'home' })"
        >
          {{ $t('Take me home') }}
        </HawkButton>
        <HawkButton
          class="text-gray-600 text-sm"
          @click="$router.push({ name: 'sign-in' })"
        >
          {{ $t('Go to login') }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
