<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import DOMPurify from 'dompurify';
import { defineAsyncComponent } from 'vue';
import { IconHawkChevronLeft, IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkLoader from '../atoms/hawk-loader.vue';

const props = defineProps({
  attachment: {
    type: Object,
  },
  has_next: {
    type: Boolean,
  },
  has_previous: {
    type: Boolean,
  },
  items: {
    type: Array,
  },
});

const emits = defineEmits(['close', 'delete', 'download', 'next', 'previous', 'buttonClicked', 'setActiveAttachment']);

const SheetViewer = defineAsyncComponent({
  loader: () => import('~/plans/components/sheet-viewer.vue'),
  loadingComponent: HawkLoader,
});

const document_viewer = ref(null);
const video_supported_format = ['avi', 'mkv', 'mov', 'mp4', 'webm', 'wmv', 'wtv'];

const file_name = DOMPurify.sanitize(props.attachment?.file_name) || '';
const file_name_split = file_name.split('.');
const setup_options = {
  no_zoom: true,
  extension: file_name_split.pop(),
};

const is_video = video_supported_format.includes(setup_options.extension?.toLowerCase());

const document_viewer_ref = ref(null);

function initDocViewer(doc_instance) {
  document_viewer.value = doc_instance;
}

function fullScreen() {
  if (document_viewer_ref?.value?.requestFullscreen)
    document_viewer_ref.value.requestFullscreen();
}
const url = props.attachment?.url || props.attachment?.service?.url;

onMounted(() => {
  if (is_video) {
    const html = `
        <!DOCTYPE html>
        <html>
            <head>
                <title>${file_name}</title>
                </head>
                <body style="margin: 0; padding: 0;">
                <video controls autoplay muted width="100%"> 
                    <source src="${DOMPurify.sanitize(url)}" type="video/mp4">
                </video>
            </body>
        </html>
    `;

    const new_tab = window.open(url, '_blank');
    new_tab.document.write(html);
    emits('close');
  }
});
</script>

<template>
  <Teleport v-if="props.attachment && !is_video" to="body">
    <div class="vfm vfm--fixed vfm--inset flex justify-center items-center" aria-modal="true" style="z-index: 1010;">
      <div class="vfm__overlay vfm--overlay vfm--absolute vfm--inset vfm--prevent-none" aria-hidden="true" style="background-color:#101828E5" />
      <div class="h-full w-full">
        <hawk-attachment-viewer-header :attachment="props.attachment" :document_viewer="document_viewer" @close="emits('close')" @fullscreen="fullScreen" @delete="emits('delete')" @download="emits('download')">
          <template #header-right-content>
            <slot name="header-right-content" />
          </template>
        </hawk-attachment-viewer-header>
        <div class="flex justify-between items-center">
          <div
            class="text-white ml-4 p-2 cursor-pointer hover:bg-gray-800 rounded-lg absolute left-0 z-1"
            :class="{ 'pointer-events-none': !props.has_previous }"
            @click="emits('previous')"
          >
            <IconHawkChevronLeft v-if="props.has_previous" />
          </div>
          <div ref="document_viewer_ref" class="w-full pb-8 text-white" :class="[props.items?.length > 1 ? 'h-[calc(100vh-180px)]' : 'h-[calc(100vh-64px)]']">
            <SheetViewer v-if="url" :sheet_url="url" :no_tools="true" scroll_view_class="border-0 h-full" class="text-white" :setup_options="setup_options" @on-doc-load="initDocViewer" />
          </div>
          <div
            class="text-white mr-4 p-2 cursor-pointer hover:bg-gray-800 rounded-lg absolute right-0 z-1"
            :class="{ 'pointer-events-none': !props.has_next }"
            @click="emits('next')"
          >
            <IconHawkChevronRight v-if="props.has_next" />
          </div>
        </div>
        <hawk-attachment-viewer-footer
          v-if="items?.length > 1"
          class="absolute bottom-0 w-full"
          :items="items"
          :attachment="attachment"
          @set-active-attachment="emits('setActiveAttachment', $event)"
        />
      </div>
    </div>
  </Teleport>
</template>
