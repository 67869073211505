<script setup>
import { computed } from 'vue';
import { IconHawkSensehawkUser } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  size: {
    type: String,
    default: 'xs',
    validator(value) {
      return ['badge', 'tiny', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  has_border: {
    type: Boolean,
    default: false,
  },
});

const avatar_classes = computed(() => {
  const classes = [];

  switch (props.size) {
    case 'badge':
    case 'tiny':
      classes.push('h-4', 'w-4');
      break;
    case 'xxs':
      classes.push('h-5', 'w-5');
      break;
    case 'xs':
      classes.push('h-6', 'w-6');
      break;
    case 'sm':
      classes.push('h-8', 'w-8');
      break;
    case 'md':
      classes.push('h-10', 'w-10');
      break;
    case 'lg':
      classes.push('h-12', 'w-12');
      break;
    case 'xl':
      classes.push('h-14', 'w-14');
      break;
    case '2xl':
      classes.push('h-16', 'w-16');
      break;
  }

  return classes;
});

const has_border = computed(() => (props.has_border ? 'border-2 border-white' : ''));
</script>

<template>
  <div class="rounded-full">
    <IconHawkSensehawkUser :class="[has_border, avatar_classes]" class="rounded-full" />
  </div>
</template>
