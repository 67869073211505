<script setup>
import DOMPurify from 'dompurify';
import { ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkCheckCircle, IconHawkXCircle } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkConfirmationPopup from '~/common/components/organisms/hawk-confirmation-popup.vue';

const props = defineProps({
  on_submit: {
    type: Function,
    default: null,
  },
  form_detail_store: {
    type: Object,
  },
  form_approval_status: {
    type: Object,
  },
  view_only: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);
const $date = inject('$date');
const $t = inject('$t');
const auth_store = useAuthStore();
const form_detail_store = props.form_detail_store;
const forms$ = ref(null);
const uploading = ref(null);

const configure_buttons = computed(() => form_detail_store?.form_detail?.approval?.configure_buttons);

const { open: open_confirmation_popup, close: close_confirmation_popup, patchOptions: patch_confirmation_popup } = useModal({
  component: HawkConfirmationPopup,
});

async function onSave() {
  forms$.value.validate();
  if (forms$.value.hasErrors)
    return;

  patch_confirmation_popup({
    attrs: {
      content: $t('Are you sure you wish to proceed with this decision?'),
      is_block_styled_button: false,
      onClose() {
        close_confirmation_popup();
      },
      onConfirm: async () => {
        close_confirmation_popup();
        uploading.value = true;
        const { comment, action, signature } = forms$.value.requestData;
        const req = {
          url: 'approval-flow/hook/action',
          body: {
            flow_uid: form_detail_store?.form_detail?.approval?.uid,
            action: props.form_approval_status?.is_reviewer ? 'review' : action,
            comment,
            signature,
          },
        };
        await props.on_submit(req);
        uploading.value = false;
      },
    },
  });
  open_confirmation_popup();
}

function onFormUpdated(event) {
  if (event.type === 'upload_started')
    uploading.value = true;
  else if (event.type === 'upload_completed')
    uploading.value = false;
}

function format_signature_data(name, file) {
  if (!file)
    return { [name]: undefined };
  return { [name]: file?.file_name ? file : ({ service: file.service_object, file_name: file.name, file_size: file.size, mime_type: file.type, meta: file.meta }) };
}

function update_comment(el$) {
  const approver_data = form_detail_store?.form_detail.approvers.find(approver => approver.uid === auth_store.logged_in_user_details.user_id);

  if (props.view_only && approver_data?.comment)
    el$.load(approver_data.comment);
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="forms$" size="sm" :display-errors="false"
      :float-placeholders="false" class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="onSave"
      :columns="{
        default: { container: 12, wrapper: 3, label: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }"
      content_class="w-[700px]"
      @updated="onFormUpdated"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ view_only ? $t("View") : $t("review") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <StaticElement
            name="text"
            content="<p>The approval block would enable the user to approve or reject any block in the workflow</p>"
            size="sm"
            class="mb-5 text-gray-600"
          />
          <StaticElement
            class="mb-5"
            info="Due Date" label="Date" :columns="
              { container: 8, label: 4, wrapper: 7 }"
          >
            {{ $date(form_detail_store?.form_detail?.approval?.due_date, "L_DATE_SHORT") }}
          </StaticElement>
          <TextareaElement
            name="comment"
            :columns="{
              container: 12,
              label: 4,
            }"
            :add-classes="{
              TextareaElement: { input: { 'opacity-60': view_only } },
            }"
            :label="`${$t('comment')}`"
            :rules="['required']"
            :placeholder="`${$t('Add your comment')}`"
            size="sm"
            :disabled="view_only"
            @mounted="update_comment"
          />
          <template v-if="!view_only && !props.form_approval_status?.is_reviewer">
            <RadiogroupElement
              name="action"
              :label="`${$t('Status')}`"
              :items="[
                { label: configure_buttons?.is_enabled ? configure_buttons.approve : 'Approve', value: 'approve' },
                { label: configure_buttons?.is_enabled ? configure_buttons.reject : 'Reject', value: 'deny' },
              ]"
              class="my-5 font-medium text-gray-600"
              :add-class=" {
                wrapper: 'w-[14rem]',
              }"
              :remove-classes="{
                RadiogroupRadio: {
                  wrapper_selected: '!bg-primary-50 !border-primary-500',
                },
              }"
              :add-classes="{
                RadiogroupRadio: {
                  wrapper_selected: '!bg-gray-100 ',
                },
              }"
              default="approve"
              view="tabs"
            >
              <template #radio="{ item, value, el$, classes, isDisabled, id, name }">
                <div :class="classes.wrapper">
                  <input
                    :id="id"
                    v-model="el$.value"
                    type="radio"
                    :value="value"
                    :class="classes.input"
                    :name="name"
                    :disabled="isDisabled"
                  >
                  <IconHawkCheckCircle v-if="item.label === (configure_buttons?.is_enabled ? configure_buttons.approve : 'Approve')" class="text-success-600 mr-2" />
                  <IconHawkXCircle v-else class="text-error-600 mr-2" />
                  <span
                    :class="classes.text"
                    v-html="DOMPurify.sanitize(item.label)"
                  />
                </div>
              </template>
            </RadiogroupElement>
            <SignatureElement name="signature" :label="`${$t('Sign')}`" :format-data="format_signature_data" :rules="['required']" />
          </template>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div v-if="props.form_approval_status?.action_complete">
              <div class="flex p-4 bg-[#FCFCFD] border border-[#D0D5DD] rounded-md">
                <span class="mr-2"> <IconHawkInfoCircle /></span>
                <span> You {{ props.form_approval_status?.is_reviewer ? 'reviewed' : props.form_approval_status?.approved ? 'approved' : 'denied' }}. Waiting for other members to approve </span>
              </div>
            </div>
            <div v-else class="flex justify-end text-sm font-medium">
              <hawk-button
                class="mr-3" type="outlined" name="submit"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                :disabled="uploading"
                :loading="uploading"
                button-class="w-full bg-blue-600" name="submit"
              >
                {{ props.form_approval_status?.is_reviewer ? $t('Submit review') : $t('Confirm') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
