<script setup>
import { keyBy } from 'lodash-es';
import { onMounted } from 'vue';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import CustomVueformTextElement from '~/common/components/vueform/vueform-text-element.vue';
import useEmitter from '~/common/composables/useEmitter';
import FromDetailSections from '~/forms/components/form-detail/form-detail-sections.vue';
import { useFormatSavePayload } from '~/forms/composables/form-detail-composable';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';

const props = defineProps({
  forms: {
    type: Array,
    default: () => [],
  },
  forms_store: {
    type: Object,
  },
  template: { type: Object, default: () => ({}) },
  selected_features_without_forms: {
    type: Array,
    default: () => [],
  },
  create_forms_callback: {
    type: Function,
  },
  form_count: {
    type: Number,
  },
});

const emit = defineEmits(['click', 'close']);
const emitter = useEmitter();
const forms_store = props.forms_store;
const form$ = ref(null);
const is_loading = ref(false);
const uploading = ref(null);
const acknowledged = ref(false);
const has_form_changed = ref(null);
const $toast = inject('$toast');

const form_detail_store = useFormDetailStore();
provide('form_detail_store', form_detail_store);
const $services = inject('$services');

const getFormFieldsData = useFormatSavePayload(form_detail_store);

function update_checklist({ field, checklist }) {
  const checklist_element = form$.value.el$(field.uid);
  checklist_element.update(checklist);
}

async function getPayload() {
  try {
    const field_value_map = form$.value.requestData;
    Object.keys(field_value_map).forEach((field_uid) => {
      if (!form$.value.elements$?.[field_uid]?.dirty)
        field_value_map[field_uid] = undefined;
    });
    const fields_data = getFormFieldsData(field_value_map);
    let forms_list = [];
    if (props.selected_features_without_forms.length)
      forms_list = await props.create_forms_callback();
    forms_list.push(...props.forms);
    const payload = { forms: { update: forms_list.map(form => ({ field_values: fields_data, uid: form?.uid })) } };
    return payload;
  }
  catch (e) {
    logger.log(e);
  }
}

const is_saving = ref(false);
async function Submit() {
  is_saving.value = true;
  try {
    const response = await $services.forms.getAll({
      query: {
        form_uid: props.forms.map(d => d.uid),
        include: 'field_values',
      },
    });

    const existing_field_values_map = keyBy(response?.data?.forms, 'uid');
    const new_payload = await getPayload();
    new_payload.forms.update.forEach((form) => {
      Object.entries(form.field_values).forEach(([key, values]) => {
        if ((Array.isArray(values) && values.length === 0) || !values)
          delete form.field_values[key];
      });

      if (existing_field_values_map[form.uid])
        form.field_values = { ...(existing_field_values_map[form.uid]?.field_values || {}), ...form.field_values };
    });
    const saved_response = await $services.forms.post(
      {
        body: new_payload,
      },
    );
    saved_response?.data?.forms?.updated?.forEach((form) => {
      if (forms_store.forms_map[form.uid])
        forms_store.forms_map[form.uid] = form;
    });
    const uids = props.forms.map(form => form.uid);
    if (uids.length)
      forms_store.forms_track_events('Saved', uids[0], { mode: uids.length > 1 ? 'Bulk' : 'Single', count: uids.length });
    emitter.emit('form_save');
    $toast({
      text: 'Forms saved successfully.',
      type: 'success',
    });
    emit('close');
  }
  catch (error) {
    logger.error(error);
    $toast({
      text: 'Forms could not be saved.',
      type: 'error',
    });
  }
  is_saving.value = false;
}

function getSteps() {
  const current_step = form_detail_store.form_detail.current_step || 1;
  return Object.keys(form_detail_store.steps_with_sections).length > 0 ? markRaw({ [current_step]: form_detail_store.steps_with_sections[current_step] }) : {};
}

function onFormUpdated(event) {
  if (event.type === 'upload_started')
    uploading.value = true;
  else if (event.type === 'upload_completed')
    uploading.value = false;
}

async function get_form_template() {
  if (props.forms?.[0]?.uid) {
    const { data } = await $services.forms.get({
      id: props.forms?.[0]?.uid,
    });
    return { ...data.form.template, ...data.form };
  }
  else {
    const { data } = await $services.forms.get({
      attribute: `templates/${props.template}`,
    });
    const visibility = data.template.visibility;
    visibility.sections = Object.keys(visibility.sections).reduce((acc, section_uid) => {
      acc[section_uid] = visibility.fields[section_uid] !== 'hide' ? 'edit' : 'hide';
      return acc;
    }, {});
    visibility.fields = Object.keys(visibility.fields).reduce((acc, field_uid) => {
      acc[field_uid] = visibility.fields[field_uid] !== 'hide' ? 'edit' : 'hide';
      return acc;
    }, {});
    return { ...data.template, visibility };
  }
}

onMounted(async () => {
  try {
    is_loading.value = true;
    const form_template = await get_form_template();
    form_detail_store.form_detail = form_template;
    form_detail_store.form_template = form_template;
    form_detail_store.form_template.current_step = props.forms[0]?.current_step || 1;
    form_detail_store.form_submissions = props.forms[0]?.submission || {};
    is_loading.value = false;
  }
  catch (e) {
    logger.error('Problem while fetching form template data', e);
    emit('close');
  }
});
</script>

<template>
  <hawk-modal-container content_class="w-full h-screen !rounded-none" @close="emit('close')">
    <div class="flex justify-between items-center px-8 h-24 border-b">
      <div>
        <div class="flex text-lg flex-col justify-start">
          <FormDetailHeaderBreadcrumbs class="pb-1" />
          <div class="text-xl font-semibold">
            {{ form_count }} {{ $t('forms selected') }}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <hawk-button
          type="text"
          class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center -mr-4"
          @click="emit('close')"
        >
          <IconHawkXClose />
        </hawk-button>
      </div>
    </div>
    <Vueform
      ref="form$"
      :endpoint="Submit"
      :display-errors="false"
      :columns="{
        default: { container: 6, wrapper: 8, label: 6 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 8, label: 4, wrapper: 8 },
      }"
      :attachment_config="{
        meta: {
          service: 'forms',
          id: form_detail_store.form_detail.uid,
        },
      }"
      :messages="{
        required: 'This field is required',
      }"
      :remove-classes="{ ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm', container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm' } }"
      size="sm"
      :templates="{ TextElement: CustomVueformTextElement }"
      @change="is_saved = false;has_form_changed = true"
      @updated="onFormUpdated"
    >
      <div class="col-span-12 flex flex-col h-[calc(100vh-100px)]">
        <HawkLoader v-if="is_loading" container_class="flex-1 !max-h-none" />
        <hawk-modal-content v-else-if="form_detail_store.form_detail.sections" class="overflow-auto flex-1 scrollbar !max-h-none">
          <div v-for="(step, idx) in getSteps()" :key="idx">
            <FormDetailStep :step_name="step.name" :step_index="step.index" :is_preview_mode="false">
              <template #step_sections="slot_props">
                <FromDetailSections :form$="form$" :step="step" :is_step_submitted="slot_props.is_step_submitted || form_detail_store.form_detail.is_approval_pending" class="py-2" @update_checklist="update_checklist" />
              </template>
            </FormDetailStep>
          </div>
          <FromDetailSections v-if="Object.keys(form_detail_store.steps_with_sections).length <= 0" :form$="form$" @update_checklist="update_checklist" />
        </hawk-modal-content>

        <hawk-modal-footer v-if="form_detail_store.form_detail.sections" class="px-8 flex items-center justify-between w-full">
          <template #left>
            <div>
              <hawk-checkbox v-model="acknowledged">
                {{ $t('I acknowledge that the provided information will overwrite existing data') }}
              </hawk-checkbox>
            </div>
          </template>
          <template #right>
            <div class="flex">
              <HawkButton type="outlined" text="Cancel" @click="emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <HawkButton
                name="submit" :submits="true" class="ml-4" button-class="bg-blue-600" :add-classes="{
                  ElementLayout: { outerWrapper: 'block', innerContainer: 'block' },
                }"
                :remove-classes="{
                  ElementLayout: { outerWrapper: 'grid', innerContainer: 'grid' },
                }"
                :loading="is_saving"
                :disabled="(!form$?.dirty && !has_form_changed) || uploading || !acknowledged"
                @click="Submit"
              >
                {{ $t('Fill') }}  {{ form_count }}  {{ $t('forms') }}
              </HawkButton>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </vueform>
  </hawk-modal-container>
</template>
