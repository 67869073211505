<script setup>
import { useRoute, useRouter } from 'vue-router';
import { IconHawkChevronLeft } from '~/common/components/molecules/hawk-icons/icons.js';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';
import Checklist from '~/tasks/pages/task-details-new/left-section/task-checklists/task-details-checklist.vue';

import { useChecklistStore } from '~/tasks/store/checklists.store.js';

const route = useRoute();
const router = useRouter();

const { checkTaskPermission } = useTasksPermissions();

const checklist_store = useChecklistStore('checklist-templates');

const is_loading = ref(false);

onMounted(async () => {
  is_loading.value = true;
  if (!checklist_store.checklists.length)
    await checklist_store.set_checklists();
  is_loading.value = false;
});

const checklist_data = computed(() => {
  return checklist_store.get_current_checklist(route.params.checklist_id) || {};
});

const breadcrumbs = [
  {
    name: 'Tasks',
    label: 'Tasks',
    to: { name: 'tasks' },
  },
  {
    name: 'Templates',
    label: 'Templates',
    to: { name: 'task-templates' },
  },
  {
    name: 'Checklists',
    label: 'Checklists',
    to: { name: 'checklistTemplates' },
  },
];

const goBack = () => router.back();
function handleBreadCrumbNav(item) {
  router.push(item.to);
}
provide('is-checklist-template', true);
</script>

<template>
  <div v-if="is_loading" class="h-[calc(100vh_-_65px)] flex items-center justify-center">
    <hawk-loader />
  </div>
  <div v-else-if="checklist_data" class="p-4">
    <hawk-breadcrumbs
      class="pb-4 border-b"
      :items="[...breadcrumbs, { name: checklist_data.name, label: checklist_data.name, disabled: true }]"
      @click="handleBreadCrumbNav"
    />
    <div class="p-3 border-b">
      <div class="inline-flex items-center cursor-pointer" @click="goBack">
        <IconHawkChevronLeft class="text-gray-600 w-5" />
        <p class="text-lg font-semibold text-gray-900 ml-4">
          {{ checklist_data.name }}
        </p>
      </div>
    </div>
    <div>
      <p class="text-lg font-semibold text-gray-900 my-3">
        {{ $t('Checklist') }}
      </p>
      <Checklist
        :checklist="checklist_data"
        :can_modify="checkTaskPermission({ permission: 'can_modify_templates' })"
        :can_modify_status="false"
        :checklist_length="1"
        @deleted="goBack"
      />
    </div>
  </div>
</template>
