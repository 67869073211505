<script setup>
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkDotsVertical, IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import FeatureTypePopup from '~/terra/components/feature-type-groups/feature-type-popup.vue';
import FtgPopup from '~/terra/components/feature-type-groups/ftg-popup.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  action_type: {
    type: String,
    default: 'menu',
  },
  ftg: {
    type: Object,
  },
  inactive_feature_types: {
    type: Array,
  },
});

const $t = inject('$t');
const $toast = inject('$toast');
const route = useRoute();
const authStore = useAuthStore();
const terra_store = useTerraStore();

const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    onCancel() {
      delete_popup.close();
    },
  },
});
const ftg_popup = useModal({
  component: FtgPopup,
  attrs: {
    onCancel() {
      ftg_popup.close();
    },
  },
});
const edit_feature_popup = useModal({
  component: FeatureTypePopup,
  attrs: {
    onCancel() {
      edit_feature_popup.close();
    },
  },
});

function openFtg(ftg = null) {
  const features_list = [];
  ftg_popup.patchOptions({
    attrs: {
      ftg,
      features_list,
      inactive_feature_types: props.inactive_feature_types,
      onDeleteFtg(ftg) {
        triggerPopup(ftg.name, $t('Are you sure you want to delete the group?'), 'ftg', ftg);
      },
      onDeleteFeature(data) {
        triggerPopup(data.feature.name, $t('Are you sure you want to delete the feature type?'), 'feature', data.feature, data.ftg);
      },
      async on_save(name) {
        let group_id = null;
        if (!ftg) {
          group_id = await terra_store.update_ftg({
            action: 'add',
            container_uid: route.params.id,
            data: {
              name,
              org: authStore.current_organization?.uid,
            },
          });
          terra_store.terra_track_events('Create class group');
          $toast({
            text: 'Feature group created successfully',
            type: 'success',
          });
        }
        else {
          await terra_store.update_ftg({
            action: 'update',
            group_uid: ftg.uid,
            data: {
              name,
              org: authStore.current_organization?.uid,
            },
          });
          $toast({
            text: 'Feature group updated successfully',
            type: 'success',
          });
        }
        return group_id;
      },
    },
  });
  ftg_popup.open();
}
function openFeature(ftg, feature = null, options = {}) {
  edit_feature_popup.patchOptions({
    attrs: {
      feature,
      ftg,
      ...options,
      onClose() {
        edit_feature_popup.close();
      },
      onDelete() {
        deleteFeature(ftg, feature);
      },
      async onAddFeature(data) {
        const feature = data;
        feature.order_index = ftg?.featureTypes?.length || 0;
        feature.featureTypeGroup = ftg.uid;
        await terra_store.update_feature_type({
          feature_type_uid: null,
          group_uid: ftg.uid,
          data: feature,
          action: 'add',
        });
      },
    },
  });
  edit_feature_popup.open();
}
function triggerPopup(header, content, type, item, group) {
  delete_popup.patchOptions({
    attrs: {
      header,
      content,
      onClose() {
        delete_popup.close();
      },
      confirm: async () => {
        try {
          if (type === 'ftg') {
            await terra_store.update_ftg({
              action: 'delete',
              group_uid: item.uid,
            });
            ftg_popup.close();
          }
          else {
            await terra_store.update_feature_type({
              feature_type_uid: item.uid,
              action: 'delete',
              group_uid: group.uid,
            });
          }
          delete_popup.close();
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  delete_popup.open();
}

function getItems(ftg) {
  return [
    {
      label: $t('Edit'),
      on_click: () => { openFtg(ftg); },
    },
    // {
    //   icon: IconHawkPencilLine,
    //   label: $t('Rename'),
    //   on_click: () => { openFtg(props.ftg); },
    // },
    {
      label: $t('Delete'),
      on_click: () => { triggerPopup(ftg.name, $t('Are you sure you want to delete the group?'), 'ftg', ftg); },
    },
  ];
}
</script>

<template>
  <HawkMenu
    v-if="action_type === 'menu'"
    :has_bordered_trigger="false"
    additional_trigger_classes="!ring-0 p-0 -mr-2.5 mt-1.5"
    additional_dropdown_classes="-ml-[136px] !w-[160px]"
    position="fixed"
  >
    <template #trigger>
      <IconHawkDotsVertical class="w-4 h-4" />
    </template>
    <template #content>
      <div class="p-0.5">
        <div v-for="(item, i) in getItems(ftg)" :key="i" class="flex items-center p-2.5 hover:bg-gray-50" @click="item.on_click">
          <div class="text-sm font-medium">
            {{ item.label }}
          </div>
        </div>
      </div>
    </template>
  </HawkMenu>
  <slot v-else name="action_trigger_btn" :actions="{ openFtg, openFeature }">
    <HawkButton icon type="light" color="gray" @click="openFtg()">
      <IconHawkPlus class="w-5 h-5 text-gray-700" />
    </HawkButton>
  </slot>
</template>
