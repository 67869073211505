<script setup>
import { IconHawkCheckCircle } from '~/common/components/molecules/hawk-icons/icons.js';
import { useThermStore } from '../store/therm.store';

const props = defineProps({
  side: {
    type: String,
    default: 'left',
  },
  therm_store_name: {
    type: String,
    default: 'therm',
  },
});

const therm_store = useThermStore(props.therm_store_name);
const map_layers = computed(() => therm_store.map_layers);
const active_group = computed(() => therm_store.active_group);
const active_projects = computed(() => therm_store.active_projects);
const filtered_layers = computed(() => {
  const layers = ['thermal'];
  active_projects.value?.forEach((project) => {
    if (
      project?.reports?.orthoTiles_visual?.tile_url
      && !layers.includes('visual')
    ) {
      layers.unshift('visual');
    }
    if (
      project?.reports?.orthoTiles_cad?.tile_url
      && !layers.includes('cad')
    ) {
      layers.unshift('cad');
    }
  });
  return layers;
});

function toggleLayers(key) {
  therm_store.set_map_layer({
    ...map_layers.value,
    [key]: !map_layers.value[key],
  });
  Object.values(active_group.value.projects).forEach((item) => {
    if (item.is_active) {
      therm_store.set_project_ortho({
        project: item,
      });
    }
  });
}
</script>

<template>
  <div :class="side === 'left' ? 'absolute z-10 bottom-2 left-3 flex flex-col gap-2' : 'absolute z-10 bottom-2 right-3 flex flex-col gap-2'">
    <div
      v-for="item in filtered_layers"
      :key="item"
      v-tippy="{ item, placement: 'right' }"
      @click="toggleLayers(item)"
    >
      <div v-tippy="item" class="rounded-[5px] p-0 border border-solid border-white h-9 w-9 cursor-pointer flex justify-center items-center">
        <img v-if="item === 'thermal'" src="./icons/therm-thermal-layer.png" class="h-9 w-9 rounded-[5px] p-0" alt="thermal layer">
        <img v-if="item === 'visual'" src="./icons/therm-visual-layer.png" class="h-9 w-9 rounded-[5px] p-0" alt="visual layer">
        <img v-if="item === 'cad'" src="./icons/therm-cad-layer.png" class="h-9 w-9 rounded-[5px] p-0" alt="cad layer">
        <div v-if="map_layers[item]" class="absolute text-sm flex justify-center items-center rounded-full mt-1 w-4 h-4 bg-[#1570EF]">
          <IconHawkCheckCircle class="text-white" />
        </div>
      </div>
    </div>
  </div>
</template>
