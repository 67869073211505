<script setup>
import { filter } from 'lodash-es';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import EmailInput from '~/forms/components/form-workflows/sidebar/email-block/email-workflow-input.vue';
import { STATIC_EMAIL_VARIABLES } from '~/forms/constants';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
  popup_title: {
    type: String,
  },
  is_popup: {
    type: Boolean,
    default: false,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['save']);
const $t = inject('$t');
const route = useRoute();
const form_workflow_store = useFormWorkflowStore();
const common_store = useCommonStore();

const columns = ref({
  lg: {
    container: 12,
    label: 3,
    wrapper: 12,
  },
});
const form_data = ref({});
const show_cc = ref(false);
const show_bcc = ref(false);
const subject_body_data = ref({
  subject: '',
  body: '',
});

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        ...(!props.is_popup && {
          name: 'Send an Email',
          description:
                  'Send customized emails to specific people manually or use dynamic fields',
        }),
        emails: [],
        cc: [],
        bcc: [],
        subject: '',
        body: '',
      };
});
const users_data = computed(() => {
  const asset_id = route.params.asset_id;
  // Using scoped members
  const users_list = filter(common_store.scope_users(asset_id), item => item.status !== 'deprovisioned');
  const members = users_list?.map((user) => {
    return {
      name: user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.email,
      value: user.uid,
      type: 'member',
      is_reviewer: true,
    };
  });
  const teams = common_store.scope_teams(asset_id)?.map((team) => {
    return {
      name: team.name,
      value: team.uid,
      type: 'team',
      is_reviewer: true,
    };
  });

  return [
    {
      label: 'Members',
      items: members?.value ? members.value : members,
    },
    {
      label: 'Teams',
      items: teams?.value ? teams.value : teams,
    },
  ];
});

const get_email_fields = computed(() => {
  return form_workflow_store.get_fields(props.step_number, ['email', 'member']);
});
const email_options = computed(() => {
  const normal_fields = get_email_fields.value.map(item => ({ name: item.name, value: item.slug, type: 'field' }));
  const static_fields = STATIC_EMAIL_VARIABLES.map(item => ({ name: item.name, value: item.id, type: 'static_field' }));
  return [
    ...users_data.value,
    {
      label: 'Fields',
      items: [...static_fields, ...normal_fields],
    },
  ];
});
const get_email_by_slug = computed(() => {
  const fields_by_slug = {};
  get_email_fields.value.forEach(
    field => (fields_by_slug[field.slug] = field),
  );
  return fields_by_slug;
});

const getTeams = computed(() => {
  return common_store.scope_teams(route.params.asset_id).reduce((acc, curr) => {
    acc[curr.uid] = curr;
    return acc;
  }, {});
});

const has_subject_and_body = computed(() => subject_body_data.value.subject && subject_body_data.value.body);

function getEmails(key) {
  return ({
    label: 'Emails',
    items: form_data.value[key]?.filter(email => email.type === 'email') || [],
  });
}

async function save() {
  const data = { ...form_data.value, ...subject_body_data.value };
  if (props.is_popup)
    emit('save', data);
  else if (props.submit_function)
    await props.submit_function(data);
}

function init() {
  form_data.value = default_data.value;
  subject_body_data.value = {
    subject: default_data.value.subject,
    body: default_data.value.body,
  };
  show_cc.value = default_data.value.cc.length;
  show_bcc.value = default_data.value.bcc.length;
}
init();
</script>

<template>
  <div>
    <Vueform
      v-model="form_data" :columns="columns" :sync="true" size="sm" :messages="{ required: $t('This field is required') }" :should_validate_on_mount="true" :display-errors="false" :endpoint="save"
      @change="form_workflow_store.is_sidebar_form_dirty = true"
    >
      <div class="col-span-12">
        <div class="flex justify-between items-start mb-10">
          <div class="w-80">
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Send an Email Block') }}
            </div>
            <div class="text-xs text-gray-600">
              {{ $t('Send customized emails to specific people manually or use dynamic fields') }}
            </div>
          </div>
          <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
            <IconHawkXClose class="text-gray-500" />
          </HawkButton>
        </div>
        <div :class="{ 'pointer-events-none': is_disabled }">
          <TextElement name="name" :label="$t('Name')" class="mb-5" rules="required" />
          <TextareaElement name="description" :label="$t('Description')" class="mb-5" />
          <EmailInput
            name="emails"
            :label="$t('To')"
            class="mb-2"
            :placeholder="$t('Enter email address')"
            :columns="columns"
            :items="[...email_options, getEmails('emails')]"
            :get_teams="getTeams"
            :get_email_by_slug="get_email_by_slug"
            :rules="['required']"
            @change="form_data.emails = $event"
          />
        </div>

        <div class="flex items-center justify-end mb-2">
          <HawkButton color="primary" class="mr-2" type="link" @click="show_cc = !show_cc">
            {{ $t('Cc') }}
          </HawkButton>
          <HawkButton color="primary" type="link" @click="show_bcc = !show_bcc">
            {{ $t('Bcc') }}
          </HawkButton>
        </div>
        <div :class="{ 'pointer-events-none': is_disabled }">
          <EmailInput
            v-show="show_cc"
            name="cc"
            :label="$t('Cc')"
            class="mb-4"
            :placeholder="$t('Enter cc')"
            :columns="columns"
            :items="[...email_options, getEmails('cc')]"
            :get_teams="getTeams"
            :get_email_by_slug="get_email_by_slug"
            @change="form_data.cc = $event"
          />
          <EmailInput
            v-show="show_bcc"
            name="bcc"
            :label="$t('Bcc')"
            class="mb-4"
            :placeholder="$t('Enter bcc')"
            :columns="columns"
            :items="[...email_options, getEmails('bcc')]"
            :get_teams="getTeams"
            :get_email_by_slug="get_email_by_slug"
            @change="form_data.bcc = $event"
          />
        </div>
        <EmailPopupButton
          class="my-3"
          :design_email="true"
          :form="subject_body_data"
          :step_number="step_number"
          :is_disabled="is_disabled"
          enable_document_suggestions
          @update-email="subject_body_data = $event"
        />
        <hr class="my-6">
        <div v-if="!is_disabled" class="flex justify-end">
          <ButtonElement
            :button-label="$t('Cancel')"
            class="mr-3"
            :secondary="true"
            @click="$emit('close')"
          />
          <ButtonElement
            :disabled="!has_subject_and_body"
            :button-label="$t('Save')"
            :submits="true"
          />
        </div>
      </div>
    </Vueform>
  </div>
</template>
