<script setup>
import { filter } from 'lodash-es';
import { useRoute } from 'vue-router';
import { IconHawkChevronDown, IconHawkDragIcon, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  members: {
    type: Array,
  },
  condition: {
    type: String,
  },
  rules: {
    type: Array,
    default: () => ['required'],
  },
  approvers_input_only: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const $t = inject('$t');
const route = useRoute();
const common_store = useCommonStore();

const radio_items = ref({
  AND: {
    label: $t('Require all members to approve'),
    description: $t('Review by engineering team is required as it doesn’t meet requirements'),
  },
  OR: {
    label: $t('Require any members to approve'),
    description: 'Review by engineering team is required as it doesn’t meet requirements',
  },
});

const users_data = computed(() => {
  const asset_id = route.params.asset_id;
  // TODO: replace with hawk assignee input
  const users_list = filter(common_store.scope_users(asset_id), item => item.status !== 'deprovisioned');
  const members = users_list?.map((user) => {
    return {
      name: user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.email,
      uid: user.uid,
      type: 'user',
      is_reviewer: false,
    };
  });
  const teams = common_store.scope_teams(asset_id)?.map((team) => {
    return {
      name: team.name,
      uid: team.uid,
      type: 'team',
      is_reviewer: false,
    };
  });

  return [
    {
      label: 'Members',
      items: members,
    },
    {
      label: 'Teams',
      items: teams,
    },
  ];
});

const members_data = ref(props.members);
const add_members_data = ref([]);

const getMaxCondition = computed(() => `max:${String(props.members?.length || 1)}`);

function getMembersData() {
  return add_members_data.value.filter(data => !members_data.value.map(val => val.uid).includes(data.uid));
}

function handleRemove(option) {
  add_members_data.value = add_members_data.value.filter(val => val.uid !== option.uid);
  emit('change', add_members_data.value);
}

function updateMembers(e) {
  add_members_data.value = e;
}

watch(members_data, (val) => {
  emit('change', val);
}, { deep: true });
</script>

<template>
  <div>
    <RadiogroupElement
      v-if="!approvers_input_only"
      name="condition"
      class="mb-3.5"
      :add-classes="{
        RadiogroupRadio: {
          container: 'mb-2.5',
        },
      }"
      :items="radio_items"
    />
    <div class="flex justify-between mb-5">
      <MultiselectElement
        :key="rules.length"
        class="w-[340px] mr-2"
        name="members"
        :placeholder="$t('Add Approvers / Reviewers')"
        :items="users_data"
        :object="true"
        :native="false"
        :group-hide-empty="true"
        :group-select="false"
        :close-on-select="false"
        :groups="true"
        :can-clear="false"
        :search="true"
        track-by="name"
        value-prop="uid"
        label-prop="name"
        :rules="rules"
        :messages="{
          required: $t('At least one member should be assigned to the step'),
        }"
        @change="updateMembers"
      >
        <template #multiple-label>
          <div class="flex flex-wrap items-start w-[336px] p-2">
            <template v-if="getMembersData().length">
              <div v-for="option in getMembersData()" :key="option.uid">
                <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
                  <HawkMembers :members="option.uid" size="badge" type="label" :name_truncate_length="20" />
                  <div class="cursor-pointer ml-1 relative" @mousedown.prevent="handleRemove(option)">
                    <IconHawkXClose class="text-gray-400 w-4 h-4" />
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-gray-500 text-sm">
                {{ $t('Add Approvers / Reviewers') }}
              </div>
            </template>
          </div>
        </template>
        <template #option="{ option }">
          <HawkMembers :members="option.uid" size="badge" type="label" :name_truncate_length="20" />
        </template>
      </MultiselectElement>
      <HawkButton type="outlined" @click="() => (members_data = add_members_data)">
        {{ $t('Add') }}
      </HawkButton>
    </div>

    <draggable
      v-if="members_data.length"
      :list="members_data"
      class="min-w-full mb-3 -mt-2"
      tag="div"
      draggable=".is_draggable"
      handle=".move"
    >
      <template #item="{ element: member, index: i }">
        <div
          class="flex items-center group justify-between p-1 my-2 text-sm cursor-pointer hawk_table_menu_item  w-min-full is_draggable"
        >
          <div class="flex items-center text-sm">
            <IconHawkDragIcon class="invisible group-hover:visible cursor-move move flex w-3.5 h-3.5  text-gray-400 mr-2" />
            <div class="text-sm font-medium">
              <HawkMembers :members="member.uid" size="badge" type="label" />
            </div>
          </div>
          <div class="flex items-center">
            <HawkMenu
              additional_trigger_classes="!ring-0"
              :items="[
                {
                  label: $t('Approver'),
                  on_click: () => (members_data[i].is_reviewer = false),
                },
                {
                  label: $t('Reviewer'),
                  on_click: () => (members_data[i].is_reviewer = true),
                },
                {
                  label: $t('Remove'),
                  on_click: () => (members_data.splice(i, 1)),
                },
              ]"
            >
              <template #trigger>
                <div class="flex items-center">
                  <div
                    class="cursor-pointer text-sm font-semibold text-gray-500"
                  >
                    {{ member.is_reviewer ? $t('Reviewer') : $t('Approver') }}
                  </div>
                  <div class="ml-1.5">
                    <IconHawkChevronDown class="text-gray-600 w-4 h-4" />
                  </div>
                </div>
              </template>
            </HawkMenu>
          </div>
        </div>
      </template>
    </draggable>

    <template v-if="!approvers_input_only">
      <div v-show="condition === 'OR'" class="flex items-center">
        <TextElement
          :key="members_data.length"
          :label="$t('Minimum approvals')"
          name="min_approvals"
          input-type="number"
          class="mb-5"
          :default="1"
          :rules="[
            'required',
            'integer',
            'min:1',
            getMaxCondition,
          ]"
          autocomplete="off"
          @input="$event => $emit('validateNumberInput', { name: 'min_approvals', e: $event })"
        >
          <template #after>
            <div class="text-xs text-gray-500 mt-1">
              {{ $t('Number of members required to approve before proceeding.') }}
            </div>
          </template>
        </TextElement>
      </div>
      <div v-show="condition === 'AND' && members_data.filter(val => val.type === 'user').length > 1" class="flex items-center justify-between mb-5">
        <div class="text-sm font-medium">
          {{ $t('Send approvals sequentially') }}
        </div>
        <ToggleElement
          name="is_sequential"
          default="true"
        />
      </div>
    </template>
  </div>
</template>
