<script setup>
import { IconHawkCheck, IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Add item',
  },
  name: {
    type: String,
    required: true,
  },
  optional: {
    type: Boolean,
  },
  input_classes: {
    type: Object,
    default: () => {
      return { input: 'text-sm' };
    },
  },
  allow_multiple: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'update']);
const form$ = ref(null);

const items_list = ref([]);
const create_multiple = ref(false);

function handlePaste(e) {
  items_list.value = e;
  create_multiple.value = true;
  updateName();
}
function handleClear() {
  items_list.value = [];
  create_multiple.value = false;
  form$.value.reset();
}
function updateName() {
  if (props.allow_multiple) {
    if (items_list.value.length && create_multiple.value)
      emit('update', items_list.value);
    else
      emit('update', [form$.value.data.item.name]);
    form$.value.reset();
    return;
  }
  emit('update', form$.value.data.item.name);
  form$.value.reset();
  items_list.value = [];
  create_multiple.value = false;
}

onMounted(() => {
  if (props.name)
    form$.value.load({ item: { name: props.name } }, true);

  form$.value.elements$.item.focus();
});
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      size="sm"
      validate-on="step"
      :endpoint="updateName"
      :display-errors="false"
      :should_validate_on_mount="false"
      :format-load="(data) => data"
    >
      <ObjectElement
        name="item"
      >
        <hawk-multiline-input
          v-if="allow_multiple"
          :options="{
            name: 'name',
            label: '',
            placeholder,
            rules: props.optional ? [] : ['required'],
            columns: { default: 11, sm: 11 },
          }"
          :clear_on_confirm="true"
          @create="handlePaste"
          @clear="handleClear"
        />
        <TextElement
          v-else
          name="name"
          autocomplete="off"
          :placeholder="props.placeholder"
          :rules="props.optional ? [] : ['required']"
          :columns="{ default: 11, sm: 11 }"
          :add-classes="props.input_classes"
        />
        <ObjectElement name="btn_group" :columns="{ default: 1, sm: 1 }">
          <ButtonElement
            :columns="{ default: 4, sm: 4 }"
            name="submit"
            :submits="true"
            :override-classes="{
              ElementLayout: {
                innerWrapper: '',
                innerContainer: '',
                outerWrapper: '',
                container: 'flex items-center justify-center',
                container_sm: '',
              },
              ButtonElement: {
                button: '',
                button_primary: '',
              },
            }"
          >
            <IconHawkCheck class="text-blue-500 cursor-pointer w-4.5" />
          </ButtonElement>

          <ButtonElement
            name="close"
            :columns="{ default: 4, sm: 4 }"
            :submits="true"
            :override-classes="{
              ElementLayout: {
                innerWrapper: '',
                innerContainer: '',
                outerWrapper: '',
                container: 'flex items-center justify-center',
                container_sm: '',
              },
              ButtonElement: {
                button: '',
                button_primary: '',
              },

            }"
          >
            <IconHawkX class="text-gray-500 cursor-pointer w-5 h-5" @click.stop="emit('close')" />
          </ButtonElement>
        </ObjectElement>
      </ObjectElement>
    </Vueform>
  </div>
</template>
