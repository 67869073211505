<script setup>
import { storeToRefs } from 'pinia';
import { IconHawkFullScreen, IconHawkMinus, IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const project_management_store = useProjectManagementStore();

const { active_view, is_fullscreen } = storeToRefs(project_management_store);
const zoom_level = computed(() => active_view.value.data.zoom_level);

const { set_view_dirtiness, modify_config } = project_management_store;

function onZoomOutClicked() {
  if (zoom_level.value > 0) {
    modify_config({ key: 'zoom_level', value: zoom_level.value - 1 });
    set_view_dirtiness(true);
    gantt.render();
  }
}

function onZoomInClicked() {
  if (zoom_level.value < 4) {
    modify_config({ key: 'zoom_level', value: zoom_level.value + 1 });
    set_view_dirtiness(true);
    gantt.render();
  }
}

function onFullscreenClicked() {
  if (is_fullscreen.value) {
    document.exitFullscreen();
  }
  else {
    const element = document.getElementById('pm-fullscreen-container');
    if (element.requestFullscreen)
      element.requestFullscreen();

    else if (element.mozRequestFullScreen)
      element.mozRequestFullScreen();

    else if (element.webkitRequestFullscreen)
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);

    else if (element.msRequestFullscreen)
      element.msRequestFullscreen();
  }
}
</script>

<template>
  <div class="flex items-center h-9 border border-gray-300 shadow-sm rounded-lg">
    <div
      class="cursor-pointer p-2"
      :class="{ 'pointer-events-none opacity-40': zoom_level <= 0 }"
      @click="onZoomOutClicked"
    >
      <IconHawkMinus class="w-5 h-5 text-gray-600" />
    </div>
    <div
      class="cursor-pointer p-2"
      :class="{ 'pointer-events-none opacity-40': zoom_level >= 4 }"
      @click="onZoomInClicked"
    >
      <IconHawkPlus class="w-5 h-5 text-gray-600" />
    </div>
    <div class="cursor-pointer p-2" @click="onFullscreenClicked">
      <IconHawkFullScreen class="w-5 h-5 text-gray-600" />
    </div>
  </div>
</template>
