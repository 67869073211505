<script setup>
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import { IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';
import PmEditCustomField from '~/project-management/components/pm-edit-custom-field.vue';
import { getGanttColumns } from '~/project-management/constants/pm-gantt-column';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const emit = defineEmits(['close']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_schedule, active_view, is_fullscreen } = storeToRefs(project_management_store);
const {
  set_view_dirtiness,
  modify_config,
} = project_management_store;

const state = reactive({
  all_columns: [],
  active_columns: [],
});

const new_field_modal = useModal({
  component: PmEditCustomField,
  attrs: {
    onClose() {
      new_field_modal.close();
    },
  },
});

onMounted(() => initialize());

watch(() => active_schedule.value.custom_fields, initialize);

function initialize() {
  const columns = getGanttColumns($t);

  if (active_schedule.value.has_activity_weightages) {
    const general_group = columns.find(column => column.name === 'general');
    general_group.children.push({
      name: 'weight',
      label: $t('Weight'),
      type: 'number',
      align: 'center',
      min_width: 80,
      width: 80,
      resize: true,
    });
  }

  if (active_schedule.value.baseline) {
    const dates_group = columns.find(column => column.name === 'dates');
    const durations_group = columns.find(column => column.name === 'durations');
    dates_group.children.push(
      {
        name: 'bl_start',
        label: $t('Baseline Start'),
        group: 'dates',
        group_label: $t('Dates'),
        type: 'date',
        align: 'center',
        min_width: 75,
        resize: true,
      },
      {
        name: 'bl_finish',
        label: $t('Baseline Finish'),
        group: 'dates',
        group_label: $t('Dates'),
        type: 'date',
        align: 'center',
        min_width: 80,
        resize: true,
      },
    );
    durations_group.children.push(
      {
        name: 'bl_duration',
        label: $t('Baseline Duration'),
        group: 'durations',
        group_label: $t('Durations'),
        type: 'number',
        align: 'center',
        min_width: 90,
        resize: true,
      },
    );
  }

  const activity_codes = active_schedule.value.activity_codes;
  if (activity_codes.length) {
    columns.push({
      name: 'activity_codes',
      label: $t('Activity Codes'),
      children: [],
    });
  }
  const activity_codes_group = columns.find(column => column.name === 'activity_codes');

  for (const code of activity_codes) {
    activity_codes_group.children.push({
      name: `activity_code_${code.name}`,
      label: code.name,
      min_width: 65,
      width: 65,
      resize: true,
    });
  }

  const custom_fields = active_schedule.value.custom_fields || {};
  if (Object.keys(custom_fields).length) {
    columns.push({
      name: 'custom_fields',
      label: $t('Custom Fields'),
      children: [],
    });
  }
  const custom_fields_group = columns.find(column => column.name === 'custom_fields');
  for (const code of Object.keys(custom_fields)) {
    custom_fields_group.children.push({
      name: `custom_field_${code}`,
      label: code,
      min_width: 65,
      width: 65,
      resize: true,
      custom_type: custom_fields[code].type,
      ...(custom_fields[code].type !== 'text' ? { align: 'center' } : {}),
    });
  }

  state.active_columns = [...active_view.value.data.columns];

  state.all_columns = [...columns];
}

function saveColumns(close, event) {
  if (event.is_save_clicked) {
    modify_config({
      key: 'columns',
      value: event?.is_new_field_modal_opened ? state.active_columns : event.active_columns,
    });
    set_view_dirtiness(true);
  }
  else {
    state.active_columns = event.active_columns;
  }
  if (close)
    emit('close');
}

function openNewFieldModal() {
  saveColumns(false, {
    is_save_clicked: true,
    is_new_field_modal_opened: true,
  });
  new_field_modal.open();
}
</script>

<template>
  <HawkColumnsSelector
    :all_columns="state.all_columns"
    :active_columns="state.active_columns"
    :texts="{
      heading: $t('Customize columns'),
      left_heading: $t('Available fields'),
      right_heading: $t('Added fields'),
    }"
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }"
    :is_nested="true"
    :allow_multiple_open_groups="false"
    @close="emit('close')"
    @save="$event => saveColumns($event.is_save_clicked, $event)"
  >
    <template #additional_content>
      <HawkButton type="text" @click="openNewFieldModal">
        <IconHawkPlus class="text-gray-600" />
        <span class="text-sm font-semibold text-gray-600">
          {{ $t('New Field') }}
        </span>
      </HawkButton>
    </template>
  </HawkColumnsSelector>
</template>
