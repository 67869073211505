<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkCreateForm, IconHawkCreateTask } from '~/common/components/molecules/hawk-icons/icons.js';

const emit = defineEmits(['createTask', 'createForm']);
const $t = inject('$t');
const $form_create_permission = inject('$form_create_permission');
const route = useRoute();
const auth_store = useAuthStore();
const menu_items = computed(() => [
  {
    title: $t('Create task'),
    icon: IconHawkCreateTask,
    action: 'createTask',
    disabled: !auth_store.check_permission('create_tasks', route.params.asset_id),
  },
  ...($form_create_permission?.value?.state === 'hidden'
    ? []
    : [{
        title: $t('Create form'),
        icon: IconHawkCreateForm,
        action: 'createForm',
        disabled: $form_create_permission?.value?.state === 'disabled',
        tooltip: $form_create_permission?.value?.tooltip,
      }]
  ),

]);
</script>

<template>
  <div class="w-[240px] p-1 bg-white rounded-md ">
    <div
      v-for="item in menu_items" :key="item.action" v-tippy="item.tooltip || ''"
      class="flex items-center p-2 hover:bg-gray-50 rounded-lg hover:text-gray-700 cursor-pointer"
      :class="{ 'pointer-events-none opacity-70': item.disabled }"
      @click="emit(item.action)"
    >
      <component :is="item.icon" class="text-gray-500 hover:text-gray-700 mr-2.5" />  <div class="text-[14px]">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
