<script setup>
import { IconHawkInfoCircle } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  csv_columns: {
    type: Array,
    required: true,
  },
  schema_columns: {
    type: Object,
    required: true,
  },
  mapping: {
    type: Object,
    required: true,
  },
  errors: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits('scrollTo');
const show_error_palette = ref(false);
const error_count = computed(() => {
  return Object.values(props.errors).filter(error_obj => !error_obj.is_valid).length;
});
const sorted_errors = computed(() => {
  let errors = {};
  Object.keys(props.errors).sort((a, b) => Number(a.split('-')[0]) > Number(b.split('-')[0])).forEach(key => errors = { ...errors, [key]: props.errors[key] });
  return errors;
});
function getCoord(coord_string) {
  const keys = coord_string.split('-');
  return [Number(keys[0]), Number(keys[1])];
}
</script>

<template>
  <div class="relative">
    <HawkFeaturedIcon
      theme="light-circle-outline"
      color="error"
      size="md"
      class="cursor-pointer"
      @click="show_error_palette = !show_error_palette"
    >
      <IconHawkInfoCircle />
    </HawkFeaturedIcon>
    <div
      v-if="show_error_palette"
      v-click-outside="() => show_error_palette = false"
      class="absolute top-[20px] right-[50px] z-999 w-[400px] rounded-md px-4 py-2 border border-gray-200 bg-white shadow-md"
    >
      <p class="font-semibold text-error-600 text-sm">
        Fix ({{ error_count }}) errors to proceed with import
      </p>
      <p class="font-medium text-gray-500 text-xs mb-2">
        Click to navigate to the error cell
      </p>
      <ul class="list-disc max-h-[350px] overflow-scroll">
        <template v-for="(value, key) in sorted_errors" :key="key">
          <li
            v-if="value.is_valid === false"
            class="text-sm text-gray-600 hover:text-gray-900 hover:underline mb-1 cursor-pointer"
            @click="emit('scrollTo', getCoord(key))"
          >
            <b>Row {{ getCoord(key)[0] + 1 }}</b>: {{ schema_columns[mapping[csv_columns[getCoord(key)[1]]]].label }} {{ value.message }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
