<script setup>
import { storeToRefs } from 'pinia';
import { IconHawkChevronDown, IconHawkChevronUp, IconHawkLoadingCircle, IconHawkSearchMd, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['expandAll']);
const project_management_store = useProjectManagementStore();
const { search_config, is_searching } = storeToRefs(project_management_store);

function setCurrentMatchIndex(index) {
  const new_index = search_config.value.match_count ? index : -1;
  if (search_config.value.current_match_index === new_index && search_config.value.search_results[new_index])
    return gantt.showTask(search_config.value.search_results[new_index]);

  search_config.value.current_match_index = new_index;
  gantt.render();
}

function findPrevious() {
  if (!search_config.value.match_count)
    return;
  const next = search_config.value.current_match_index - 1;
  setCurrentMatchIndex(next < 0 ? search_config.value.match_count - 1 : next);
}

function findNext(event) {
  if (!search_config.value.match_count)
    return;
  if (event?.shiftKey) {
    findPrevious();
    return;
  }
  const next = search_config.value.current_match_index + 1;
  setCurrentMatchIndex(next === search_config.value.match_count ? 0 : next);
}
</script>

<template>
  <Vueform v-model="search_config" size="sm" sync>
    <TextElement
      name="search_string"
      :placeholder="$t('Search')"
      class="w-64"
      @input="emit('expandAll')"
      @keyup.enter="findNext"
    >
      <template #addon-before>
        <IconHawkSearchMd class="h-5 w-5 text-gray-600" aria-hidden="true" />
      </template>
      <template #addon-after>
        <div v-if="is_searching" class="flex items-center ml-2">
          <IconHawkLoadingCircle class="animate-spin h-4 w-4 ml-2" />
        </div>
        <div v-else-if="search_config.search_string" class="flex items-center ml-2">
          <template v-if="search_config?.match_count > 0">
            <span class="text-xs text-gray-900 whitespace-nowrap font-medium">
              {{ search_config.current_match_index + 1 }}/{{ search_config.match_count }}
            </span>
            <IconHawkChevronUp class="h-4 w-4 ml-2 text-black cursor-pointer" @click="findPrevious" />
            <IconHawkChevronDown class="h-4 w-4 ml-2 text-black cursor-pointer" @click="findNext" />
          </template>
          <IconHawkXClose class="h-4 w-4 ml-2 text-black cursor-pointer" @click="search_config.search_string = ''" />
        </div>
      </template>
    </TextElement>
  </Vueform>
</template>
