<script setup>
import { uniq } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { IconHawkWatcher, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import FormStatus from '~/forms/atoms/form-status.vue';
import EditData from '~/forms/atoms/form-text-input-update.vue';
import FormDetailHeaderBreadcrumbs from '~/forms/components/form-detail/form-detail-header-breadcrumbs.vue';
import FormDetailHeaderDropdown from '~/forms/components/form-detail/form-detail-header-dropdown.vue';
import WatchersPopup from '~/tasks/components/organisms/watchers-popup.vue';

const props = defineProps({
  is_preview_mode: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);
const form_detail_store = inject('form_detail_store');
const $t = inject('$t');
const $toast = inject('$toast');
const router = useRouter();
const route = useRoute();
const form_name_ref = ref(null);
// status column
async function updateFormName(name) {
  const payload = {
    uid: form_detail_store.form_detail.uid,
    name,
  };
  await form_detail_store.update_form_details({ body: payload });
  form_detail_store.forms_list_store.forms_track_events('Name updated', form_detail_store.form_detail.uid, { mode: 'Single', count: 1 });
}
const forms_detail = computed(() => form_detail_store.form_detail);
const is_submitted = forms_detail.value?.status?.submission_status === 'submitted';
const is_disabled = computed(() => !forms_detail.value.can_modify || props.is_preview_mode || is_submitted || forms_detail.value.is_approval_pending);

const { open: openWatchersPopup, close: closeWatchersPopup, patchOptions } = useModal({
  component: WatchersPopup,
});

function handleWatchers() {
  patchOptions({
    attrs: {
      module: 'form',
      watchers: forms_detail.value.watchers.map(watcher_id => ({ uid: watcher_id })),
      viewed: forms_detail.value.viewed || {},
      can_modify: forms_detail.value.can_modify,
      asset_id: forms_detail.value?.target_element?.asset,
      onClose() {
        closeWatchersPopup();
      },
      async on_update(payload) {
        try {
          // INFO: Backend need array of string(watcher uid) as payload
          const watchers = uniq(payload.watchers.map(watcher => watcher.uid));
          await form_detail_store.update_form_details({ body: { watchers } });
          form_detail_store.forms_list_store.forms_track_events('Watchers updated', forms_detail.value.uid, { mode: 'Single', count: 1 });
        }
        catch (err) {
          logger.error(err);
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
        closeWatchersPopup();
      },
    },
  });
  openWatchersPopup();
}
</script>

<template>
  <div class="flex justify-between items-center px-8 h-24 border-b">
    <slot v-if="$slots.header_left" name="header_left" />
    <div v-else>
      <div class="flex text-lg flex-col justify-start">
        <slot name="title_text">
          <FormDetailHeaderBreadcrumbs class="pb-1" />
          <EditData
            ref="form_name_ref" :data="form_detail_store?.form_detail?.name" type="name" :can_modify="!is_submitted"
            text_default="Name" :save="updateFormName" class="w-[100%] font-semibold"
          />
        </slot>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <slot name="header_right" />
      <div v-if="!props.is_preview_mode && forms_detail.can_modify" class=" mx-3 cursor-pointer relative hover:bg-gray-50 rounded-md">
        <div v-if="forms_detail.watchers.length" class="badge">
          {{ forms_detail.watchers.length }}
        </div>
        <hawk-button
          icon
          type="text"
          @click="handleWatchers"
        >
          <IconHawkWatcher class="h-5 w-6 text-gray-500" />
        </hawk-button>
      </div>
      <FormStatus v-if="!props.is_preview_mode" :item="form_detail_store?.form_detail.status" custom_class="content-center text-sm rounded-lg px-2 py-4 inline-flex text-xs whitespace-nowrap font-medium" />
      <FormDetailHeaderDropdown v-if="!props.is_preview_mode" :form_detail_store="form_detail_store" :form_name_ref="form_name_ref" :is_disabled="is_disabled" :is_preview="props.is_preview_mode" :is_submitted="is_submitted" />

      <hawk-button
        icon
        type="text"
        @click="emit('close')"
      >
        <IconHawkXClose class="text-gray-600" />
      </hawk-button>
    </div>
  </div>
</template>
