<script setup>
import { IconHawkCheck, IconHawkCross } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  focus: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['addNotes']);

const form$ = ref(null);
const edit_note = ref(false);

onMounted(() => {
  form$.value.load(props.item);
  if (props.focus) {
    edit_note.value = true;
    setTimeout(() => form$.value.elements$.note.focus(), 0);
  }
});

function addNotesToItem() {
  const form_data = form$.value.data;
  emit('addNotes', form_data.note);
  edit_note.value = false;
}

function revert() {
  form$.value.load(props.item);
  edit_note.value = false;
}
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      size="sm"
    >
      <EditorElement
        v-click-outside="() => revert()"
        name="note"
        :debounce="500"
        :hide-tools="['bold', 'italic', 'strike', 'link', 'heading', 'quote', 'code', 'bullet-list', 'number-list', 'decrease-nesting', 'increase-nesting', 'attach', 'undo', 'redo']"
        :override-classes="{
          ElementLayout: {
            outerWrapper: 'flex flex-wrap',
            innerWrapper: 'rounded-lg shadow-box-circle w-[373px]',
            innerContainer: 'flex items-center ',
          },
        }"
        @click="edit_note = true"
      >
        <template #after>
          <div v-if="edit_note" class="flex items-center mx-2">
            <IconHawkCheck class="text-blue-500 cursor-pointer mx-4 w-4.5" @click.stop="addNotesToItem" />
            <IconHawkCross class="text-gray-500 cursor-pointer w-2.5" @click.stop="revert" />
          </div>
        </template>
      </EditorElement>
    </Vueform>
  </div>
</template>
