import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkAutonumber, IconHawkCalendar, IconHawkCheckboxBase, IconHawkCheckSquare, IconHawkChevronDown, IconHawkCoinsStackedTwo, IconHawkFileFive, IconHawkFilePlusTwo, IconHawkFileTwo, IconHawkFormula, IconHawkGlobeTwo, IconHawkInfoCircle, IconHawkMailThree, IconHawkNumber, IconHawkPhone, IconHawkSignature, IconHawkToggleThreeRight, IconHawkUploadOne, IconHawkUsersOne } from '~/common/components/molecules/hawk-icons/icons.js';

export function useFormBuilderAllFields() {
  const auth_store = useAuthStore();
  const fields = [
    {
      name: 'Text Field',
      type: 'short_text',
      dataType: 'short_text',
      component: 'text-field',
      filter: { type: 'string' },
      config: { name: 'text' },
      icon: IconHawkFileFive,
    },
    {
      name: 'Number',
      type: 'number',
      dataType: 'number',
      filter: { type: 'integer' },
      component: 'number-field',
      config: { name: 'number', representation: 'Default' },
      icon: IconHawkNumber,
    },
    {
      name: 'Text Area',
      type: 'long_text',
      dataType: 'long_text',
      component: 'rich-text',
      icon: IconHawkFileTwo,
      filter: {
        type: 'string',
        input: 'textarea',
        operators: [
          'equal',
          'not_equal',
          'in',
          'not_in',
          'is_null',
          'is_not_null',
        ],
      },
      config: { name: 'Long text' },
    },
    {
      name: 'Yes/No',
      type: 'yes-no',
      dataType: 'dropdown',
      icon: IconHawkToggleThreeRight,
      filter: { type: 'string', input: 'radio', hasConfig: true },
      component: 'yes-no-field',
      config: [{ name: 'Yes' }, { name: 'No' }, { name: 'N/A' }],
    },
    {
      name: 'Checkbox',
      type: 'checkbox',
      dataType: 'label',
      component: 'checkbox-field',
      icon: IconHawkCheckSquare,
      filter: {
        type: 'string',
        multiple: true,
        input: 'checkbox',
        hasConfig: true,
        operators: [
          'equal',
          'not_equal',
          'in',
          'not_in',
          'is_null',
          'is_not_null',
        ],
      },
      config: [{ name: 'Option' }],
    },
    {
      name: 'Radio',
      type: 'radio',
      dataType: 'dropdown',
      filter: { type: 'string', input: 'radio', hasConfig: true },
      component: 'radio-field',
      config: [{ name: 'Option-1' }],
      icon: IconHawkCheckboxBase,
      icon_class: 'w-4 h-4',
    },
    {
      name: 'Dropdown',
      type: 'dropdown',
      dataType: 'dropdown',
      component: 'dropdown-field',
      icon: IconHawkChevronDown,
      filter: {
        type: 'string',
        input: 'select',
        hasConfig: true,
        operators: [
          'equal',
          'not_equal',
          'in',
          'not_in',
          'is_null',
          'is_not_null',
        ],
      },
      config: [{ name: 'Option-1' }],
    },
    {
      name: 'Member',
      type: 'members',
      dataType: 'member',
      component: 'member-field',
      config: {
        allow_multiple_selection: false,
      },
      icon: IconHawkUsersOne,
    },
    {
      name: 'File Upload',
      type: 'attachment',
      dataType: 'attachment',
      component: 'upload-field-multiple',
      config: {
        type: ['Video', 'Document', 'Spreadsheet', 'Image', 'Audio'],
        custom: 'all',
        uploadOptions: 'both',
        limitNoOfFiles: '-1',
      },
      icon: IconHawkUploadOne,
    },
    {
      name: 'Date & Time',
      type: 'date_time',
      dataType: 'date_time',
      filter: {
        type: 'datetime',
        plugin: 'datetimepicker',
        plugin_config: {
          format: 'YYYY-MM-DDTHH:mm:00.000[Z]', // 2021-04-30T03:57:00.000Z
        },
      },
      component: 'date-time-field',
      config: {
        name: 'date time',
        type: 'date',
        dateplaceholder: '',
        canSelectPastDates: true,
      },
      icon: IconHawkCalendar,
    },

    {
      name: 'Email',
      type: 'email',
      dataType: 'email',
      component: 'email-field',
      filter: { type: 'string' },
      config: { name: 'email' },
      icon: IconHawkMailThree,
    },
    {
      name: 'Phone number',
      type: 'phone',
      dataType: 'phone_number',
      filter: { type: 'string' },
      component: 'phone-field',
      config: { name: 'phone' },
      icon: IconHawkPhone,
    },
    {
      name: 'Money',
      type: 'money',
      dataType: 'decimal',
      filter: { type: 'integer' },
      component: 'money-field',
      config: { name: 'money' },
      icon: IconHawkCoinsStackedTwo,
    },
    {
      name: 'Signature',
      type: 'attachment',
      dataType: 'attachment',
      component: 'signature-field',
      mandatory: false,
      properties: {
        type: 'signature',
      },
      icon: IconHawkSignature,
    },
    {
      name: 'Website',
      type: 'url',
      dataType: 'url',
      component: 'website-field',
      filter: { type: 'string' },
      config: { name: 'website' },
      icon: IconHawkGlobeTwo,
    },
    {
      name: 'Info',
      type: 'info',
      dataType: 'long_text',
      component: 'info-field',
      mandatory: false,
      filter: {
        type: 'string',
        input: 'textarea',
        operators: [
          'equal',
          'not_equal',
          'in',
          'not_in',
          'is_null',
          'is_not_null',
        ],
      },
      config: { name: 'Long text' },
      icon: IconHawkInfoCircle,
    },
    {
      name: 'Auto Number',
      type: 'auto_number',
      dataType: 'auto_number',
      component: 'auto-numbering-field',
      config: { name: 'auto_numbering' },
      mandatory: false,
      properties: {
        calculate: 'on_submit',
        contextual: false,
        next_number: null,
        type: 'auto_numbering',
      },
      icon: IconHawkAutonumber,
    },
    ...(auth_store.check_split('multi_text_field')
      ? [
          {
            name: 'Multi text',
            type: 'multi_text',
            dataType: 'multi_text',
            component: 'multi-text-field',
            mandatory: false,
            properties: {
              type: 'multi_text',
            },
            icon: IconHawkFilePlusTwo,
          },
        ]
      : []),
    {
      name: 'Formula',
      type: 'formula',
      dataType: 'short_text',
      component: 'formula-field',
      config: { name: 'formula', type: 'formula' },
      mandatory: false,
      icon: IconHawkFormula,
    },
  ];

  const field_map = fields.reduce((obj, field) => {
    obj[field.properties?.type || field.type] = field;
    return obj;
  }, {});
  return { fields, field_map };
}
