<script setup>
import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';

import { ref } from 'vue';
import { IconHawkCalendar, IconHawkChevronDown } from '~/common/components/molecules/hawk-icons/icons.js';
import { useThermStore } from '../store/therm.store';

const props = defineProps({
  side: {
    type: String,
    default: 'left',
  },
  therm_store_name: {
    type: String,
    default: 'therm',
  },
  is_dashboard_view: {
    type: Boolean,
    default: false,
  },
  handle_toggle_group: {
    type: Function,
    default: null,
  },
});
const active = ref(false);
const is_loading = ref(false);
const therm_store = useThermStore(props.therm_store_name);

const get_active_group_name = computed(() => {
  const active_group_value = therm_store.active_group;
  if (active_group_value) {
    return (
      (active_group_value?.properties?.date
        && dayjs(active_group_value.properties.date)?.utc()?.format('DD MMM YYYY'))
        || active_group_value.name
    );
  }
  else {
    return '';
  }
});

// Methods
async function toggleGroup(item) {
  if (item.uid === therm_store.active_group?.uid) {
    active.value = false;
    return;
  }
  try {
    is_loading.value = true;
    therm_store.selected_features = [];
    if (!props.handle_toggle_group && therm_store.active_group) {
      const promises = [];
      therm_store.active_projects.forEach((element) => {
        promises.push(
          therm_store.set_therm_active_project({ project: element, is_active: false }),
        );
      });
      await Promise.all(promises);
    }
    await therm_store.set_active_group({ group: item });
    if (props.handle_toggle_group) {
      props.handle_toggle_group();
    }
    else {
      await therm_store.set_therm_active_project({
        project: sortBy(Object.values(item.projects), [
          item => item.name,
        ])[0],
        is_active: true,
      });
    }
    is_loading.value = false;
  }
  catch (err) {
    logger.log(err);
    is_loading.value = false;
  }
}
</script>

<template>
  <div class="flex justify-between items-center">
    <p v-if="!is_dashboard_view" class="text-sm whitespace-nowrap mr-2 text-gray-700 leading-5 font-medium">
      {{ $t('Scan date') }}
    </p>
    <HawkMenu position="fixed" @open="active = true" @close="active = false">
      <template #trigger>
        <HawkButton v-if="!is_dashboard_view" class="whitespace-nowrap" type="outlined" :loading="is_loading">
          <HawkText :length="12" class="text-gray-900" :content="get_active_group_name" />
          <IconHawkChevronDown v-if="active" :style="{ transform: 'rotate(180deg)' }" class="text-gray-600" />
          <IconHawkChevronDown v-else class="text-gray-600" />
        </HawkButton>
        <HawkButton v-else class="whitespace-nowrap" type="outlined" :loading="is_loading">
          <div class="flex items-center gap-x-2">
            <IconHawkCalendar class="text-gray-600" />
            <span class="text-sm text-gray-600">{{ $t('Scan date') }}:</span>
            <HawkText :length="12" class="font-semibold text-gray-900" :content="get_active_group_name" />
          </div>
        </HawkButton>
      </template>
      <template #content="{ close }">
        <div
          v-for="item in therm_store.groups_by_scan_dates"
          :key="item"
          class="p-3 w-35 z-40 cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 text-sm font-medium"
          @click="toggleGroup(item); close()"
        >
          <HawkText
            :length="12" class="font-semibold text-gray-900" :content="(item
              && item.properties
              && item.properties.date
              && dayjs(item.properties.date)?.utc()?.format('DD MMM YYYY'))
              || (item && item.name)"
          />
        </div>
      </template>
    </HawkMenu>
  </div>
</template>
