<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { IconHawkDownloadTwo, IconHawkEye, IconHawkTrashThree } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkAttachmentViewer from '~/common/components/organisms/hawk-attachment-viewer.vue';
import { truncateMiddle } from '~/common/filters/truncate.js';
import { getFileExtension } from '~/common/utils/common.utils.js';
import { useDocumentViewer } from '~/plans/composables/useDocumentViewer';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  show_file_size: {
    type: Boolean,
    default: false,
  },
  filename_truncate_length: {
    type: Number,
    default: 30,
  },
  created_at_truncate_length: {
    type: Number,
    default: 30,
  },
  can_delete: {
    default: true,
  },
  can_download: {
    type: Boolean,
    default: true,
  },
  can_view: {
    type: Boolean,
    default: true,
  },
  is_loading: {
    default: false,
  },
  is_loading_uid: {
    default: null,
  },
  variant: {
    type: String,
    default: '',
  },
  additional_classes: {
    type: String,
    default: '',
  },
  imgContainerClasses: {
    type: String,
    default: '',
  },
  showImageNameOnHover: {
    type: Boolean,
    default: false,
  },
  canScrollToFormField: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['download', 'delete', 'view', 'scrollToFormField']);

const { setup_core } = useDocumentViewer({});

const is_setting_up_document_viewer = ref(false);
const attachment_index = ref(null);
const video_supported_format = ['avi', 'mkv', 'mov', 'mp4', 'webm', 'wmv', 'wtv'];

if (!window?.Core)
  setup_core().then(() => is_setting_up_document_viewer.value = true);

function getSupportedAttachment(file) {
  const extension = getFileExtension(file.file_name);
  if (!window.Core)
    return true;

  return window.Core.SupportedFileFormats.CLIENT.includes(extension?.toLowerCase()) || video_supported_format.includes(extension?.toLowerCase());
}

const openAttachment = function (index, file) {
  if (props.canScrollToFormField) {
    emit('scrollToFormField');
    return;
  }

  if (!getURL(file))
    return;

  if (getSupportedAttachment(file)) {
    const extension = getFileExtension(file.file_name);
    attachment_index.value = index;
    emit('view', { ...file, extension });
  }
};

function getURL(attachment) {
  return attachment?.url || attachment?.service?.url;
}
</script>

<template>
  <slot>
    <div :key="is_setting_up_document_viewer" class="grid gap-y-2" :class="additional_classes">
      <div v-for="(item, index) in items" :key="item" class="">
        <slot v-if="$slots.content" name="content" :content="item" />

        <div v-else-if="variant === 'mini'" class="relative grid items-center w-full group/thumbnail" :class="{ 'cursor-pointer hover:bg-gray-50 pointer-events-auto': getSupportedAttachment(item) }" @click.stop="openAttachment(index, item)">
          <div class="flex gap-4 items-center">
            <div class="shrink-0 relative subgroup">
              <div class="h-7 w-7 rounded-lg relative" :class="imgContainerClasses">
                <img v-if="item.thumbnail_small || (item.thumbnails?.small)" v-tippy="(showImageNameOnHover && item?.file_name) ? truncateMiddle(item.file_name) : ''" :src="item.thumbnail_small || (item.thumbnails?.small)" class="w-full h-full rounded-md" alt="thumbnail">
                <HawkIconThumbnail v-else :type="getFileExtension(item.file_name)" class="rounded-md" />
                <div v-if="getURL(item) && can_view" class="absolute w-full h-full top-0 left-0 right-0 items-center justify-center bg-[#10182866] text-white hidden rounded-md" :class="{ 'group-hover/thumbnail:flex': getSupportedAttachment(item) }">
                  <IconHawkEye />
                </div>
              </div>
            </div>
            <div v-if="!showImageNameOnHover" class="text-sm">
              <HawkText :content="item?.file_name" :length="filename_truncate_length" type="truncateMiddle" />
            </div>
          </div>
          <div v-if="can_download" class="absolute right-0 invisible group-hover:flex group-hover:visible" :class="{ '!visible': is_loading_uid === item.id }">
            <HawkButton :loading="is_loading_uid === item.id" icon type="light" color="gray" class="!w-7 !h-7" @click.stop="emit('download', item)">
              <IconHawkDownloadTwo class="w-4 h-4" />
            </HawkButton>
          </div>
        </div>

        <div
          v-else class="relative grid items-center px-4 w-full py-3 min-h-[72px] rounded-lg border pointer-events-auto border-gray-300 bg-white group"
          :class="{ 'cursor-pointer hover:bg-gray-50': getSupportedAttachment(item) }"
          @click="openAttachment(index, item)"
        >
          <div class="flex gap-4">
            <div class="shrink-0 relative subgroup">
              <div class="h-8 w-8 rounded-lg relative">
                <img v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)" class="w-full h-full" alt="thumbnail">
                <HawkIconThumbnail v-else :type="getFileExtension(item.file_name)" class="rounded-md" />
                <div v-if="getURL(item) && can_view" class="absolute w-full h-full top-0 left-0 items-center justify-center bg-[#10182866] text-white hidden" :class="{ 'group-hover:flex': getSupportedAttachment(item) }">
                  <IconHawkEye />
                </div>
              </div>
            </div>
            <div class="text-sm">
              <slot name="title">
                <div class="mb-1">
                  <HawkText :content="item?.file_name" :length="filename_truncate_length" type="truncateMiddle" />
                </div>
              </slot>
              <slot name="description">
                <div class="flex gap-2">
                  <HawkMembers v-if="item.owner?.uid" :members="item.owner?.uid" type="badge" />
                  <div v-if="show_file_size && item?.file_size">
                    {{ $prettysize(item?.file_size) }}
                  </div>
                  <div v-if="item.created_at">
                    <HawkText :content="$date(item.created_at, 'L_DATETIME_MED')" :length="created_at_truncate_length" />
                  </div>
                </div>
              </slot>
            </div>
          </div>
          <div class="absolute right-4 top-3 z-10 gap-3 hidden group-hover:flex group-hover:visible">
            <hawkButton v-if="can_download" icon type="light" color="gray" :disabled="!getURL(item)" @click.stop="emit('download', item)">
              <IconHawkDownloadTwo />
            </hawkButton>
            <hawkButton v-if="can_delete" icon type="light" color="gray" @click.stop="emit('delete', item)">
              <IconHawkTrashThree />
            </hawkButton>
          </div>
        </div>
      </div>
      <HawkAttachmentViewer
        v-if="items"
        :key="attachment_index"
        :attachment="items[attachment_index]"
        :has_next="!!items[attachment_index + 1]"
        :has_previous="items[attachment_index - 1]"
        @next="attachment_index++"
        @previous="attachment_index--"
        @download="emit('download', items[attachment_index])"
        @delete="emit('delete', items[attachment_index]);attachment_index = null"
        @close="attachment_index = null"
      />
    </div>
  </slot>
</template>
