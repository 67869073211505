<script setup>
import { IconHawkCheck, IconHawkChevronDown, IconHawkChevronUp } from '~/common/components/molecules/hawk-icons/icons.js';
import { useThermStore } from '~/therm/store/therm.store.js';

const props = defineProps({
  priority: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'badge',
  },
});
const therm_store = useThermStore();

const current_priority = computed(() => therm_store.priority_map[props.priority] || {});
</script>

<template>
  <div v-if="type === 'badge'" class="flex content-center text-sm items-center">
    <span class="pr-2">
      <component :is="current_priority.icon" class="w-3" :class="`text-${current_priority.color}-500`" />
    </span>
    <span class="whitespace-nowrap" :class="`text-${current_priority.color}-700`">
      {{ current_priority.name }}
    </span>
  </div>
  <HawkMenu v-else additional_trigger_classes="!ring-0 p-0" position="fixed">
    <template #trigger="{ open }">
      <div v-if="current_priority?.name" class="flex content-center text-sm items-center">
        <span class="pr-2">
          <component :is="current_priority.icon" class="w-3" :class="`text-${current_priority.color}-500`" />
        </span>
        <span class="whitespace-nowrap" :class="`text-${current_priority.color}-700`">
          {{ current_priority.name }}
        </span>
      </div>
      <div v-else class="flex items-center w-full cursor-pointer">
        <div class="text-sm text-gray-500 font-medium">
          {{ $t('Select priority') }}
        </div>
        <div class="ml-1 text-gray-500">
          <IconHawkChevronUp v-if="open" class="w-5 h-5" />
          <IconHawkChevronDown v-else class="w-5 h-5" />
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <div class="p-1 text-sm font-medium w-52">
        <div
          v-for="(item) in therm_store.priority_map" :key="item.uid"
          class="p-2.5 flex items-center justify-between cursor-pointer rounded-lg hover:bg-gray-50"
          @click="$emit('update', item), close()"
        >
          <div class="flex content-center text-sm items-center">
            <span class="pr-2">
              <component :is="item.icon" class="w-3" :class="`text-${item.color}-500`" />
            </span>
            <span class="whitespace-nowrap" :class="`text-${item.color}-700`">
              {{ item.name }}
            </span>
          </div>
          <IconHawkCheck v-if="item.uid === current_priority.uid" class="text-primary-700 w-5 h-5" />
        </div>
      </div>
    </template>
  </HawkMenu>
</template>
