<script setup>
import { useModal } from 'vue-final-modal';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import { IconHawkChevronLeft, IconHawkEye, IconHawkInfoCircle, IconHawkRocketTwo } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import EditData from '~/forms/atoms/form-text-input-update.vue';
import FormTemplateDetailDropdown from '~/forms/components/form-template-detail/form-template-detail-dropdown.vue';

import FormTemplateDetailHeaderDescription from '~/forms/components/form-template-detail/form-template-detail-header-description.vue';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store.js';

const props = defineProps({
  is_sticky: {
    type: Boolean,
    default: false,
  },
});

const { $t, $toast, auth_store, route, router } = useCommonImports();

const form_template_detail_store = inject('form_template_detail_store');
const form_workflow_store = useFormWorkflowStore();
const is_publishing = ref(false);
const has_modify_access = form_template_detail_store?.form_template_detail?.can_modify_template;

function go_back() {
  router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid, asset_id: route.params.asset_id } });
}

function preview() {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: route.params.template_uid, is_preview: true })) } });
}
async function publishFormTemplate() {
  try {
    is_publishing.value = true;
    await form_template_detail_store.update_form_details({
      body: {
        status: 'published',
      },
    });
    const template_details = form_template_detail_store.form_template_detail;
    if (template_details?.workflow?.active)
      await form_workflow_store.getFormWorkflow({ form_id: template_details.uid, workflow_id: template_details.workflow.uid });
    form_template_detail_store.form_template_track_events('Published');
  }
  catch (e) {

  }
  is_publishing.value = false;
}

const unpublish_button = ref({ text: $t('Unpublish'), emit: 'unpublish', loading: false });

const { open: openUnpublishFormTemplateModal, close: closeModal } = useModal({
  component: HawkModalTemplate,
  attrs: {
    footer_buttons: [unpublish_button.value],
    content_class: 'w-[600px] rounded-lg',
    onClose() {
      closeModal();
    },
    async onUnpublish() {
      unpublish_button.value.loading = true;
      await form_template_detail_store.update_form_details({
        body: {
          status: 'draft',
        },
      });
      await form_template_detail_store.set_form_template({ id: route.params.template_uid });
      form_template_detail_store.form_template_track_events('Unpublished');
      unpublish_button.value.loading = false;
      closeModal();
    },
  },
  slots: {
    header_left: $t('Unpublish form'),
    subtitle_text: form_template_detail_store.form_template_detail.name,
    default: `${$t('Are you sure you want to unpublish the form')}? <div class='text-sm text-gray-500'> ${$t('Unpublishing this form will make all open/draft submissions not accessible until the form is published')}.</div>`,
  },
});
</script>

<template>
  <div class="flex justify-between">
    <div class="flex items-center cursor-pointer hover-group" @click="go_back">
      <hawk-button type="text">
        <IconHawkChevronLeft class="text-blue-600 text-xl" />
      </hawk-button>
      <span v-if="!props.is_sticky && $route.name !== 'forms-template-workflow-builder'">{{ $t("Back") }}</span>
      <hawk-text v-if="props.is_sticky && $route.name !== 'forms-template-workflow-builder'" :length="80" class="font-semibold" :content="form_template_detail_store?.form_template_detail?.name" />
      <EditData
        v-if="$route.name === 'forms-template-workflow-builder'"
        class="ml-2 mt-2  !text-md text-gray-900 font-semibold "
        :data="form_template_detail_store?.form_template_detail?.name" :can_modify="false"
        text_default="name"
      >
        <div v-tippy="{ content: FormTemplateDetailHeaderDescription, maxWidth: 'none' }">
          <IconHawkInfoCircle class="ml-1 relative top-0.5 w-4 h-4 text-gray-600" />
        </div>
      </EditData>
    </div>
    <div class="flex">
      <HawkButton type="outlined" class="mr-4" @click="preview">
        <IconHawkEye class="text-blue-600" />
        {{ $t("Preview") }}
      </HawkButton>
      <div v-if="has_modify_access">
        <HawkButton v-if="form_template_detail_store.form_template_detail.status === 'draft'" :loading="is_publishing" @click="publishFormTemplate">
          <IconHawkRocketTwo class="text-blue-600" />
          {{ $t("Publish") }}
        </HawkButton>
        <hawk-button v-else :loading="is_publishing" @click="openUnpublishFormTemplateModal">
          {{ $t("Unpublish") }}
        </hawk-button>
      </div>
      <FormTemplateDetailDropdown v-if="props.is_sticky" class="ml-2 -mr-2" :is_form_builder_page="true" />
    </div>
  </div>
</template>
