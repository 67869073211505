<script setup>
import { IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['open', 'close']);
const is_open = ref(false);
const labelProp = computed(() => {
  return props.options.labelProp || props.options['label-prop'] || 'name';
});
</script>

<template>
  <TagsElement
    v-bind="{
      'close-on-select': false,
      ...options,
    }"
    @tag="options?.handleCreateInline"
    @open="is_open = true, emit('open', $event)"
    @close="is_open = false, emit('open', $event)"
  >
    <template #before-list>
      <slot name="before-list" />
    </template>
    <template #tag="{ option, handleTagRemove }">
      <div class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-0.5 px-1.5 mr-1 mb-1">
        <p
          v-tippy="option[labelProp].length > 15 ? option[labelProp] : ''"
          class="text-gray-700"
        >
          {{ $filters.truncate(option[labelProp], 15) }}
        </p>
        <div v-if="options?.tags_removable" class="hover:bg-gray-50 rounded ml-2" @mousedown.prevent="handleTagRemove(option, $event)">
          <IconHawkX class="text-[17px] text-gray-400 hover:text-gray-600" />
        </div>
      </div>
    </template>
    <template #after>
      <slot name="after" />
    </template>
  </TagsElement>
</template>
