<script setup>
import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import { IconHawkBuildingOne, IconHawkFileTwo, IconHawkPackage, IconHawkReceiptCheck, IconHawkSettingsOne, IconHawkTruckOne } from '~/common/components/molecules/hawk-icons/icons.js';
import useAbortController from '~/common/composables/abort-controller.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryGetStarted from '~/inventory/components/inventory-get-started.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const InventorySomethingWentWrong = defineAsyncComponent({
  loader: () => import('~/inventory/components/inventory-something-went-wrong.vue'),
  loadingComponent: HawkLoader,
});

const inventory_store = useInventoryStore();
const controllers = useAbortController();
const { $t, auth_store, route, router } = useCommonImports();
const state = reactive({
  is_loading: false,
  is_initial_data_api_failed: false,
});

const filtered_workflows = computed(() => {
  return inventory_store.workflows.filter((workflow) => {
    return auth_store.check_permission('view_adjustments', route.params.asset_id) || workflow?.permissions?.draft || workflow?.permissions?.publish;
  });
});

const sidebar_top_items = computed(() => [
  {
    uid: 'items',
    label: $t('Items'),
    to: { name: 'inventory-items', params: { asset_id: route.params.asset_id || null } },
    icon: IconHawkPackage,
    is_disabled: !auth_store.check_permission('display_items', route.params.asset_id),
  },
  {
    uid: 'warehouses',
    label: $t('Warehouses'),
    to: { name: 'inventory-warehouses', params: { asset_id: route.params.asset_id || null } },
    icon: IconHawkBuildingOne,
  },
  ...(filtered_workflows.value?.length
    ? [
        {
          uid: 'transactions',
          label: $t('Transactions'),
          to: { name: 'inventory-transactions', params: { asset_id: route.params.asset_id || null } },
          icon: IconHawkTruckOne,
        },
      ]
    : []),
  ...(route.params.asset_id
    ? [{
        uid: 'reports',
        label: $t('Reports'),
        to: { name: 'inventory-reports', params: { asset_id: route.params.asset_id || null } },
        icon: IconHawkFileTwo,
        is_disabled: !auth_store.check_permission('view_inventory_reports', route.params.asset_id),
        tooltip: !auth_store.check_permission('view_inventory_reports', route.params.asset_id) && $t('You don\'t have permission to view the page'),
      }]
    : []),
  ...(route.params.asset_id
    ? [{
        uid: 'bom',
        label: $t('BOM'),
        to: { name: 'inventory-bom', params: { asset_id: route.params.asset_id || null } },
        icon: IconHawkReceiptCheck,
        is_disabled: !auth_store.check_permission('view_bom', route.params.asset_id),
        tooltip: !auth_store.check_permission('view_bom', route.params.asset_id) && $t('You don\'t have permission to view the page'),
      }]
    : []),
]);
const sidebar_bottom_items = computed(() =>
  auth_store.check_permission('modify_inventory_settings', route.params.asset_id)
    ? [
        {
          uid: 'settings',
          label: $t('Settings'),
          to: { name: 'inventory-settings', params: { asset_id: route.params.asset_id || null } },
          icon: IconHawkSettingsOne,
        },
      ]
    : []);

async function redirect_to_accessible_page() {
  if (
    (route.name === 'inventory-items' && !auth_store.check_permission('display_items', route.params.asset_id))
    || (route.name === 'inventory-reports' && !auth_store.check_permission('view_inventory_reports', route.params.asset_id))
    || (route.name === 'inventory-warehouses' && !auth_store.check_permission('view_warehouses', route.params.asset_id))
    || (route.name === 'inventory-bom' && !auth_store.check_permission('view_bom', route.params.asset_id))
    || (route.name === 'inventory-settings' && !auth_store.check_permission('modify_inventory_settings', route.params.asset_id))
  ) {
    router.push({ name: 'inventory-transactions' });
  }
}

async function inventoryInit(initialize = false) {
  if (!auth_store.check_permission('access_inventory', route.params.asset_id)) {
    router.push({ name: 'permission-denied' });
    return;
  }

  try {
    state.is_loading = true;
    state.is_initial_data_api_failed = false;
    if (initialize)
      await inventory_store.initialize_inventory();
    const signal = controllers.add('init');

    await inventory_store.set_inventory_initial_data({
      query: { asset: route.params.asset_id },
      signal,
    });

    await redirect_to_accessible_page();
    state.is_loading = false;
  }
  catch (error) {
    logger.error('[DEBUG] inventory.vue::99\n', error);
    state.is_loading = false;
    state.is_initial_data_api_failed = true;
  }
}

watch(() => route.params.asset_id, async () => inventoryInit(), { immediate: true });
</script>

<template>
  <HawkLoader v-if="state.is_loading" />
  <InventorySomethingWentWrong v-else-if="state.is_initial_data_api_failed" @on-init="() => inventoryInit()" />
  <InventoryGetStarted v-else-if="auth_store.check_permission('modify_item_workflows') && !inventory_store?.workflows?.length" @on-init="() => inventoryInit(true)" />
  <div v-else class="flex">
    <hawk-sidebar-navigation :sidebar_top_items="sidebar_top_items" :sidebar_bottom_items="sidebar_bottom_items" />
    <div class="flex-1 pl-20 relative">
      <RouterView />
    </div>
  </div>
</template>
