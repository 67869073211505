<script setup>
import { flatten } from 'lodash-es';
import { IconHawkFilterLines } from '~/common/components/molecules/hawk-icons/icons.js';
import { useThermTaskFilters } from '~/therm/composables/therm-filters';
import ThermProjects from '../components/therm-projects.vue';
import ThermReportGraph from '../components/therm-report-graph.vue';
import { useThermStore } from '../store/therm.store';

const therm_store = useThermStore();
const $t = inject('$t');

const features = computed(() => therm_store.features);
const active_group = computed(() => therm_store.active_group);
const ftg = computed(() => therm_store.ftg);
const load_graph = ref(true);

const get_barchart_data = computed(() => {
  if (!ftg.value)
    return {};
  const grouped_by_project = features.value.reduce((r, a) => {
    r[a.properties.projectUid] = r[a.properties.projectUid] || [];
    r[a.properties.projectUid].push(a);
    return r;
  }, {});
  const feature_types = flatten(ftg.value.map(val => val.featureTypes));
  const data = {
    categories: [
      {
        category: Object.keys(grouped_by_project)
          .reverse()
          .map(project_id =>
            new Object({
              label: active_group.value.projects[project_id].name,
            }),
          ),
      },
    ],
    dataset: feature_types.map((feature_type) => {
      const feature_count = Object.keys(grouped_by_project)
        .reverse()
        .map((project_id) => {
          return new Object({
            value: grouped_by_project[project_id].filter(
              feature =>
                feature.properties.featureTypeId === feature_type.id,
            ).length,
          });
        });
      if (feature_count.filter(cnt => cnt.value > 0).length) {
        return {
          seriesname: feature_type.name,
          data: feature_count,
          color: getHexVal(
            feature_type.properties.color.slice(4, -1),
          ),
        };
      }
      return {};
    }),
  };
  data.dataset = data.dataset.filter(val => val !== undefined);
  return data;
});

const get_barchart_details = computed(() => {
  return {
    type: 'stackedcolumn2d',
    title: $t('Defects by Inverters'),
    xAxisName: $t('Inverters'),
    yAxisName: $t('No of defects'),
    plotToolText: '$seriesName: $value',
    id: 'container-1',
  };
});

const get_temperature_graph_data = computed(() => {
  const temparature_difference = features.value
    .map(feature => feature.properties.temperature_difference.toFixed(3))
    .sort((a, b) => a - b);
  const grouped_data = temparature_difference.reduce((acc, curr) => {
    if (acc[curr])
      acc[curr] = ++acc[curr];
    else
      acc[curr] = 1;
    return acc;
  }, {});
  const data = [];
  for (const temperature in grouped_data) {
    data.push({
      label: temperature,
      value: grouped_data[temperature],
    });
  }

  return { data };
});

const get_temperature_graph_details = computed(() => {
  return {
    type: 'column2d',
    title: $t('Temperature Histogram'),
    xAxisName: $t('Temperature difference'),
    yAxisName: $t('No of defects'),
    plotToolText: '$yAxisName: $value',
    id: 'container-2',
  };
});

function getHexVal(val) {
  val = val.split(',');
  return (
    `#${
      val
        .map((x) => {
          const hex = (+x).toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
        })
        .join('')}`
  );
}

const get_severity_levels_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Severity levels'),
    id: 'container-3',
  };
});

const get_severity_levels_graph_data = computed(() => {
  const severity = {
    'Severity 1': 0,
    'Severity 2': 0,
    'Severity 3': 0,
  };
  features.value
    .forEach((feature) => {
      const diff = feature.properties.temperature_difference;
      if (diff && diff > 40)
        severity['Severity 3']++;

      else if (
        [108, 109, 303, 315].includes(feature.properties.class_id)
      )
        severity['Severity 1']++;

      else
        severity['Severity 2']++;
    });
  const data = [];
  for (const severity_level in severity) {
    data.push({
      label: `${severity_level}, count: ${severity[severity_level]}`,
      value: severity[severity_level],
    });
  }
  return { data, description: true, type: 'severity' };
});
</script>

<template>
  <div class="relative overflow-y-scroll border border-l border-t border-gray-300 scrollbar">
    <ThermProjects class="top-4" />
    <div class="absolute top-8 right-4">
      <HawkButton type="outlined" class="text-gray-600 font-normal text-sm" @click="useThermTaskFilters">
        <IconHawkFilterLines />
        {{ $t('Filters') }}
      </HawkButton>
    </div>
    <div class="mt-[110px] ml-5 pr-[15px]">
      <ThermReportGraph
        v-if="load_graph"
        :data="get_barchart_data"
        :graph_details="get_barchart_details"
      />
      <ThermReportGraph
        v-if="load_graph"
        class="mt-[30px] mb-[36px]"
        :data="get_temperature_graph_data"
        :graph_details="get_temperature_graph_details"
      />
      <ThermReportGraph
        v-if="load_graph"
        class="mt-[30px] mb-[36px]"
        :data="get_severity_levels_graph_data"
        :graph_details="get_severity_levels_graph_details"
      />
    </div>
  </div>
</template>
