<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { IconHawkInfoCircle, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import PmEditView from '~/project-management/components/pm-edit-view.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const $toast = inject('$toast');
const project_management_store = useProjectManagementStore();

const { view_dirtiness, active_view } = storeToRefs(project_management_store);
const { update_view, set_view_dirtiness } = project_management_store;

const state = reactive({
  is_dismissed: false,
});

const new_view_modal = useModal({
  component: PmEditView,
  attrs: {
    onClose() {
      new_view_modal.close();
    },
  },
});

watch(view_dirtiness, (val) => {
  if (val)
    state.is_dismissed = false;
});

async function onUpdateClicked() {
  if (active_view.value?.uid === '__default') {
    new_view_modal.patchOptions({ attrs: { mode: 'add' } });
    new_view_modal.open();
  }
  else {
    await update_view(active_view.value);

    $toast({
      text: 'Custom view has been saved successfully.',
      type: 'success', // one of 'success', 'warning', 'error', and 'info' (default 'info')
      title: 'Successfully saved view', // (optional)
      timeout: 2000, // timeout in ms (default 2 seconds)
      position: 'bottom-right', // 'top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left',
      has_close_button: true,
    });
  }

  set_view_dirtiness(false);
}
</script>

<template>
  <div v-if="view_dirtiness && !state.is_dismissed" class="fixed bottom-10 right-7 w-[25rem] flex border border-[#FEC84B] rounded-lg p-4 bg-white z-10">
    <div class="bg-yellow-100 w-8 h-8 min-w-[2rem] rounded-full flex items-center justify-center">
      <IconHawkInfoCircle class="text-black" />
    </div>
    <div class="ml-4 mr-6">
      <div class="text-sm font-semibold text-gray-900 mb-1">
        {{ $t('Changes detected') }}
      </div>
      <div v-if="active_view.value?.uid === '__default'" class="text-sm font-medium text-gray-600 mb-4">
        {{ $t('save-custom-view') }}
      </div>
      <div v-else class="text-sm font-medium text-gray-600 mb-4">
        {{ $t('update-current-view') }}
      </div>
      <div class="flex">
        <div class="text-sm text-gray-600 font-semibold cursor-pointer" @click="state.is_dismissed = true">
          {{ $t('Dismiss') }}
        </div>
        <div class="text-sm text-primary font-semibold cursor-pointer ml-3" @click="onUpdateClicked">
          {{ $t('Save view') }}
        </div>
      </div>
    </div>
    <div class="cursor-pointer" @click="state.is_dismissed = true">
      <IconHawkXClose />
    </div>
  </div>
</template>
