<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { reactive, ref } from 'vue';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  custom_view: {
    type: Object,
    default: null,
  },
  on_submit: {
    type: Function,
    default: null,
  },
});

const emit = defineEmits(['close']);
const form$ = ref(null);

const state = reactive({
  loading: false,
});

function onFormMounted(el$) {
  if (props.custom_view) {
    el$.load({
      name: props.custom_view.name,
      description: props.custom_view.description,
      private: props.custom_view.private,
      members: props.custom_view.members,
    });
  }
}

async function saveHandler(form$) {
  state.loading = true;
  await props?.on_submit(form$.data);
  state.loading = false;
  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg min-w-[600px]">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :format-load="onFormMounted"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @mounted="onFormMounted"
      @submit="saveHandler"
    >
      <div class="col-span-12">
        <div class="p-4 flex items-center justify-between border-b">
          <div class="text-[18px] font-semibold">
            {{ custom_view ? $t('Edit') : $t('Add View') }}
          </div>
          <div @click="$emit('close')">
            <IconHawkXClose />
          </div>
        </div>
        <hawk-modal-content>
          <TextElement
            v-bind="{
              label: `${$t('Name')}`,
              name: 'name',
              placeholder: 'Enter view name',
              rules: ['required'],
              class: 'mb-6',
            }"
          />
          <TextareaElement
            v-bind="{
              name: 'description',
              label: $t('Description'),
              placeholder: $t('Enter description'),
              class: 'mb-6',
            }"
          />
          <RadiogroupElement
            v-bind="{
              name: 'private',
              label: $t('Access'),
              class: 'mb-6',
              default: false,
              items: [
                { value: false, label: $t('Anyone can access') },
                { value: true, label: $t('Only selected members can access') },
              ],
            }"
          />
          <hawk-assignee-input
            :multi="true"
            :options="{
              label: $t('Members'),
              name: 'members',
              placeholder: 'Add members',
              conditions: [['private', '==', true]],
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="custom_view ? $t('Update') : $t('Save')"
                :loading="state.loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
