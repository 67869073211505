<script setup>
import { orderBy } from 'lodash-es';
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';
import { IconHawkFilterFunnelOne, IconHawkFilterFunnelRed } from '~/common/components/molecules/hawk-icons/icons.js';
import { trainCase } from '~/common/utils/common.utils';
import NumberTable from '~/terra/components/feature-details/num-table-modal.vue';

import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  is_feature_slider: {
    type: Boolean,
    default: false,
  },
  feature: {
    type: Object,
    default: null,
  },
  hide_quick_filter: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['toggleFilterHandler']);

const terra_store = useTerraStore();

const number_table_modal = useModal({
  component: NumberTable,
  attrs: {
    onClose() {
      number_table_modal.close();
    },
  },
});

const extraProperties = computed(() => {
  const feature = props.feature || terra_store.selected_features[0];
  let { extraProperties } = feature.properties;
  const additional_properties = terra_store.get_feature_additional_properties(feature);
  extraProperties = {
    ...extraProperties,
    ...additional_properties,
  };
  if (!extraProperties)
    return [];
  return orderBy(Object.keys(extraProperties).map((key) => {
    return {
      name: key,
      value: extraProperties[key],
    };
  }), 'name');
});

function openModal(val) {
  number_table_modal.patchOptions({
    attrs: {
      data: val,
    },
  });
  number_table_modal.open();
}

function getSelectedStatus(item) {
  return !terra_store?.filters_state?.key_values_data?.[item.name]?.[item.value]?.selected;
}

function toggleFilter(property) {
  const updated_selected_status = getSelectedStatus(property);
  terra_store.filters_state.key_values_data = {
    ...terra_store.filters_state.key_values_data,
    [property.name]: {
      ...(terra_store.filters_state.key_values_data[property.name] || {}),
      [property.value]: {
        selected: updated_selected_status,
      },
    },
  };
  if (!terra_store.filters_state.quick_filter && !updated_selected_status)
    terra_store.update_features_on_map_flag++;
  else
    terra_store.filters_state.quick_filter = updated_selected_status ? property : null;
}

emit('checkStatusHandler', item => getSelectedStatus(item));
emit('toggleFilterHandler', property => toggleFilter(property));
</script>

<template>
  <div
    v-for="(item, index) in extraProperties"
    :key="index"
    class="text-sm font-normal py-2"
  >
    <div class="flex text-sm group cursor-pointer">
      <div class="shrink-0 w-48 font-medium break-all mr-1" :class="is_feature_slider ? 'text-gray-500' : 'text-gray-700'">
        {{ trainCase(item.name) }}
      </div>
      <div class="flex-auto text-gray-900">
        <hawk-button v-if="item.name === '_serial_numbers'" type="link" class="!p-0 !h-auto" @click="openModal(item.value)">
          Show details
        </hawk-button>
        <div v-else class="flex items-center justify-between">
          <span class="break-all">
            {{ item.value }}
          </span>
          <span v-if="!hide_quick_filter && item.value" :class="{ invisible: getSelectedStatus(item) }" class="group-hover:visible" @click="toggleFilter(item)">
            <IconHawkFilterFunnelOne v-if="getSelectedStatus(item)" v-tippy="$t('Add to filters')" class="w-4 h-4" />
            <IconHawkFilterFunnelRed v-else class="w-4 h-4" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
