<script setup>
import { IconHawkChevronDown } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  resource_details: {
    type: Object,
    default: () => ({}),
  },
});

const { get_asset } = useCommonStore();

const show_details = ref(false);
</script>

<template>
  <div v-if="resource_details?.properties?.length">
    <div
      class="w-auto inline-flex items-center text-sm font-semibold cursor-pointer py-2 text-gray-600"
      @click="show_details = !show_details"
    >
      {{ $t('Show more details') }}
      <IconHawkChevronDown
        class="transition-transform ml-2"
        :class="{ 'rotate-180': show_details }"
      />
    </div>
    <div v-if="show_details" class="mt-3">
      <ul v-for="entry in resource_details.properties" :key="entry.name">
        <li v-if="entry?.label && entry?.value" class="mb-4 text-sm">
          <p class="text-gray-600 font-medium">
            {{ entry.label }}:
          </p>
          <a
            v-if="entry.type === 'link'"
            :href="entry.value"
            target="_blank"
            rel="noopener noreferrer"
            class="text-primary-600 hover:underline hover:underline-offset-1"
          >
            {{ $t('Download') }}
          </a>
          <p v-else>
            {{ entry.value }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
