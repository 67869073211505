<script setup>
import { cloneDeep, includes, indexOf, isEqual, keyBy } from 'lodash-es';
import { onMounted, ref } from 'vue';
import { IconHawkFilterLines } from '~/common/components/molecules/hawk-icons/icons.js';
import { useThermTaskFilters } from '~/therm/composables/therm-filters';
import ThermDefectsList from '../components/therm-defects-list.vue';
import ThermProjects from '../components/therm-projects.vue';
import { useThermStore } from '../store/therm.store';

const search = ref('');
const therm_store = useThermStore();
const ordered_features = ref(therm_store.selected_features.map(feature => feature.properties.uid));

const tableData = computed(() => {
  const result = [];
  therm_store.features.forEach((project) => {
    search.value.length ? ((project.properties?.modified_string_number || project.properties?.string_number)?.includes(search.value) && result.push(project.properties)) : result.push(project.properties);
  });
  const features_selected = result.filter(f => includes(ordered_features.value, f.uid));
  const features_not_selected = result.filter(f => !includes(ordered_features.value, f.uid));
  return [...features_selected, ...features_not_selected];
});

function handleSelectedDefects(defects) {
  therm_store.selected_features = cloneDeep(defects.map(defect => therm_store.features_hash[defect.uid]));
}
</script>

<template>
  <div class="w-[calc(100vw-250px)] px-4 border-solid border-gray-300 border-t border-l overflow-x-hidden">
    <ThermBulkActions v-if="therm_store.selected_features.length" class="absolute left-6 top-11" :defect_table_instance="therm_store.defect_table_instance" :defects="therm_store.selected_features.map(f => f.properties)" />
    <ThermProjects v-else class="top-4" />

    <div v-if="therm_store.selected_features.length === 0" class="filter-search absolute top-4 right-5 flex gap-[14px]">
      <HawkSearchInput v-model:search="search" :placeholder="$t('Search')" @update:model-value="search = $event" />
      <HawkButton type="outlined" class="text-gray-500 font-regular text-sm" @click="useThermTaskFilters">
        <IconHawkFilterLines />
        {{ $t('Filters') }}
      </HawkButton>
    </div>
    <ThermDefectsList :table-data="tableData" @selected-defects="handleSelectedDefects" />
  </div>
</template>
