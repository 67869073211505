<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';

import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
  options: {
    type: Object,
  },
  tag_class: {
    type: String,
    default: 'max-w-[200px]',
  },
  multi: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clear']);

const route = useRoute();
const common_store = useCommonStore();
const teams_data = computed(() => {
  const team_id = props?.asset_id ? props.asset_id : route.params.asset_id;
  // Using scoped members
  let teams = common_store.scope_teams(team_id)?.map((team) => {
    return {
      name: team.name,
      uid: team.uid,
    };
  });
  if (props?.options?.existing_teams)
    teams = teams.filter(item => !props.options.existing_teams.includes(item.uid));

  return teams;
});
</script>

<template>
  <TagsElement
    v-if="multi"
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': false,
      'groups': false,
      'object': true,
      'group-select': false,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': teams_data,
      ...options,
    }"
    @clear="emit('clear', $event)"
  >
    <template #option="{ option }">
      <HawkMembers :members="option.uid" type="label" size="xs" :has_avatar="false" />
    </template>
    <template #tag="{ option, handleTagRemove }">
      <div
        v-if="option.default"
        v-tippy="{ content: 'Marked as default' }"
        class="bg-gray-100 !text-gray-500 flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1"
      >
        <HawkMembers :members="option" name_class="!font-normal" type="label" size="xs" :has_avatar="false" name_classes="!text-gray-500" />
      </div>
      <div
        v-else
        class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1"
      >
        <HawkMembers :members="option" name_class="!font-normal" type="label" size="xs" :has_avatar="false" />
        <div class="hover:bg-gray-100 rounded ml-1">
          <IconHawkXClose class="text-gray-400 w-4 h-4" @mousedown.prevent="handleTagRemove(option, $event)" />
        </div>
      </div>
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
  </TagsElement>
  <SelectElement
    v-else
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': true,
      'groups': true,
      'group-hide-empty': true,
      'hide-selected': true,
      'object': true,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': teams_data,
      ...options,
    }"
  >
    <template #option="{ option }">
      <p class="text-gray-700">
        {{ option.name }}
      </p>
    </template>
    <template #single-label="{ value }">
      <div class="flex items-center h-full absolute left-0 pl-3">
        {{ value.name }}
      </div>
    </template>
  </SelectElement>
</template>
