<script setup>
import { IconHawkArrowNarrowLeft, IconHawkMailOne } from '~/common/components/molecules/hawk-icons/icons.js';

const email = window.history.state.email;
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-4">
        <IconHawkMailOne width="66" height="66" />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t("Check your email") }}
      </p>
      <p class="text-gray-600 w-[360px] mx-auto">
        {{ $t("We sent a verification link to") }}
        <span class="font-medium">{{ email }}</span>
        {{ $t("with instructions to reset your password") }}
      </p>
      <div class="py-8 flex justify-center items-center text-gray-600 text-sm">
        <RouterLink class="mx-2" to="sign-in">
          <div class="text-blue-600 hover:text-blue-500 font-semibold flex items-center">
            <IconHawkArrowNarrowLeft class="mr-3" />
            {{ $t("Back to Sign in") }}
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
