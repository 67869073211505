<script setup>
import { groupBy } from 'lodash-es';
import { useModal, useModalSlot } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkEditTwo, IconHawkPlus, IconHawkUsersThree } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkShare from '~/common/components/organisms/hawk-share-new.vue';
import SharingBlock from '~/terra/components/sharing/terra-sharing-blocks.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';

const emit = defineEmits(['close']);
const $t = inject('$t');
const $services = inject('$services');
const route = useRoute();
const terra_store = useTerraStore();
const auth_store = useAuthStore();
const state = reactive({
  added_users: [],
  added_teams: [],
  is_public: true,
  is_loading: false,
});

function sharePopupHandler(edit_data) {
  let user_access = edit_data?.access || 'full';
  let shared_blocks = [];
  const selected_blocks = (edit_data?.resources || []).filter(block => block.type !== 'container').map(block => block.uid);
  const is_edit_mode = edit_data ? Object.keys(edit_data)?.length > 0 : false;
  const { open: openShareModal, close: closeShareModal, patchOptions } = useModal({
    component: HawkShare,
    slots: {
      default: useModalSlot({
        component: SharingBlock,
        attrs: {
          user_access,
          selected_blocks,
          onUpdate(e) {
            shared_blocks = e;
          },
        },
      }),
    },
    attrs: {
      onClose() {
        closeShareModal();
      },
      is_modal: true,
      is_edit_mode,
      edit_data: edit_data || {},
      members: state.added_users || [],
      teams: state.added_teams || [],
      access_levels: [
        { name: 'full', label: $t('Full access'), description: $t('Allow access to all groups/layers') },
        { name: 'limited', label: $t('Limited access'), description: $t('Allow access to only selected groups and layers') },
      ],
      hide_edit_options: true,
      hide_people_access: true,
      is_global_access_visible: false,
      onAccessLevelChanged(e) {
        user_access = e;
        shared_blocks = [];
        patchOptions({
          slots: {
            default: useModalSlot({
              component: SharingBlock,
              attrs: {
                user_access,
                shared_blocks,
              },
            }),
          },
        });
      },
      async onRemoveAccess() {
        if (edit_data.type === 'user')
          state.added_users = state.added_users.filter(user => user.uid !== edit_data.uid);
        else
          state.added_teams = state.added_teams.filter(team => team.uid !== edit_data.uid);
        try {
          await $services.terra.share({
            method: 'delete',
            url: `/assets/${route.params.asset_id}/resources/container/${route.params.id}`,
            body: [
              {
                type: edit_data.type,
                uid: edit_data.uid,
              },
            ],
          });
          terra_store.terra_track_events('Member removed');
        }
        catch (error) {
          logger.error('Failed to remove user', error);
        }
      },
      async onUpdatedMembers(users_data) {
        const resources = createResourceData(users_data, shared_blocks);

        await addOrUpdateUsers(users_data, resources, edit_data);
        terra_store.terra_track_events(`Member ${is_edit_mode ? 'updated' : 'added'}`);
        if (!is_edit_mode)
          terra_store.terra_track_events('Sharing - Add member');
      },
    },
  });
  openShareModal();
}

function createResourceData(users_data, shared_blocks) {
  let resources = [];

  if (users_data[0].access === 'full') {
    resources = [{
      type: 'container',
      uid: route.params.id,
      name: terra_store.container.name,
      container: route.params.id,
      asset: route.params.asset_id,
    }];
  }
  else {
    resources = shared_blocks.map((uid) => {
      const type = terra_store.container.groups[uid] ? 'group' : 'project';
      const data = {};
      if (type === 'group') {
        data.name = terra_store.container.groups[uid].name;
      }
      else {
        const project = terra_store.active_projects_data_map({ all_projects: true })[uid];
        data.name = project.name;
        data.group = project.group;
      }
      return {
        type,
        uid,
        container: route.params.id,
        asset: route.params.asset_id,
        ...data,
      };
    });
  }
  return resources;
}

async function addOrUpdateUsers(users_data, resources, edit_data) {
  const payload = [];
  users_data.forEach((user) => {
    const data = {
      type: user.member ? 'user' : 'team',
      uid: user.uid,
      access: user.access,
      resources,
    };

    if (edit_data) {
      if (user.member) {
        const index = state.added_users.findIndex(value => value.uid === user.uid);
        state.added_users.splice(index, 1, data);
      }
      else {
        const index = state.added_teams.findIndex(value => value.uid === user.uid);
        state.added_teams.splice(index, 1, data);
      }
    }

    else
      if (user.member) {
        state.added_users.push(data);
      }
      else {
        state.added_teams.push(data);
      }

    payload.push(data);
  });
  try {
    await $services.terra.share({
      method: 'post',
      url: `/assets/${route.params.asset_id}/resources/container/${route.params.id}`,
      body: payload,
    });
  }
  catch (error) {
    if (edit_data)
      logger.error('Failed to update user', error);
    else
      logger.error('Failed to add users', error);
  }
}

function getAccess(resources) {
  if (resources.length) {
    if (resources[0].type === 'container')
      return 'Full access';
    return 'Custom';
  }
  else {
    return 'No access';
  }
}

function groupResources(resources) {
  const groups = [];
  const projects = [];
  resources.forEach((resource) => {
    if (resource.type === 'group')
      groups.push(resource);
    else if (resource.type === 'project')
      projects.push(resource);
  });
  const group_projects_map = groupBy(projects, (project) => {
    return terra_store.active_projects_data_map({ all_projects: true })[project.uid].group;
  });
  return { full_access_groups: groups, project_access: group_projects_map };
}

function togglePublicAccess() {
  state.is_public = !state.is_public;
  $services.terra.share({
    method: 'post',
    url: `/assets/${route.params.asset_id}/resources/container/${route.params.id}/public`,
    body: {
      public: state.is_public,
    },
  });
}

onMounted(async () => {
  state.is_loading = true;
  try {
    const { data } = await $services.terra.share({
      url: `/assets/${route.params.asset_id}/resources/container/${route.params.id}`,
      method: 'get',
    });
    data.forEach((item) => {
      item.access = 'limited';
      if (item.resources.length && item.resources[0].type === 'container')
        item.access = 'full';
      if (item.type === 'user')
        state.added_users.push(item);
      else
        state.added_teams.push(item);
    });
  }
  catch (error) {
    logger.error('Failed to fetch users', error);
  }
  state.is_loading = false;
});
</script>

<template>
  <div class="py-4 flex flex-col justify-between h-full">
    <div>
      <div class="flex items-center justify-between px-4 mb-6">
        <p class="font-semibold">
          {{ $t('Share map') }}
        </p>
        <HawkButton v-if="auth_store.check_permission('share_terra_containers', route.params.asset_id)" icon type="light" color="gray" @click="sharePopupHandler()">
          <IconHawkPlus />
        </HawkButton>
      </div>
      <!-- <div class="flex justify-between mb-6">
        <div>
          <div class="text-sm">
            {{ $t('Allow all asset members') }}
          </div>
          <div class="text-xs">
            {{ $t('It will give full access to everyone') }}
          </div>
        </div>
        <HawkToggle :initial-state="state.is_public" @click="togglePublicAccess()" />
      </div> -->
      <HawkLoader v-if="state.is_loading" />
      <HawkIllustrations v-else-if="![...state.added_users, ...state.added_teams]?.length" class="px-4" type="no-data" for="map-no-members" variant="mini_vertical">
        <template #content>
          <HawkButton v-if="auth_store.check_permission('share_terra_containers')" class="mt-6" @click="sharePopupHandler()">
            {{ $t('Add members') }}
          </HawkButton>
        </template>
      </HawkIllustrations>
      <div v-else class="h-[calc(100vh-140px)] px-4 scrollbar">
        <div v-if="state.added_users?.length" class="grid gap-3">
          <div class="text-sm font-semibold col-span-12">
            {{ $t('Members with access') }}
          </div>
          <div v-for="(user) of state.added_users" :key="user.uid" class="col-span-12 flex items-center hover:bg-gray-50 rounded-2xl py-3 px-2 justify-between group">
            <div class="flex items-center gap-3">
              <HawkMembers :members="user.uid" size="xs" />
              <div>
                <HawkMembers :members="user.uid" type="label" :name_truncate_length="28" :has_avatar="false" name_classes="text-sm text-gray-700" />
                <div v-if="getAccess(user.resources) !== 'custom'" class="text-xs text-gray-500">
                  {{ getAccess(user.resources) }}
                </div>
                <div v-else>
                  <div v-for="group of groupResources(user.resources).full_access_groups" :key="group.uid" class="mb-1">
                    <div class="flex items-center text-xs text-gray-500 flex-wrap gap-1">
                      <span>{{ group.name }}</span> <span>({{ $t('All layers') }})</span>
                    </div>
                  </div>
                  <div v-for="(projects, group_uid) of groupResources(user.resources).project_access" :key="group_uid" class="mb-1">
                    <div class="flex items-center text-xs text-gray-500 flex-wrap gap-1">
                      <span>{{ terra_store.container.groups[group_uid]?.name }}</span> <span>-></span> <span>{{ projects.map(project => project.name).join(", ") }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HawkButton v-if="auth_store.check_permission('share_terra_containers')" icon type="text" class="flex-shrink-0 invisible group-hover:visible" @click="sharePopupHandler(user)">
              <IconHawkEditTwo />
            </HawkButton>
          </div>
        </div>
        <div v-if="state.added_teams?.length" class="grid gap-3 mt-3">
          <div class="text-sm font-semibold col-span-12">
            {{ $t('Teams with access') }}
          </div>
          <div v-for="(team) of state.added_teams" :key="team.uid" class="col-span-12 flex items-center hover:bg-gray-50 rounded-2xl py-3 px-2 justify-between group">
            <div class="flex items-center gap-3">
              <IconHawkUsersThree />
              <div>
                <HawkMembers :members="team.uid" name_classes="text-sm text-gray-700" type="label" size="xs" />
                <div v-if="getAccess(team.resources) !== 'custom'" class="text-xs text-gray-500">
                  {{ getAccess(team.resources) }}
                </div>
                <div v-else>
                  <div v-for="group of groupResources(team.resources).full_access_groups" :key="group.uid" class="mb-1">
                    <div class="flex items-center text-xs text-gray-500 flex-wrap gap-1">
                      <span>{{ group.name }}</span> <span>({{ $t('All layers') }})</span>
                    </div>
                  </div>
                  <div v-for="(projects, group_uid) of groupResources(team.resources).project_access" :key="group_uid" class="mb-1">
                    <div class="flex items-center text-xs text-gray-500 flex-wrap gap-1">
                      <span>{{ terra_store.container.groups[group_uid]?.name }}</span> <span>-></span> <span>{{ projects.map(project => project.name).join(", ") }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HawkButton v-if="auth_store.check_permission('share_terra_containers')" icon type="text" class="flex-shrink-0 invisible group-hover:visible" @click="sharePopupHandler(team)">
              <IconHawkEditTwo />
            </HawkButton>
          </div>
        </div>
      </div>
    </div>
    <!-- <HawkAlertBanner v-if="!has_permission">
      <template #icon>
        <HawkFeaturedIcon theme="light-circle-outline" size="sm" class="flex-shrink-0">
          <IconHawkInfoCircle />
        </HawkFeaturedIcon>
      </template>
      <template #content>
        <div class="text-gray-600 font-normal text-sm mb-2">
          {{ $t('Advanced, Manager and admins can access this map') }}
        </div>
        <HawkButton type="link">
          {{ $t('View') }}
        </HawkButton>
      </template>
    </HawkAlertBanner> -->
  </div>
</template>
