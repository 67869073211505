<script setup>
import { useModal } from 'vue-final-modal';
import { IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';
import FormBuilderTableSummaryPopup from '~/forms/components/form-builder/form-builder-table-summary-popup.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';

const $track_event = inject('$track_event');
const section = inject('form_builder_section_configuration');
const form_template_detail_store = useFormTemplateDetailStore();
function getFieldMap(section, data) {
  const map = {};
  section.fields.forEach((field) => {
    map[field.uid] = { name: field.name, id: field.uid, footer: data[field.uid], value: 0 };
  });
  return map;
}
const { open: openSummaryModal, close: closeSummaryModal } = useModal({
  component: FormBuilderTableSummaryPopup,
  attrs: {
    section,
    onClose() {
      closeSummaryModal();
    },
    async on_submit(form) {
      const field_map = getFieldMap(section.value, form.data.footer);

      const field_array = form.data.data.map((obj) => {
        return { ...obj, show: false };
      });

      const field_value = form.data.data.reduce((accumulator, currentValue) => {
        const name = currentValue.name;
        accumulator.push({ [name]: 0 });
        return accumulator;
      }, []);

      const properties = form.data.summary_type === 'detailed_summary' ? { ...section.value.properties, ...{ section_summary: { summary_type: 'detailed_summary', data: field_array, value: field_value } } } : { ...section.value.properties, ...{ section_summary: { data: field_map, summary_type: 'column_summary' } } };
      const index = form_template_detail_store.form_template_detail.sections.findIndex(new_section => new_section.uid === section.value.uid);
      form_template_detail_store.form_template_detail.sections[index].properties = properties;
      $track_event('add_table_summary', {
        type: form.data.summary_type.split(' ').map(val => val.charAt(0).toUpperCase() + val.slice(1)).join(' '),
        count: form.data.summary_type === 'detailed_summary' ? field_array.length : Object.values(form.data.footer).filter(val => val !== 'None').length,
      });
      closeSummaryModal();
    },
  },
});
</script>

<template>
  <div class="flex cursor-pointer items-center rounded ">
    <HawkButton type="text" :disabled="!section.uid" @click="openSummaryModal">
      <IconHawkPlus class="text-lg" />
      {{ $t('Setup summary') }}
    </HawkButton>
  </div>
</template>
