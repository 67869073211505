<script setup>
import { computed } from 'vue';
import { IconHawkChevronDown, IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
  options: {
    type: Object,
  },
  tag_class: {
    type: String,
    default: 'max-w-[200px]',
  },
  multi: {
    type: Boolean,
    default: false,
  },
  has_select_all: {
    type: Boolean,
    default: false,
  },
});
const common_store = useCommonStore();
const assets_data = computed(() => {
  let assets = common_store.assets?.map((asset) => {
    return {
      name: asset.name,
      uid: asset.uid,
    };
  });
  if (props?.options?.existing_assets)
    assets = assets.filter(item => !props.options.existing_assets.includes(item.uid));
  if (props.has_select_all) {
    return [
      {
        label: 'Select all assets',
        items: assets,
      },
    ];
  }
  return assets;
});
</script>

<template>
  <TagsElement
    v-if="multi"
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': false,
      'object': true,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': assets_data,
      ...(has_select_all ? { 'groups': true, 'group-select': true } : {}),
      ...options,
    }"
  >
    <template #option="{ option }">
      <HawkAssetName :uid="option.uid" />
    </template>
    <template #tag="{ option, handleTagRemove }">
      <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
        <HawkAssetName :uid="option.uid" />
        <div class="hover:bg-gray-100 rounded">
          <IconHawkX @mousedown.prevent="handleTagRemove(option, $event)" />
        </div>
      </div>
    </template>
    <template #caret>
      <IconHawkChevronDown class="mr-3 text-lg caret w-8" />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
  </TagsElement>
  <SelectElement
    v-else
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': true,
      'groups': true,
      'group-hide-empty': true,
      'hide-selected': true,
      'object': true,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': assets_data,
      ...options,
    }"
  >
    <template #option="{ option }">
      <p class="text-gray-700">
        {{ option.name }}
      </p>
    </template>
    <template #single-label="{ value }">
      <div class="flex items-center h-full absolute left-0 pl-3">
        {{ value.name }}
      </div>
    </template>
  </SelectElement>
</template>

<style lang="scss" scoped>
:deep([type='text']:focus) {
  box-shadow: none;
}

:deep(.form-pl-input-y-sm) {
  padding-left: 14px;
}

:deep(.form-pl-input-sm) {
  padding-left: 18px;
}

:deep(.focused-hover\:form-shadow-input-hover.form-focus:hover) {
  border: 1px solid #84CAFF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E9FF;
}

:deep(.outline-zero) {
  outline: none;
}
</style>
