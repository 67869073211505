<script setup>
// --------------------------------- Imports -------------------------------- //
import { IconHawkCheck, IconHawkCodeTwo } from '~/common/components/molecules/hawk-icons/icons.js';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //
const emits = defineEmits(['copyClick']);

// ---------------------------- Injects/Provides ---------------------------- //
const form_detail_store = inject('form_detail_store');

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //
const is_copied = ref(false);

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const is_slug_mode_enabled = computed(() => form_detail_store?.is_slug_mode);

// -------------------------------- Functions ------------------------------- //
function onCopyClick() {
  is_copied.value = true;

  emits('copyClick');
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <span v-if="is_slug_mode_enabled" v-click-outside="() => is_copied = false" class="text-success-600 text-xs flex items-center gap-1 cursor-pointer" @click.prevent="onCopyClick">
    <template v-if="is_copied">
      <IconHawkCheck class="inline-block h-4 w-4" />
      {{ $t('Copied') }}
    </template>
    <template v-else>
      <IconHawkCodeTwo v-tippy="$t('Copy')" class="inline-block text-primary-600 h-4 w-4" />
    </template>
  </span>
</template>
