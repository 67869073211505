<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { IconHawkCalendarToday, IconHawkFilterLines, IconHawkMaximizeOne, IconHawkMinimizeOne } from '~/common/components/molecules/hawk-icons/icons.js';
import PmFilterMenu from '~/project-management/components/menus/pm-filter-menu.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const project_management_store = useProjectManagementStore();
const { active_view } = storeToRefs(project_management_store);
const { set_all_tasks_open_state } = project_management_store;

const state = reactive({
  is_expanded: true,
});

const is_group_by_length_zero = computed(() => active_view.value.data.group_by.length !== 0);

function toggleExpandCollapse(bool) {
  set_all_tasks_open_state(bool);
  state.is_expanded = bool;
}

function toggleCollapsed() {
  toggleExpandCollapse(!state.is_expanded);
}

function onTodayClicked() {
  const gantt = window.gantt;
  gantt.showDate(new Date());
}

const filter_modal = useModal({
  component: PmFilterMenu,
  attrs: {
    onClose() {
      filter_modal.close();
    },
  },
});
</script>

<template>
  <div class="flex justify-between mb-4">
    <div class="flex items-center">
      <PmSearch @expand-all="() => toggleExpandCollapse(true)" />
      <hawk-button
        icon
        type="outlined"
        class="ml-3"
        :disabled="is_group_by_length_zero"
        :class="{
          'cursor-not-allowed': is_group_by_length_zero,
        }"
        @click="filter_modal.open()"
      >
        <IconHawkFilterLines class="w-5 h-5" />
      </hawk-button>
      <PmViewMenu class="ml-3" />
      <hawk-button
        type="text"
        class="ml-3"
        @click="toggleCollapsed"
      >
        <template v-if="state.is_expanded">
          <IconHawkMinimizeOne class="w-5 h-5" />
          <span class="text-sm font-medium">
            {{ $t('Collapse all') }}
          </span>
        </template>
        <template v-else>
          <IconHawkMaximizeOne class="w-5 h-5" />
          <span class="text-sm font-medium">
            {{ $t('Expand all') }}
          </span>
        </template>
      </hawk-button>
    </div>
    <div class="flex items-center ml-auto">
      <hawk-button
        type="outlined"
        @click="onTodayClicked"
      >
        <IconHawkCalendarToday class="w-5 h-5" />
        <span>{{ $t("Today") }}</span>
      </hawk-button>
      <PmGanttZoom class="ml-3" />
      <PmSettingsMenu class="ml-3" />
    </div>
  </div>
</template>
