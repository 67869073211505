<script>
import { IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';

export default {
  props: {
    items: {
      type: Array,
      default: () => [
        // { uid: '1', label: 'Step 1', icon: 'icon-ic-baseline-chevron-right', to: { name: 'tasks' }, on_click: () => {}, disabled: false },
        // { uid: '2', label: 'Step 2', icon: 'icon-ic-baseline-chevron-right', to: { name: 'tasks' }, on_click: () => {}, disabled: false },
        // { uid: '3', label: 'Step 3', icon: 'icon-ic-baseline-chevron-right', to: {}, on_click: () => {}, disabled: false },
      ],
    },
    active_item: {
      type: String,
    },
    truncate_length: {
      type: Number,
      default: 20,
    },
    show_active_color: {
      type: Boolean,
      default: true,
    },
    text_classes: {
      type: String,
      default: '',
    },
    max_tags_to_display: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    truncated_tooltip_placement: {
      type: String,
      default: 'top',
    },
  },
  emits: ['crumbClicked'],
  computed: {
    filtered_items() {
      return this.items.filter((_item, index) => {
        return (index < this.max_tags_to_display - 1 || index === this.items.length - 1);
      });
    },
    truncated_items() {
      return this.items.filter((_item, index) => {
        return !(index < this.max_tags_to_display - 1 || index === this.items.length - 1);
      });
    },
  },
  methods: {
    onItemClick(item) {
      if (item.to)
        this.$router.push(item.to);
      else this.$emit('crumbClicked', item);
    },
  },
};
</script>

<template>
  <nav class="flex text-sm items-center" aria-label="Breadcrumb">
    <ol class="flex items-center flex-wrap gap-3">
      <li
        v-for="(item, index) in filtered_items"
        :key="item.label"
        :class="{ 'cursor-pointer': !item.disabled }"
        class="flex-shrink-0 flex items-center"
        @click="!item.disabled && onItemClick(item)"
      >
        <div
          v-if="items.length !== filtered_items.length && index === filtered_items.length - 1"
          class="mr-3"
          @click.stop
        >
          <div
            class="flex items-center gap-3 font-medium text-gray-500 hover:text-gray-700"
          >
            <span
              v-tippy="{
                content: truncated_items.map((item) => item.label).join(` > `),
                placement: truncated_tooltip_placement,
              }"
              class="text-sm"
              :class="text_classes"
            >
              <HawkText :length="item.truncate_length" content="..." />
            </span>
            <slot v-if="$slots.separator" name="separator" />
            <IconHawkChevronRight v-else aria-hidden="true" class="w-[16px] h-[16px] text-gray-300" />
          </div>
        </div>
        <div
          class="flex items-center gap-3"
          :class="[
            (active_item ? active_item === item.uid : index === filtered_items.length - 1)
              ? show_active_color ? 'font-semibold text-primary-700 hover:text-gray-900' : 'text-gray-500 hover:text-gray-700'
              : 'font-medium text-gray-500 hover:text-gray-700',
          ]"
        >
          <span
            class="text-sm" :class="text_classes"
            :aria-current="item.current ? 'page' : undefined"
          >
            <HawkText :length="item.truncate_length" :content="item.label" />
          </span>
          <div v-if="index !== filtered_items.length - 1" class="shrink-0 text-gray-300">
            <slot v-if="$slots.separator" name="separator" />
            <IconHawkChevronRight v-else aria-hidden="true" class="w-[16px] h-[16px] text-gray-300" />
          </div>
        </div>
      </li>
    </ol>
  </nav>
</template>
