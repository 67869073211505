<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkInfoCircle, IconHawkWarnIcon } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  item: {
    type: Object,
  },
  custom_class: {
    type: String,
    default: null,
  },
  form: {
    type: Object,
    default: () => ({}),
  },
});

const default_grey = '#667085';
const $t = inject('$t');

const item = computed(() => props.item || props.form?.status || {});

const auth_store = useAuthStore();
// form_approval_tooltip
const form_approval = computed(() => {
  if (props.form?.is_approval_pending) {
    const approver = props.form?.approvers?.filter(
      user => auth_store?.logged_in_user_details?.user_id === user.uid,
    );
    approver.sort((a, b) => Number(b.approver) - Number(a.approver));
    return {
      pending: props.form?.is_approval_pending,
      show_waiting: approver.length === 0,
      is_reviewer: approver.length && !approver[0].approver,
      action_complete:
        approver.length
        && (approver[0].approver
          ? approver[0].outcome !== null
          : approver[0].outcome),
      ...(approver.length ? { approved: approver[0].outcome } : {}),
    };
  }
  return {};
});
const tooltip_message = computed(() => {
  if (form_approval?.value?.show_waiting) {
    return $t(
      'Waiting for approval',
    );
  }
  else if (form_approval?.value?.action_complete) {
    const approved = form_approval?.value?.approved ? $t('You approved') : $t('You denied');
    return `${form_approval?.value?.is_reviewer ? $t('You reviewed') : approved}. ${$t('Waiting for other members to approve')}`;
  }
  else if (form_approval?.value?.is_reviewer) {
    return $t(
      'Your review is pending',
    );
  }
  else {
    return $t(
      'Your approval is pending',
    );
  }
});
</script>

<template>
  <div class="flex p-1">
    <hawk-badge
      v-if="item.name"
      :custom_color="item.color || default_grey"
      :class="custom_class"
    >
      <div v-if="form?.is_approval_pending" v-tippy="tooltip_message" class="flex items-center">
        <span> <IconHawkInfoCircle v-if="form_approval?.show_waiting || form_approval?.action_complete" class="w-3 h-3" :style="`color:${item.color ? `${item.color}` : `${default_grey}`}`" />
          <IconHawkWarnIcon
            v-else
            class="w-3 h-3"
            :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`"
          />
        </span>
        <p class="px-1 capitalize truncate text-xs" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`">
          {{ item?.name }}
        </p>
        <span v-if="item.value" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`">({{ item?.value }})</span>
      </div>
      <div v-else-if="item?.overdueMetrics" v-tippy="item?.overdueMetrics" class="flex items-center">
        <span> <IconHawkInfoCircle v-if="item?.overdueMetrics" class="w-3 h-3" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`" /></span>
        <p class="px-1 truncate text-xs" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`">
          {{ item?.name }}
        </p>
        <span v-if="item.value" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`">({{ item?.value }})</span>
      </div>
      <div v-else class="flex items-center">
        <span> <IconHawkInfoCircle v-if="item?.overdueMetrics" :style="`color:${item?.color}`" class="w-3 h-3" /></span>
        <p class="px-1 capitalize truncate text-xs" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`">
          {{ item?.name }}
        </p>
        <span v-if="item.value" :style="`color:${item?.color ? `${item?.color}` : `${default_grey}`}`">({{ item?.value }})</span>
      </div>
    </hawk-badge>
  </div>
</template>
