<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkPinned } from '~/common/components/molecules/hawk-icons/icons.js';
import { useChatStore } from '~/common/stores/chat.store.js';

import CommentBlock from '~/tasks/pages/task-details-new/task-comments.vue/comment/task-comment.vue';
import TaskCommentInput from '~/tasks/pages/task-details-new/task-comments.vue/task-comment-input.vue';

const props = defineProps({
  comment: {
    type: Object,
    required: true,
  },
  is_reply: {
    type: Boolean,
    default: false,
  },
  task_uid: {
    type: String,
    required: true,
  },
  asset_id: {
    type: String,
    default: '',
  },
  module: {
    type: String,
    default: 'tasks',
  },
});

const location = inject('location');
const task_store = inject('task_store');

const chat_store = useChatStore();
const { logged_in_user_details } = useAuthStore();

const logged_in_user_id = logged_in_user_details?.user_id;
const can_reply = ref(false);
const replies = ref(null);
const show_replies = ref(true);

const comment_attachments = computed(() => {
  if (props.module === 'tasks') {
    return [
      ...(props.comment?.attachment_uids?.map?.(a => task_store.attachments_map?.[a]) || []),
      ...(props.comment?.attachment_upload || []), // for backwards compatibility
    ].filter(Boolean);
  }
  else {
    return props.comment?.attachment_uids || [];
  }
});

const liked_by_me = computed(() => {
  return props.comment?.own_reactions?.[0]?.type === 'like';
});

const disabled_controls = computed(() => {
  let disabled_control_list = ['settings'];
  if (props.is_reply)
    disabled_control_list = disabled_control_list.concat(['pin', 'reply']);
  if (logged_in_user_id !== props.comment?.user?.id)
    disabled_control_list.push('edit');
  return disabled_control_list;
});

watch(() => props.comment, async (new_comment) => {
  if (new_comment.reply_count)
    await getReplies();
}, { immediate: true });

async function getReplies() {
  if (props.comment?.id && props.comment?.reply_count) {
    const messages = await chat_store.chat_channel.getReplies(props.comment.id);
    replies.value = messages.messages || [];
  }
}

async function scrollToReply() {
  const element = document.getElementById(`${location}-block`);
  element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
  task_store.task_track_events('Comment added', { mentions: mentions.length, comment_type: 'Reply' }, props.task_uid);
}

function toggleReplyInput() {
  can_reply.value = true;
  show_replies.value = true;
  // Bring the input in view if it's not already
  const scroll = () => {
    const block = document.getElementById(`${location}-block`);
    const targetDiv = document.getElementById(`${props.comment.id}-input`);
    const targetRect = targetDiv.getBoundingClientRect();
    const location_offset = location === 'conversations' ? 180 : 195;
    const scrollPosition = block.scrollTop + targetRect.bottom - window.innerHeight + location_offset;
    block.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };
  setTimeout(() => scroll(), 100);
}

async function deleteComment() {
  await chat_store.chat.client.deleteMessage(props.comment.id);
  task_store.task_track_events('Comment deleted', {}, props.task_uid);
}

async function deleteAttachment(file) {
  await task_store.delete_task_attachments(props.task_uid, file.uid);
  task_store.task_track_events('Attachments removed', {
    count: 1,
    filetype: file.type || file.mime_type,
    filesize: file.file_size,
    location: 'Comment',
  }, props.task_uid);

  if (props.comment.text) {
    const attachment_uids = props.comment.attachment_uids.filter(uid => uid !== file.uid);

    const payload = {
      ...props.comment,
      attachment_uids,
      service: 'tasks',
    };

    // needed for backwards compatibility, because in the ancient times, a whole object used to be added to the message for each attachment
    // if the attachment the user trying to delete is not present in the attachment_uids array
    if (attachment_uids.length >= props.comment.attachment_uids.length) {
      const attachment_upload = props.comment.attachment_upload.filter(obj => obj.uid !== file.uid);
      payload.attachment_upload = attachment_upload;
    }

    await chat_store.updateMessage(payload);
    task_store.task_track_events('Comment updated', { mentions: mentions.length }, props.task_uid);
  }
  else {
    await deleteComment();
  }
}

function downloadAttachment(file) {
  task_store.task_track_events('Attachments downloaded', { filesize: file.file_size, filetype: file.mime_type || file.extension, location: 'Comment' }, props.task_uid);
}

function closeReply() {
  can_reply.value = false;
}

defineExpose({ closeReply });
</script>

<script>
export default {
  name: 'CommentBlock',
};
</script>

<template>
  <div :id="comment.id">
    <div
      class="flex items-start hover:bg-gray-50 rounded group px-3 py-2 -ml-3 -mr-3"
      :class="{
        'opacity-50': props.comment.type === 'deleted',
      }"
    >
      <HawkMembers size="xs" :members="props.comment?.user?.id" class="mr-2" />
      <div class="w-full">
        <div class="flex items-center mb-0.5 flex-wrap" :class="{ 'w-full justify-between': !comment_attachments?.length }">
          <div class="mr-2 text-center flex items-center">
            <HawkMembers :members="props.comment.user.id" :has_avatar="false" type="label" class="text-sm whitespace-nowrap" />
            <IconHawkPinned v-if="props.comment.pinned" class="h-3.5 w-3.5 mx-2 text-warning-400" />
          </div>
          <p
            v-if="props.comment.created_at" class="text-xs font-regular text-gray-600 text-center whitespace-nowrap"
            :class="{
              'group-hover:hidden': props.comment.type !== 'deleted',
            }"
          >
            {{ $date(props.comment.created_at, 'DATETIME_LONG_WITH_LONG_DAY_AND_MONTH') }}
          </p>
        </div>
        <p v-if="props.comment.type === 'deleted'" class="text-sm font-regular text-gray-700 italic">
          {{ is_reply ? $t('This reply was deleted') : $t('This message was deleted') }}
        </p>
        <div v-else class="-mx-2">
          <TaskCommentInput
            v-if="props.comment.text"
            :key="props.comment.text"
            :module="props.module"
            :task_uid="task_uid"
            :chat_store="chat_store"
            :user="logged_in_user_id"
            :comment="comment"
            :comment_input_config="{
              disabled_controls,
              liked_by_me,
              type: 'static',
              comment_text: props.comment.text,
              reaction_count: props.comment?.reaction_counts?.like,
            }"
            @reply="toggleReplyInput"
            @delete="deleteComment"
          >
            <template #right_footer>
              <div v-if="replies && replies.length" class="text-sm font-semibold text-blue-700 cursor-pointer" @click="show_replies = !show_replies">
                {{ show_replies ? $t('Hide replies') : $t('Show replies') }} ({{ replies?.length || 0 }})
              </div>
            </template>
          </TaskCommentInput>
          <div v-if="comment_attachments?.length" class="w-fit mb-4 mt-2">
            <HawkAttachmentsGrid
              :items="comment_attachments"
              :can_delete="logged_in_user_id === comment.user.id"
              @delete="deleteAttachment"
              @download="downloadAttachment"
              @view="task_store.task_track_events('Attachments viewed', { filesize: $event.file_size, filetype: $event.mime_type || $event.extension, location: 'Comment' }, task_uid)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_replies && replies?.length" class="ml-11">
      <div v-for="(reply, index) in replies" :key="index">
        <CommentBlock
          :asset_id="props.asset_id"
          :comment="reply"
          :is_reply="true"
        />
      </div>
    </div>
    <div v-if="can_reply" :id="`${comment.id}-input`" class="mt-2 mb-4 flex ml-11">
      <HawkMembers size="xs" :members="logged_in_user_id" class="mr-3 h-8" />
      <div class="w-full">
        <TaskCommentInput
          type="reply"
          :task_uid="task_uid"
          :module="props.module"
          :chat_store="chat_store"
          :user="logged_in_user_id"
          :comment="comment"
          :comment_input_config="{
            autofocus: true,
            disabled_controls: ['settings'],
            type: 'static',
          }"
          @send="scrollToReply"
          @close="can_reply = false"
        />
      </div>
    </div>
  </div>
</template>
