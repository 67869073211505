<script setup>
// --------------------------------- Imports -------------------------------- //
import { Validator } from '@vueform/vueform';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  sub_header: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'section',
  },
  section: {
    type: Object,
    default: () => {},
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['close', 'save']);

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //
const form_template_detail_store = useFormTemplateDetailStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const text_map = {
  section: {
    label: $t('Section name'),
    placeholder: $t('Enter name of duplicate section'),
    validation_msg: $t('Section with same name already exist'),
  },
  field: {
    label: $t('Field name'),
    placeholder: $t('Enter name of duplicate field'),
    validation_msg: $t('Field with same name already exist'),
  },
};

// ------------------------ Variables - Local - refs ------------------------ //
const form$ = ref(null);

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const active_section_names = computed(() => {
  if (form_template_detail_store.form_template_detail?.workflow) {
    const active_step_sections = (form_template_detail_store.steps_with_sections[form_template_detail_store.step_number]?.sections || []).filter(section => section.status === 'active');
    return active_step_sections.map(section => section.name.toLowerCase());
  }

  return (form_template_detail_store.sections || []).filter(section => section.status === 'active').map(section => section.name.toLowerCase());
});

const active_field_names = computed(() => {
  return (props.section?.fields || []).filter(field => field.status === 'active').map(field => field.name.toLowerCase());
});

// -------------------------------- Functions ------------------------------- //
function onApply() {
  emit('save', form$.value.data);
  emit('close');
}

const sameNameValidation = class extends Validator {
  get message() {
    return text_map[props.type].validation_msg;
  }

  check(value) {
    if (props.type === 'section')
      return value && !active_section_names.value.includes(value.toLowerCase());
    else
      return value && !active_field_names.value.includes(value.toLowerCase());
  }
};

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <HawkModalContainer content_class="rounded-lg w-[700px]">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onApply"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span v-if="sub_header" class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent :is_scroll="false">
          <div class="flex flex-col gap-6">
            <TextElement
              name="name"
              :rules="['required', sameNameValidation]"
              :label="text_map[type].label"
              :placeholder="text_map[type].placeholder"
            />
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                  {{ $t('Apply') }}
                </ButtonElement>
              </div>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
