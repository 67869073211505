<script setup>
import { useModal } from 'vue-final-modal';
import { IconHawkEmail, IconHawkInfoCircle } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import EmailPopup from '~/forms/components/form-workflows/sidebar/email-block/email-popup.vue';

const props = defineProps({
  form: {
    type: Object,
    default() {
      return {};
    },
  },
  step_number: {
    type: Number,
    default: -1,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  popup_title: {
    type: String,
    default: 'Custom Email',
  },
  design_email: {
    type: Boolean,
    default: false,
  },
  enable_document_suggestions: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['updateEmail']);

const email_popup = useModal({
  component: EmailPopup,
  attrs: {
    onClose() {
      email_popup.close();
    },
    onSave(val) {
      if (props.form.customize_email === false)
        emit('updateEmail', { ...val, customize_email: true });
      else
        emit('updateEmail', val);
      email_popup.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    content: 'This will clear the custom email, are you sure?',
    onClose() {
      delete_popup.close();
    },
    confirm() {
      emit('updateEmail', { subject: '', body: '', customize_email: false });
      email_popup.close();
      delete_popup.close();
    },
  },
});

function openEmailPopup() {
  email_popup.patchOptions({
    attrs: {
      is_disabled: props.is_disabled,
      is_popup: true,
      step_number: props.step_number,
      popup_title: props.popup_title,
      form: props.form,
      enable_document_suggestions: props.enable_document_suggestions,
      onResetForm() {
        delete_popup.open();
      },
    },
  });
  email_popup.open();
}
</script>

<template>
  <div v-if="design_email" class="rounded-xl border p-6 text-gray-900 border-warning-300 bg-warning-25">
    <div class="flex items-start">
      <div class="mr-6">
        <HawkFeaturedIcon theme="light-circle" size="lg" color="warning">
          <IconHawkInfoCircle />
        </HawkFeaturedIcon>
      </div>
      <div>
        <h4 class="text-md font-semibold text-gray-900 mb-2">
          {{ $t('Customize email') }}
        </h4>
        <p class="text-xs text-gray-600">
          {{ $t('Design custom email with dynamic data') }}
        </p>
      </div>
    </div>
    <HawkButton block class="mt-6" type="outlined" @click="openEmailPopup()">
      <IconHawkEmail class="w-5 h-5" />
      <span style="line-height: initial;">
        {{ $t('Design') }}
      </span>
    </HawkButton>
  </div>
  <div v-else class="flex justify-between items-center mb-3">
    <div class="text-gray-700 text-sm font-medium">
      {{ $t('Custom Email') }}
    </div>
    <HawkButton type="link" @click="openEmailPopup()">
      {{ form.customize_email ? $t('Edit') : $t('Add') }}
    </HawkButton>
  </div>
</template>
