<script setup>
import { groupBy } from 'lodash-es';
import { reactive, watch } from 'vue';
import { IconHawkTrashThree, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { useThermStore } from '~/therm/store/therm.store';

const therm_store = useThermStore();

const state = reactive({
  expand_right_sidebar: therm_store.expand_right_sidebar,
  count_hovered_feature_id: null,
});

const grouped_features = computed(() => {
  return groupBy(therm_store.selected_features, f => f.properties.featureTypeId);
});

function handleDeselectAllFeatures(id) {
  therm_store.selected_features = therm_store.selected_features.filter((feature) => {
    return feature.properties.featureTypeId !== Number(id);
  });
}

function handleDeselectFeature(feature_uid) {
  therm_store.selected_features = therm_store.selected_features.filter((feature) => {
    return feature.properties.uid !== feature_uid;
  });
}

function handleItemSelect(item) {
  handleDeselectFeature(item.uid);
}

function getFeatureName(f) {
  return f.properties?.modified_string_number || f.properties?.string_number || 'Untitled';
}

watch(() => therm_store.selected_features, (val) => {
  if (val?.length > 1) {
    state.expand_right_sidebar = !!therm_store.expand_right_sidebar;
    therm_store.expand_right_sidebar = false;
  }
  else {
    therm_store.expand_right_sidebar = state.expand_right_sidebar;
  }
  if (therm_store.map) {
    setTimeout(() => {
      therm_store.map.resize();
    }, 250);
  }
});
</script>

<template>
  <div v-if="therm_store.selected_features.length > 1" class="absolute bottom-5 right-5 w-[352px] bg-white rounded-lg">
    <div class="px-4 pt-4">
      <div class="flex justify-between items-center mb-2">
        <div class="text-[16px] font-semibold">
          {{ therm_store.selected_features.length }} {{ $t('selected') }}
        </div>
        <ThermBulkActions
          v-if="therm_store.selected_features.length"
          :is_default_menu="true"
          :defects="therm_store.selected_features.map(f => f.properties)"
        />
      </div>
    </div>
    <div class="px-4 pb-4 mt-3 max-h-[200px] scrollbar overflow-y-scroll">
      <div class="flex flex-col gap-4">
        <div v-for="(value, id) in grouped_features" :key="id">
          <div class="flex justify-between py-[7px] mb-2 items-center">
            <div class="flex gap-3 items-center">
              <div
                class="w-4 h-4 rounded-full"
                :style="
                  `border:1px solid ${
                    therm_store.feature_types[id].properties ? therm_store.feature_types[id].properties.color : 'red'
                  }; background: ${
                    therm_store.feature_types[id].properties ? therm_store.feature_types[id].properties.color : 'red'
                  }`
                "
              />
              <span class="font-medium text-sm text-gray-700">{{ therm_store.feature_types[id]?.name || 'Unassociated' }}</span>
            </div>
            <div class="min-w-5 min-h-5 bg-gray-100 cursor-pointer rounded-lg flex items-center justify-center" @mouseover="state.count_hovered_feature_id = id" @mouseleave="state.count_hovered_feature_id = null">
              <span class="font-medium text-xs text-gray-700" @click="handleDeselectAllFeatures(id)">
                <span v-if="state.count_hovered_feature_id === id"><IconHawkTrashThree class="w-3.5 h-3.5" /></span>
                <span v-else>{{ value.length }}</span>
              </span>
            </div>
          </div>
          <div class="relative flex items-center flex-row gap-2 flex-wrap">
            <div v-for="feature in value.slice(0, 2)" :key="feature.properties.uid">
              <span class="cursor-pointer pt-[3px] pr-[4px] w-[150px] pb-[4px] pl-[8px] border border-gray-300 rounded-lg flex items-center justify-center text-sm font-medium">
                <HawkText :content="getFeatureName(feature)" :length="12" />
                <IconHawkXClose class="w-[10px] h-[10px] text-gray-400 ml-auto" @click="handleDeselectFeature(feature.properties.uid)" />
              </span>
            </div>
            <div v-if="value.length > 2">
              <HawkMenu
                :items="value.slice(2).map(f => ({ label: getFeatureName(f), uid: f.properties?.uid }))"
                :has_bordered_trigger="false"
                position="fixed"
                class="z-30"
                additional_trigger_classes="!ring-0"
                additional_item_classes="!p-1 !min-w-[8rem]"
                additional_dropdown_classes="max-h-[200px] overflow-auto scrollbar"
                @select="handleItemSelect"
              >
                <template #trigger>
                  <span class="cursor-pointer pt-[3px] min-w-[60px] pr-[4px] pb-[4px] pl-[8px] border border-gray-300 rounded-lg flex items-center  text-sm font-medium">
                    + {{ `${value.length}` - 2 }}
                    <IconHawkXClose class="w-[10px] h-[10px] text-gray-400 ml-auto" />
                  </span>
                </template>
                <template #item="{ item }">
                  <div class="cursor-pointer p-1 w-[150px] h-[29px] border border-gray-300 rounded-lg flex items-center justify-center text-sm font-medium">
                    <HawkText :content="item.label" :length="10" class="cursor-pointer p-1" />
                    <IconHawkXClose class="w-[10px] h-[10px] text-gray-400 ml-auto mr-1" />
                  </div>
                </template>
              </HawkMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
