import { defineAsyncComponent } from 'vue';

export const IconHawkActivityHeart = defineAsyncComponent(() => import('~icons/hawk/activity-heart'));
export const IconHawkActivity = defineAsyncComponent(() => import('~icons/hawk/activity'));
export const IconHawkAirplay = defineAsyncComponent(() => import('~icons/hawk/airplay'));
export const IconHawkAirpods = defineAsyncComponent(() => import('~icons/hawk/airpods'));
export const IconHawkAlarmClockCheck = defineAsyncComponent(() => import('~icons/hawk/alarm-clock-check'));
export const IconHawkAlarmClockMinus = defineAsyncComponent(() => import('~icons/hawk/alarm-clock-minus'));
export const IconHawkAlarmClockOff = defineAsyncComponent(() => import('~icons/hawk/alarm-clock-off'));
export const IconHawkAlarmClockPlus = defineAsyncComponent(() => import('~icons/hawk/alarm-clock-plus'));
export const IconHawkAlarmClock = defineAsyncComponent(() => import('~icons/hawk/alarm-clock'));
export const IconHawkAlertCircle = defineAsyncComponent(() => import('~icons/hawk/alert-circle'));
export const IconHawkAlertHexagon = defineAsyncComponent(() => import('~icons/hawk/alert-hexagon'));
export const IconHawkAlertOctagon = defineAsyncComponent(() => import('~icons/hawk/alert-octagon'));
export const IconHawkAlertSquare = defineAsyncComponent(() => import('~icons/hawk/alert-square'));
export const IconHawkAlertTriangleRedRipple = defineAsyncComponent(() => import('~icons/hawk/alert-triangle-red-ripple'));
export const IconHawkAlertTriangle = defineAsyncComponent(() => import('~icons/hawk/alert-triangle'));
export const IconHawkAlignBottomOne = defineAsyncComponent(() => import('~icons/hawk/align-bottom-one'));
export const IconHawkAlignBottomTwo = defineAsyncComponent(() => import('~icons/hawk/align-bottom-two'));
export const IconHawkAlignCenter = defineAsyncComponent(() => import('~icons/hawk/align-center'));
export const IconHawkAlignHorizontalCentreOne = defineAsyncComponent(() => import('~icons/hawk/align-horizontal-centre-one'));
export const IconHawkAlignHorizontalCentreTwo = defineAsyncComponent(() => import('~icons/hawk/align-horizontal-centre-two'));
export const IconHawkAlignJustify = defineAsyncComponent(() => import('~icons/hawk/align-justify'));
export const IconHawkAlignLeftOne = defineAsyncComponent(() => import('~icons/hawk/align-left-one'));
export const IconHawkAlignLeftTwo = defineAsyncComponent(() => import('~icons/hawk/align-left-two'));
export const IconHawkAlignLeft = defineAsyncComponent(() => import('~icons/hawk/align-left'));
export const IconHawkAlignRightOne = defineAsyncComponent(() => import('~icons/hawk/align-right-one'));
export const IconHawkAlignRightTwo = defineAsyncComponent(() => import('~icons/hawk/align-right-two'));
export const IconHawkAlignRight = defineAsyncComponent(() => import('~icons/hawk/align-right'));
export const IconHawkAlignTopArrowOne = defineAsyncComponent(() => import('~icons/hawk/align-top-arrow-one'));
export const IconHawkAlignTopArrowTwo = defineAsyncComponent(() => import('~icons/hawk/align-top-arrow-two'));
export const IconHawkAlignVerticalCenterOne = defineAsyncComponent(() => import('~icons/hawk/align-vertical-center-one'));
export const IconHawkAlignVerticalCenterTwo = defineAsyncComponent(() => import('~icons/hawk/align-vertical-center-two'));
export const IconHawkAnchor = defineAsyncComponent(() => import('~icons/hawk/anchor'));
export const IconHawkAnnotationAlert = defineAsyncComponent(() => import('~icons/hawk/annotation-alert'));
export const IconHawkAnnotationCheck = defineAsyncComponent(() => import('~icons/hawk/annotation-check'));
export const IconHawkAnnotationDots = defineAsyncComponent(() => import('~icons/hawk/annotation-dots'));
export const IconHawkAnnotationHeart = defineAsyncComponent(() => import('~icons/hawk/annotation-heart'));
export const IconHawkAnnotationInfo = defineAsyncComponent(() => import('~icons/hawk/annotation-info'));
export const IconHawkAnnotationPlus = defineAsyncComponent(() => import('~icons/hawk/annotation-plus'));
export const IconHawkAnnotationQuestion = defineAsyncComponent(() => import('~icons/hawk/annotation-question'));
export const IconHawkAnnotationX = defineAsyncComponent(() => import('~icons/hawk/annotation-x'));
export const IconHawkAnnotation = defineAsyncComponent(() => import('~icons/hawk/annotation'));
export const IconHawkAnnouncementOne = defineAsyncComponent(() => import('~icons/hawk/announcement-one'));
export const IconHawkAnnouncementThree = defineAsyncComponent(() => import('~icons/hawk/announcement-three'));
export const IconHawkAnnouncementTwo = defineAsyncComponent(() => import('~icons/hawk/announcement-two'));
export const IconHawkArchive = defineAsyncComponent(() => import('~icons/hawk/archive'));
export const IconHawkArrowBlockDown = defineAsyncComponent(() => import('~icons/hawk/arrow-block-down'));
export const IconHawkArrowBlockLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-block-left'));
export const IconHawkArrowBlockRight = defineAsyncComponent(() => import('~icons/hawk/arrow-block-right'));
export const IconHawkArrowBlockUp = defineAsyncComponent(() => import('~icons/hawk/arrow-block-up'));
export const IconHawkArrowCircleBrokenDownLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-down-left'));
export const IconHawkArrowCircleBrokenDownRight = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-down-right'));
export const IconHawkArrowCircleBrokenDown = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-down'));
export const IconHawkArrowCircleBrokenLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-left'));
export const IconHawkArrowCircleBrokenRight = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-right'));
export const IconHawkArrowCircleBrokenUpLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-up-left'));
export const IconHawkArrowCircleBrokenUpRight = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-up-right'));
export const IconHawkArrowCircleBrokenUp = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-broken-up'));
export const IconHawkArrowCircleDownLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-down-left'));
export const IconHawkArrowCircleDownRight = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-down-right'));
export const IconHawkArrowCircleDown = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-down'));
export const IconHawkArrowCircleLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-left'));
export const IconHawkArrowCircleRight = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-right'));
export const IconHawkArrowCircleUpLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-up-left'));
export const IconHawkArrowCircleUpRight = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-up-right'));
export const IconHawkArrowCircleUp = defineAsyncComponent(() => import('~icons/hawk/arrow-circle-up'));
export const IconHawkArrowDownLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-down-left'));
export const IconHawkArrowDownRight = defineAsyncComponent(() => import('~icons/hawk/arrow-down-right'));
export const IconHawkArrowDown = defineAsyncComponent(() => import('~icons/hawk/arrow-down'));
export const IconHawkArrowLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-left'));
export const IconHawkArrowNarrowDownLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-down-left'));
export const IconHawkArrowNarrowDownRight = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-down-right'));
export const IconHawkArrowNarrowDown = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-down'));
export const IconHawkArrowNarrowLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-left'));
export const IconHawkArrowNarrowRight = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-right'));
export const IconHawkArrowNarrowUpLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-up-left'));
export const IconHawkArrowNarrowUpRight = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-up-right'));
export const IconHawkArrowNarrowUp = defineAsyncComponent(() => import('~icons/hawk/arrow-narrow-up'));
export const IconHawkArrowRight = defineAsyncComponent(() => import('~icons/hawk/arrow-right'));
export const IconHawkArrowSquareDownLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-square-down-left'));
export const IconHawkArrowSquareDownRight = defineAsyncComponent(() => import('~icons/hawk/arrow-square-down-right'));
export const IconHawkArrowSquareDown = defineAsyncComponent(() => import('~icons/hawk/arrow-square-down'));
export const IconHawkArrowSquareLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-square-left'));
export const IconHawkArrowSquareRight = defineAsyncComponent(() => import('~icons/hawk/arrow-square-right'));
export const IconHawkArrowSquareUpLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-square-up-left'));
export const IconHawkArrowSquareUpRight = defineAsyncComponent(() => import('~icons/hawk/arrow-square-up-right'));
export const IconHawkArrowSquareUp = defineAsyncComponent(() => import('~icons/hawk/arrow-square-up'));
export const IconHawkArrowUpLeft = defineAsyncComponent(() => import('~icons/hawk/arrow-up-left'));
export const IconHawkArrowUpRight = defineAsyncComponent(() => import('~icons/hawk/arrow-up-right'));
export const IconHawkArrowUp = defineAsyncComponent(() => import('~icons/hawk/arrow-up'));
export const IconHawkArrowsDown = defineAsyncComponent(() => import('~icons/hawk/arrows-down'));
export const IconHawkArrowsLeft = defineAsyncComponent(() => import('~icons/hawk/arrows-left'));
export const IconHawkArrowsRight = defineAsyncComponent(() => import('~icons/hawk/arrows-right'));
export const IconHawkArrowsTriangle = defineAsyncComponent(() => import('~icons/hawk/arrows-triangle'));
export const IconHawkArrowsUp = defineAsyncComponent(() => import('~icons/hawk/arrows-up'));
export const IconHawkAsteriskOne = defineAsyncComponent(() => import('~icons/hawk/asterisk-one'));
export const IconHawkAsteriskTwo = defineAsyncComponent(() => import('~icons/hawk/asterisk-two'));
export const IconHawkAsterisk = defineAsyncComponent(() => import('~icons/hawk/asterisk'));
export const IconHawkAtSign = defineAsyncComponent(() => import('~icons/hawk/at-sign'));
export const IconHawkAtomOne = defineAsyncComponent(() => import('~icons/hawk/atom-one'));
export const IconHawkAtomTwo = defineAsyncComponent(() => import('~icons/hawk/atom-two'));
export const IconHawkAttachmentOne = defineAsyncComponent(() => import('~icons/hawk/attachment-one'));
export const IconHawkAttachmentTwo = defineAsyncComponent(() => import('~icons/hawk/attachment-two'));
export const IconHawkAutonumber = defineAsyncComponent(() => import('~icons/hawk/autonumber'));
export const IconHawkAwardFive = defineAsyncComponent(() => import('~icons/hawk/award-five'));
export const IconHawkAwardFour = defineAsyncComponent(() => import('~icons/hawk/award-four'));
export const IconHawkAwardOne = defineAsyncComponent(() => import('~icons/hawk/award-one'));
export const IconHawkAwardThree = defineAsyncComponent(() => import('~icons/hawk/award-three'));
export const IconHawkAwardTwo = defineAsyncComponent(() => import('~icons/hawk/award-two'));
export const IconHawkBackpack = defineAsyncComponent(() => import('~icons/hawk/backpack'));
export const IconHawkBankNoteOne = defineAsyncComponent(() => import('~icons/hawk/bank-note-one'));
export const IconHawkBankNoteThree = defineAsyncComponent(() => import('~icons/hawk/bank-note-three'));
export const IconHawkBankNoteTwo = defineAsyncComponent(() => import('~icons/hawk/bank-note-two'));
export const IconHawkBank = defineAsyncComponent(() => import('~icons/hawk/bank'));
export const IconHawkBarChartCircleOne = defineAsyncComponent(() => import('~icons/hawk/bar-chart-circle-one'));
export const IconHawkBarChartCircleThree = defineAsyncComponent(() => import('~icons/hawk/bar-chart-circle-three'));
export const IconHawkBarChartCircleTwo = defineAsyncComponent(() => import('~icons/hawk/bar-chart-circle-two'));
export const IconHawkBarChartEight = defineAsyncComponent(() => import('~icons/hawk/bar-chart-eight'));
export const IconHawkBarChartEleven = defineAsyncComponent(() => import('~icons/hawk/bar-chart-eleven'));
export const IconHawkBarChartFive = defineAsyncComponent(() => import('~icons/hawk/bar-chart-five'));
export const IconHawkBarChartFour = defineAsyncComponent(() => import('~icons/hawk/bar-chart-four'));
export const IconHawkBarChartNine = defineAsyncComponent(() => import('~icons/hawk/bar-chart-nine'));
export const IconHawkBarChartOne = defineAsyncComponent(() => import('~icons/hawk/bar-chart-one'));
export const IconHawkBarChartSeven = defineAsyncComponent(() => import('~icons/hawk/bar-chart-seven'));
export const IconHawkBarChartSix = defineAsyncComponent(() => import('~icons/hawk/bar-chart-six'));
export const IconHawkBarChartSquareDown = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-down'));
export const IconHawkBarChartSquareMinus = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-minus'));
export const IconHawkBarChartSquareOne = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-one'));
export const IconHawkBarChartSquarePlus = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-plus'));
export const IconHawkBarChartSquareThree = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-three'));
export const IconHawkBarChartSquareTwo = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-two'));
export const IconHawkBarChartSquareUp = defineAsyncComponent(() => import('~icons/hawk/bar-chart-square-up'));
export const IconHawkBarChartTen = defineAsyncComponent(() => import('~icons/hawk/bar-chart-ten'));
export const IconHawkBarChartThree = defineAsyncComponent(() => import('~icons/hawk/bar-chart-three'));
export const IconHawkBarChartTwelve = defineAsyncComponent(() => import('~icons/hawk/bar-chart-twelve'));
export const IconHawkBarChartTwo = defineAsyncComponent(() => import('~icons/hawk/bar-chart-two'));
export const IconHawkBarLineChart = defineAsyncComponent(() => import('~icons/hawk/bar-line-chart'));
export const IconHawkBasicSection = defineAsyncComponent(() => import('~icons/hawk/basic-section'));
export const IconHawkBatteryChargingOne = defineAsyncComponent(() => import('~icons/hawk/battery-charging-one'));
export const IconHawkBatteryChargingTwo = defineAsyncComponent(() => import('~icons/hawk/battery-charging-two'));
export const IconHawkBatteryEmpty = defineAsyncComponent(() => import('~icons/hawk/battery-empty'));
export const IconHawkBatteryFull = defineAsyncComponent(() => import('~icons/hawk/battery-full'));
export const IconHawkBatteryLow = defineAsyncComponent(() => import('~icons/hawk/battery-low'));
export const IconHawkBatteryMid = defineAsyncComponent(() => import('~icons/hawk/battery-mid'));
export const IconHawkBeakerOne = defineAsyncComponent(() => import('~icons/hawk/beaker-one'));
export const IconHawkBeakerTwo = defineAsyncComponent(() => import('~icons/hawk/beaker-two'));
export const IconHawkBellFour = defineAsyncComponent(() => import('~icons/hawk/bell-four'));
export const IconHawkBellMinus = defineAsyncComponent(() => import('~icons/hawk/bell-minus'));
export const IconHawkBellOffOne = defineAsyncComponent(() => import('~icons/hawk/bell-off-one'));
export const IconHawkBellOffThree = defineAsyncComponent(() => import('~icons/hawk/bell-off-three'));
export const IconHawkBellOffTwo = defineAsyncComponent(() => import('~icons/hawk/bell-off-two'));
export const IconHawkBellOne = defineAsyncComponent(() => import('~icons/hawk/bell-one'));
export const IconHawkBellPlus = defineAsyncComponent(() => import('~icons/hawk/bell-plus'));
export const IconHawkBellRingingFour = defineAsyncComponent(() => import('~icons/hawk/bell-ringing-four'));
export const IconHawkBellRingingOne = defineAsyncComponent(() => import('~icons/hawk/bell-ringing-one'));
export const IconHawkBellRingingThree = defineAsyncComponent(() => import('~icons/hawk/bell-ringing-three'));
export const IconHawkBellRingingTwo = defineAsyncComponent(() => import('~icons/hawk/bell-ringing-two'));
export const IconHawkBellThree = defineAsyncComponent(() => import('~icons/hawk/bell-three'));
export const IconHawkBellTwo = defineAsyncComponent(() => import('~icons/hawk/bell-two'));
export const IconHawkBezierCurveOne = defineAsyncComponent(() => import('~icons/hawk/bezier-curve-one'));
export const IconHawkBezierCurveThree = defineAsyncComponent(() => import('~icons/hawk/bezier-curve-three'));
export const IconHawkBezierCurveTwo = defineAsyncComponent(() => import('~icons/hawk/bezier-curve-two'));
export const IconHawkBluetoothConnect = defineAsyncComponent(() => import('~icons/hawk/bluetooth-connect'));
export const IconHawkBluetoothOff = defineAsyncComponent(() => import('~icons/hawk/bluetooth-off'));
export const IconHawkBluetoothOn = defineAsyncComponent(() => import('~icons/hawk/bluetooth-on'));
export const IconHawkBluetoothSignal = defineAsyncComponent(() => import('~icons/hawk/bluetooth-signal'));
export const IconHawkBoldOne = defineAsyncComponent(() => import('~icons/hawk/bold-one'));
export const IconHawkBoldSquare = defineAsyncComponent(() => import('~icons/hawk/bold-square'));
export const IconHawkBoldTwo = defineAsyncComponent(() => import('~icons/hawk/bold-two'));
export const IconHawkBookClosed = defineAsyncComponent(() => import('~icons/hawk/book-closed'));
export const IconHawkBookOpenOne = defineAsyncComponent(() => import('~icons/hawk/book-open-one'));
export const IconHawkBookOpenTwo = defineAsyncComponent(() => import('~icons/hawk/book-open-two'));
export const IconHawkBookmarkAdd = defineAsyncComponent(() => import('~icons/hawk/bookmark-add'));
export const IconHawkBookmarkCheck = defineAsyncComponent(() => import('~icons/hawk/bookmark-check'));
export const IconHawkBookmarkMinus = defineAsyncComponent(() => import('~icons/hawk/bookmark-minus'));
export const IconHawkBookmarkX = defineAsyncComponent(() => import('~icons/hawk/bookmark-x'));
export const IconHawkBookmark = defineAsyncComponent(() => import('~icons/hawk/bookmark'));
export const IconHawkBox = defineAsyncComponent(() => import('~icons/hawk/box'));
export const IconHawkBracketsCheck = defineAsyncComponent(() => import('~icons/hawk/brackets-check'));
export const IconHawkBracketsEllipses = defineAsyncComponent(() => import('~icons/hawk/brackets-ellipses'));
export const IconHawkBracketsMinus = defineAsyncComponent(() => import('~icons/hawk/brackets-minus'));
export const IconHawkBracketsPlus = defineAsyncComponent(() => import('~icons/hawk/brackets-plus'));
export const IconHawkBracketsSlash = defineAsyncComponent(() => import('~icons/hawk/brackets-slash'));
export const IconHawkBracketsX = defineAsyncComponent(() => import('~icons/hawk/brackets-x'));
export const IconHawkBrackets = defineAsyncComponent(() => import('~icons/hawk/brackets'));
export const IconHawkBriefcaseOne = defineAsyncComponent(() => import('~icons/hawk/briefcase-one'));
export const IconHawkBriefcaseTwo = defineAsyncComponent(() => import('~icons/hawk/briefcase-two'));
export const IconHawkBrowser = defineAsyncComponent(() => import('~icons/hawk/browser'));
export const IconHawkBrushHeavy = defineAsyncComponent(() => import('~icons/hawk/brush-heavy'));
export const IconHawkBrushLight = defineAsyncComponent(() => import('~icons/hawk/brush-light'));
export const IconHawkBrushMedium = defineAsyncComponent(() => import('~icons/hawk/brush-medium'));
export const IconHawkBrushOne = defineAsyncComponent(() => import('~icons/hawk/brush-one'));
export const IconHawkBrushThree = defineAsyncComponent(() => import('~icons/hawk/brush-three'));
export const IconHawkBrushTwo = defineAsyncComponent(() => import('~icons/hawk/brush-two'));
export const IconHawkBuildingEight = defineAsyncComponent(() => import('~icons/hawk/building-eight'));
export const IconHawkBuildingFive = defineAsyncComponent(() => import('~icons/hawk/building-five'));
export const IconHawkBuildingFour = defineAsyncComponent(() => import('~icons/hawk/building-four'));
export const IconHawkBuildingOne = defineAsyncComponent(() => import('~icons/hawk/building-one'));
export const IconHawkBuildingSeven = defineAsyncComponent(() => import('~icons/hawk/building-seven'));
export const IconHawkBuildingSix = defineAsyncComponent(() => import('~icons/hawk/building-six'));
export const IconHawkBuildingThree = defineAsyncComponent(() => import('~icons/hawk/building-three'));
export const IconHawkBuildingTwo = defineAsyncComponent(() => import('~icons/hawk/building-two'));
export const IconHawkBus = defineAsyncComponent(() => import('~icons/hawk/bus'));
export const IconHawkCalculator = defineAsyncComponent(() => import('~icons/hawk/calculator'));
export const IconHawkCalendarCheckOne = defineAsyncComponent(() => import('~icons/hawk/calendar-check-one'));
export const IconHawkCalendarCheckTwo = defineAsyncComponent(() => import('~icons/hawk/calendar-check-two'));
export const IconHawkCalendarDate = defineAsyncComponent(() => import('~icons/hawk/calendar-date'));
export const IconHawkCalendarHeartOne = defineAsyncComponent(() => import('~icons/hawk/calendar-heart-one'));
export const IconHawkCalendarHeartTwo = defineAsyncComponent(() => import('~icons/hawk/calendar-heart-two'));
export const IconHawkCalendarMinusOne = defineAsyncComponent(() => import('~icons/hawk/calendar-minus-one'));
export const IconHawkCalendarMinusTwo = defineAsyncComponent(() => import('~icons/hawk/calendar-minus-two'));
export const IconHawkCalendarPlusOne = defineAsyncComponent(() => import('~icons/hawk/calendar-plus-one'));
export const IconHawkCalendarPlusTwo = defineAsyncComponent(() => import('~icons/hawk/calendar-plus-two'));
export const IconHawkCalendarToday = defineAsyncComponent(() => import('~icons/hawk/calendar-today'));
export const IconHawkCalendar = defineAsyncComponent(() => import('~icons/hawk/calendar'));
export const IconHawkCalibrate = defineAsyncComponent(() => import('~icons/hawk/calibrate'));
export const IconHawkCameraLens = defineAsyncComponent(() => import('~icons/hawk/camera-lens'));
export const IconHawkCameraOff = defineAsyncComponent(() => import('~icons/hawk/camera-off'));
export const IconHawkCameraOne = defineAsyncComponent(() => import('~icons/hawk/camera-one'));
export const IconHawkCameraPlus = defineAsyncComponent(() => import('~icons/hawk/camera-plus'));
export const IconHawkCameraThree = defineAsyncComponent(() => import('~icons/hawk/camera-three'));
export const IconHawkCameraTwo = defineAsyncComponent(() => import('~icons/hawk/camera-two'));
export const IconHawkCarOne = defineAsyncComponent(() => import('~icons/hawk/car-one'));
export const IconHawkCarTwo = defineAsyncComponent(() => import('~icons/hawk/car-two'));
export const IconHawkCertificateOne = defineAsyncComponent(() => import('~icons/hawk/certificate-one'));
export const IconHawkCertificateTwo = defineAsyncComponent(() => import('~icons/hawk/certificate-two'));
export const IconHawkChartBreakoutCircle = defineAsyncComponent(() => import('~icons/hawk/chart-breakout-circle'));
export const IconHawkChartBreakoutSquare = defineAsyncComponent(() => import('~icons/hawk/chart-breakout-square'));
export const IconHawkCheckCircleBroken = defineAsyncComponent(() => import('~icons/hawk/check-circle-broken'));
export const IconHawkCheckCircleFilled = defineAsyncComponent(() => import('~icons/hawk/check-circle-filled'));
export const IconHawkCheckCircleGreen = defineAsyncComponent(() => import('~icons/hawk/check-circle-green'));
export const IconHawkCheckCircle = defineAsyncComponent(() => import('~icons/hawk/check-circle'));
export const IconHawkCheckDoneOne = defineAsyncComponent(() => import('~icons/hawk/check-done-one'));
export const IconHawkCheckDoneTwo = defineAsyncComponent(() => import('~icons/hawk/check-done-two'));
export const IconHawkCheckFive = defineAsyncComponent(() => import('~icons/hawk/check-five'));
export const IconHawkCheckFour = defineAsyncComponent(() => import('~icons/hawk/check-four'));
export const IconHawkCheckHeart = defineAsyncComponent(() => import('~icons/hawk/check-heart'));
export const IconHawkCheckSquareBroken = defineAsyncComponent(() => import('~icons/hawk/check-square-broken'));
export const IconHawkCheckSquare = defineAsyncComponent(() => import('~icons/hawk/check-square'));
export const IconHawkCheckThree = defineAsyncComponent(() => import('~icons/hawk/check-three'));
export const IconHawkCheckTwo = defineAsyncComponent(() => import('~icons/hawk/check-two'));
export const IconHawkCheckVerifiedOne = defineAsyncComponent(() => import('~icons/hawk/check-verified-one'));
export const IconHawkCheckVerifiedThree = defineAsyncComponent(() => import('~icons/hawk/check-verified-three'));
export const IconHawkCheckVerifiedTwo = defineAsyncComponent(() => import('~icons/hawk/check-verified-two'));
export const IconHawkCheck = defineAsyncComponent(() => import('~icons/hawk/check'));
export const IconHawkCheckboxBaseBlue = defineAsyncComponent(() => import('~icons/hawk/checkbox_base_blue'));
export const IconHawkCheckboxBase = defineAsyncComponent(() => import('~icons/hawk/checkbox-base'));
export const IconHawkChecklistSection = defineAsyncComponent(() => import('~icons/hawk/checklist-section'));
export const IconHawkChevronDownDouble = defineAsyncComponent(() => import('~icons/hawk/chevron-down-double'));
export const IconHawkChevronDownTerra = defineAsyncComponent(() => import('~icons/hawk/chevron-down-terra'));
export const IconHawkChevronDown = defineAsyncComponent(() => import('~icons/hawk/chevron-down'));
export const IconHawkChevronLeftDouble = defineAsyncComponent(() => import('~icons/hawk/chevron-left-double'));
export const IconHawkChevronLeft = defineAsyncComponent(() => import('~icons/hawk/chevron-left'));
export const IconHawkChevronRightDouble = defineAsyncComponent(() => import('~icons/hawk/chevron-right-double'));
export const IconHawkChevronRight = defineAsyncComponent(() => import('~icons/hawk/chevron-right'));
export const IconHawkChevronSelectorHorizontal = defineAsyncComponent(() => import('~icons/hawk/chevron-selector-horizontal'));
export const IconHawkChevronSelectorVertical = defineAsyncComponent(() => import('~icons/hawk/chevron-selector-vertical'));
export const IconHawkChevronUpDouble = defineAsyncComponent(() => import('~icons/hawk/chevron-up-double'));
export const IconHawkChevronUpTwo = defineAsyncComponent(() => import('~icons/hawk/chevron-up-two'));
export const IconHawkChevronUp = defineAsyncComponent(() => import('~icons/hawk/chevron-up'));
export const IconHawkChromeCast = defineAsyncComponent(() => import('~icons/hawk/chrome-cast'));
export const IconHawkCircleCut = defineAsyncComponent(() => import('~icons/hawk/circle-cut'));
export const IconHawkCircle = defineAsyncComponent(() => import('~icons/hawk/circle'));
export const IconHawkClapperboard = defineAsyncComponent(() => import('~icons/hawk/clapperboard'));
export const IconHawkClipboardAttachment = defineAsyncComponent(() => import('~icons/hawk/clipboard-attachment'));
export const IconHawkClipboardCheck = defineAsyncComponent(() => import('~icons/hawk/clipboard-check'));
export const IconHawkClipboardDownload = defineAsyncComponent(() => import('~icons/hawk/clipboard-download'));
export const IconHawkClipboardMinus = defineAsyncComponent(() => import('~icons/hawk/clipboard-minus'));
export const IconHawkClipboardPlus = defineAsyncComponent(() => import('~icons/hawk/clipboard-plus'));
export const IconHawkClipboardX = defineAsyncComponent(() => import('~icons/hawk/clipboard-x'));
export const IconHawkClipboard = defineAsyncComponent(() => import('~icons/hawk/clipboard'));
export const IconHawkClockCheck = defineAsyncComponent(() => import('~icons/hawk/clock-check'));
export const IconHawkClockFastForward = defineAsyncComponent(() => import('~icons/hawk/clock-fast-forward'));
export const IconHawkClockHistory = defineAsyncComponent(() => import('~icons/hawk/clock-history'));
export const IconHawkClockPlus = defineAsyncComponent(() => import('~icons/hawk/clock-plus'));
export const IconHawkClockRefresh = defineAsyncComponent(() => import('~icons/hawk/clock-refresh'));
export const IconHawkClockRewind = defineAsyncComponent(() => import('~icons/hawk/clock-rewind'));
export const IconHawkClockSnooze = defineAsyncComponent(() => import('~icons/hawk/clock-snooze'));
export const IconHawkClockStopwatch = defineAsyncComponent(() => import('~icons/hawk/clock-stopwatch'));
export const IconHawkClock = defineAsyncComponent(() => import('~icons/hawk/clock'));
export const IconHawkCloudBlankOne = defineAsyncComponent(() => import('~icons/hawk/cloud-blank-one'));
export const IconHawkCloudBlankTwo = defineAsyncComponent(() => import('~icons/hawk/cloud-blank-two'));
export const IconHawkCloudLightning = defineAsyncComponent(() => import('~icons/hawk/cloud-lightning'));
export const IconHawkCloudMoon = defineAsyncComponent(() => import('~icons/hawk/cloud-moon'));
export const IconHawkCloudOff = defineAsyncComponent(() => import('~icons/hawk/cloud-off'));
export const IconHawkCloudOne = defineAsyncComponent(() => import('~icons/hawk/cloud-one'));
export const IconHawkCloudRainingFive = defineAsyncComponent(() => import('~icons/hawk/cloud-raining-five'));
export const IconHawkCloudRainingFour = defineAsyncComponent(() => import('~icons/hawk/cloud-raining-four'));
export const IconHawkCloudRainingOne = defineAsyncComponent(() => import('~icons/hawk/cloud-raining-one'));
export const IconHawkCloudRainingSix = defineAsyncComponent(() => import('~icons/hawk/cloud-raining-six'));
export const IconHawkCloudRainingThree = defineAsyncComponent(() => import('~icons/hawk/cloud-raining-three'));
export const IconHawkCloudRainingTwo = defineAsyncComponent(() => import('~icons/hawk/cloud-raining-two'));
export const IconHawkCloudSnowingOne = defineAsyncComponent(() => import('~icons/hawk/cloud-snowing-one'));
export const IconHawkCloudSnowingTwo = defineAsyncComponent(() => import('~icons/hawk/cloud-snowing-two'));
export const IconHawkCloudSunOne = defineAsyncComponent(() => import('~icons/hawk/cloud-sun-one'));
export const IconHawkCloudSunThree = defineAsyncComponent(() => import('~icons/hawk/cloud-sun-three'));
export const IconHawkCloudSunTwo = defineAsyncComponent(() => import('~icons/hawk/cloud-sun-two'));
export const IconHawkCloudThree = defineAsyncComponent(() => import('~icons/hawk/cloud-three'));
export const IconHawkCloudTwo = defineAsyncComponent(() => import('~icons/hawk/cloud-two'));
export const IconHawkCodeBrowser = defineAsyncComponent(() => import('~icons/hawk/code-browser'));
export const IconHawkCodeCircleOne = defineAsyncComponent(() => import('~icons/hawk/code-circle-one'));
export const IconHawkCodeCircleThree = defineAsyncComponent(() => import('~icons/hawk/code-circle-three'));
export const IconHawkCodeCircleTwo = defineAsyncComponent(() => import('~icons/hawk/code-circle-two'));
export const IconHawkCodeOne = defineAsyncComponent(() => import('~icons/hawk/code-one'));
export const IconHawkCodeSnippetOne = defineAsyncComponent(() => import('~icons/hawk/code-snippet-one'));
export const IconHawkCodeSnippetTwo = defineAsyncComponent(() => import('~icons/hawk/code-snippet-two'));
export const IconHawkCodeSquareOne = defineAsyncComponent(() => import('~icons/hawk/code-square-one'));
export const IconHawkCodeSquareTwo = defineAsyncComponent(() => import('~icons/hawk/code-square-two'));
export const IconHawkCodeTwo = defineAsyncComponent(() => import('~icons/hawk/code-two'));
export const IconHawkCodepen = defineAsyncComponent(() => import('~icons/hawk/codepen'));
export const IconHawkCoinsFour = defineAsyncComponent(() => import('~icons/hawk/coins-four'));
export const IconHawkCoinsHand = defineAsyncComponent(() => import('~icons/hawk/coins-hand'));
export const IconHawkCoinsOne = defineAsyncComponent(() => import('~icons/hawk/coins-one'));
export const IconHawkCoinsStackedFour = defineAsyncComponent(() => import('~icons/hawk/coins-stacked-four'));
export const IconHawkCoinsStackedOne = defineAsyncComponent(() => import('~icons/hawk/coins-stacked-one'));
export const IconHawkCoinsStackedThree = defineAsyncComponent(() => import('~icons/hawk/coins-stacked-three'));
export const IconHawkCoinsStackedTwo = defineAsyncComponent(() => import('~icons/hawk/coins-stacked-two'));
export const IconHawkCoinsSwapOne = defineAsyncComponent(() => import('~icons/hawk/coins-swap-one'));
export const IconHawkCoinsSwapTwo = defineAsyncComponent(() => import('~icons/hawk/coins-swap-two'));
export const IconHawkCoinsThree = defineAsyncComponent(() => import('~icons/hawk/coins-three'));
export const IconHawkCoinsTwo = defineAsyncComponent(() => import('~icons/hawk/coins-two'));
export const IconHawkColorsCopy = defineAsyncComponent(() => import('~icons/hawk/colors-copy'));
export const IconHawkColors = defineAsyncComponent(() => import('~icons/hawk/colors'));
export const IconHawkColumnsOne = defineAsyncComponent(() => import('~icons/hawk/columns-one'));
export const IconHawkColumnsThree = defineAsyncComponent(() => import('~icons/hawk/columns-three'));
export const IconHawkColumnsTwo = defineAsyncComponent(() => import('~icons/hawk/columns-two'));
export const IconHawkCommand = defineAsyncComponent(() => import('~icons/hawk/command'));
export const IconHawkCompare = defineAsyncComponent(() => import('~icons/hawk/compare'));
export const IconHawkCompassOne = defineAsyncComponent(() => import('~icons/hawk/compass-one'));
export const IconHawkCompassThree = defineAsyncComponent(() => import('~icons/hawk/compass-three'));
export const IconHawkCompassTwo = defineAsyncComponent(() => import('~icons/hawk/compass-two'));
export const IconHawkCompass = defineAsyncComponent(() => import('~icons/hawk/compass'));
export const IconHawkContainer = defineAsyncComponent(() => import('~icons/hawk/container'));
export const IconHawkContrastOne = defineAsyncComponent(() => import('~icons/hawk/contrast-one'));
export const IconHawkContrastThree = defineAsyncComponent(() => import('~icons/hawk/contrast-three'));
export const IconHawkContrastTwo = defineAsyncComponent(() => import('~icons/hawk/contrast-two'));
export const IconHawkCopyFive = defineAsyncComponent(() => import('~icons/hawk/copy-five'));
export const IconHawkCopyFour = defineAsyncComponent(() => import('~icons/hawk/copy-four'));
export const IconHawkCopyOne = defineAsyncComponent(() => import('~icons/hawk/copy-one'));
export const IconHawkCopySeven = defineAsyncComponent(() => import('~icons/hawk/copy-seven'));
export const IconHawkCopySix = defineAsyncComponent(() => import('~icons/hawk/copy-six'));
export const IconHawkCopyThree = defineAsyncComponent(() => import('~icons/hawk/copy-three'));
export const IconHawkCopyTwo = defineAsyncComponent(() => import('~icons/hawk/copy-two'));
export const IconHawkCornerDownLeft = defineAsyncComponent(() => import('~icons/hawk/corner-down-left'));
export const IconHawkCornerDownRight = defineAsyncComponent(() => import('~icons/hawk/corner-down-right'));
export const IconHawkCornerLeftDown = defineAsyncComponent(() => import('~icons/hawk/corner-left-down'));
export const IconHawkCornerLeftUp = defineAsyncComponent(() => import('~icons/hawk/corner-left-up'));
export const IconHawkCornerRightDown = defineAsyncComponent(() => import('~icons/hawk/corner-right-down'));
export const IconHawkCornerRightUp = defineAsyncComponent(() => import('~icons/hawk/corner-right-up'));
export const IconHawkCornerUpLeft = defineAsyncComponent(() => import('~icons/hawk/corner-up-left'));
export const IconHawkCornerUpRight = defineAsyncComponent(() => import('~icons/hawk/corner-up-right'));
export const IconHawkCpuChipOne = defineAsyncComponent(() => import('~icons/hawk/cpu-chip-one'));
export const IconHawkCpuChipTwo = defineAsyncComponent(() => import('~icons/hawk/cpu-chip-two'));
export const IconHawkCreateForm = defineAsyncComponent(() => import('~icons/hawk/create-form'));
export const IconHawkCreateTask = defineAsyncComponent(() => import('~icons/hawk/create-task'));
export const IconHawkCreditCardCheck = defineAsyncComponent(() => import('~icons/hawk/credit-card-check'));
export const IconHawkCreditCardDown = defineAsyncComponent(() => import('~icons/hawk/credit-card-down'));
export const IconHawkCreditCardDownload = defineAsyncComponent(() => import('~icons/hawk/credit-card-download'));
export const IconHawkCreditCardEdit = defineAsyncComponent(() => import('~icons/hawk/credit-card-edit'));
export const IconHawkCreditCardLock = defineAsyncComponent(() => import('~icons/hawk/credit-card-lock'));
export const IconHawkCreditCardMinus = defineAsyncComponent(() => import('~icons/hawk/credit-card-minus'));
export const IconHawkCreditCardOne = defineAsyncComponent(() => import('~icons/hawk/credit-card-one'));
export const IconHawkCreditCardPlus = defineAsyncComponent(() => import('~icons/hawk/credit-card-plus'));
export const IconHawkCreditCardRefresh = defineAsyncComponent(() => import('~icons/hawk/credit-card-refresh'));
export const IconHawkCreditCardSearch = defineAsyncComponent(() => import('~icons/hawk/credit-card-search'));
export const IconHawkCreditCardShield = defineAsyncComponent(() => import('~icons/hawk/credit-card-shield'));
export const IconHawkCreditCardTwo = defineAsyncComponent(() => import('~icons/hawk/credit-card-two'));
export const IconHawkCreditCardUp = defineAsyncComponent(() => import('~icons/hawk/credit-card-up'));
export const IconHawkCreditCardUpload = defineAsyncComponent(() => import('~icons/hawk/credit-card-upload'));
export const IconHawkCreditCardX = defineAsyncComponent(() => import('~icons/hawk/credit-card-x'));
export const IconHawkCriticalPriorityFlag = defineAsyncComponent(() => import('~icons/hawk/critical-priority-flag'));
export const IconHawkCropOne = defineAsyncComponent(() => import('~icons/hawk/crop-one'));
export const IconHawkCropTwo = defineAsyncComponent(() => import('~icons/hawk/crop-two'));
export const IconHawkCross = defineAsyncComponent(() => import('~icons/hawk/cross'));
export const IconHawkCryptocurrencyFour = defineAsyncComponent(() => import('~icons/hawk/cryptocurrency-four'));
export const IconHawkCryptocurrencyOne = defineAsyncComponent(() => import('~icons/hawk/cryptocurrency-one'));
export const IconHawkCryptocurrencyThree = defineAsyncComponent(() => import('~icons/hawk/cryptocurrency-three'));
export const IconHawkCryptocurrencyTwo = defineAsyncComponent(() => import('~icons/hawk/cryptocurrency-two'));
export const IconHawkCubeFour = defineAsyncComponent(() => import('~icons/hawk/cube-four'));
export const IconHawkCubeOne = defineAsyncComponent(() => import('~icons/hawk/cube-one'));
export const IconHawkCubeOutline = defineAsyncComponent(() => import('~icons/hawk/cube-outline'));
export const IconHawkCubeThree = defineAsyncComponent(() => import('~icons/hawk/cube-three'));
export const IconHawkCubeTwo = defineAsyncComponent(() => import('~icons/hawk/cube-two'));
export const IconHawkCurrencyBitcoinCircle = defineAsyncComponent(() => import('~icons/hawk/currency-bitcoin-circle'));
export const IconHawkCurrencyBitcoin = defineAsyncComponent(() => import('~icons/hawk/currency-bitcoin'));
export const IconHawkCurrencyDollarCircle = defineAsyncComponent(() => import('~icons/hawk/currency-dollar-circle'));
export const IconHawkCurrencyDollar = defineAsyncComponent(() => import('~icons/hawk/currency-dollar'));
export const IconHawkCurrencyEthereumCircle = defineAsyncComponent(() => import('~icons/hawk/currency-ethereum-circle'));
export const IconHawkCurrencyEthereum = defineAsyncComponent(() => import('~icons/hawk/currency-ethereum'));
export const IconHawkCurrencyEuroCircle = defineAsyncComponent(() => import('~icons/hawk/currency-euro-circle'));
export const IconHawkCurrencyEuro = defineAsyncComponent(() => import('~icons/hawk/currency-euro'));
export const IconHawkCurrencyPoundCircle = defineAsyncComponent(() => import('~icons/hawk/currency-pound-circle'));
export const IconHawkCurrencyPound = defineAsyncComponent(() => import('~icons/hawk/currency-pound'));
export const IconHawkCurrencyRubleCircle = defineAsyncComponent(() => import('~icons/hawk/currency-ruble-circle'));
export const IconHawkCurrencyRuble = defineAsyncComponent(() => import('~icons/hawk/currency-ruble'));
export const IconHawkCurrencyRupeeCircle = defineAsyncComponent(() => import('~icons/hawk/currency-rupee-circle'));
export const IconHawkCurrencyRupee = defineAsyncComponent(() => import('~icons/hawk/currency-rupee'));
export const IconHawkCurrencyYenCircle = defineAsyncComponent(() => import('~icons/hawk/currency-yen-circle'));
export const IconHawkCurrencyYen = defineAsyncComponent(() => import('~icons/hawk/currency-yen'));
export const IconHawkCursorBox = defineAsyncComponent(() => import('~icons/hawk/cursor-box'));
export const IconHawkCursorClickOne = defineAsyncComponent(() => import('~icons/hawk/cursor-click-one'));
export const IconHawkCursorClickTwo = defineAsyncComponent(() => import('~icons/hawk/cursor-click-two'));
export const IconHawkCursorFour = defineAsyncComponent(() => import('~icons/hawk/cursor-four'));
export const IconHawkCursorOne = defineAsyncComponent(() => import('~icons/hawk/cursor-one'));
export const IconHawkCursorThree = defineAsyncComponent(() => import('~icons/hawk/cursor-three'));
export const IconHawkCursorTwo = defineAsyncComponent(() => import('~icons/hawk/cursor-two'));
export const IconHawkCurveLine = defineAsyncComponent(() => import('~icons/hawk/curve-line'));
export const IconHawkDashboardPreviewInfo = defineAsyncComponent(() => import('~icons/hawk/dashboard-preview-info'));
export const IconHawkDashboardPreviewWarning = defineAsyncComponent(() => import('~icons/hawk/dashboard-preview-warning'));
export const IconHawkData = defineAsyncComponent(() => import('~icons/hawk/data'));
export const IconHawkDatabaseOne = defineAsyncComponent(() => import('~icons/hawk/database-one'));
export const IconHawkDatabaseThree = defineAsyncComponent(() => import('~icons/hawk/database-three'));
export const IconHawkDatabaseTwo = defineAsyncComponent(() => import('~icons/hawk/database-two'));
export const IconHawkDataflowFour = defineAsyncComponent(() => import('~icons/hawk/dataflow-four'));
export const IconHawkDataflowOne = defineAsyncComponent(() => import('~icons/hawk/dataflow-one'));
export const IconHawkDataflowThree = defineAsyncComponent(() => import('~icons/hawk/dataflow-three'));
export const IconHawkDataflowTwo = defineAsyncComponent(() => import('~icons/hawk/dataflow-two'));
export const IconHawkDeletePopup = defineAsyncComponent(() => import('~icons/hawk/delete-popup'));
export const IconHawkDelete = defineAsyncComponent(() => import('~icons/hawk/delete'));
export const IconHawkDelta = defineAsyncComponent(() => import('~icons/hawk/delta'));
export const IconHawkDependencyBlocking = defineAsyncComponent(() => import('~icons/hawk/dependency-blocking'));
export const IconHawkDependencyLinked = defineAsyncComponent(() => import('~icons/hawk/dependency-linked'));
export const IconHawkDependencyWaitingOn = defineAsyncComponent(() => import('~icons/hawk/dependency-waiting-on'));
export const IconHawkDiamondOne = defineAsyncComponent(() => import('~icons/hawk/diamond-one'));
export const IconHawkDiamondTwo = defineAsyncComponent(() => import('~icons/hawk/diamond-two'));
export const IconHawkDiceFive = defineAsyncComponent(() => import('~icons/hawk/dice-five'));
export const IconHawkDiceFour = defineAsyncComponent(() => import('~icons/hawk/dice-four'));
export const IconHawkDiceOne = defineAsyncComponent(() => import('~icons/hawk/dice-one'));
export const IconHawkDiceSix = defineAsyncComponent(() => import('~icons/hawk/dice-six'));
export const IconHawkDiceThree = defineAsyncComponent(() => import('~icons/hawk/dice-three'));
export const IconHawkDiceTwo = defineAsyncComponent(() => import('~icons/hawk/dice-two'));
export const IconHawkDiscOne = defineAsyncComponent(() => import('~icons/hawk/disc-one'));
export const IconHawkDiscTwo = defineAsyncComponent(() => import('~icons/hawk/disc-two'));
export const IconHawkDistributeSpacingHorizontal = defineAsyncComponent(() => import('~icons/hawk/distribute-spacing-horizontal'));
export const IconHawkDistributeSpacingVertical = defineAsyncComponent(() => import('~icons/hawk/distribute-spacing-vertical'));
export const IconHawkDivideOne = defineAsyncComponent(() => import('~icons/hawk/divide-one'));
export const IconHawkDivideThree = defineAsyncComponent(() => import('~icons/hawk/divide-three'));
export const IconHawkDivideTwo = defineAsyncComponent(() => import('~icons/hawk/divide-two'));
export const IconHawkDivider = defineAsyncComponent(() => import('~icons/hawk/divider'));
export const IconHawkDotpointsOne = defineAsyncComponent(() => import('~icons/hawk/dotpoints-one'));
export const IconHawkDotpointsTwo = defineAsyncComponent(() => import('~icons/hawk/dotpoints-two'));
export const IconHawkDotsGrid = defineAsyncComponent(() => import('~icons/hawk/dots-grid'));
export const IconHawkDotsHorizontal = defineAsyncComponent(() => import('~icons/hawk/dots-horizontal'));
export const IconHawkDotsVertical = defineAsyncComponent(() => import('~icons/hawk/dots-vertical'));
export const IconHawkDownloadCloudOne = defineAsyncComponent(() => import('~icons/hawk/download-cloud-one'));
export const IconHawkDownloadCloudTwo = defineAsyncComponent(() => import('~icons/hawk/download-cloud-two'));
export const IconHawkDownloadFour = defineAsyncComponent(() => import('~icons/hawk/download-four'));
export const IconHawkDownloadOne = defineAsyncComponent(() => import('~icons/hawk/download-one'));
export const IconHawkDownloadThree = defineAsyncComponent(() => import('~icons/hawk/download-three'));
export const IconHawkDownloadTwo = defineAsyncComponent(() => import('~icons/hawk/download-two'));
export const IconHawkDragIcon = defineAsyncComponent(() => import('~icons/hawk/drag-icon'));
export const IconHawkDragTableIcon = defineAsyncComponent(() => import('~icons/hawk/drag-table-icon'));
export const IconHawkDrop = defineAsyncComponent(() => import('~icons/hawk/drop'));
export const IconHawkDropletsOne = defineAsyncComponent(() => import('~icons/hawk/droplets-one'));
export const IconHawkDropletsThree = defineAsyncComponent(() => import('~icons/hawk/droplets-three'));
export const IconHawkDropletsTwo = defineAsyncComponent(() => import('~icons/hawk/droplets-two'));
export const IconHawkDropper = defineAsyncComponent(() => import('~icons/hawk/dropper'));
export const IconHawkEditFive = defineAsyncComponent(() => import('~icons/hawk/edit-five'));
export const IconHawkEditFour = defineAsyncComponent(() => import('~icons/hawk/edit-four'));
export const IconHawkEditOne = defineAsyncComponent(() => import('~icons/hawk/edit-one'));
export const IconHawkEditThree = defineAsyncComponent(() => import('~icons/hawk/edit-three'));
export const IconHawkEditTwo = defineAsyncComponent(() => import('~icons/hawk/edit-two'));
export const IconHawkEdit = defineAsyncComponent(() => import('~icons/hawk/edit'));
export const IconHawkElbowLeft = defineAsyncComponent(() => import('~icons/hawk/elbow-left'));
export const IconHawkElbowRight = defineAsyncComponent(() => import('~icons/hawk/elbow-right'));
export const IconHawkElevation = defineAsyncComponent(() => import('~icons/hawk/elevation'));
export const IconHawkEmail = defineAsyncComponent(() => import('~icons/hawk/email'));
export const IconHawkEqualNot = defineAsyncComponent(() => import('~icons/hawk/equal-not'));
export const IconHawkEqual = defineAsyncComponent(() => import('~icons/hawk/equal'));
export const IconHawkEraser = defineAsyncComponent(() => import('~icons/hawk/eraser'));
export const IconHawkExpandFive = defineAsyncComponent(() => import('~icons/hawk/expand-five'));
export const IconHawkExpandFour = defineAsyncComponent(() => import('~icons/hawk/expand-four'));
export const IconHawkExpandOne = defineAsyncComponent(() => import('~icons/hawk/expand-one'));
export const IconHawkExpandSix = defineAsyncComponent(() => import('~icons/hawk/expand-six'));
export const IconHawkExpandThree = defineAsyncComponent(() => import('~icons/hawk/expand-three'));
export const IconHawkExpandTwo = defineAsyncComponent(() => import('~icons/hawk/expand-two'));
export const IconHawkEyeOffTwo = defineAsyncComponent(() => import('~icons/hawk/eye-off-two'));
export const IconHawkEyeOff = defineAsyncComponent(() => import('~icons/hawk/eye-off'));
export const IconHawkEyeTwo = defineAsyncComponent(() => import('~icons/hawk/eye-two'));
export const IconHawkEye = defineAsyncComponent(() => import('~icons/hawk/eye'));
export const IconHawkFaceContent = defineAsyncComponent(() => import('~icons/hawk/face-content'));
export const IconHawkFaceFrown = defineAsyncComponent(() => import('~icons/hawk/face-frown'));
export const IconHawkFaceHappy = defineAsyncComponent(() => import('~icons/hawk/face-happy'));
export const IconHawkFaceIdSquare = defineAsyncComponent(() => import('~icons/hawk/face-id-square'));
export const IconHawkFaceId = defineAsyncComponent(() => import('~icons/hawk/face-id'));
export const IconHawkFaceNeutral = defineAsyncComponent(() => import('~icons/hawk/face-neutral'));
export const IconHawkFaceSad = defineAsyncComponent(() => import('~icons/hawk/face-sad'));
export const IconHawkFaceSmile = defineAsyncComponent(() => import('~icons/hawk/face-smile'));
export const IconHawkFaceWink = defineAsyncComponent(() => import('~icons/hawk/face-wink'));
export const IconHawkFastBackward = defineAsyncComponent(() => import('~icons/hawk/fast-backward'));
export const IconHawkFastForward = defineAsyncComponent(() => import('~icons/hawk/fast-forward'));
export const IconHawkFeather = defineAsyncComponent(() => import('~icons/hawk/feather'));
export const IconHawkFigma = defineAsyncComponent(() => import('~icons/hawk/figma'));
export const IconHawkFileAttachmentFive = defineAsyncComponent(() => import('~icons/hawk/file-attachment-five'));
export const IconHawkFileAttachmentFour = defineAsyncComponent(() => import('~icons/hawk/file-attachment-four'));
export const IconHawkFileAttachmentOne = defineAsyncComponent(() => import('~icons/hawk/file-attachment-one'));
export const IconHawkFileAttachmentThree = defineAsyncComponent(() => import('~icons/hawk/file-attachment-three'));
export const IconHawkFileAttachmentTwo = defineAsyncComponent(() => import('~icons/hawk/file-attachment-two'));
export const IconHawkFileCheckOne = defineAsyncComponent(() => import('~icons/hawk/file-check-one'));
export const IconHawkFileCheckThree = defineAsyncComponent(() => import('~icons/hawk/file-check-three'));
export const IconHawkFileCheckTwo = defineAsyncComponent(() => import('~icons/hawk/file-check-two'));
export const IconHawkFileCodeOne = defineAsyncComponent(() => import('~icons/hawk/file-code-one'));
export const IconHawkFileCodeTwo = defineAsyncComponent(() => import('~icons/hawk/file-code-two'));
export const IconHawkFileDownloadOne = defineAsyncComponent(() => import('~icons/hawk/file-download-one'));
export const IconHawkFileDownloadThree = defineAsyncComponent(() => import('~icons/hawk/file-download-three'));
export const IconHawkFileDownloadTwo = defineAsyncComponent(() => import('~icons/hawk/file-download-two'));
export const IconHawkFileDownload = defineAsyncComponent(() => import('~icons/hawk/file-download'));
export const IconHawkFileExport = defineAsyncComponent(() => import('~icons/hawk/file-export'));
export const IconHawkFileFive = defineAsyncComponent(() => import('~icons/hawk/file-five'));
export const IconHawkFileFour = defineAsyncComponent(() => import('~icons/hawk/file-four'));
export const IconHawkFileHeartOne = defineAsyncComponent(() => import('~icons/hawk/file-heart-one'));
export const IconHawkFileHeartThree = defineAsyncComponent(() => import('~icons/hawk/file-heart-three'));
export const IconHawkFileHeartTwo = defineAsyncComponent(() => import('~icons/hawk/file-heart-two'));
export const IconHawkFileIcon = defineAsyncComponent(() => import('~icons/hawk/file-icon'));
export const IconHawkFileLockOne = defineAsyncComponent(() => import('~icons/hawk/file-lock-one'));
export const IconHawkFileLockThree = defineAsyncComponent(() => import('~icons/hawk/file-lock-three'));
export const IconHawkFileLockTwo = defineAsyncComponent(() => import('~icons/hawk/file-lock-two'));
export const IconHawkFileMinusOne = defineAsyncComponent(() => import('~icons/hawk/file-minus-one'));
export const IconHawkFileMinusThree = defineAsyncComponent(() => import('~icons/hawk/file-minus-three'));
export const IconHawkFileMinusTwo = defineAsyncComponent(() => import('~icons/hawk/file-minus-two'));
export const IconHawkFileOne = defineAsyncComponent(() => import('~icons/hawk/file-one'));
export const IconHawkFilePlusOne = defineAsyncComponent(() => import('~icons/hawk/file-plus-one'));
export const IconHawkFilePlusThree = defineAsyncComponent(() => import('~icons/hawk/file-plus-three'));
export const IconHawkFilePlusTwo = defineAsyncComponent(() => import('~icons/hawk/file-plus-two'));
export const IconHawkFileQuestionOne = defineAsyncComponent(() => import('~icons/hawk/file-question-one'));
export const IconHawkFileQuestionThree = defineAsyncComponent(() => import('~icons/hawk/file-question-three'));
export const IconHawkFileQuestionTwo = defineAsyncComponent(() => import('~icons/hawk/file-question-two'));
export const IconHawkFileSearchOne = defineAsyncComponent(() => import('~icons/hawk/file-search-one'));
export const IconHawkFileSearchThree = defineAsyncComponent(() => import('~icons/hawk/file-search-three'));
export const IconHawkFileSearchTwo = defineAsyncComponent(() => import('~icons/hawk/file-search-two'));
export const IconHawkFileSeven = defineAsyncComponent(() => import('~icons/hawk/file-seven'));
export const IconHawkFileShieldOne = defineAsyncComponent(() => import('~icons/hawk/file-shield-one'));
export const IconHawkFileShieldThree = defineAsyncComponent(() => import('~icons/hawk/file-shield-three'));
export const IconHawkFileShieldTwo = defineAsyncComponent(() => import('~icons/hawk/file-shield-two'));
export const IconHawkFileSix = defineAsyncComponent(() => import('~icons/hawk/file-six'));
export const IconHawkFileThree = defineAsyncComponent(() => import('~icons/hawk/file-three'));
export const IconHawkFileTwo = defineAsyncComponent(() => import('~icons/hawk/file-two'));
export const IconHawkFileUpload = defineAsyncComponent(() => import('~icons/hawk/file-upload'));
export const IconHawkFileXOne = defineAsyncComponent(() => import('~icons/hawk/file-x-one'));
export const IconHawkFileXThree = defineAsyncComponent(() => import('~icons/hawk/file-x-three'));
export const IconHawkFileXTwo = defineAsyncComponent(() => import('~icons/hawk/file-x-two'));
export const IconHawkFilledCircle = defineAsyncComponent(() => import('~icons/hawk/filled-circle'));
export const IconHawkFilmOne = defineAsyncComponent(() => import('~icons/hawk/film-one'));
export const IconHawkFilmThree = defineAsyncComponent(() => import('~icons/hawk/film-three'));
export const IconHawkFilmTwo = defineAsyncComponent(() => import('~icons/hawk/film-two'));
export const IconHawkFilterFunnelOne = defineAsyncComponent(() => import('~icons/hawk/filter-funnel-one'));
export const IconHawkFilterFunnelRed = defineAsyncComponent(() => import('~icons/hawk/filter-funnel-red'));
export const IconHawkFilterFunnelTwo = defineAsyncComponent(() => import('~icons/hawk/filter-funnel-two'));
export const IconHawkFilterIcon = defineAsyncComponent(() => import('~icons/hawk/filter-icon'));
export const IconHawkFilterLines = defineAsyncComponent(() => import('~icons/hawk/filter-lines'));
export const IconHawkFilter = defineAsyncComponent(() => import('~icons/hawk/filter'));
export const IconHawkFingerprintFour = defineAsyncComponent(() => import('~icons/hawk/fingerprint-four'));
export const IconHawkFingerprintOne = defineAsyncComponent(() => import('~icons/hawk/fingerprint-one'));
export const IconHawkFingerprintThree = defineAsyncComponent(() => import('~icons/hawk/fingerprint-three'));
export const IconHawkFingerprintTwo = defineAsyncComponent(() => import('~icons/hawk/fingerprint-two'));
export const IconHawkFlagFive = defineAsyncComponent(() => import('~icons/hawk/flag-five'));
export const IconHawkFlagFour = defineAsyncComponent(() => import('~icons/hawk/flag-four'));
export const IconHawkFlagOne = defineAsyncComponent(() => import('~icons/hawk/flag-one'));
export const IconHawkFlagSix = defineAsyncComponent(() => import('~icons/hawk/flag-six'));
export const IconHawkFlagThree = defineAsyncComponent(() => import('~icons/hawk/flag-three'));
export const IconHawkFlagTwo = defineAsyncComponent(() => import('~icons/hawk/flag-two'));
export const IconHawkFlashOff = defineAsyncComponent(() => import('~icons/hawk/flash-off'));
export const IconHawkFlash = defineAsyncComponent(() => import('~icons/hawk/flash'));
export const IconHawkFlexAlignBottom = defineAsyncComponent(() => import('~icons/hawk/flex-align-bottom'));
export const IconHawkFlexAlignLeft = defineAsyncComponent(() => import('~icons/hawk/flex-align-left'));
export const IconHawkFlexAlignRight = defineAsyncComponent(() => import('~icons/hawk/flex-align-right'));
export const IconHawkFlexAlignTop = defineAsyncComponent(() => import('~icons/hawk/flex-align-top'));
export const IconHawkFlipBackward = defineAsyncComponent(() => import('~icons/hawk/flip-backward'));
export const IconHawkFlipForward = defineAsyncComponent(() => import('~icons/hawk/flip-forward'));
export const IconHawkFolderAdd = defineAsyncComponent(() => import('~icons/hawk/folder-add'));
export const IconHawkFolderCheck = defineAsyncComponent(() => import('~icons/hawk/folder-check'));
export const IconHawkFolderClosed = defineAsyncComponent(() => import('~icons/hawk/folder-closed'));
export const IconHawkFolderCode = defineAsyncComponent(() => import('~icons/hawk/folder-code'));
export const IconHawkFolderDownload = defineAsyncComponent(() => import('~icons/hawk/folder-download'));
export const IconHawkFolderLock = defineAsyncComponent(() => import('~icons/hawk/folder-lock'));
export const IconHawkFolderMinus = defineAsyncComponent(() => import('~icons/hawk/folder-minus'));
export const IconHawkFolderPlus = defineAsyncComponent(() => import('~icons/hawk/folder-plus'));
export const IconHawkFolderQuestion = defineAsyncComponent(() => import('~icons/hawk/folder-question'));
export const IconHawkFolderSearch = defineAsyncComponent(() => import('~icons/hawk/folder-search'));
export const IconHawkFolderShield = defineAsyncComponent(() => import('~icons/hawk/folder-shield'));
export const IconHawkFolderX = defineAsyncComponent(() => import('~icons/hawk/folder-x'));
export const IconHawkFolder = defineAsyncComponent(() => import('~icons/hawk/folder'));
export const IconHawkFormReminderIcon = defineAsyncComponent(() => import('~icons/hawk/form-reminder-icon'));
export const IconHawkFormTemplateIcon = defineAsyncComponent(() => import('~icons/hawk/form-template-icon'));
export const IconHawkFormWorkflowIcon = defineAsyncComponent(() => import('~icons/hawk/form-workflow-icon'));
export const IconHawkFormula = defineAsyncComponent(() => import('~icons/hawk/formula'));
export const IconHawkFramer = defineAsyncComponent(() => import('~icons/hawk/framer'));
export const IconHawkFullScreen = defineAsyncComponent(() => import('~icons/hawk/full-screen'));
export const IconHawkGamingPadOne = defineAsyncComponent(() => import('~icons/hawk/gaming-pad-one'));
export const IconHawkGamingPadTwo = defineAsyncComponent(() => import('~icons/hawk/gaming-pad-two'));
export const IconHawkGanttGridTimeline = defineAsyncComponent(() => import('~icons/hawk/gantt-grid-timeline'));
export const IconHawkGanttGrid = defineAsyncComponent(() => import('~icons/hawk/gantt-grid'));
export const IconHawkGanttTimeline = defineAsyncComponent(() => import('~icons/hawk/gantt-timeline'));
export const IconHawkGiftOne = defineAsyncComponent(() => import('~icons/hawk/gift-one'));
export const IconHawkGiftTwo = defineAsyncComponent(() => import('~icons/hawk/gift-two'));
export const IconHawkGitBranchOne = defineAsyncComponent(() => import('~icons/hawk/git-branch-one'));
export const IconHawkGitBranchTwo = defineAsyncComponent(() => import('~icons/hawk/git-branch-two'));
export const IconHawkGitCommit = defineAsyncComponent(() => import('~icons/hawk/git-commit'));
export const IconHawkGitMerge = defineAsyncComponent(() => import('~icons/hawk/git-merge'));
export const IconHawkGitPullRequest = defineAsyncComponent(() => import('~icons/hawk/git-pull-request'));
export const IconHawkGlassesOne = defineAsyncComponent(() => import('~icons/hawk/glasses-one'));
export const IconHawkGlassesTwo = defineAsyncComponent(() => import('~icons/hawk/glasses-two'));
export const IconHawkGlobeFive = defineAsyncComponent(() => import('~icons/hawk/globe-five'));
export const IconHawkGlobeFour = defineAsyncComponent(() => import('~icons/hawk/globe-four'));
export const IconHawkGlobeOneCopy = defineAsyncComponent(() => import('~icons/hawk/globe-one-copy'));
export const IconHawkGlobeOne = defineAsyncComponent(() => import('~icons/hawk/globe-one'));
export const IconHawkGlobeSix = defineAsyncComponent(() => import('~icons/hawk/globe-six'));
export const IconHawkGlobeThree = defineAsyncComponent(() => import('~icons/hawk/globe-three'));
export const IconHawkGlobeTwoCopy = defineAsyncComponent(() => import('~icons/hawk/globe-two-copy'));
export const IconHawkGlobeTwo = defineAsyncComponent(() => import('~icons/hawk/globe-two'));
export const IconHawkGlobe = defineAsyncComponent(() => import('~icons/hawk/globe'));
export const IconHawkGoogleChrome = defineAsyncComponent(() => import('~icons/hawk/google-chrome'));
export const IconHawkGraduationHatOne = defineAsyncComponent(() => import('~icons/hawk/graduation-hat-one'));
export const IconHawkGraduationHatTwo = defineAsyncComponent(() => import('~icons/hawk/graduation-hat-two'));
export const IconHawkGridDotsBlank = defineAsyncComponent(() => import('~icons/hawk/grid-dots-blank'));
export const IconHawkGridDotsBottom = defineAsyncComponent(() => import('~icons/hawk/grid-dots-bottom'));
export const IconHawkGridDotsHorizontalCenter = defineAsyncComponent(() => import('~icons/hawk/grid-dots-horizontal-center'));
export const IconHawkGridDotsLeft = defineAsyncComponent(() => import('~icons/hawk/grid-dots-left'));
export const IconHawkGridDotsOuter = defineAsyncComponent(() => import('~icons/hawk/grid-dots-outer'));
export const IconHawkGridDotsRight = defineAsyncComponent(() => import('~icons/hawk/grid-dots-right'));
export const IconHawkGridDotsTop = defineAsyncComponent(() => import('~icons/hawk/grid-dots-top'));
export const IconHawkGridDotsVerticalCenter = defineAsyncComponent(() => import('~icons/hawk/grid-dots-vertical-center'));
export const IconHawkGridOne = defineAsyncComponent(() => import('~icons/hawk/grid-one'));
export const IconHawkGridThree = defineAsyncComponent(() => import('~icons/hawk/grid-three'));
export const IconHawkGridTimeline = defineAsyncComponent(() => import('~icons/hawk/grid-timeline'));
export const IconHawkGridTwo = defineAsyncComponent(() => import('~icons/hawk/grid-two'));
export const IconHawkHandCopy = defineAsyncComponent(() => import('~icons/hawk/hand-copy'));
export const IconHawkHand = defineAsyncComponent(() => import('~icons/hawk/hand'));
export const IconHawkHardDrive = defineAsyncComponent(() => import('~icons/hawk/hard-drive'));
export const IconHawkHashOne = defineAsyncComponent(() => import('~icons/hawk/hash-one'));
export const IconHawkHashTwo = defineAsyncComponent(() => import('~icons/hawk/hash-two'));
export const IconHawkHeadingOne = defineAsyncComponent(() => import('~icons/hawk/heading-one'));
export const IconHawkHeadingSquare = defineAsyncComponent(() => import('~icons/hawk/heading-square'));
export const IconHawkHeadingTwo = defineAsyncComponent(() => import('~icons/hawk/heading-two'));
export const IconHawkHeadphonesOne = defineAsyncComponent(() => import('~icons/hawk/headphones-one'));
export const IconHawkHeadphonesTwo = defineAsyncComponent(() => import('~icons/hawk/headphones-two'));
export const IconHawkHeartCircle = defineAsyncComponent(() => import('~icons/hawk/heart-circle'));
export const IconHawkHeartEmpty = defineAsyncComponent(() => import('~icons/hawk/heart-empty'));
export const IconHawkHeartFilled = defineAsyncComponent(() => import('~icons/hawk/heart-filled'));
export const IconHawkHeartHand = defineAsyncComponent(() => import('~icons/hawk/heart-hand'));
export const IconHawkHeartHexagon = defineAsyncComponent(() => import('~icons/hawk/heart-hexagon'));
export const IconHawkHeartOctagon = defineAsyncComponent(() => import('~icons/hawk/heart-octagon'));
export const IconHawkHeartRounded = defineAsyncComponent(() => import('~icons/hawk/heart-rounded'));
export const IconHawkHeartSquare = defineAsyncComponent(() => import('~icons/hawk/heart-square'));
export const IconHawkHeart = defineAsyncComponent(() => import('~icons/hawk/heart'));
export const IconHawkHearts = defineAsyncComponent(() => import('~icons/hawk/hearts'));
export const IconHawkHelpCircle = defineAsyncComponent(() => import('~icons/hawk/help-circle'));
export const IconHawkHelpOctagonOne = defineAsyncComponent(() => import('~icons/hawk/help-octagon-one'));
export const IconHawkHelpOctagon = defineAsyncComponent(() => import('~icons/hawk/help-octagon'));
export const IconHawkHelpSquare = defineAsyncComponent(() => import('~icons/hawk/help-square'));
export const IconHawkHexagonOne = defineAsyncComponent(() => import('~icons/hawk/hexagon-one'));
export const IconHawkHexagonTwo = defineAsyncComponent(() => import('~icons/hawk/hexagon-two'));
export const IconHawkHighPriorityFlag = defineAsyncComponent(() => import('~icons/hawk/high-priority-flag'));
export const IconHawkHomeFive = defineAsyncComponent(() => import('~icons/hawk/home-five'));
export const IconHawkHomeFour = defineAsyncComponent(() => import('~icons/hawk/home-four'));
export const IconHawkHomeLine = defineAsyncComponent(() => import('~icons/hawk/home-line'));
export const IconHawkHomeOne = defineAsyncComponent(() => import('~icons/hawk/home-one'));
export const IconHawkHomeSmile = defineAsyncComponent(() => import('~icons/hawk/home-smile'));
export const IconHawkHomeThree = defineAsyncComponent(() => import('~icons/hawk/home-three'));
export const IconHawkHomeTwo = defineAsyncComponent(() => import('~icons/hawk/home-two'));
export const IconHawkHorizontalBarChartOne = defineAsyncComponent(() => import('~icons/hawk/horizontal-bar-chart-one'));
export const IconHawkHorizontalBarChartThree = defineAsyncComponent(() => import('~icons/hawk/horizontal-bar-chart-three'));
export const IconHawkHorizontalBarChartTwo = defineAsyncComponent(() => import('~icons/hawk/horizontal-bar-chart-two'));
export const IconHawkHourglassOne = defineAsyncComponent(() => import('~icons/hawk/hourglass-one'));
export const IconHawkHourglassThree = defineAsyncComponent(() => import('~icons/hawk/hourglass-three'));
export const IconHawkHourglassTwo = defineAsyncComponent(() => import('~icons/hawk/hourglass-two'));
export const IconHawkHurricaneOne = defineAsyncComponent(() => import('~icons/hawk/hurricane-one'));
export const IconHawkHurricaneThree = defineAsyncComponent(() => import('~icons/hawk/hurricane-three'));
export const IconHawkHurricaneTwo = defineAsyncComponent(() => import('~icons/hawk/hurricane-two'));
export const IconHawkImageCheck = defineAsyncComponent(() => import('~icons/hawk/image-check'));
export const IconHawkImageDown = defineAsyncComponent(() => import('~icons/hawk/image-down'));
export const IconHawkImageFive = defineAsyncComponent(() => import('~icons/hawk/image-five'));
export const IconHawkImageFour = defineAsyncComponent(() => import('~icons/hawk/image-four'));
export const IconHawkImageIndentLeft = defineAsyncComponent(() => import('~icons/hawk/image-indent-left'));
export const IconHawkImageIndentRight = defineAsyncComponent(() => import('~icons/hawk/image-indent-right'));
export const IconHawkImageLeft = defineAsyncComponent(() => import('~icons/hawk/image-left'));
export const IconHawkImageOne = defineAsyncComponent(() => import('~icons/hawk/image-one'));
export const IconHawkImagePlus = defineAsyncComponent(() => import('~icons/hawk/image-plus'));
export const IconHawkImageRight = defineAsyncComponent(() => import('~icons/hawk/image-right'));
export const IconHawkImageThree = defineAsyncComponent(() => import('~icons/hawk/image-three'));
export const IconHawkImageTwo = defineAsyncComponent(() => import('~icons/hawk/image-two'));
export const IconHawkImageUp = defineAsyncComponent(() => import('~icons/hawk/image-up'));
export const IconHawkImageUserCheck = defineAsyncComponent(() => import('~icons/hawk/image-user-check'));
export const IconHawkImageUserDown = defineAsyncComponent(() => import('~icons/hawk/image-user-down'));
export const IconHawkImageUserLeft = defineAsyncComponent(() => import('~icons/hawk/image-user-left'));
export const IconHawkImageUserPlus = defineAsyncComponent(() => import('~icons/hawk/image-user-plus'));
export const IconHawkImageUserRight = defineAsyncComponent(() => import('~icons/hawk/image-user-right'));
export const IconHawkImageUserUp = defineAsyncComponent(() => import('~icons/hawk/image-user-up'));
export const IconHawkImageUserX = defineAsyncComponent(() => import('~icons/hawk/image-user-x'));
export const IconHawkImageUser = defineAsyncComponent(() => import('~icons/hawk/image-user'));
export const IconHawkImageX = defineAsyncComponent(() => import('~icons/hawk/image-x'));
export const IconHawkImport = defineAsyncComponent(() => import('~icons/hawk/import'));
export const IconHawkInboxOne = defineAsyncComponent(() => import('~icons/hawk/inbox-one'));
export const IconHawkInboxTwo = defineAsyncComponent(() => import('~icons/hawk/inbox-two'));

export const IconHawkInfinity = defineAsyncComponent(() => import('~icons/hawk/infinity'));
export const IconHawkInfoCircleGray = defineAsyncComponent(() => import('~icons/hawk/info-circle-gray'));
export const IconHawkInfoCircleRed = defineAsyncComponent(() => import('~icons/hawk/info-circle-red'));
export const IconHawkInfoCircleTwo = defineAsyncComponent(() => import('~icons/hawk/info-circle-two'));
export const IconHawkInfoCircle = defineAsyncComponent(() => import('~icons/hawk/info-circle'));
export const IconHawkInfoHexagon = defineAsyncComponent(() => import('~icons/hawk/info-hexagon'));
export const IconHawkInfoOctagon = defineAsyncComponent(() => import('~icons/hawk/info-octagon'));
export const IconHawkInfoSquare = defineAsyncComponent(() => import('~icons/hawk/info-square'));
export const IconHawkIntersectCircle = defineAsyncComponent(() => import('~icons/hawk/intersect-circle'));
export const IconHawkIntersectSquare = defineAsyncComponent(() => import('~icons/hawk/intersect-square'));
export const IconHawkItalicOne = defineAsyncComponent(() => import('~icons/hawk/italic-one'));
export const IconHawkItalicSquare = defineAsyncComponent(() => import('~icons/hawk/italic-square'));
export const IconHawkItalicTwo = defineAsyncComponent(() => import('~icons/hawk/italic-two'));
export const IconHawkKeyMac = defineAsyncComponent(() => import('~icons/hawk/key-mac'));
export const IconHawkKeyOne = defineAsyncComponent(() => import('~icons/hawk/key-one'));
export const IconHawkKeyTwo = defineAsyncComponent(() => import('~icons/hawk/key-two'));
export const IconHawkKeyboardOne = defineAsyncComponent(() => import('~icons/hawk/keyboard-one'));
export const IconHawkKeyboardTwo = defineAsyncComponent(() => import('~icons/hawk/keyboard-two'));
export const IconHawkLaptopOne = defineAsyncComponent(() => import('~icons/hawk/laptop-one'));
export const IconHawkLaptopTwo = defineAsyncComponent(() => import('~icons/hawk/laptop-two'));
export const IconHawkLayerSingle = defineAsyncComponent(() => import('~icons/hawk/layer-single'));
export const IconHawkLayersThreeOne = defineAsyncComponent(() => import('~icons/hawk/layers-three-one'));
export const IconHawkLayersThreeTwo = defineAsyncComponent(() => import('~icons/hawk/layers-three-two'));
export const IconHawkLayersTwoOne = defineAsyncComponent(() => import('~icons/hawk/layers-two-one'));
export const IconHawkLayersTwoTwo = defineAsyncComponent(() => import('~icons/hawk/layers-two-two'));
export const IconHawkLayoutAltFour = defineAsyncComponent(() => import('~icons/hawk/layout-alt-four'));
export const IconHawkLayoutAltOne = defineAsyncComponent(() => import('~icons/hawk/layout-alt-one'));
export const IconHawkLayoutAltThree = defineAsyncComponent(() => import('~icons/hawk/layout-alt-three'));
export const IconHawkLayoutAltTwo = defineAsyncComponent(() => import('~icons/hawk/layout-alt-two'));
export const IconHawkLayoutBottom = defineAsyncComponent(() => import('~icons/hawk/layout-bottom'));
export const IconHawkLayoutGridOne = defineAsyncComponent(() => import('~icons/hawk/layout-grid-one'));
export const IconHawkLayoutGridTwo = defineAsyncComponent(() => import('~icons/hawk/layout-grid-two'));
export const IconHawkLayoutLeft = defineAsyncComponent(() => import('~icons/hawk/layout-left'));
export const IconHawkLayoutRight = defineAsyncComponent(() => import('~icons/hawk/layout-right'));
export const IconHawkLayoutTop = defineAsyncComponent(() => import('~icons/hawk/layout-top'));
export const IconHawkLeftIndentOne = defineAsyncComponent(() => import('~icons/hawk/left-indent-one'));
export const IconHawkLeftIndentTwo = defineAsyncComponent(() => import('~icons/hawk/left-indent-two'));
export const IconHawkLetterSpacingOne = defineAsyncComponent(() => import('~icons/hawk/letter-spacing-one'));
export const IconHawkLetterSpacingTwo = defineAsyncComponent(() => import('~icons/hawk/letter-spacing-two'));
export const IconHawkLifeBuoyOne = defineAsyncComponent(() => import('~icons/hawk/life-buoy-one'));
export const IconHawkLifeBuoyTwo = defineAsyncComponent(() => import('~icons/hawk/life-buoy-two'));
export const IconHawkLightbulbFive = defineAsyncComponent(() => import('~icons/hawk/lightbulb-five'));
export const IconHawkLightbulbFour = defineAsyncComponent(() => import('~icons/hawk/lightbulb-four'));
export const IconHawkLightbulbOne = defineAsyncComponent(() => import('~icons/hawk/lightbulb-one'));
export const IconHawkLightbulbThree = defineAsyncComponent(() => import('~icons/hawk/lightbulb-three'));
export const IconHawkLightbulbTwo = defineAsyncComponent(() => import('~icons/hawk/lightbulb-two'));
export const IconHawkLightningOne = defineAsyncComponent(() => import('~icons/hawk/lightning-one'));
export const IconHawkLightningTwo = defineAsyncComponent(() => import('~icons/hawk/lightning-two'));
export const IconHawkLineChartDownFive = defineAsyncComponent(() => import('~icons/hawk/line-chart-down-five'));
export const IconHawkLineChartDownFour = defineAsyncComponent(() => import('~icons/hawk/line-chart-down-four'));
export const IconHawkLineChartDownOne = defineAsyncComponent(() => import('~icons/hawk/line-chart-down-one'));
export const IconHawkLineChartDownThree = defineAsyncComponent(() => import('~icons/hawk/line-chart-down-three'));
export const IconHawkLineChartDownTwo = defineAsyncComponent(() => import('~icons/hawk/line-chart-down-two'));
export const IconHawkLineChartUpFive = defineAsyncComponent(() => import('~icons/hawk/line-chart-up-five'));
export const IconHawkLineChartUpFour = defineAsyncComponent(() => import('~icons/hawk/line-chart-up-four'));
export const IconHawkLineChartUpOne = defineAsyncComponent(() => import('~icons/hawk/line-chart-up-one'));
export const IconHawkLineChartUpThree = defineAsyncComponent(() => import('~icons/hawk/line-chart-up-three'));
export const IconHawkLineChartUpTwo = defineAsyncComponent(() => import('~icons/hawk/line-chart-up-two'));
export const IconHawkLineHeight = defineAsyncComponent(() => import('~icons/hawk/line-height'));
export const IconHawkLine = defineAsyncComponent(() => import('~icons/hawk/line'));
export const IconHawkLinestring = defineAsyncComponent(() => import('~icons/hawk/linestring'));
export const IconHawkLinkBrokenOne = defineAsyncComponent(() => import('~icons/hawk/link-broken-one'));
export const IconHawkLinkBrokenTwo = defineAsyncComponent(() => import('~icons/hawk/link-broken-two'));
export const IconHawkLinkExternalOne = defineAsyncComponent(() => import('~icons/hawk/link-external-one'));
export const IconHawkLinkExternalTwo = defineAsyncComponent(() => import('~icons/hawk/link-external-two'));
export const IconHawkLinkFive = defineAsyncComponent(() => import('~icons/hawk/link-five'));
export const IconHawkLinkFour = defineAsyncComponent(() => import('~icons/hawk/link-four'));
export const IconHawkLinkHierarchy = defineAsyncComponent(() => import('~icons/hawk/link-hierarchy'));
export const IconHawkLinkOne = defineAsyncComponent(() => import('~icons/hawk/link-one'));
export const IconHawkLinkThree = defineAsyncComponent(() => import('~icons/hawk/link-three'));
export const IconHawkLinkTwo = defineAsyncComponent(() => import('~icons/hawk/link-two'));
export const IconHawkList = defineAsyncComponent(() => import('~icons/hawk/list'));
export const IconHawkLoadingCircle = defineAsyncComponent(() => import('~icons/hawk/loading-circle'));
export const IconHawkLoadingOne = defineAsyncComponent(() => import('~icons/hawk/loading-one'));
export const IconHawkLoadingThree = defineAsyncComponent(() => import('~icons/hawk/loading-three'));
export const IconHawkLoadingTwo = defineAsyncComponent(() => import('~icons/hawk/loading-two'));
export const IconHawkLocation = defineAsyncComponent(() => import('~icons/hawk/location'));
export const IconHawkLockFour = defineAsyncComponent(() => import('~icons/hawk/lock-four'));
export const IconHawkLockKeyholeCircle = defineAsyncComponent(() => import('~icons/hawk/lock-keyhole-circle'));
export const IconHawkLockKeyholeSquare = defineAsyncComponent(() => import('~icons/hawk/lock-keyhole-square'));
export const IconHawkLockOne = defineAsyncComponent(() => import('~icons/hawk/lock-one'));
export const IconHawkLockThree = defineAsyncComponent(() => import('~icons/hawk/lock-three'));
export const IconHawkLockTwo = defineAsyncComponent(() => import('~icons/hawk/lock-two'));
export const IconHawkLockUnlockRipple = defineAsyncComponent(() => import('~icons/hawk/lock-unlock-ripple'));
export const IconHawkLockUnlockedFour = defineAsyncComponent(() => import('~icons/hawk/lock-unlocked-four'));
export const IconHawkLockUnlockedOne = defineAsyncComponent(() => import('~icons/hawk/lock-unlocked-one'));
export const IconHawkLockUnlockedThree = defineAsyncComponent(() => import('~icons/hawk/lock-unlocked-three'));
export const IconHawkLockUnlockedTwo = defineAsyncComponent(() => import('~icons/hawk/lock-unlocked-two'));
export const IconHawkLogInFour = defineAsyncComponent(() => import('~icons/hawk/log-in-four'));
export const IconHawkLogInOne = defineAsyncComponent(() => import('~icons/hawk/log-in-one'));
export const IconHawkLogInThree = defineAsyncComponent(() => import('~icons/hawk/log-in-three'));
export const IconHawkLogInTwo = defineAsyncComponent(() => import('~icons/hawk/log-in-two'));
export const IconHawkLogOutFour = defineAsyncComponent(() => import('~icons/hawk/log-out-four'));
export const IconHawkLogOutOne = defineAsyncComponent(() => import('~icons/hawk/log-out-one'));
export const IconHawkLogOutThree = defineAsyncComponent(() => import('~icons/hawk/log-out-three'));
export const IconHawkLogOutTwo = defineAsyncComponent(() => import('~icons/hawk/log-out-two'));
export const IconHawkLongArrowRight = defineAsyncComponent(() => import('~icons/hawk/long-arrow-right'));
export const IconHawkLowPriorityFlag = defineAsyncComponent(() => import('~icons/hawk/low-priority-flag'));
export const IconHawkLuggageOne = defineAsyncComponent(() => import('~icons/hawk/luggage-one'));
export const IconHawkLuggageThree = defineAsyncComponent(() => import('~icons/hawk/luggage-three'));
export const IconHawkLuggageTwo = defineAsyncComponent(() => import('~icons/hawk/luggage-two'));
export const IconHawkMagicWandOne = defineAsyncComponent(() => import('~icons/hawk/magic-wand-one'));
export const IconHawkMagicWandTwo = defineAsyncComponent(() => import('~icons/hawk/magic-wand-two'));
export const IconHawkMailFive = defineAsyncComponent(() => import('~icons/hawk/mail-five'));
export const IconHawkMailFour = defineAsyncComponent(() => import('~icons/hawk/mail-four'));
export const IconHawkMailOne = defineAsyncComponent(() => import('~icons/hawk/mail-one'));
export const IconHawkMailThree = defineAsyncComponent(() => import('~icons/hawk/mail-three'));
export const IconHawkMailTwo = defineAsyncComponent(() => import('~icons/hawk/mail-two'));
export const IconHawkMapOne = defineAsyncComponent(() => import('~icons/hawk/map-one'));
export const IconHawkMapTwo = defineAsyncComponent(() => import('~icons/hawk/map-two'));
export const IconHawkMark = defineAsyncComponent(() => import('~icons/hawk/mark'));
export const IconHawkMarkerPinFive = defineAsyncComponent(() => import('~icons/hawk/marker-pin-five'));
export const IconHawkMarkerPinFlaticon = defineAsyncComponent(() => import('~icons/hawk/marker-pin-flaticon'));
export const IconHawkMarkerPinFour = defineAsyncComponent(() => import('~icons/hawk/marker-pin-four'));
export const IconHawkMarkerPinOne = defineAsyncComponent(() => import('~icons/hawk/marker-pin-one'));
export const IconHawkMarkerPinSix = defineAsyncComponent(() => import('~icons/hawk/marker-pin-six'));
export const IconHawkMarkerPinThree = defineAsyncComponent(() => import('~icons/hawk/marker-pin-three'));
export const IconHawkMarkerPinTwo = defineAsyncComponent(() => import('~icons/hawk/marker-pin-two'));
export const IconHawkMaximizeOne = defineAsyncComponent(() => import('~icons/hawk/maximize-one'));
export const IconHawkMaximizeTwo = defineAsyncComponent(() => import('~icons/hawk/maximize-two'));
export const IconHawkMedicalCircle = defineAsyncComponent(() => import('~icons/hawk/medical-circle'));
export const IconHawkMedicalCross = defineAsyncComponent(() => import('~icons/hawk/medical-cross'));
export const IconHawkMedicalSquare = defineAsyncComponent(() => import('~icons/hawk/medical-square'));
export const IconHawkMediumPriorityFlag = defineAsyncComponent(() => import('~icons/hawk/medium-priority-flag'));
export const IconHawkMenuFive = defineAsyncComponent(() => import('~icons/hawk/menu-five'));
export const IconHawkMenuFour = defineAsyncComponent(() => import('~icons/hawk/menu-four'));
export const IconHawkMenuOne = defineAsyncComponent(() => import('~icons/hawk/menu-one'));
export const IconHawkMenuThree = defineAsyncComponent(() => import('~icons/hawk/menu-three'));
export const IconHawkMenuTwo = defineAsyncComponent(() => import('~icons/hawk/menu-two'));
export const IconHawkMessageAlertCircle = defineAsyncComponent(() => import('~icons/hawk/message-alert-circle'));
export const IconHawkMessageAlertSquare = defineAsyncComponent(() => import('~icons/hawk/message-alert-square'));
export const IconHawkMessageChatCircle = defineAsyncComponent(() => import('~icons/hawk/message-chat-circle'));
export const IconHawkMessageChatSquare = defineAsyncComponent(() => import('~icons/hawk/message-chat-square'));
export const IconHawkMessageCheckCircle = defineAsyncComponent(() => import('~icons/hawk/message-check-circle'));
export const IconHawkMessageCheckSquare = defineAsyncComponent(() => import('~icons/hawk/message-check-square'));
export const IconHawkMessageCircleOne = defineAsyncComponent(() => import('~icons/hawk/message-circle-one'));
export const IconHawkMessageCircleTwo = defineAsyncComponent(() => import('~icons/hawk/message-circle-two'));
export const IconHawkMessageDotsCircle = defineAsyncComponent(() => import('~icons/hawk/message-dots-circle'));
export const IconHawkMessageDotsSquare = defineAsyncComponent(() => import('~icons/hawk/message-dots-square'));
export const IconHawkMessageHeartCircle = defineAsyncComponent(() => import('~icons/hawk/message-heart-circle'));
export const IconHawkMessageHeartSquare = defineAsyncComponent(() => import('~icons/hawk/message-heart-square'));
export const IconHawkMessageNotificationCircle = defineAsyncComponent(() => import('~icons/hawk/message-notification-circle'));
export const IconHawkMessageNotificationSquare = defineAsyncComponent(() => import('~icons/hawk/message-notification-square'));
export const IconHawkMessagePlusCircle = defineAsyncComponent(() => import('~icons/hawk/message-plus-circle'));
export const IconHawkMessagePlusSquare = defineAsyncComponent(() => import('~icons/hawk/message-plus-square'));
export const IconHawkMessageQuestionCircle = defineAsyncComponent(() => import('~icons/hawk/message-question-circle'));
export const IconHawkMessageQuestionSquare = defineAsyncComponent(() => import('~icons/hawk/message-question-square'));
export const IconHawkMessageSmileCircle = defineAsyncComponent(() => import('~icons/hawk/message-smile-circle'));
export const IconHawkMessageSmileSquare = defineAsyncComponent(() => import('~icons/hawk/message-smile-square'));
export const IconHawkMessageSquareOne = defineAsyncComponent(() => import('~icons/hawk/message-square-one'));
export const IconHawkMessageSquareTwo = defineAsyncComponent(() => import('~icons/hawk/message-square-two'));
export const IconHawkMessageTextCircleOne = defineAsyncComponent(() => import('~icons/hawk/message-text-circle-one'));
export const IconHawkMessageTextCircleTwo = defineAsyncComponent(() => import('~icons/hawk/message-text-circle-two'));
export const IconHawkMessageTextSquareOne = defineAsyncComponent(() => import('~icons/hawk/message-text-square-one'));
export const IconHawkMessageTextSquareTwo = defineAsyncComponent(() => import('~icons/hawk/message-text-square-two'));
export const IconHawkMessageXCircle = defineAsyncComponent(() => import('~icons/hawk/message-x-circle'));
export const IconHawkMessageXSquare = defineAsyncComponent(() => import('~icons/hawk/message-x-square'));
export const IconHawkMeterLow = defineAsyncComponent(() => import('~icons/hawk/meter-low'));
export const IconHawkMeterMedium = defineAsyncComponent(() => import('~icons/hawk/meter-medium'));
export const IconHawkMicrophoneOffOne = defineAsyncComponent(() => import('~icons/hawk/microphone-off-one'));
export const IconHawkMicrophoneOffTwo = defineAsyncComponent(() => import('~icons/hawk/microphone-off-two'));
export const IconHawkMicrophoneOne = defineAsyncComponent(() => import('~icons/hawk/microphone-one'));
export const IconHawkMicrophoneTwo = defineAsyncComponent(() => import('~icons/hawk/microphone-two'));
export const IconHawkMicroscope = defineAsyncComponent(() => import('~icons/hawk/microscope'));
export const IconHawkMinimizeOne = defineAsyncComponent(() => import('~icons/hawk/minimize-one'));
export const IconHawkMinimizeTwo = defineAsyncComponent(() => import('~icons/hawk/minimize-two'));
export const IconHawkMinusCircle = defineAsyncComponent(() => import('~icons/hawk/minus-circle'));
export const IconHawkMinusSquare = defineAsyncComponent(() => import('~icons/hawk/minus-square'));
export const IconHawkMinus = defineAsyncComponent(() => import('~icons/hawk/minus'));
export const IconHawkModemOne = defineAsyncComponent(() => import('~icons/hawk/modem-one'));
export const IconHawkModemTwo = defineAsyncComponent(() => import('~icons/hawk/modem-two'));
export const IconHawkMonitorFive = defineAsyncComponent(() => import('~icons/hawk/monitor-five'));
export const IconHawkMonitorFour = defineAsyncComponent(() => import('~icons/hawk/monitor-four'));
export const IconHawkMonitorOne = defineAsyncComponent(() => import('~icons/hawk/monitor-one'));
export const IconHawkMonitorThree = defineAsyncComponent(() => import('~icons/hawk/monitor-three'));
export const IconHawkMonitorTwo = defineAsyncComponent(() => import('~icons/hawk/monitor-two'));
export const IconHawkMoonEclipse = defineAsyncComponent(() => import('~icons/hawk/moon-eclipse'));
export const IconHawkMoonOne = defineAsyncComponent(() => import('~icons/hawk/moon-one'));
export const IconHawkMoonStar = defineAsyncComponent(() => import('~icons/hawk/moon-star'));
export const IconHawkMoonTwo = defineAsyncComponent(() => import('~icons/hawk/moon-two'));
export const IconHawkMouse = defineAsyncComponent(() => import('~icons/hawk/mouse'));
export const IconHawkMove = defineAsyncComponent(() => import('~icons/hawk/move'));
export const IconHawkMsExcel = defineAsyncComponent(() => import('~icons/hawk/ms-excel'));
export const IconHawkMusicNoteOne = defineAsyncComponent(() => import('~icons/hawk/music-note-one'));
export const IconHawkMusicNotePlus = defineAsyncComponent(() => import('~icons/hawk/music-note-plus'));
export const IconHawkMusicNoteTwo = defineAsyncComponent(() => import('~icons/hawk/music-note-two'));
export const IconHawkNavigationPointerOffOne = defineAsyncComponent(() => import('~icons/hawk/navigation-pointer-off-one'));
export const IconHawkNavigationPointerOffTwo = defineAsyncComponent(() => import('~icons/hawk/navigation-pointer-off-two'));
export const IconHawkNavigationPointerOne = defineAsyncComponent(() => import('~icons/hawk/navigation-pointer-one'));
export const IconHawkNavigationPointerTwo = defineAsyncComponent(() => import('~icons/hawk/navigation-pointer-two'));
export const IconHawkNoPriorityFlag = defineAsyncComponent(() => import('~icons/hawk/no-priority-flag'));
export const IconHawkNotificationBox = defineAsyncComponent(() => import('~icons/hawk/notification-box'));
export const IconHawkNotificationMessage = defineAsyncComponent(() => import('~icons/hawk/notification-message'));
export const IconHawkNotificationText = defineAsyncComponent(() => import('~icons/hawk/notification-text'));
export const IconHawkNumber = defineAsyncComponent(() => import('~icons/hawk/number'));
export const IconHawkNumberedList = defineAsyncComponent(() => import('~icons/hawk/numbered-list'));
export const IconHawkOctagon = defineAsyncComponent(() => import('~icons/hawk/octagon'));
export const IconHawkOpacity = defineAsyncComponent(() => import('~icons/hawk/opacity'));
export const IconHawkOverdueIcon = defineAsyncComponent(() => import('~icons/hawk/overdue-icon'));
export const IconHawkPackageCheck = defineAsyncComponent(() => import('~icons/hawk/package-check'));
export const IconHawkPackageMinus = defineAsyncComponent(() => import('~icons/hawk/package-minus'));
export const IconHawkPackagePlus = defineAsyncComponent(() => import('~icons/hawk/package-plus'));
export const IconHawkPackageSearch = defineAsyncComponent(() => import('~icons/hawk/package-search'));
export const IconHawkPackageX = defineAsyncComponent(() => import('~icons/hawk/package-x'));
export const IconHawkPackage = defineAsyncComponent(() => import('~icons/hawk/package'));
export const IconHawkPaintPour = defineAsyncComponent(() => import('~icons/hawk/paint-pour'));
export const IconHawkPaint = defineAsyncComponent(() => import('~icons/hawk/paint'));
export const IconHawkPalette = defineAsyncComponent(() => import('~icons/hawk/palette'));
export const IconHawkPaperclip = defineAsyncComponent(() => import('~icons/hawk/paperclip'));
export const IconHawkParagraphSpacing = defineAsyncComponent(() => import('~icons/hawk/paragraph-spacing'));
export const IconHawkParagraphWrap = defineAsyncComponent(() => import('~icons/hawk/paragraph-wrap'));
export const IconHawkPasscodeLock = defineAsyncComponent(() => import('~icons/hawk/passcode-lock'));
export const IconHawkPasscode = defineAsyncComponent(() => import('~icons/hawk/passcode'));
export const IconHawkPassport = defineAsyncComponent(() => import('~icons/hawk/passport'));
export const IconHawkPauseCircle = defineAsyncComponent(() => import('~icons/hawk/pause-circle'));
export const IconHawkPauseSquare = defineAsyncComponent(() => import('~icons/hawk/pause-square'));
export const IconHawkPause = defineAsyncComponent(() => import('~icons/hawk/pause'));
export const IconHawkPenToolMinus = defineAsyncComponent(() => import('~icons/hawk/pen-tool-minus'));
export const IconHawkPenToolOne = defineAsyncComponent(() => import('~icons/hawk/pen-tool-one'));
export const IconHawkPenToolPlus = defineAsyncComponent(() => import('~icons/hawk/pen-tool-plus'));
export const IconHawkPenToolTwo = defineAsyncComponent(() => import('~icons/hawk/pen-tool-two'));
export const IconHawkPencilLine = defineAsyncComponent(() => import('~icons/hawk/pencil-line'));
export const IconHawkPencilOne = defineAsyncComponent(() => import('~icons/hawk/pencil-one'));
export const IconHawkPencilTwo = defineAsyncComponent(() => import('~icons/hawk/pencil-two'));
export const IconHawkPencil = defineAsyncComponent(() => import('~icons/hawk/pencil'));
export const IconHawkPentagon = defineAsyncComponent(() => import('~icons/hawk/pentagon'));
export const IconHawkPercentOne = defineAsyncComponent(() => import('~icons/hawk/percent-one'));
export const IconHawkPercentThree = defineAsyncComponent(() => import('~icons/hawk/percent-three'));
export const IconHawkPercentTwo = defineAsyncComponent(() => import('~icons/hawk/percent-two'));
export const IconHawkPerspectiveOne = defineAsyncComponent(() => import('~icons/hawk/perspective-one'));
export const IconHawkPerspectiveTwo = defineAsyncComponent(() => import('~icons/hawk/perspective-two'));
export const IconHawkPhoneCallOne = defineAsyncComponent(() => import('~icons/hawk/phone-call-one'));
export const IconHawkPhoneCallTwo = defineAsyncComponent(() => import('~icons/hawk/phone-call-two'));
export const IconHawkPhoneHangUp = defineAsyncComponent(() => import('~icons/hawk/phone-hang-up'));
export const IconHawkPhoneIncomingOne = defineAsyncComponent(() => import('~icons/hawk/phone-incoming-one'));
export const IconHawkPhoneIncomingTwo = defineAsyncComponent(() => import('~icons/hawk/phone-incoming-two'));
export const IconHawkPhoneOne = defineAsyncComponent(() => import('~icons/hawk/phone-one'));
export const IconHawkPhoneOutgoingOne = defineAsyncComponent(() => import('~icons/hawk/phone-outgoing-one'));
export const IconHawkPhoneOutgoingTwo = defineAsyncComponent(() => import('~icons/hawk/phone-outgoing-two'));
export const IconHawkPhonePause = defineAsyncComponent(() => import('~icons/hawk/phone-pause'));
export const IconHawkPhonePlus = defineAsyncComponent(() => import('~icons/hawk/phone-plus'));
export const IconHawkPhoneTwo = defineAsyncComponent(() => import('~icons/hawk/phone-two'));
export const IconHawkPhoneX = defineAsyncComponent(() => import('~icons/hawk/phone-x'));
export const IconHawkPhone = defineAsyncComponent(() => import('~icons/hawk/phone'));
export const IconHawkPieChartFour = defineAsyncComponent(() => import('~icons/hawk/pie-chart-four'));
export const IconHawkPieChartOne = defineAsyncComponent(() => import('~icons/hawk/pie-chart-one'));
export const IconHawkPieChartThree = defineAsyncComponent(() => import('~icons/hawk/pie-chart-three'));
export const IconHawkPieChartTwo = defineAsyncComponent(() => import('~icons/hawk/pie-chart-two'));
export const IconHawkPiggyBankOne = defineAsyncComponent(() => import('~icons/hawk/piggy-bank-one'));
export const IconHawkPiggyBankTwo = defineAsyncComponent(() => import('~icons/hawk/piggy-bank-two'));
export const IconHawkPilcrowOne = defineAsyncComponent(() => import('~icons/hawk/pilcrow-one'));
export const IconHawkPilcrowSquare = defineAsyncComponent(() => import('~icons/hawk/pilcrow-square'));
export const IconHawkPilcrowTwo = defineAsyncComponent(() => import('~icons/hawk/pilcrow-two'));
export const IconHawkPinOne = defineAsyncComponent(() => import('~icons/hawk/pin-one'));
export const IconHawkPinTwoInactive = defineAsyncComponent(() => import('~icons/hawk/pin-two-inactive'));
export const IconHawkPinTwo = defineAsyncComponent(() => import('~icons/hawk/pin-two'));
export const IconHawkPin = defineAsyncComponent(() => import('~icons/hawk/pin'));
export const IconHawkPinnedOne = defineAsyncComponent(() => import('~icons/hawk/pinned-one'));
export const IconHawkPinned = defineAsyncComponent(() => import('~icons/hawk/pinned'));
export const IconHawkPlaceholder = defineAsyncComponent(() => import('~icons/hawk/placeholder'));
export const IconHawkPlane = defineAsyncComponent(() => import('~icons/hawk/plane'));
export const IconHawkPlayCircle = defineAsyncComponent(() => import('~icons/hawk/play-circle'));
export const IconHawkPlaySquare = defineAsyncComponent(() => import('~icons/hawk/play-square'));
export const IconHawkPlay = defineAsyncComponent(() => import('~icons/hawk/play'));
export const IconHawkPlusCircle = defineAsyncComponent(() => import('~icons/hawk/plus-circle'));
export const IconHawkPlusGray = defineAsyncComponent(() => import('~icons/hawk/plus-gray'));
export const IconHawkPlusSquare = defineAsyncComponent(() => import('~icons/hawk/plus-square'));
export const IconHawkPlusWhite = defineAsyncComponent(() => import('~icons/hawk/plus-white'));
export const IconHawkPlus = defineAsyncComponent(() => import('~icons/hawk/plus'));
export const IconHawkPmProgressPointer = defineAsyncComponent(() => import('~icons/hawk/pm-progress-pointer'));
export const IconHawkPodcast = defineAsyncComponent(() => import('~icons/hawk/podcast'));
export const IconHawkPointThree = defineAsyncComponent(() => import('~icons/hawk/point-three'));
export const IconHawkPoint = defineAsyncComponent(() => import('~icons/hawk/point'));
export const IconHawkPolygon = defineAsyncComponent(() => import('~icons/hawk/polygon'));
export const IconHawkPolyline = defineAsyncComponent(() => import('~icons/hawk/polyline'));
export const IconHawkPowerOne = defineAsyncComponent(() => import('~icons/hawk/power-one'));
export const IconHawkPowerThree = defineAsyncComponent(() => import('~icons/hawk/power-three'));
export const IconHawkPowerTwo = defineAsyncComponent(() => import('~icons/hawk/power-two'));
export const IconHawkPresentationChartOne = defineAsyncComponent(() => import('~icons/hawk/presentation-chart-one'));
export const IconHawkPresentationChartThree = defineAsyncComponent(() => import('~icons/hawk/presentation-chart-three'));
export const IconHawkPresentationChartTwo = defineAsyncComponent(() => import('~icons/hawk/presentation-chart-two'));
export const IconHawkPrint = defineAsyncComponent(() => import('~icons/hawk/print'));
export const IconHawkPrinter = defineAsyncComponent(() => import('~icons/hawk/printer'));
export const IconHawkPuzzlePieceOne = defineAsyncComponent(() => import('~icons/hawk/puzzle-piece-one'));
export const IconHawkPuzzlePieceTwo = defineAsyncComponent(() => import('~icons/hawk/puzzle-piece-two'));
export const IconHawkQrCodeOne = defineAsyncComponent(() => import('~icons/hawk/qr-code-one'));
export const IconHawkQrCodeTwo = defineAsyncComponent(() => import('~icons/hawk/qr-code-two'));
export const IconHawkReadMode = defineAsyncComponent(() => import('~icons/hawk/read-mode'));
export const IconHawkReceiptCheck = defineAsyncComponent(() => import('~icons/hawk/receipt-check'));
export const IconHawkReceipt = defineAsyncComponent(() => import('~icons/hawk/receipt'));
export const IconHawkRecordingOne = defineAsyncComponent(() => import('~icons/hawk/recording-one'));
export const IconHawkRecordingThree = defineAsyncComponent(() => import('~icons/hawk/recording-three'));
export const IconHawkRecordingTwo = defineAsyncComponent(() => import('~icons/hawk/recording-two'));
export const IconHawkRectangle = defineAsyncComponent(() => import('~icons/hawk/rectangle'));
export const IconHawkReflectOne = defineAsyncComponent(() => import('~icons/hawk/reflect-one'));
export const IconHawkReflectTwo = defineAsyncComponent(() => import('~icons/hawk/reflect-two'));
export const IconHawkRefreshCcwFive = defineAsyncComponent(() => import('~icons/hawk/refresh-ccw-five'));
export const IconHawkRefreshCcwFour = defineAsyncComponent(() => import('~icons/hawk/refresh-ccw-four'));
export const IconHawkRefreshCcwOne = defineAsyncComponent(() => import('~icons/hawk/refresh-ccw-one'));
export const IconHawkRefreshCcwThree = defineAsyncComponent(() => import('~icons/hawk/refresh-ccw-three'));
export const IconHawkRefreshCcwTwo = defineAsyncComponent(() => import('~icons/hawk/refresh-ccw-two'));
export const IconHawkRefreshCwFive = defineAsyncComponent(() => import('~icons/hawk/refresh-cw-five'));
export const IconHawkRefreshCwFour = defineAsyncComponent(() => import('~icons/hawk/refresh-cw-four'));
export const IconHawkRefreshCwOne = defineAsyncComponent(() => import('~icons/hawk/refresh-cw-one'));
export const IconHawkRefreshCwThree = defineAsyncComponent(() => import('~icons/hawk/refresh-cw-three'));
export const IconHawkRefreshCwTwo = defineAsyncComponent(() => import('~icons/hawk/refresh-cw-two'));
export const IconHawkReminder = defineAsyncComponent(() => import('~icons/hawk/reminder'));
export const IconHawkRepeatFour = defineAsyncComponent(() => import('~icons/hawk/repeat-four'));
export const IconHawkRepeatOne = defineAsyncComponent(() => import('~icons/hawk/repeat-one'));
export const IconHawkRepeatThree = defineAsyncComponent(() => import('~icons/hawk/repeat-three'));
export const IconHawkRepeatTwo = defineAsyncComponent(() => import('~icons/hawk/repeat-two'));
export const IconHawkReverseLeft = defineAsyncComponent(() => import('~icons/hawk/reverse-left'));
export const IconHawkReverseRight = defineAsyncComponent(() => import('~icons/hawk/reverse-right'));
export const IconHawkRightIndentOne = defineAsyncComponent(() => import('~icons/hawk/right-indent-one'));
export const IconHawkRightIndentTwo = defineAsyncComponent(() => import('~icons/hawk/right-indent-two'));
export const IconHawkRocketOne = defineAsyncComponent(() => import('~icons/hawk/rocket-one'));
export const IconHawkRocketTwo = defineAsyncComponent(() => import('~icons/hawk/rocket-two'));
export const IconHawkRollback = defineAsyncComponent(() => import('~icons/hawk/rollback'));
export const IconHawkRollerBrush = defineAsyncComponent(() => import('~icons/hawk/roller-brush'));
export const IconHawkRoute = defineAsyncComponent(() => import('~icons/hawk/route'));
export const IconHawkRowsOne = defineAsyncComponent(() => import('~icons/hawk/rows-one'));
export const IconHawkRowsThree = defineAsyncComponent(() => import('~icons/hawk/rows-three'));
export const IconHawkRowsTwo = defineAsyncComponent(() => import('~icons/hawk/rows-two'));
export const IconHawkRssOne = defineAsyncComponent(() => import('~icons/hawk/rss-one'));
export const IconHawkRssTwo = defineAsyncComponent(() => import('~icons/hawk/rss-two'));
export const IconHawkRuler = defineAsyncComponent(() => import('~icons/hawk/ruler'));
export const IconHawkSafe = defineAsyncComponent(() => import('~icons/hawk/safe'));
export const IconHawkSaleFour = defineAsyncComponent(() => import('~icons/hawk/sale-four'));
export const IconHawkSaleOne = defineAsyncComponent(() => import('~icons/hawk/sale-one'));
export const IconHawkSaleThree = defineAsyncComponent(() => import('~icons/hawk/sale-three'));
export const IconHawkSaleTwo = defineAsyncComponent(() => import('~icons/hawk/sale-two'));
export const IconHawkSaveOne = defineAsyncComponent(() => import('~icons/hawk/save-one'));
export const IconHawkSaveThree = defineAsyncComponent(() => import('~icons/hawk/save-three'));
export const IconHawkSaveTwo = defineAsyncComponent(() => import('~icons/hawk/save-two'));
export const IconHawkScaleOne = defineAsyncComponent(() => import('~icons/hawk/scale-one'));
export const IconHawkScaleThree = defineAsyncComponent(() => import('~icons/hawk/scale-three'));
export const IconHawkScaleTwo = defineAsyncComponent(() => import('~icons/hawk/scale-two'));
export const IconHawkScalesOne = defineAsyncComponent(() => import('~icons/hawk/scales-one'));
export const IconHawkScalesTwo = defineAsyncComponent(() => import('~icons/hawk/scales-two'));
export const IconHawkScan = defineAsyncComponent(() => import('~icons/hawk/scan'));
export const IconHawkSchedule = defineAsyncComponent(() => import('~icons/hawk/schedule'));
export const IconHawkScissorsCutOne = defineAsyncComponent(() => import('~icons/hawk/scissors-cut-one'));
export const IconHawkScissorsCutTwo = defineAsyncComponent(() => import('~icons/hawk/scissors-cut-two'));
export const IconHawkScissorsOne = defineAsyncComponent(() => import('~icons/hawk/scissors-one'));
export const IconHawkScissorsTwo = defineAsyncComponent(() => import('~icons/hawk/scissors-two'));
export const IconHawkSearchLg = defineAsyncComponent(() => import('~icons/hawk/search-lg'));
export const IconHawkSearchMd = defineAsyncComponent(() => import('~icons/hawk/search-md'));
export const IconHawkSearchRefraction = defineAsyncComponent(() => import('~icons/hawk/search-refraction'));
export const IconHawkSearchSm = defineAsyncComponent(() => import('~icons/hawk/search-sm'));
export const IconHawkSearch = defineAsyncComponent(() => import('~icons/hawk/search'));
export const IconHawkSendOne = defineAsyncComponent(() => import('~icons/hawk/send-one'));
export const IconHawkSendThree = defineAsyncComponent(() => import('~icons/hawk/send-three'));
export const IconHawkSendTwo = defineAsyncComponent(() => import('~icons/hawk/send-two'));
export const IconHawkSend = defineAsyncComponent(() => import('~icons/hawk/send'));
export const IconHawkSensehawkUser = defineAsyncComponent(() => import('~icons/hawk/sensehawk-user'));
export const IconHawkServerFive = defineAsyncComponent(() => import('~icons/hawk/server-five'));
export const IconHawkServerFour = defineAsyncComponent(() => import('~icons/hawk/server-four'));
export const IconHawkServerOne = defineAsyncComponent(() => import('~icons/hawk/server-one'));
export const IconHawkServerSix = defineAsyncComponent(() => import('~icons/hawk/server-six'));
export const IconHawkServerThree = defineAsyncComponent(() => import('~icons/hawk/server-three'));
export const IconHawkServerTwo = defineAsyncComponent(() => import('~icons/hawk/server-two'));
export const IconHawkSettingsFour = defineAsyncComponent(() => import('~icons/hawk/settings-four'));
export const IconHawkSettingsIcon = defineAsyncComponent(() => import('~icons/hawk/settings-icon'));
export const IconHawkSettingsOne = defineAsyncComponent(() => import('~icons/hawk/settings-one'));
export const IconHawkSettingsThree = defineAsyncComponent(() => import('~icons/hawk/settings-three'));
export const IconHawkSettingsTwo = defineAsyncComponent(() => import('~icons/hawk/settings-two'));
export const IconHawkShareFive = defineAsyncComponent(() => import('~icons/hawk/share-five'));
export const IconHawkShareFour = defineAsyncComponent(() => import('~icons/hawk/share-four'));
export const IconHawkShareOne = defineAsyncComponent(() => import('~icons/hawk/share-one'));
export const IconHawkShareSeven = defineAsyncComponent(() => import('~icons/hawk/share-seven'));
export const IconHawkShareSix = defineAsyncComponent(() => import('~icons/hawk/share-six'));
export const IconHawkShareThree = defineAsyncComponent(() => import('~icons/hawk/share-three'));
export const IconHawkShareTwo = defineAsyncComponent(() => import('~icons/hawk/share-two'));
export const IconHawkShieldDollar = defineAsyncComponent(() => import('~icons/hawk/shield-dollar'));
export const IconHawkShieldOff = defineAsyncComponent(() => import('~icons/hawk/shield-off'));
export const IconHawkShieldOne = defineAsyncComponent(() => import('~icons/hawk/shield-one'));
export const IconHawkShieldPlus = defineAsyncComponent(() => import('~icons/hawk/shield-plus'));
export const IconHawkShieldThree = defineAsyncComponent(() => import('~icons/hawk/shield-three'));
export const IconHawkShieldTick = defineAsyncComponent(() => import('~icons/hawk/shield-tick'));
export const IconHawkShieldTwo = defineAsyncComponent(() => import('~icons/hawk/shield-two'));
export const IconHawkShieldZap = defineAsyncComponent(() => import('~icons/hawk/shield-zap'));
export const IconHawkShoppingBagOne = defineAsyncComponent(() => import('~icons/hawk/shopping-bag-one'));
export const IconHawkShoppingBagThree = defineAsyncComponent(() => import('~icons/hawk/shopping-bag-three'));
export const IconHawkShoppingBagTwo = defineAsyncComponent(() => import('~icons/hawk/shopping-bag-two'));
export const IconHawkShoppingCartOne = defineAsyncComponent(() => import('~icons/hawk/shopping-cart-one'));
export const IconHawkShoppingCartThree = defineAsyncComponent(() => import('~icons/hawk/shopping-cart-three'));
export const IconHawkShoppingCartTwo = defineAsyncComponent(() => import('~icons/hawk/shopping-cart-two'));
export const IconHawkShuffleOne = defineAsyncComponent(() => import('~icons/hawk/shuffle-one'));
export const IconHawkShuffleTwo = defineAsyncComponent(() => import('~icons/hawk/shuffle-two'));
export const IconHawkSignalOne = defineAsyncComponent(() => import('~icons/hawk/signal-one'));
export const IconHawkSignalThree = defineAsyncComponent(() => import('~icons/hawk/signal-three'));
export const IconHawkSignalTwo = defineAsyncComponent(() => import('~icons/hawk/signal-two'));
export const IconHawkSignature = defineAsyncComponent(() => import('~icons/hawk/signature'));
export const IconHawkSimcard = defineAsyncComponent(() => import('~icons/hawk/simcard'));
export const IconHawkSkew = defineAsyncComponent(() => import('~icons/hawk/skew'));
export const IconHawkSkipBack = defineAsyncComponent(() => import('~icons/hawk/skip-back'));
export const IconHawkSkipForward = defineAsyncComponent(() => import('~icons/hawk/skip-forward'));
export const IconHawkSlashCircleOne = defineAsyncComponent(() => import('~icons/hawk/slash-circle-one'));
export const IconHawkSlashCircleTwo = defineAsyncComponent(() => import('~icons/hawk/slash-circle-two'));
export const IconHawkSlashDivider = defineAsyncComponent(() => import('~icons/hawk/slash-divider'));
export const IconHawkSlashOctagon = defineAsyncComponent(() => import('~icons/hawk/slash-octagon'));
export const IconHawkSlidersFour = defineAsyncComponent(() => import('~icons/hawk/sliders-four'));
export const IconHawkSlidersOne = defineAsyncComponent(() => import('~icons/hawk/sliders-one'));
export const IconHawkSlidersThree = defineAsyncComponent(() => import('~icons/hawk/sliders-three'));
export const IconHawkSlidersTwo = defineAsyncComponent(() => import('~icons/hawk/sliders-two'));
export const IconHawkSnowflakeOne = defineAsyncComponent(() => import('~icons/hawk/snowflake-one'));
export const IconHawkSnowflakeTwo = defineAsyncComponent(() => import('~icons/hawk/snowflake-two'));
export const IconHawkSpaceWidthOne = defineAsyncComponent(() => import('~icons/hawk/space-width-one'));
export const IconHawkSpacingHeightOne = defineAsyncComponent(() => import('~icons/hawk/spacing-height-one'));
export const IconHawkSpacingHeightTwo = defineAsyncComponent(() => import('~icons/hawk/spacing-height-two'));
export const IconHawkSpacingWidthOne = defineAsyncComponent(() => import('~icons/hawk/spacing-width-one'));
export const IconHawkSpacingWidthTwo = defineAsyncComponent(() => import('~icons/hawk/spacing-width-two'));
export const IconHawkSpeakerOne = defineAsyncComponent(() => import('~icons/hawk/speaker-one'));
export const IconHawkSpeakerThree = defineAsyncComponent(() => import('~icons/hawk/speaker-three'));
export const IconHawkSpeakerTwo = defineAsyncComponent(() => import('~icons/hawk/speaker-two'));
export const IconHawkSpeedometerFour = defineAsyncComponent(() => import('~icons/hawk/speedometer-four'));
export const IconHawkSpeedometerOne = defineAsyncComponent(() => import('~icons/hawk/speedometer-one'));
export const IconHawkSpeedometerThree = defineAsyncComponent(() => import('~icons/hawk/speedometer-three'));
export const IconHawkSpeedometerTwo = defineAsyncComponent(() => import('~icons/hawk/speedometer-two'));
export const IconHawkSquare = defineAsyncComponent(() => import('~icons/hawk/square'));
export const IconHawkStand = defineAsyncComponent(() => import('~icons/hawk/stand'));
export const IconHawkStarFilled = defineAsyncComponent(() => import('~icons/hawk/star-filled'));
export const IconHawkStarFive = defineAsyncComponent(() => import('~icons/hawk/star-five'));
export const IconHawkStarFour = defineAsyncComponent(() => import('~icons/hawk/star-four'));
export const IconHawkStarHover = defineAsyncComponent(() => import('~icons/hawk/star-hover'));
export const IconHawkStarOne = defineAsyncComponent(() => import('~icons/hawk/star-one'));
export const IconHawkStarSeven = defineAsyncComponent(() => import('~icons/hawk/star-seven'));
export const IconHawkStarSix = defineAsyncComponent(() => import('~icons/hawk/star-six'));
export const IconHawkStarThree = defineAsyncComponent(() => import('~icons/hawk/star-three'));
export const IconHawkStarTwo = defineAsyncComponent(() => import('~icons/hawk/star-two'));
export const IconHawkStarsOne = defineAsyncComponent(() => import('~icons/hawk/stars-one'));
export const IconHawkStarsThree = defineAsyncComponent(() => import('~icons/hawk/stars-three'));
export const IconHawkStarsTwo = defineAsyncComponent(() => import('~icons/hawk/stars-two'));
export const IconHawkStickerCircle = defineAsyncComponent(() => import('~icons/hawk/sticker-circle'));
export const IconHawkStickerSquare = defineAsyncComponent(() => import('~icons/hawk/sticker-square'));
export const IconHawkStopCircle = defineAsyncComponent(() => import('~icons/hawk/stop-circle'));
export const IconHawkStopSquare = defineAsyncComponent(() => import('~icons/hawk/stop-square'));
export const IconHawkStop = defineAsyncComponent(() => import('~icons/hawk/stop'));
export const IconHawkStrikethroughOne = defineAsyncComponent(() => import('~icons/hawk/strikethrough-one'));
export const IconHawkStrikethroughSquare = defineAsyncComponent(() => import('~icons/hawk/strikethrough-square'));
export const IconHawkStrikethroughTwo = defineAsyncComponent(() => import('~icons/hawk/strikethrough-two'));
export const IconHawkStrokeDash = defineAsyncComponent(() => import('~icons/hawk/stroke-dash'));
export const IconHawkSubscript = defineAsyncComponent(() => import('~icons/hawk/subscript'));
export const IconHawkSunSettingOne = defineAsyncComponent(() => import('~icons/hawk/sun-setting-one'));
export const IconHawkSunSettingThree = defineAsyncComponent(() => import('~icons/hawk/sun-setting-three'));
export const IconHawkSunSettingTwo = defineAsyncComponent(() => import('~icons/hawk/sun-setting-two'));
export const IconHawkSun = defineAsyncComponent(() => import('~icons/hawk/sun'));
export const IconHawkSunrise = defineAsyncComponent(() => import('~icons/hawk/sunrise'));
export const IconHawkSunset = defineAsyncComponent(() => import('~icons/hawk/sunset'));
export const IconHawkSwitchHorizontalOne = defineAsyncComponent(() => import('~icons/hawk/switch-horizontal-one'));
export const IconHawkSwitchHorizontalTwo = defineAsyncComponent(() => import('~icons/hawk/switch-horizontal-two'));
export const IconHawkSwitchVerticalOne = defineAsyncComponent(() => import('~icons/hawk/switch-vertical-one'));
export const IconHawkSwitchVerticalTwo = defineAsyncComponent(() => import('~icons/hawk/switch-vertical-two'));
export const IconHawkTableSection = defineAsyncComponent(() => import('~icons/hawk/table_section'));
export const IconHawkTableEdit = defineAsyncComponent(() => import('~icons/hawk/table-edit'));
export const IconHawkTableGant = defineAsyncComponent(() => import('~icons/hawk/table-gant'));
export const IconHawkTableTwo = defineAsyncComponent(() => import('~icons/hawk/table-two'));
export const IconHawkTable = defineAsyncComponent(() => import('~icons/hawk/table'));
export const IconHawkTabletOne = defineAsyncComponent(() => import('~icons/hawk/tablet-one'));
export const IconHawkTabletTwo = defineAsyncComponent(() => import('~icons/hawk/tablet-two'));
export const IconHawkTagOne = defineAsyncComponent(() => import('~icons/hawk/tag-one'));
export const IconHawkTagThree = defineAsyncComponent(() => import('~icons/hawk/tag-three'));
export const IconHawkTagTwo = defineAsyncComponent(() => import('~icons/hawk/tag-two'));
export const IconHawkTargetFive = defineAsyncComponent(() => import('~icons/hawk/target-five'));
export const IconHawkTargetFour = defineAsyncComponent(() => import('~icons/hawk/target-four'));
export const IconHawkTargetOne = defineAsyncComponent(() => import('~icons/hawk/target-one'));
export const IconHawkTargetThree = defineAsyncComponent(() => import('~icons/hawk/target-three'));
export const IconHawkTargetTwo = defineAsyncComponent(() => import('~icons/hawk/target-two'));
export const IconHawkTaskItem = defineAsyncComponent(() => import('~icons/hawk/task-item'));
export const IconHawkTelescope = defineAsyncComponent(() => import('~icons/hawk/telescope'));
export const IconHawkTemporary = defineAsyncComponent(() => import('~icons/hawk/temporary'));
export const IconHawkTerminalBrowser = defineAsyncComponent(() => import('~icons/hawk/terminal-browser'));
export const IconHawkTerminalCircle = defineAsyncComponent(() => import('~icons/hawk/terminal-circle'));
export const IconHawkTerminalSquare = defineAsyncComponent(() => import('~icons/hawk/terminal-square'));
export const IconHawkTerminal = defineAsyncComponent(() => import('~icons/hawk/terminal'));
export const IconHawkTextInput = defineAsyncComponent(() => import('~icons/hawk/text-input'));
export const IconHawkThermometerCold = defineAsyncComponent(() => import('~icons/hawk/thermometer-cold'));
export const IconHawkThermometerOne = defineAsyncComponent(() => import('~icons/hawk/thermometer-one'));
export const IconHawkThermometerThree = defineAsyncComponent(() => import('~icons/hawk/thermometer-three'));
export const IconHawkThermometerTwo = defineAsyncComponent(() => import('~icons/hawk/thermometer-two'));
export const IconHawkThermometerWarm = defineAsyncComponent(() => import('~icons/hawk/thermometer-warm'));
export const IconHawkThumbsDown = defineAsyncComponent(() => import('~icons/hawk/thumbs-down'));
export const IconHawkThumbsUpFilled = defineAsyncComponent(() => import('~icons/hawk/thumbs-up-filled'));
export const IconHawkThumbsUp = defineAsyncComponent(() => import('~icons/hawk/thumbs-up'));
export const IconHawkTickMark = defineAsyncComponent(() => import('~icons/hawk/tick-mark'));
export const IconHawkTicketOne = defineAsyncComponent(() => import('~icons/hawk/ticket-one'));
export const IconHawkTicketTwo = defineAsyncComponent(() => import('~icons/hawk/ticket-two'));
export const IconHawkToggleOneLeft = defineAsyncComponent(() => import('~icons/hawk/toggle-one-left'));
export const IconHawkToggleOneRight = defineAsyncComponent(() => import('~icons/hawk/toggle-one-right'));
export const IconHawkToggleThreeLeft = defineAsyncComponent(() => import('~icons/hawk/toggle-three-left'));
export const IconHawkToggleThreeRight = defineAsyncComponent(() => import('~icons/hawk/toggle-three-right'));
export const IconHawkToggleTwoLeft = defineAsyncComponent(() => import('~icons/hawk/toggle-two-left'));
export const IconHawkToggleTwoRight = defineAsyncComponent(() => import('~icons/hawk/toggle-two-right'));
export const IconHawkToolOne = defineAsyncComponent(() => import('~icons/hawk/tool-one'));
export const IconHawkToolTwo = defineAsyncComponent(() => import('~icons/hawk/tool-two'));
export const IconHawkTrain = defineAsyncComponent(() => import('~icons/hawk/train'));
export const IconHawkTram = defineAsyncComponent(() => import('~icons/hawk/tram'));
export const IconHawkTransform = defineAsyncComponent(() => import('~icons/hawk/transform'));
export const IconHawkTranslateOne = defineAsyncComponent(() => import('~icons/hawk/translate-one'));
export const IconHawkTranslateTwo = defineAsyncComponent(() => import('~icons/hawk/translate-two'));
export const IconHawkTrashFour = defineAsyncComponent(() => import('~icons/hawk/trash-four'));
export const IconHawkTrashOneRedRipple = defineAsyncComponent(() => import('~icons/hawk/trash-one-red-ripple'));
export const IconHawkTrashOne = defineAsyncComponent(() => import('~icons/hawk/trash-one'));
export const IconHawkTrashThree = defineAsyncComponent(() => import('~icons/hawk/trash-three'));
export const IconHawkTrashTwo = defineAsyncComponent(() => import('~icons/hawk/trash-two'));
export const IconHawkTrendDownOne = defineAsyncComponent(() => import('~icons/hawk/trend-down-one'));
export const IconHawkTrendDownTwo = defineAsyncComponent(() => import('~icons/hawk/trend-down-two'));
export const IconHawkTrendUpOne = defineAsyncComponent(() => import('~icons/hawk/trend-up-one'));
export const IconHawkTrendUpTwo = defineAsyncComponent(() => import('~icons/hawk/trend-up-two'));
export const IconHawkTriangle = defineAsyncComponent(() => import('~icons/hawk/triangle'));
export const IconHawkTristateChecked = defineAsyncComponent(() => import('~icons/hawk/tristate-checked'));
export const IconHawkTristateIntermediate = defineAsyncComponent(() => import('~icons/hawk/tristate-intermediate'));
export const IconHawkTristateUnchecked = defineAsyncComponent(() => import('~icons/hawk/tristate-unchecked'));
export const IconHawkTrophyFilled = defineAsyncComponent(() => import('~icons/hawk/trophy-filled'));
export const IconHawkTrophyOne = defineAsyncComponent(() => import('~icons/hawk/trophy-one'));
export const IconHawkTrophyTwo = defineAsyncComponent(() => import('~icons/hawk/trophy-two'));
export const IconHawkTruckOne = defineAsyncComponent(() => import('~icons/hawk/truck-one'));
export const IconHawkTruckTwo = defineAsyncComponent(() => import('~icons/hawk/truck-two'));
export const IconHawkTvOne = defineAsyncComponent(() => import('~icons/hawk/tv-one'));
export const IconHawkTvThree = defineAsyncComponent(() => import('~icons/hawk/tv-three'));
export const IconHawkTvTwo = defineAsyncComponent(() => import('~icons/hawk/tv-two'));
export const IconHawkTypeOne = defineAsyncComponent(() => import('~icons/hawk/type-one'));
export const IconHawkTypeSquare = defineAsyncComponent(() => import('~icons/hawk/type-square'));
export const IconHawkTypeStrikethroughOne = defineAsyncComponent(() => import('~icons/hawk/type-strikethrough-one'));
export const IconHawkTypeStrikethroughTwo = defineAsyncComponent(() => import('~icons/hawk/type-strikethrough-two'));
export const IconHawkTypeTwo = defineAsyncComponent(() => import('~icons/hawk/type-two'));
export const IconHawkUmbrellaOne = defineAsyncComponent(() => import('~icons/hawk/umbrella-one'));
export const IconHawkUmbrellaThree = defineAsyncComponent(() => import('~icons/hawk/umbrella-three'));
export const IconHawkUmbrellaTwo = defineAsyncComponent(() => import('~icons/hawk/umbrella-two'));
export const IconHawkUnderlineOne = defineAsyncComponent(() => import('~icons/hawk/underline-one'));
export const IconHawkUnderlineSquare = defineAsyncComponent(() => import('~icons/hawk/underline-square'));
export const IconHawkUnderlineTwo = defineAsyncComponent(() => import('~icons/hawk/underline-two'));
export const IconHawkUnknownUser = defineAsyncComponent(() => import('~icons/hawk/unknown-user'));
export const IconHawkUploadCloudOne = defineAsyncComponent(() => import('~icons/hawk/upload-cloud-one'));
export const IconHawkUploadCloudTwoGrayRipple = defineAsyncComponent(() => import('~icons/hawk/upload-cloud-two-gray-ripple'));
export const IconHawkUploadCloudTwoInlineGrayRipple = defineAsyncComponent(() => import('~icons/hawk/upload-cloud-two-inline-gray-ripple'));
export const IconHawkUploadCloudTwo = defineAsyncComponent(() => import('~icons/hawk/upload-cloud-two'));
export const IconHawkUploadFileIcon = defineAsyncComponent(() => import('~icons/hawk/upload-file-icon'));
export const IconHawkUploadFour = defineAsyncComponent(() => import('~icons/hawk/upload-four'));
export const IconHawkUploadOne = defineAsyncComponent(() => import('~icons/hawk/upload-one'));
export const IconHawkUploadThree = defineAsyncComponent(() => import('~icons/hawk/upload-three'));
export const IconHawkUploadTwo = defineAsyncComponent(() => import('~icons/hawk/upload-two'));
export const IconHawkUsbFlashDrive = defineAsyncComponent(() => import('~icons/hawk/usb-flash-drive'));
export const IconHawkUserCheckOne = defineAsyncComponent(() => import('~icons/hawk/user-check-one'));
export const IconHawkUserCheckTwo = defineAsyncComponent(() => import('~icons/hawk/user-check-two'));
export const IconHawkUserCircle = defineAsyncComponent(() => import('~icons/hawk/user-circle'));
export const IconHawkUserDownOne = defineAsyncComponent(() => import('~icons/hawk/user-down-one'));
export const IconHawkUserDownTwo = defineAsyncComponent(() => import('~icons/hawk/user-down-two'));
export const IconHawkUserEdit = defineAsyncComponent(() => import('~icons/hawk/user-edit'));
export const IconHawkUserGroup = defineAsyncComponent(() => import('~icons/hawk/user-group'));
export const IconHawkUserLeftOne = defineAsyncComponent(() => import('~icons/hawk/user-left-one'));
export const IconHawkUserLeftTwo = defineAsyncComponent(() => import('~icons/hawk/user-left-two'));
export const IconHawkUserMinusOne = defineAsyncComponent(() => import('~icons/hawk/user-minus-one'));
export const IconHawkUserMinusTwo = defineAsyncComponent(() => import('~icons/hawk/user-minus-two'));
export const IconHawkUserOne = defineAsyncComponent(() => import('~icons/hawk/user-one'));
export const IconHawkUserPlusOne = defineAsyncComponent(() => import('~icons/hawk/user-plus-one'));
export const IconHawkUserPlusTwo = defineAsyncComponent(() => import('~icons/hawk/user-plus-two'));
export const IconHawkUserRightOne = defineAsyncComponent(() => import('~icons/hawk/user-right-one'));
export const IconHawkUserRightTwo = defineAsyncComponent(() => import('~icons/hawk/user-right-two'));
export const IconHawkUserSquare = defineAsyncComponent(() => import('~icons/hawk/user-square'));
export const IconHawkUserThree = defineAsyncComponent(() => import('~icons/hawk/user-three'));
export const IconHawkUserTwo = defineAsyncComponent(() => import('~icons/hawk/user-two'));
export const IconHawkUserUpOne = defineAsyncComponent(() => import('~icons/hawk/user-up-one'));
export const IconHawkUserUpTwo = defineAsyncComponent(() => import('~icons/hawk/user-up-two'));
export const IconHawkUserXOne = defineAsyncComponent(() => import('~icons/hawk/user-x-one'));
export const IconHawkUserXTwo = defineAsyncComponent(() => import('~icons/hawk/user-x-two'));
export const IconHawkUsersCheck = defineAsyncComponent(() => import('~icons/hawk/users-check'));
export const IconHawkUsersDown = defineAsyncComponent(() => import('~icons/hawk/users-down'));
export const IconHawkUsersEdit = defineAsyncComponent(() => import('~icons/hawk/users-edit'));
export const IconHawkUsersLeft = defineAsyncComponent(() => import('~icons/hawk/users-left'));
export const IconHawkUsersMinus = defineAsyncComponent(() => import('~icons/hawk/users-minus'));
export const IconHawkUsersOne = defineAsyncComponent(() => import('~icons/hawk/users-one'));
export const IconHawkUsersPlus = defineAsyncComponent(() => import('~icons/hawk/users-plus'));
export const IconHawkUsersRight = defineAsyncComponent(() => import('~icons/hawk/users-right'));
export const IconHawkUsersShare = defineAsyncComponent(() => import('~icons/hawk/users-share'));
export const IconHawkUsersThree = defineAsyncComponent(() => import('~icons/hawk/users-three'));
export const IconHawkUsersTwo = defineAsyncComponent(() => import('~icons/hawk/users-two'));
export const IconHawkUsersUp = defineAsyncComponent(() => import('~icons/hawk/users-up'));
export const IconHawkUsersX = defineAsyncComponent(() => import('~icons/hawk/users-x'));
export const IconHawkVariable = defineAsyncComponent(() => import('~icons/hawk/variable'));
export const IconHawkVideoRecorderOff = defineAsyncComponent(() => import('~icons/hawk/video-recorder-off'));
export const IconHawkVideoRecorder = defineAsyncComponent(() => import('~icons/hawk/video-recorder'));
export const IconHawkVirus = defineAsyncComponent(() => import('~icons/hawk/virus'));
export const IconHawkVoicemail = defineAsyncComponent(() => import('~icons/hawk/voicemail'));
export const IconHawkVolumeCalculation = defineAsyncComponent(() => import('~icons/hawk/volume-calculation'));
export const IconHawkVolumeMax = defineAsyncComponent(() => import('~icons/hawk/volume-max'));
export const IconHawkVolumeMin = defineAsyncComponent(() => import('~icons/hawk/volume-min'));
export const IconHawkVolumeMinus = defineAsyncComponent(() => import('~icons/hawk/volume-minus'));
export const IconHawkVolumePlus = defineAsyncComponent(() => import('~icons/hawk/volume-plus'));
export const IconHawkVolumeX = defineAsyncComponent(() => import('~icons/hawk/volume-x'));
export const IconHawkWalletFive = defineAsyncComponent(() => import('~icons/hawk/wallet-five'));
export const IconHawkWalletFour = defineAsyncComponent(() => import('~icons/hawk/wallet-four'));
export const IconHawkWalletOne = defineAsyncComponent(() => import('~icons/hawk/wallet-one'));
export const IconHawkWalletThree = defineAsyncComponent(() => import('~icons/hawk/wallet-three'));
export const IconHawkWalletTwo = defineAsyncComponent(() => import('~icons/hawk/wallet-two'));
export const IconHawkWarnIcon = defineAsyncComponent(() => import('~icons/hawk/warn-icon'));
export const IconHawkWarningSign = defineAsyncComponent(() => import('~icons/hawk/warning-sign'));
export const IconHawkWatchCircle = defineAsyncComponent(() => import('~icons/hawk/watch-circle'));
export const IconHawkWatchSquare = defineAsyncComponent(() => import('~icons/hawk/watch-square'));
export const IconHawkWatcher = defineAsyncComponent(() => import('~icons/hawk/watcher'));
export const IconHawkWaves = defineAsyncComponent(() => import('~icons/hawk/waves'));
export const IconHawkWebcamOne = defineAsyncComponent(() => import('~icons/hawk/webcam-one'));
export const IconHawkWebcamTwo = defineAsyncComponent(() => import('~icons/hawk/webcam-two'));
export const IconHawkWifiOff = defineAsyncComponent(() => import('~icons/hawk/wifi-off'));
export const IconHawkWifi = defineAsyncComponent(() => import('~icons/hawk/wifi'));
export const IconHawkWindOne = defineAsyncComponent(() => import('~icons/hawk/wind-one'));
export const IconHawkWindThree = defineAsyncComponent(() => import('~icons/hawk/wind-three'));
export const IconHawkWindTwo = defineAsyncComponent(() => import('~icons/hawk/wind-two'));
export const IconHawkWindowIcon = defineAsyncComponent(() => import('~icons/hawk/window-icon'));
export const IconHawkXCircleRed = defineAsyncComponent(() => import('~icons/hawk/x-circle-red'));
export const IconHawkXCircle = defineAsyncComponent(() => import('~icons/hawk/x-circle'));
export const IconHawkXClose = defineAsyncComponent(() => import('~icons/hawk/x-close'));
export const IconHawkXSquare = defineAsyncComponent(() => import('~icons/hawk/x-square'));
export const IconHawkXTwo = defineAsyncComponent(() => import('~icons/hawk/x-two'));
export const IconHawkX = defineAsyncComponent(() => import('~icons/hawk/x'));
export const IconHawkYesNo = defineAsyncComponent(() => import('~icons/hawk/yes-no'));
export const IconHawkYoutube = defineAsyncComponent(() => import('~icons/hawk/youtube'));
export const IconHawkZapCircle = defineAsyncComponent(() => import('~icons/hawk/zap-circle'));
export const IconHawkZapFast = defineAsyncComponent(() => import('~icons/hawk/zap-fast'));
export const IconHawkZapOff = defineAsyncComponent(() => import('~icons/hawk/zap-off'));
export const IconHawkZapSquare = defineAsyncComponent(() => import('~icons/hawk/zap-square'));
export const IconHawkZap = defineAsyncComponent(() => import('~icons/hawk/zap'));
export const IconHawkZoomIn = defineAsyncComponent(() => import('~icons/hawk/zoom-in'));
export const IconHawkZoomOut = defineAsyncComponent(() => import('~icons/hawk/zoom-out'));

const icons = {
  IconHawkActivityHeart,
  IconHawkActivity,
  IconHawkAirplay,
  IconHawkAirpods,
  IconHawkAlarmClockCheck,
  IconHawkAlarmClockMinus,
  IconHawkAlarmClockOff,
  IconHawkAlarmClockPlus,
  IconHawkAlarmClock,
  IconHawkAlertCircle,
  IconHawkAlertHexagon,
  IconHawkAlertOctagon,
  IconHawkAlertSquare,
  IconHawkAlertTriangleRedRipple,
  IconHawkAlertTriangle,
  IconHawkAlignBottomOne,
  IconHawkAlignBottomTwo,
  IconHawkAlignCenter,
  IconHawkAlignHorizontalCentreOne,
  IconHawkAlignHorizontalCentreTwo,
  IconHawkAlignJustify,
  IconHawkAlignLeftOne,
  IconHawkAlignLeftTwo,
  IconHawkAlignLeft,
  IconHawkAlignRightOne,
  IconHawkAlignRightTwo,
  IconHawkAlignRight,
  IconHawkAlignTopArrowOne,
  IconHawkAlignTopArrowTwo,
  IconHawkAlignVerticalCenterOne,
  IconHawkAlignVerticalCenterTwo,
  IconHawkAnchor,
  IconHawkAnnotationAlert,
  IconHawkAnnotationCheck,
  IconHawkAnnotationDots,
  IconHawkAnnotationHeart,
  IconHawkAnnotationInfo,
  IconHawkAnnotationPlus,
  IconHawkAnnotationQuestion,
  IconHawkAnnotationX,
  IconHawkAnnotation,
  IconHawkAnnouncementOne,
  IconHawkAnnouncementThree,
  IconHawkAnnouncementTwo,
  IconHawkArchive,
  IconHawkArrowBlockDown,
  IconHawkArrowBlockLeft,
  IconHawkArrowBlockRight,
  IconHawkArrowBlockUp,
  IconHawkArrowCircleBrokenDownLeft,
  IconHawkArrowCircleBrokenDownRight,
  IconHawkArrowCircleBrokenDown,
  IconHawkArrowCircleBrokenLeft,
  IconHawkArrowCircleBrokenRight,
  IconHawkArrowCircleBrokenUpLeft,
  IconHawkArrowCircleBrokenUpRight,
  IconHawkArrowCircleBrokenUp,
  IconHawkArrowCircleDownLeft,
  IconHawkArrowCircleDownRight,
  IconHawkArrowCircleDown,
  IconHawkArrowCircleLeft,
  IconHawkArrowCircleRight,
  IconHawkArrowCircleUpLeft,
  IconHawkArrowCircleUpRight,
  IconHawkArrowCircleUp,
  IconHawkArrowDownLeft,
  IconHawkArrowDownRight,
  IconHawkArrowDown,
  IconHawkArrowLeft,
  IconHawkArrowNarrowDownLeft,
  IconHawkArrowNarrowDownRight,
  IconHawkArrowNarrowDown,
  IconHawkArrowNarrowLeft,
  IconHawkArrowNarrowRight,
  IconHawkArrowNarrowUpLeft,
  IconHawkArrowNarrowUpRight,
  IconHawkArrowNarrowUp,
  IconHawkArrowRight,
  IconHawkArrowSquareDownLeft,
  IconHawkArrowSquareDownRight,
  IconHawkArrowSquareDown,
  IconHawkArrowSquareLeft,
  IconHawkArrowSquareRight,
  IconHawkArrowSquareUpLeft,
  IconHawkArrowSquareUpRight,
  IconHawkArrowSquareUp,
  IconHawkArrowUpLeft,
  IconHawkArrowUpRight,
  IconHawkArrowUp,
  IconHawkArrowsDown,
  IconHawkArrowsLeft,
  IconHawkArrowsRight,
  IconHawkArrowsTriangle,
  IconHawkArrowsUp,
  IconHawkAsteriskOne,
  IconHawkAsteriskTwo,
  IconHawkAsterisk,
  IconHawkAtSign,
  IconHawkAtomOne,
  IconHawkAtomTwo,
  IconHawkAttachmentOne,
  IconHawkAttachmentTwo,
  IconHawkAutonumber,
  IconHawkAwardFive,
  IconHawkAwardFour,
  IconHawkAwardOne,
  IconHawkAwardThree,
  IconHawkAwardTwo,
  IconHawkBackpack,
  IconHawkBankNoteOne,
  IconHawkBankNoteThree,
  IconHawkBankNoteTwo,
  IconHawkBank,
  IconHawkBarChartCircleOne,
  IconHawkBarChartCircleThree,
  IconHawkBarChartCircleTwo,
  IconHawkBarChartEight,
  IconHawkBarChartEleven,
  IconHawkBarChartFive,
  IconHawkBarChartFour,
  IconHawkBarChartNine,
  IconHawkBarChartOne,
  IconHawkBarChartSeven,
  IconHawkBarChartSix,
  IconHawkBarChartSquareDown,
  IconHawkBarChartSquareMinus,
  IconHawkBarChartSquareOne,
  IconHawkBarChartSquarePlus,
  IconHawkBarChartSquareThree,
  IconHawkBarChartSquareTwo,
  IconHawkBarChartSquareUp,
  IconHawkBarChartTen,
  IconHawkBarChartThree,
  IconHawkBarChartTwelve,
  IconHawkBarChartTwo,
  IconHawkBarLineChart,
  IconHawkBasicSection,
  IconHawkBatteryChargingOne,
  IconHawkBatteryChargingTwo,
  IconHawkBatteryEmpty,
  IconHawkBatteryFull,
  IconHawkBatteryLow,
  IconHawkBatteryMid,
  IconHawkBeakerOne,
  IconHawkBeakerTwo,
  IconHawkBellFour,
  IconHawkBellMinus,
  IconHawkBellOffOne,
  IconHawkBellOffThree,
  IconHawkBellOffTwo,
  IconHawkBellOne,
  IconHawkBellPlus,
  IconHawkBellRingingFour,
  IconHawkBellRingingOne,
  IconHawkBellRingingThree,
  IconHawkBellRingingTwo,
  IconHawkBellThree,
  IconHawkBellTwo,
  IconHawkBezierCurveOne,
  IconHawkBezierCurveThree,
  IconHawkBezierCurveTwo,
  IconHawkBluetoothConnect,
  IconHawkBluetoothOff,
  IconHawkBluetoothOn,
  IconHawkBluetoothSignal,
  IconHawkBoldOne,
  IconHawkBoldSquare,
  IconHawkBoldTwo,
  IconHawkBookClosed,
  IconHawkBookOpenOne,
  IconHawkBookOpenTwo,
  IconHawkBookmarkAdd,
  IconHawkBookmarkCheck,
  IconHawkBookmarkMinus,
  IconHawkBookmarkX,
  IconHawkBookmark,
  IconHawkBox,
  IconHawkBracketsCheck,
  IconHawkBracketsEllipses,
  IconHawkBracketsMinus,
  IconHawkBracketsPlus,
  IconHawkBracketsSlash,
  IconHawkBracketsX,
  IconHawkBrackets,
  IconHawkBriefcaseOne,
  IconHawkBriefcaseTwo,
  IconHawkBrowser,
  IconHawkBrushHeavy,
  IconHawkBrushLight,
  IconHawkBrushMedium,
  IconHawkBrushOne,
  IconHawkBrushThree,
  IconHawkBrushTwo,
  IconHawkBuildingEight,
  IconHawkBuildingFive,
  IconHawkBuildingFour,
  IconHawkBuildingOne,
  IconHawkBuildingSeven,
  IconHawkBuildingSix,
  IconHawkBuildingThree,
  IconHawkBuildingTwo,
  IconHawkBus,
  IconHawkCalculator,
  IconHawkCalendarCheckOne,
  IconHawkCalendarCheckTwo,
  IconHawkCalendarDate,
  IconHawkCalendarHeartOne,
  IconHawkCalendarHeartTwo,
  IconHawkCalendarMinusOne,
  IconHawkCalendarMinusTwo,
  IconHawkCalendarPlusOne,
  IconHawkCalendarPlusTwo,
  IconHawkCalendarToday,
  IconHawkCalendar,
  IconHawkCalibrate,
  IconHawkCameraLens,
  IconHawkCameraOff,
  IconHawkCameraOne,
  IconHawkCameraPlus,
  IconHawkCameraThree,
  IconHawkCameraTwo,
  IconHawkCarOne,
  IconHawkCarTwo,
  IconHawkCertificateOne,
  IconHawkCertificateTwo,
  IconHawkChartBreakoutCircle,
  IconHawkChartBreakoutSquare,
  IconHawkCheckCircleBroken,
  IconHawkCheckCircleFilled,
  IconHawkCheckCircleGreen,
  IconHawkCheckCircle,
  IconHawkCheckDoneOne,
  IconHawkCheckDoneTwo,
  IconHawkCheckFive,
  IconHawkCheckFour,
  IconHawkCheckHeart,
  IconHawkCheckSquareBroken,
  IconHawkCheckSquare,
  IconHawkCheckThree,
  IconHawkCheckTwo,
  IconHawkCheckVerifiedOne,
  IconHawkCheckVerifiedThree,
  IconHawkCheckVerifiedTwo,
  IconHawkCheck,
  IconHawkCheckboxBaseBlue,
  IconHawkCheckboxBase,
  IconHawkChecklistSection,
  IconHawkChevronDownDouble,
  IconHawkChevronDownTerra,
  IconHawkChevronDown,
  IconHawkChevronLeftDouble,
  IconHawkChevronLeft,
  IconHawkChevronRightDouble,
  IconHawkChevronRight,
  IconHawkChevronSelectorHorizontal,
  IconHawkChevronSelectorVertical,
  IconHawkChevronUpDouble,
  IconHawkChevronUpTwo,
  IconHawkChevronUp,
  IconHawkChromeCast,
  IconHawkCircleCut,
  IconHawkCircle,
  IconHawkClapperboard,
  IconHawkClipboardAttachment,
  IconHawkClipboardCheck,
  IconHawkClipboardDownload,
  IconHawkClipboardMinus,
  IconHawkClipboardPlus,
  IconHawkClipboardX,
  IconHawkClipboard,
  IconHawkClockCheck,
  IconHawkClockFastForward,
  IconHawkClockHistory,
  IconHawkClockPlus,
  IconHawkClockRefresh,
  IconHawkClockRewind,
  IconHawkClockSnooze,
  IconHawkClockStopwatch,
  IconHawkClock,
  IconHawkCloudBlankOne,
  IconHawkCloudBlankTwo,
  IconHawkCloudLightning,
  IconHawkCloudMoon,
  IconHawkCloudOff,
  IconHawkCloudOne,
  IconHawkCloudRainingFive,
  IconHawkCloudRainingFour,
  IconHawkCloudRainingOne,
  IconHawkCloudRainingSix,
  IconHawkCloudRainingThree,
  IconHawkCloudRainingTwo,
  IconHawkCloudSnowingOne,
  IconHawkCloudSnowingTwo,
  IconHawkCloudSunOne,
  IconHawkCloudSunThree,
  IconHawkCloudSunTwo,
  IconHawkCloudThree,
  IconHawkCloudTwo,
  IconHawkCodeBrowser,
  IconHawkCodeCircleOne,
  IconHawkCodeCircleThree,
  IconHawkCodeCircleTwo,
  IconHawkCodeOne,
  IconHawkCodeSnippetOne,
  IconHawkCodeSnippetTwo,
  IconHawkCodeSquareOne,
  IconHawkCodeSquareTwo,
  IconHawkCodeTwo,
  IconHawkCodepen,
  IconHawkCoinsFour,
  IconHawkCoinsHand,
  IconHawkCoinsOne,
  IconHawkCoinsStackedFour,
  IconHawkCoinsStackedOne,
  IconHawkCoinsStackedThree,
  IconHawkCoinsStackedTwo,
  IconHawkCoinsSwapOne,
  IconHawkCoinsSwapTwo,
  IconHawkCoinsThree,
  IconHawkCoinsTwo,
  IconHawkColorsCopy,
  IconHawkColors,
  IconHawkColumnsOne,
  IconHawkColumnsThree,
  IconHawkColumnsTwo,
  IconHawkCommand,
  IconHawkCompare,
  IconHawkCompassOne,
  IconHawkCompassThree,
  IconHawkCompassTwo,
  IconHawkCompass,
  IconHawkContainer,
  IconHawkContrastOne,
  IconHawkContrastThree,
  IconHawkContrastTwo,
  IconHawkCopyFive,
  IconHawkCopyFour,
  IconHawkCopyOne,
  IconHawkCopySeven,
  IconHawkCopySix,
  IconHawkCopyThree,
  IconHawkCopyTwo,
  IconHawkCornerDownLeft,
  IconHawkCornerDownRight,
  IconHawkCornerLeftDown,
  IconHawkCornerLeftUp,
  IconHawkCornerRightDown,
  IconHawkCornerRightUp,
  IconHawkCornerUpLeft,
  IconHawkCornerUpRight,
  IconHawkCpuChipOne,
  IconHawkCpuChipTwo,
  IconHawkCreateForm,
  IconHawkCreateTask,
  IconHawkCreditCardCheck,
  IconHawkCreditCardDown,
  IconHawkCreditCardDownload,
  IconHawkCreditCardEdit,
  IconHawkCreditCardLock,
  IconHawkCreditCardMinus,
  IconHawkCreditCardOne,
  IconHawkCreditCardPlus,
  IconHawkCreditCardRefresh,
  IconHawkCreditCardSearch,
  IconHawkCreditCardShield,
  IconHawkCreditCardTwo,
  IconHawkCreditCardUp,
  IconHawkCreditCardUpload,
  IconHawkCreditCardX,
  IconHawkCriticalPriorityFlag,
  IconHawkCropOne,
  IconHawkCropTwo,
  IconHawkCross,
  IconHawkCryptocurrencyFour,
  IconHawkCryptocurrencyOne,
  IconHawkCryptocurrencyThree,
  IconHawkCryptocurrencyTwo,
  IconHawkCubeFour,
  IconHawkCubeOne,
  IconHawkCubeOutline,
  IconHawkCubeThree,
  IconHawkCubeTwo,
  IconHawkCurrencyBitcoinCircle,
  IconHawkCurrencyBitcoin,
  IconHawkCurrencyDollarCircle,
  IconHawkCurrencyDollar,
  IconHawkCurrencyEthereumCircle,
  IconHawkCurrencyEthereum,
  IconHawkCurrencyEuroCircle,
  IconHawkCurrencyEuro,
  IconHawkCurrencyPoundCircle,
  IconHawkCurrencyPound,
  IconHawkCurrencyRubleCircle,
  IconHawkCurrencyRuble,
  IconHawkCurrencyRupeeCircle,
  IconHawkCurrencyRupee,
  IconHawkCurrencyYenCircle,
  IconHawkCurrencyYen,
  IconHawkCursorBox,
  IconHawkCursorClickOne,
  IconHawkCursorClickTwo,
  IconHawkCursorFour,
  IconHawkCursorOne,
  IconHawkCursorThree,
  IconHawkCursorTwo,
  IconHawkCurveLine,
  IconHawkDashboardPreviewInfo,
  IconHawkDashboardPreviewWarning,
  IconHawkData,
  IconHawkDatabaseOne,
  IconHawkDatabaseThree,
  IconHawkDatabaseTwo,
  IconHawkDataflowFour,
  IconHawkDataflowOne,
  IconHawkDataflowThree,
  IconHawkDataflowTwo,
  IconHawkDeletePopup,
  IconHawkDelete,
  IconHawkDelta,
  IconHawkDependencyBlocking,
  IconHawkDependencyLinked,
  IconHawkDependencyWaitingOn,
  IconHawkDiamondOne,
  IconHawkDiamondTwo,
  IconHawkDiceFive,
  IconHawkDiceFour,
  IconHawkDiceOne,
  IconHawkDiceSix,
  IconHawkDiceThree,
  IconHawkDiceTwo,
  IconHawkDiscOne,
  IconHawkDiscTwo,
  IconHawkDistributeSpacingHorizontal,
  IconHawkDistributeSpacingVertical,
  IconHawkDivideOne,
  IconHawkDivideThree,
  IconHawkDivideTwo,
  IconHawkDivider,
  IconHawkDotpointsOne,
  IconHawkDotpointsTwo,
  IconHawkDotsGrid,
  IconHawkDotsHorizontal,
  IconHawkDotsVertical,
  IconHawkDownloadCloudOne,
  IconHawkDownloadCloudTwo,
  IconHawkDownloadFour,
  IconHawkDownloadOne,
  IconHawkDownloadThree,
  IconHawkDownloadTwo,
  IconHawkDragIcon,
  IconHawkDragTableIcon,
  IconHawkDrop,
  IconHawkDropletsOne,
  IconHawkDropletsThree,
  IconHawkDropletsTwo,
  IconHawkDropper,
  IconHawkEditFive,
  IconHawkEditFour,
  IconHawkEditOne,
  IconHawkEditThree,
  IconHawkEditTwo,
  IconHawkEdit,
  IconHawkElbowLeft,
  IconHawkElbowRight,
  IconHawkElevation,
  IconHawkEmail,
  IconHawkEqualNot,
  IconHawkEqual,
  IconHawkEraser,
  IconHawkExpandFive,
  IconHawkExpandFour,
  IconHawkExpandOne,
  IconHawkExpandSix,
  IconHawkExpandThree,
  IconHawkExpandTwo,
  IconHawkEyeOffTwo,
  IconHawkEyeOff,
  IconHawkEyeTwo,
  IconHawkEye,
  IconHawkFaceContent,
  IconHawkFaceFrown,
  IconHawkFaceHappy,
  IconHawkFaceIdSquare,
  IconHawkFaceId,
  IconHawkFaceNeutral,
  IconHawkFaceSad,
  IconHawkFaceSmile,
  IconHawkFaceWink,
  IconHawkFastBackward,
  IconHawkFastForward,
  IconHawkFeather,
  IconHawkFigma,
  IconHawkFileAttachmentFive,
  IconHawkFileAttachmentFour,
  IconHawkFileAttachmentOne,
  IconHawkFileAttachmentThree,
  IconHawkFileAttachmentTwo,
  IconHawkFileCheckOne,
  IconHawkFileCheckThree,
  IconHawkFileCheckTwo,
  IconHawkFileCodeOne,
  IconHawkFileCodeTwo,
  IconHawkFileDownloadOne,
  IconHawkFileDownloadThree,
  IconHawkFileDownloadTwo,
  IconHawkFileDownload,
  IconHawkFileExport,
  IconHawkFileFive,
  IconHawkFileFour,
  IconHawkFileHeartOne,
  IconHawkFileHeartThree,
  IconHawkFileHeartTwo,
  IconHawkFileIcon,
  IconHawkFileLockOne,
  IconHawkFileLockThree,
  IconHawkFileLockTwo,
  IconHawkFileMinusOne,
  IconHawkFileMinusThree,
  IconHawkFileMinusTwo,
  IconHawkFileOne,
  IconHawkFilePlusOne,
  IconHawkFilePlusThree,
  IconHawkFilePlusTwo,
  IconHawkFileQuestionOne,
  IconHawkFileQuestionThree,
  IconHawkFileQuestionTwo,
  IconHawkFileSearchOne,
  IconHawkFileSearchThree,
  IconHawkFileSearchTwo,
  IconHawkFileSeven,
  IconHawkFileShieldOne,
  IconHawkFileShieldThree,
  IconHawkFileShieldTwo,
  IconHawkFileSix,
  IconHawkFileThree,
  IconHawkFileTwo,
  IconHawkFileUpload,
  IconHawkFileXOne,
  IconHawkFileXThree,
  IconHawkFileXTwo,
  IconHawkFilledCircle,
  IconHawkFilmOne,
  IconHawkFilmThree,
  IconHawkFilmTwo,
  IconHawkFilterFunnelOne,
  IconHawkFilterFunnelRed,
  IconHawkFilterFunnelTwo,
  IconHawkFilterIcon,
  IconHawkFilterLines,
  IconHawkFilter,
  IconHawkFingerprintFour,
  IconHawkFingerprintOne,
  IconHawkFingerprintThree,
  IconHawkFingerprintTwo,
  IconHawkFlagFive,
  IconHawkFlagFour,
  IconHawkFlagOne,
  IconHawkFlagSix,
  IconHawkFlagThree,
  IconHawkFlagTwo,
  IconHawkFlashOff,
  IconHawkFlash,
  IconHawkFlexAlignBottom,
  IconHawkFlexAlignLeft,
  IconHawkFlexAlignRight,
  IconHawkFlexAlignTop,
  IconHawkFlipBackward,
  IconHawkFlipForward,
  IconHawkFolderAdd,
  IconHawkFolderCheck,
  IconHawkFolderClosed,
  IconHawkFolderCode,
  IconHawkFolderDownload,
  IconHawkFolderLock,
  IconHawkFolderMinus,
  IconHawkFolderPlus,
  IconHawkFolderQuestion,
  IconHawkFolderSearch,
  IconHawkFolderShield,
  IconHawkFolderX,
  IconHawkFolder,
  IconHawkFormReminderIcon,
  IconHawkFormTemplateIcon,
  IconHawkFormWorkflowIcon,
  IconHawkFormula,
  IconHawkFramer,
  IconHawkFullScreen,
  IconHawkGamingPadOne,
  IconHawkGamingPadTwo,
  IconHawkGanttGridTimeline,
  IconHawkGanttGrid,
  IconHawkGanttTimeline,
  IconHawkGiftOne,
  IconHawkGiftTwo,
  IconHawkGitBranchOne,
  IconHawkGitBranchTwo,
  IconHawkGitCommit,
  IconHawkGitMerge,
  IconHawkGitPullRequest,
  IconHawkGlassesOne,
  IconHawkGlassesTwo,
  IconHawkGlobeFive,
  IconHawkGlobeFour,
  IconHawkGlobeOneCopy,
  IconHawkGlobeOne,
  IconHawkGlobeSix,
  IconHawkGlobeThree,
  IconHawkGlobeTwoCopy,
  IconHawkGlobeTwo,
  IconHawkGlobe,
  IconHawkGoogleChrome,
  IconHawkGraduationHatOne,
  IconHawkGraduationHatTwo,
  IconHawkGridDotsBlank,
  IconHawkGridDotsBottom,
  IconHawkGridDotsHorizontalCenter,
  IconHawkGridDotsLeft,
  IconHawkGridDotsOuter,
  IconHawkGridDotsRight,
  IconHawkGridDotsTop,
  IconHawkGridDotsVerticalCenter,
  IconHawkGridOne,
  IconHawkGridThree,
  IconHawkGridTimeline,
  IconHawkGridTwo,
  IconHawkHandCopy,
  IconHawkHand,
  IconHawkHardDrive,
  IconHawkHashOne,
  IconHawkHashTwo,
  IconHawkHeadingOne,
  IconHawkHeadingSquare,
  IconHawkHeadingTwo,
  IconHawkHeadphonesOne,
  IconHawkHeadphonesTwo,
  IconHawkHeartCircle,
  IconHawkHeartEmpty,
  IconHawkHeartFilled,
  IconHawkHeartHand,
  IconHawkHeartHexagon,
  IconHawkHeartOctagon,
  IconHawkHeartRounded,
  IconHawkHeartSquare,
  IconHawkHeart,
  IconHawkHearts,
  IconHawkHelpCircle,
  IconHawkHelpOctagonOne,
  IconHawkHelpOctagon,
  IconHawkHelpSquare,
  IconHawkHexagonOne,
  IconHawkHexagonTwo,
  IconHawkHighPriorityFlag,
  IconHawkHomeFive,
  IconHawkHomeFour,
  IconHawkHomeLine,
  IconHawkHomeOne,
  IconHawkHomeSmile,
  IconHawkHomeThree,
  IconHawkHomeTwo,
  IconHawkHorizontalBarChartOne,
  IconHawkHorizontalBarChartThree,
  IconHawkHorizontalBarChartTwo,
  IconHawkHourglassOne,
  IconHawkHourglassThree,
  IconHawkHourglassTwo,
  IconHawkHurricaneOne,
  IconHawkHurricaneThree,
  IconHawkHurricaneTwo,
  IconHawkImageCheck,
  IconHawkImageDown,
  IconHawkImageFive,
  IconHawkImageFour,
  IconHawkImageIndentLeft,
  IconHawkImageIndentRight,
  IconHawkImageLeft,
  IconHawkImageOne,
  IconHawkImagePlus,
  IconHawkImageRight,
  IconHawkImageThree,
  IconHawkImageTwo,
  IconHawkImageUp,
  IconHawkImageUserCheck,
  IconHawkImageUserDown,
  IconHawkImageUserLeft,
  IconHawkImageUserPlus,
  IconHawkImageUserRight,
  IconHawkImageUserUp,
  IconHawkImageUserX,
  IconHawkImageUser,
  IconHawkImageX,
  IconHawkImport,
  IconHawkInboxOne,
  IconHawkInboxTwo,
  IconHawkInfinity,
  IconHawkInfoCircleGray,
  IconHawkInfoCircleRed,
  IconHawkInfoCircleTwo,
  IconHawkInfoCircle,
  IconHawkInfoHexagon,
  IconHawkInfoOctagon,
  IconHawkInfoSquare,
  IconHawkIntersectCircle,
  IconHawkIntersectSquare,
  IconHawkItalicOne,
  IconHawkItalicSquare,
  IconHawkItalicTwo,
  IconHawkKeyMac,
  IconHawkKeyOne,
  IconHawkKeyTwo,
  IconHawkKeyboardOne,
  IconHawkKeyboardTwo,
  IconHawkLaptopOne,
  IconHawkLaptopTwo,
  IconHawkLayerSingle,
  IconHawkLayersThreeOne,
  IconHawkLayersThreeTwo,
  IconHawkLayersTwoOne,
  IconHawkLayersTwoTwo,
  IconHawkLayoutAltFour,
  IconHawkLayoutAltOne,
  IconHawkLayoutAltThree,
  IconHawkLayoutAltTwo,
  IconHawkLayoutBottom,
  IconHawkLayoutGridOne,
  IconHawkLayoutGridTwo,
  IconHawkLayoutLeft,
  IconHawkLayoutRight,
  IconHawkLayoutTop,
  IconHawkLeftIndentOne,
  IconHawkLeftIndentTwo,
  IconHawkLetterSpacingOne,
  IconHawkLetterSpacingTwo,
  IconHawkLifeBuoyOne,
  IconHawkLifeBuoyTwo,
  IconHawkLightbulbFive,
  IconHawkLightbulbFour,
  IconHawkLightbulbOne,
  IconHawkLightbulbThree,
  IconHawkLightbulbTwo,
  IconHawkLightningOne,
  IconHawkLightningTwo,
  IconHawkLineChartDownFive,
  IconHawkLineChartDownFour,
  IconHawkLineChartDownOne,
  IconHawkLineChartDownThree,
  IconHawkLineChartDownTwo,
  IconHawkLineChartUpFive,
  IconHawkLineChartUpFour,
  IconHawkLineChartUpOne,
  IconHawkLineChartUpThree,
  IconHawkLineChartUpTwo,
  IconHawkLineHeight,
  IconHawkLine,
  IconHawkLinestring,
  IconHawkLinkBrokenOne,
  IconHawkLinkBrokenTwo,
  IconHawkLinkExternalOne,
  IconHawkLinkExternalTwo,
  IconHawkLinkFive,
  IconHawkLinkFour,
  IconHawkLinkHierarchy,
  IconHawkLinkOne,
  IconHawkLinkThree,
  IconHawkLinkTwo,
  IconHawkList,
  IconHawkLoadingCircle,
  IconHawkLoadingOne,
  IconHawkLoadingThree,
  IconHawkLoadingTwo,
  IconHawkLocation,
  IconHawkLockFour,
  IconHawkLockKeyholeCircle,
  IconHawkLockKeyholeSquare,
  IconHawkLockOne,
  IconHawkLockThree,
  IconHawkLockTwo,
  IconHawkLockUnlockRipple,
  IconHawkLockUnlockedFour,
  IconHawkLockUnlockedOne,
  IconHawkLockUnlockedThree,
  IconHawkLockUnlockedTwo,
  IconHawkLogInFour,
  IconHawkLogInOne,
  IconHawkLogInThree,
  IconHawkLogInTwo,
  IconHawkLogOutFour,
  IconHawkLogOutOne,
  IconHawkLogOutThree,
  IconHawkLogOutTwo,
  IconHawkLongArrowRight,
  IconHawkLowPriorityFlag,
  IconHawkLuggageOne,
  IconHawkLuggageThree,
  IconHawkLuggageTwo,
  IconHawkMagicWandOne,
  IconHawkMagicWandTwo,
  IconHawkMailFive,
  IconHawkMailFour,
  IconHawkMailOne,
  IconHawkMailThree,
  IconHawkMailTwo,
  IconHawkMapOne,
  IconHawkMapTwo,
  IconHawkMark,
  IconHawkMarkerPinFive,
  IconHawkMarkerPinFlaticon,
  IconHawkMarkerPinFour,
  IconHawkMarkerPinOne,
  IconHawkMarkerPinSix,
  IconHawkMarkerPinThree,
  IconHawkMarkerPinTwo,
  IconHawkMaximizeOne,
  IconHawkMaximizeTwo,
  IconHawkMedicalCircle,
  IconHawkMedicalCross,
  IconHawkMedicalSquare,
  IconHawkMediumPriorityFlag,
  IconHawkMenuFive,
  IconHawkMenuFour,
  IconHawkMenuOne,
  IconHawkMenuThree,
  IconHawkMenuTwo,
  IconHawkMessageAlertCircle,
  IconHawkMessageAlertSquare,
  IconHawkMessageChatCircle,
  IconHawkMessageChatSquare,
  IconHawkMessageCheckCircle,
  IconHawkMessageCheckSquare,
  IconHawkMessageCircleOne,
  IconHawkMessageCircleTwo,
  IconHawkMessageDotsCircle,
  IconHawkMessageDotsSquare,
  IconHawkMessageHeartCircle,
  IconHawkMessageHeartSquare,
  IconHawkMessageNotificationCircle,
  IconHawkMessageNotificationSquare,
  IconHawkMessagePlusCircle,
  IconHawkMessagePlusSquare,
  IconHawkMessageQuestionCircle,
  IconHawkMessageQuestionSquare,
  IconHawkMessageSmileCircle,
  IconHawkMessageSmileSquare,
  IconHawkMessageSquareOne,
  IconHawkMessageSquareTwo,
  IconHawkMessageTextCircleOne,
  IconHawkMessageTextCircleTwo,
  IconHawkMessageTextSquareOne,
  IconHawkMessageTextSquareTwo,
  IconHawkMessageXCircle,
  IconHawkMessageXSquare,
  IconHawkMeterLow,
  IconHawkMeterMedium,
  IconHawkMicrophoneOffOne,
  IconHawkMicrophoneOffTwo,
  IconHawkMicrophoneOne,
  IconHawkMicrophoneTwo,
  IconHawkMicroscope,
  IconHawkMinimizeOne,
  IconHawkMinimizeTwo,
  IconHawkMinusCircle,
  IconHawkMinusSquare,
  IconHawkMinus,
  IconHawkModemOne,
  IconHawkModemTwo,
  IconHawkMonitorFive,
  IconHawkMonitorFour,
  IconHawkMonitorOne,
  IconHawkMonitorThree,
  IconHawkMonitorTwo,
  IconHawkMoonEclipse,
  IconHawkMoonOne,
  IconHawkMoonStar,
  IconHawkMoonTwo,
  IconHawkMouse,
  IconHawkMove,
  IconHawkMsExcel,
  IconHawkMusicNoteOne,
  IconHawkMusicNotePlus,
  IconHawkMusicNoteTwo,
  IconHawkNavigationPointerOffOne,
  IconHawkNavigationPointerOffTwo,
  IconHawkNavigationPointerOne,
  IconHawkNavigationPointerTwo,
  IconHawkNoPriorityFlag,
  IconHawkNotificationBox,
  IconHawkNotificationMessage,
  IconHawkNotificationText,
  IconHawkNumber,
  IconHawkNumberedList,
  IconHawkOctagon,
  IconHawkOpacity,
  IconHawkOverdueIcon,
  IconHawkPackageCheck,
  IconHawkPackageMinus,
  IconHawkPackagePlus,
  IconHawkPackageSearch,
  IconHawkPackageX,
  IconHawkPackage,
  IconHawkPaintPour,
  IconHawkPaint,
  IconHawkPalette,
  IconHawkPaperclip,
  IconHawkParagraphSpacing,
  IconHawkParagraphWrap,
  IconHawkPasscodeLock,
  IconHawkPasscode,
  IconHawkPassport,
  IconHawkPauseCircle,
  IconHawkPauseSquare,
  IconHawkPause,
  IconHawkPenToolMinus,
  IconHawkPenToolOne,
  IconHawkPenToolPlus,
  IconHawkPenToolTwo,
  IconHawkPencilLine,
  IconHawkPencilOne,
  IconHawkPencilTwo,
  IconHawkPencil,
  IconHawkPentagon,
  IconHawkPercentOne,
  IconHawkPercentThree,
  IconHawkPercentTwo,
  IconHawkPerspectiveOne,
  IconHawkPerspectiveTwo,
  IconHawkPhoneCallOne,
  IconHawkPhoneCallTwo,
  IconHawkPhoneHangUp,
  IconHawkPhoneIncomingOne,
  IconHawkPhoneIncomingTwo,
  IconHawkPhoneOne,
  IconHawkPhoneOutgoingOne,
  IconHawkPhoneOutgoingTwo,
  IconHawkPhonePause,
  IconHawkPhonePlus,
  IconHawkPhoneTwo,
  IconHawkPhoneX,
  IconHawkPhone,
  IconHawkPieChartFour,
  IconHawkPieChartOne,
  IconHawkPieChartThree,
  IconHawkPieChartTwo,
  IconHawkPiggyBankOne,
  IconHawkPiggyBankTwo,
  IconHawkPilcrowOne,
  IconHawkPilcrowSquare,
  IconHawkPilcrowTwo,
  IconHawkPinOne,
  IconHawkPinTwoInactive,
  IconHawkPinTwo,
  IconHawkPin,
  IconHawkPinnedOne,
  IconHawkPinned,
  IconHawkPlaceholder,
  IconHawkPlane,
  IconHawkPlayCircle,
  IconHawkPlaySquare,
  IconHawkPlay,
  IconHawkPlusCircle,
  IconHawkPlusGray,
  IconHawkPlusSquare,
  IconHawkPlusWhite,
  IconHawkPlus,
  IconHawkPmProgressPointer,
  IconHawkPodcast,
  IconHawkPointThree,
  IconHawkPoint,
  IconHawkPolygon,
  IconHawkPolyline,
  IconHawkPowerOne,
  IconHawkPowerThree,
  IconHawkPowerTwo,
  IconHawkPresentationChartOne,
  IconHawkPresentationChartThree,
  IconHawkPresentationChartTwo,
  IconHawkPrint,
  IconHawkPrinter,
  IconHawkPuzzlePieceOne,
  IconHawkPuzzlePieceTwo,
  IconHawkQrCodeOne,
  IconHawkQrCodeTwo,
  IconHawkReadMode,
  IconHawkReceiptCheck,
  IconHawkReceipt,
  IconHawkRecordingOne,
  IconHawkRecordingThree,
  IconHawkRecordingTwo,
  IconHawkRectangle,
  IconHawkReflectOne,
  IconHawkReflectTwo,
  IconHawkRefreshCcwFive,
  IconHawkRefreshCcwFour,
  IconHawkRefreshCcwOne,
  IconHawkRefreshCcwThree,
  IconHawkRefreshCcwTwo,
  IconHawkRefreshCwFive,
  IconHawkRefreshCwFour,
  IconHawkRefreshCwOne,
  IconHawkRefreshCwThree,
  IconHawkRefreshCwTwo,
  IconHawkReminder,
  IconHawkRepeatFour,
  IconHawkRepeatOne,
  IconHawkRepeatThree,
  IconHawkRepeatTwo,
  IconHawkReverseLeft,
  IconHawkReverseRight,
  IconHawkRightIndentOne,
  IconHawkRightIndentTwo,
  IconHawkRocketOne,
  IconHawkRocketTwo,
  IconHawkRollback,
  IconHawkRollerBrush,
  IconHawkRoute,
  IconHawkRowsOne,
  IconHawkRowsThree,
  IconHawkRowsTwo,
  IconHawkRssOne,
  IconHawkRssTwo,
  IconHawkRuler,
  IconHawkSafe,
  IconHawkSaleFour,
  IconHawkSaleOne,
  IconHawkSaleThree,
  IconHawkSaleTwo,
  IconHawkSaveOne,
  IconHawkSaveThree,
  IconHawkSaveTwo,
  IconHawkScaleOne,
  IconHawkScaleThree,
  IconHawkScaleTwo,
  IconHawkScalesOne,
  IconHawkScalesTwo,
  IconHawkScan,
  IconHawkSchedule,
  IconHawkScissorsCutOne,
  IconHawkScissorsCutTwo,
  IconHawkScissorsOne,
  IconHawkScissorsTwo,
  IconHawkSearchLg,
  IconHawkSearchMd,
  IconHawkSearchRefraction,
  IconHawkSearchSm,
  IconHawkSearch,
  IconHawkSendOne,
  IconHawkSendThree,
  IconHawkSendTwo,
  IconHawkSend,
  IconHawkSensehawkUser,
  IconHawkServerFive,
  IconHawkServerFour,
  IconHawkServerOne,
  IconHawkServerSix,
  IconHawkServerThree,
  IconHawkServerTwo,
  IconHawkSettingsFour,
  IconHawkSettingsIcon,
  IconHawkSettingsOne,
  IconHawkSettingsThree,
  IconHawkSettingsTwo,
  IconHawkShareFive,
  IconHawkShareFour,
  IconHawkShareOne,
  IconHawkShareSeven,
  IconHawkShareSix,
  IconHawkShareThree,
  IconHawkShareTwo,
  IconHawkShieldDollar,
  IconHawkShieldOff,
  IconHawkShieldOne,
  IconHawkShieldPlus,
  IconHawkShieldThree,
  IconHawkShieldTick,
  IconHawkShieldTwo,
  IconHawkShieldZap,
  IconHawkShoppingBagOne,
  IconHawkShoppingBagThree,
  IconHawkShoppingBagTwo,
  IconHawkShoppingCartOne,
  IconHawkShoppingCartThree,
  IconHawkShoppingCartTwo,
  IconHawkShuffleOne,
  IconHawkShuffleTwo,
  IconHawkSignalOne,
  IconHawkSignalThree,
  IconHawkSignalTwo,
  IconHawkSignature,
  IconHawkSimcard,
  IconHawkSkew,
  IconHawkSkipBack,
  IconHawkSkipForward,
  IconHawkSlashCircleOne,
  IconHawkSlashCircleTwo,
  IconHawkSlashDivider,
  IconHawkSlashOctagon,
  IconHawkSlidersFour,
  IconHawkSlidersOne,
  IconHawkSlidersThree,
  IconHawkSlidersTwo,
  IconHawkSnowflakeOne,
  IconHawkSnowflakeTwo,
  IconHawkSpaceWidthOne,
  IconHawkSpacingHeightOne,
  IconHawkSpacingHeightTwo,
  IconHawkSpacingWidthOne,
  IconHawkSpacingWidthTwo,
  IconHawkSpeakerOne,
  IconHawkSpeakerThree,
  IconHawkSpeakerTwo,
  IconHawkSpeedometerFour,
  IconHawkSpeedometerOne,
  IconHawkSpeedometerThree,
  IconHawkSpeedometerTwo,
  IconHawkSquare,
  IconHawkStand,
  IconHawkStarFilled,
  IconHawkStarFive,
  IconHawkStarFour,
  IconHawkStarHover,
  IconHawkStarOne,
  IconHawkStarSeven,
  IconHawkStarSix,
  IconHawkStarThree,
  IconHawkStarTwo,
  IconHawkStarsOne,
  IconHawkStarsThree,
  IconHawkStarsTwo,
  IconHawkStickerCircle,
  IconHawkStickerSquare,
  IconHawkStopCircle,
  IconHawkStopSquare,
  IconHawkStop,
  IconHawkStrikethroughOne,
  IconHawkStrikethroughSquare,
  IconHawkStrikethroughTwo,
  IconHawkStrokeDash,
  IconHawkSubscript,
  IconHawkSunSettingOne,
  IconHawkSunSettingThree,
  IconHawkSunSettingTwo,
  IconHawkSun,
  IconHawkSunrise,
  IconHawkSunset,
  IconHawkSwitchHorizontalOne,
  IconHawkSwitchHorizontalTwo,
  IconHawkSwitchVerticalOne,
  IconHawkSwitchVerticalTwo,
  IconHawkTableSection,
  IconHawkTableEdit,
  IconHawkTableGant,
  IconHawkTableTwo,
  IconHawkTable,
  IconHawkTabletOne,
  IconHawkTabletTwo,
  IconHawkTagOne,
  IconHawkTagThree,
  IconHawkTagTwo,
  IconHawkTargetFive,
  IconHawkTargetFour,
  IconHawkTargetOne,
  IconHawkTargetThree,
  IconHawkTargetTwo,
  IconHawkTaskItem,
  IconHawkTelescope,
  IconHawkTemporary,
  IconHawkTerminalBrowser,
  IconHawkTerminalCircle,
  IconHawkTerminalSquare,
  IconHawkTerminal,
  IconHawkTextInput,
  IconHawkThermometerCold,
  IconHawkThermometerOne,
  IconHawkThermometerThree,
  IconHawkThermometerTwo,
  IconHawkThermometerWarm,
  IconHawkThumbsDown,
  IconHawkThumbsUpFilled,
  IconHawkThumbsUp,
  IconHawkTickMark,
  IconHawkTicketOne,
  IconHawkTicketTwo,
  IconHawkToggleOneLeft,
  IconHawkToggleOneRight,
  IconHawkToggleThreeLeft,
  IconHawkToggleThreeRight,
  IconHawkToggleTwoLeft,
  IconHawkToggleTwoRight,
  IconHawkToolOne,
  IconHawkToolTwo,
  IconHawkTrain,
  IconHawkTram,
  IconHawkTransform,
  IconHawkTranslateOne,
  IconHawkTranslateTwo,
  IconHawkTrashFour,
  IconHawkTrashOneRedRipple,
  IconHawkTrashOne,
  IconHawkTrashThree,
  IconHawkTrashTwo,
  IconHawkTrendDownOne,
  IconHawkTrendDownTwo,
  IconHawkTrendUpOne,
  IconHawkTrendUpTwo,
  IconHawkTriangle,
  IconHawkTristateChecked,
  IconHawkTristateIntermediate,
  IconHawkTristateUnchecked,
  IconHawkTrophyFilled,
  IconHawkTrophyOne,
  IconHawkTrophyTwo,
  IconHawkTruckOne,
  IconHawkTruckTwo,
  IconHawkTvOne,
  IconHawkTvThree,
  IconHawkTvTwo,
  IconHawkTypeOne,
  IconHawkTypeSquare,
  IconHawkTypeStrikethroughOne,
  IconHawkTypeStrikethroughTwo,
  IconHawkTypeTwo,
  IconHawkUmbrellaOne,
  IconHawkUmbrellaThree,
  IconHawkUmbrellaTwo,
  IconHawkUnderlineOne,
  IconHawkUnderlineSquare,
  IconHawkUnderlineTwo,
  IconHawkUnknownUser,
  IconHawkUploadCloudOne,
  IconHawkUploadCloudTwoGrayRipple,
  IconHawkUploadCloudTwoInlineGrayRipple,
  IconHawkUploadCloudTwo,
  IconHawkUploadFileIcon,
  IconHawkUploadFour,
  IconHawkUploadOne,
  IconHawkUploadThree,
  IconHawkUploadTwo,
  IconHawkUsbFlashDrive,
  IconHawkUserCheckOne,
  IconHawkUserCheckTwo,
  IconHawkUserCircle,
  IconHawkUserDownOne,
  IconHawkUserDownTwo,
  IconHawkUserEdit,
  IconHawkUserGroup,
  IconHawkUserLeftOne,
  IconHawkUserLeftTwo,
  IconHawkUserMinusOne,
  IconHawkUserMinusTwo,
  IconHawkUserOne,
  IconHawkUserPlusOne,
  IconHawkUserPlusTwo,
  IconHawkUserRightOne,
  IconHawkUserRightTwo,
  IconHawkUserSquare,
  IconHawkUserThree,
  IconHawkUserTwo,
  IconHawkUserUpOne,
  IconHawkUserUpTwo,
  IconHawkUserXOne,
  IconHawkUserXTwo,
  IconHawkUsersCheck,
  IconHawkUsersDown,
  IconHawkUsersEdit,
  IconHawkUsersLeft,
  IconHawkUsersMinus,
  IconHawkUsersOne,
  IconHawkUsersPlus,
  IconHawkUsersRight,
  IconHawkUsersShare,
  IconHawkUsersThree,
  IconHawkUsersTwo,
  IconHawkUsersUp,
  IconHawkUsersX,
  IconHawkVariable,
  IconHawkVideoRecorderOff,
  IconHawkVideoRecorder,
  IconHawkVirus,
  IconHawkVoicemail,
  IconHawkVolumeCalculation,
  IconHawkVolumeMax,
  IconHawkVolumeMin,
  IconHawkVolumeMinus,
  IconHawkVolumePlus,
  IconHawkVolumeX,
  IconHawkWalletFive,
  IconHawkWalletFour,
  IconHawkWalletOne,
  IconHawkWalletThree,
  IconHawkWalletTwo,
  IconHawkWarnIcon,
  IconHawkWarningSign,
  IconHawkWatchCircle,
  IconHawkWatchSquare,
  IconHawkWatcher,
  IconHawkWaves,
  IconHawkWebcamOne,
  IconHawkWebcamTwo,
  IconHawkWifiOff,
  IconHawkWifi,
  IconHawkWindOne,
  IconHawkWindThree,
  IconHawkWindTwo,
  IconHawkWindowIcon,
  IconHawkXCircleRed,
  IconHawkXCircle,
  IconHawkXClose,
  IconHawkXSquare,
  IconHawkXTwo,
  IconHawkX,
  IconHawkYesNo,
  IconHawkYoutube,
  IconHawkZapCircle,
  IconHawkZapFast,
  IconHawkZapOff,
  IconHawkZapSquare,
  IconHawkZap,
  IconHawkZoomIn,
  IconHawkZoomOut,
};
export default { ...icons };
