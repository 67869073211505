<script setup>
import { reactive } from 'vue';
import { IconHawkCheck, IconHawkLongArrowRight } from '~/common/components/molecules/hawk-icons/icons.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  gradient_form_data: {
    type: Object,
    default: null,
  },
  object_key: {
    type: String,
  },
  object_values: {
    type: Object,
  },
  on_save: {
    type: Function,
  },
});

const { getGradientColors } = useTerraHelperComposable();

const state = reactive({
  form: {},
});

const palette_colors = [
  { label: 'Blues', value: ['#FFFFFF', '#3244EA'], id: 1 },
  { label: 'Greys', value: ['#FFFFFF', '#111111'], id: 2 },
  { label: 'Greens', value: ['#FFFFFF', '#0AA907'], id: 3 },
  { label: 'Reds', value: ['#FFFFFF', '#C21515'], id: 4 },
  { label: 'Spectral', value: ['#DD1010', '#F2B822', '#F2E922', '#22F2CC', '#228EF2'], id: 5 },
];

function getGradientStyle(colors) {
  const color_stops = colors.map((color, index) => {
    const percent = (index / (colors.length - 1)) * 100;
    return `${color} ${percent}%`;
  });
  return `linear-gradient(90deg, ${color_stops.join(', ')})`;
}

async function onSave() {
  await props.on_save(state.form);
}

function getGradientColorsWithValueRange() {
  const gradient_colors = getGradientColors(state.form);
  const data = Object.keys(props.object_values).map(val => +val);
  const min = Math.min(...data);
  const max = Math.max(...data);
  const step = (max - min) / state.form.classes;

  for (let i = 0; i < gradient_colors.length; i++) {
    const start = (min + i * step).toFixed(2);
    const end = (+(min + (i + 1) * step) - 0.01).toFixed(2);
    const end_inclusive = (i === gradient_colors.length - 1) ? max.toFixed(2) : end;
    gradient_colors[i] = {
      ...gradient_colors[i],
      value_start: Number.parseFloat(start).toString(),
      value_end: Number.parseFloat(end_inclusive).toString(),
    };
  }

  return gradient_colors;
}

function init() {
  state.form = {
    property: props.object_key,
    color: palette_colors[0],
    classes: 4,
    ...(props.gradient_form_data || {}),
  };
}
init();
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="state.form"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
      sync size="sm" :display-errors="false" :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Color by property') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            class="mb-5"
            name="property"
            :label="$t('Property')"
            readonly
          />
          <SelectElement
            class="mb-5"
            name="color"
            :label="$t('Palette')"
            :placeholder="$t('Select palette')"
            :items="palette_colors"
            :can-clear="false"
            :native="false"
            :can-deselect="false"
            :object="true"
          >
            <template #single-label="{ value }">
              <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                <div class="w-5 h-5 rounded-sm mr-2" :style="{ backgroundImage: getGradientStyle(value.value) }" />
                <div class="text-[14px]">
                  {{ value.label }}
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <div class="flex justify-between w-full">
                <div class="flex items-center">
                  <div class="w-5 h-5 rounded-sm mr-2" :style="{ backgroundImage: getGradientStyle(option.value) }" />
                  <span class="text-[14px]">{{ option.label }}</span>
                </div>
                <IconHawkCheck
                  v-if="option.id === state.form?.color?.id"
                  class="text-primary-500"
                />
              </div>
            </template>
          </SelectElement>
          <TextElement
            name="classes"
            input-type="number"
            class="mb-5"
            :default="4"
            :rules="[
              'required',
              'integer',
              'min:1',
              'max:10',
            ]"
            autocomplete="off"
            :label="$t('Classes')"
          />
          <StaticElement name="ranges" :label="$t('Color range')">
            <div v-for="(color_range, i) in getGradientColorsWithValueRange(state.form)" :key="i" class="grid grid-cols-4 gap-x-2 mb-2">
              <div class="text-sm text-black col-span-2">
                {{ color_range.value_start }}-{{ color_range.value_end }}
              </div>
              <IconHawkLongArrowRight class="w-16" />
              <div class="w-16 flex justify-center">
                <div class="w-5 h-5 rounded-sm" :style="{ background: color_range.color }" />
              </div>
            </div>
          </StaticElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
