<script setup>
import { groupBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { IconHawkDownloadOne } from '~/common/components/molecules/hawk-icons/icons.js';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

const route = useRoute();
const $services = inject('$services');

const is_loading = ref(false);
const data = ref(null);

const columns = computed(() => {
  return [
    {
      header: 'Report Name',
      accessorKey: 'name',
      id: 'name',
      cell: info => info.getValue(),
      columns: [],
    },
    {
      header: 'Size',
      accessorKey: 'size',
      id: 'size',
      cell: info => info.getValue(),
      columns: [],
    },
    {
      header: 'Type',
      accessorKey: 'report_type',
      id: 'report_type',
      cell: info => info.getValue(),
      columns: [],
    },
    {
      header: '',
      accessorKey: 'uid',
      id: 'uid',
      cell: info => info.getValue(),
      columns: [],
    },
  ];
});

async function getData() {
  try {
    is_loading.value = true;
    const response = await $services.assets.get({
      url: `${import.meta.env.VITE_APP_CORE_API_HOST}/assets`,
      attribute: 'reports',
      id: route.params.asset_id,
      query: {
        datas: false,
        page_size: Number.MAX_SAFE_INTEGER,
        page: 1,
        shared: true,
      },
    });
    data.value = response.data.results;
    const res = Object.values(groupByData(response.data.results, 'group')).map((i) => {
      return {
        name: i[0]?.group?.name,
        subRows: Object.values(groupByData(i, 'project')).map((j) => {
          return {
            name: j[0]?.project?.name,
            subRows: j,
          };
        }),
      };
    });

    data.value = res;
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
    logger.log(error);
  }
}

function groupByData(data, prop) {
  return groupBy(data, item => item?.[prop]?.uid);
}

async function download(report) {
  const response = await $services.assets.getAll({
    url: `${import.meta.env.VITE_APP_CORE_API_HOST}/projects/${report.project.uid}/reports/${report.uid}/download`,
  });
  if (response.data.url)
    window.open(response.data.url, '_blank');
}

onMounted(async () => {
  getData();
});

watch(() => route.params.asset_id, () => {
  getData();
});
</script>

<template>
  <div>
    <HawkPageHeader title="Reports" />
    <div class="mx-4">
      <HawkLoader v-if="is_loading" />
      <HawkIllustrations v-else-if="!data?.length" />
      <TableWrapperVue v-else-if="data?.length">
        <HawkTable
          :key="columns.length"
          :data="data"
          :columns="columns"
          :is_loading="is_loading"
          is_gapless
        >
          <template #size="size">
            <div v-if="size.data.getValue()">
              {{ $prettysize(size.data.getValue()) }}
            </div>
          </template>
          <template #report_type="report_type">
            <div v-if="report_type.data.getValue()" class="uppercase">
              {{ report_type.data.getValue() || "NA" }}
            </div>
          </template>
          <template #uid="uid">
            <HawkButton v-if="uid.data.getValue()?.length" type="text" icon @click="download(uid.data.row.original)">
              <IconHawkDownloadOne />
            </HawkButton>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
