<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { IconHawkInfoCircle, IconHawkWarnIcon } from '~/common/components/molecules/hawk-icons/icons.js';
import FormDetailApprovalModal from './form-detail-approval-modal.vue';

const props = defineProps({
  form_approval: {
    type: Object,
  },
});
const show_waiting = props.form_approval?.show_waiting;
const form_detail_store = inject('form_detail_store');
const router = useRouter();
const route = useRoute();
// approval modal
const { open: openApprovalPopup, close: closeApprovalPopup, patchOptions: patchApprovalPopup } = useModal({
  component: FormDetailApprovalModal,
  attrs: {
    form_detail_store,
    onClose() {
      closeApprovalPopup();
    },
    async on_submit(req) {
      await form_detail_store?.approve_form(form_detail_store.form_detail.uid, req);
      closeApprovalPopup();
      router.push({ query: {} });
    },
    form_approval_status: props.form_approval,
  },
});

function viewApprovalModal() {
  patchApprovalPopup({
    attrs: {
      view_only: true,
    },
  });
  openApprovalPopup();
}
</script>

<template>
  <div>
    <!-- Waiting for approval -->
    <div>
      <div v-if="show_waiting" class="flex p-4 border border-[#FEC84B] rounded-lg bg-[#FFFCF5] items-center">
        <div class="mr-2">
          <IconHawkWarnIcon class="text-[#DC6803]" />
        </div>
        <div class="text-[#B54708] text-sm font-semibold">
          {{ $t('Waiting for approval') }}
        </div>
      </div>
    </div>
    <!-- User approved -->
    <div>
      <div v-if="props.form_approval?.action_complete" class="flex bg-[#FCFCFD] border border-[#D0D5DD] rounded-lg p-4 justify-between items-center">
        <div class="flex items-center">
          <span class="mr-2"> <IconHawkInfoCircle /></span>
          <span> {{ $t('You') }} {{ props.form_approval?.is_reviewer ? 'reviewed' : props.form_approval?.approved ? 'approved' : 'denied' }}. {{ $t('Waiting for other members to approve') }} </span>
        </div>
        <span class="cursor-pointer" @click="viewApprovalModal">{{ $t('View') }}</span>
      </div>
      <!-- Requesting approval -->
      <div v-if="!show_waiting && !props.form_approval?.action_complete" class="flex bg-[#FFFCF5] border border-[#FEC84B] rounded-lg p-4 justify-between items-center">
        <div class="flex items-center">
          <span class="mr-2"> <IconHawkWarnIcon class="text-[#DC6803]" /></span>
          <span class="text-[#B54708] text-sm font-semibold"> {{ $t('Requesting') }} {{ props.form_approval?.is_reviewer ? 'review' : 'approval' }} {{ $t('for approval block') }} </span>
        </div>
        <span class="text-[#B54708] cursor-pointer text-sm font-semibold" @click="openApprovalPopup">{{ $t('review') }}</span>
      </div>
    </div>
  </div>
</template>
