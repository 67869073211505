<script setup>
import { keyBy } from 'lodash-es';
import { computed, ref } from 'vue';
import { IconHawkInfoCircle, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import FormWorkflowSchemaInput from '~/forms/atoms/form-schema-input.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
    default: -1,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['save', 'close']);

const $t = inject('$t');
const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();

const form_data = ref({});
const columns = ref({
  lg: {
    container: 12,
    label: 3,
    wrapper: 12,
  },
});

const form_blocks = computed(() => {
  const step_blocks = form_workflow_store.blocks.filter(val => val.type === 'step');
  const step_block_map = keyBy(step_blocks, 'step');
  const ancestral_step_numbers = form_workflow_store.getSteps(props.step_number);

  return ancestral_step_numbers.map(step_number => step_block_map[step_number]);
});

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        name: $t('Rollback'),
        description: null,
        message: null,
        jump_to: null,
      };
});

async function save() {
  form_data.value.rule_id = form_workflow_store.new_node?.rule_id ?? props.form.rule_id;

  if (!form_data.value.jump_to)
    form_data.value.jump_to = form_blocks.value[0]?.uid;

  const data = { ...form_data.value };

  if (props.submit_function)
    await props.submit_function(data);
}

function init() {
  form_data.value = default_data.value;
}
init();
</script>

<template>
  <div>
    <Vueform
      v-model="form_data" :columns="columns" :sync="true" size="sm" :messages="{ required: $t('This field is required') }" :display-errors="false" :endpoint="save"
      @change="form_workflow_store.is_sidebar_form_dirty = true"
    >
      <div class="col-span-12">
        <div class="flex justify-between items-start mb-10">
          <div class="w-80">
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Rollback Block') }}
            </div>
            <div class="text-xs text-gray-600">
              {{ $t('Jump back to the previous form block.') }}
            </div>
          </div>
          <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
            <IconHawkXClose class="text-gray-500" />
          </HawkButton>
        </div>
        <div :class="{ 'pointer-events-none': is_disabled }">
          <TextElement :disabled="is_disabled" name="name" :label="$t('Name')" class="mb-5" rules="required" />
          <TextareaElement :disabled="is_disabled" name="description" :label="$t('Description')" :placeholder="$t('Enter description')" class="mb-5" />
          <SelectElement
            :disabled="is_disabled"
            class="mb-5"
            name="jump_to"
            :native="false"
            :placeholder="$t('Select a form block')"
            label-prop="name"
            value-prop="uid"
            :items="form_blocks"
          >
            <template #label>
              <div class="flex gap-2 items-center">
                {{ $t('Jump to') }}
                <div
                  v-tippy="{
                    content: $t('Choose a form block to rollback the form execution on reaching this block. If not selected, it will automatically go to the previous form block.'),
                    maxWidth: 300,
                  }"
                  class="text-center"
                >
                  <IconHawkInfoCircle class="h-4 w-4" />
                </div>
              </div>
            </template>
          </SelectElement>
          <FormWorkflowSchemaInput
            name="message"
            :rules="['required']"
            :label="$t('Reason for rollback')"
            :disabled="is_disabled"
            :editor_options="{
              placeholder_text: `${$t('Enter reason')}`,
            }"
            :filter_fields="[
              'short_text',
              'number',
              'dropdown',
              'radio',
              'date_time',
            ]"
            :step_number="step_number"
          />
          <div class="flex">
            <HawkText
              class="text-sm font-semibold mr-1"
              :content="`${$t('Note')}:`"
            />
            <HawkText
              length="1000"
              class="text-sm"
              :content="`${$t('The form will be reassigned to the same members who were assignees at the time of submission of the')} ${form_blocks?.find(item => item.uid === form_data?.jump_to)?.name || 'previous step'}`"
            />
          </div>
          <hr class="my-6">
          <div v-if="!is_disabled" class="flex justify-end">
            <ButtonElement
              name="cancel" :button-label="$t('Cancel')" class="mr-3" :secondary="true"
              @click="$emit('close')"
            />
            <ButtonElement name="save" :button-label="$t('Save')" :submits="true" />
          </div>
        </div>
      </div>
    </Vueform>
  </div>
</template>
