<script setup>
import { computed, onMounted, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import DocumentGenerateTrigger from '~/forms/components/form-workflows/sidebar/document-generate-block/document-generate-trigger.vue';

const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['save', 'close']);

const $t = inject('$t');
const form$ = ref(null);
const state = reactive({
  columns: {
    lg: {
      container: 12,
      label: 3,
      wrapper: 12,
    },
  },
  is_form_initialized: false,
  document: {},
  is_confirm_popup_opened: false,
});

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        name: 'Document Generation Block',
        description: 'To generate a document for the block',
        document: null,
        schema: null,
        wait: 0,
      };
});

const confirm_button = ref({ text: $t('Confirm'), emit: 'confirm', loading: false });

async function openConfirmChangesPopup(formData, { data }) {
  if (!state.is_confirm_popup_opened) {
    const { open: openConfirmCreateBlockModal, close: closeModal } = useModal({
      component: HawkModalTemplate,
      attrs: {
        footer_buttons: [confirm_button.value],
        content_class: 'w-[600px] rounded-lg',
        async onClose() {
          closeModal();
        },
        async onConfirm() {
          confirm_button.value.loading = true;
          await save(data);
          confirm_button.value.loading = false;
          closeModal();
        },
      },
      slots: {
        title_text: $t('Info'),
        default: $t('Any edits done to the form blocks of this template may require you to upload a new document.'),
      },
    });
    openConfirmCreateBlockModal();
    state.is_confirm_popup_opened = true;
  }
  else {
    await save(data);
  }
}

function onUpdate() {
  const { data, formatData } = form$.value.form$.el$('document');
  state.document = formatData('document', data.document).document;
}
async function save(data) {
  if (props.submit_function) {
    await props.submit_function({
      ...data,
      wait: Number(data.wait || 0),
      document: state.document,
    });
  }
}

onMounted(async () => {
  await form$.value.load({ wait: 0, ...default_data.value }, true);
  state.is_form_initialized = true;
  state.document = default_data.value.document;
});
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      :columns="state.columns" size="sm" :messages="{ required: $t('This field is required') }" :display-errors="false" :endpoint="openConfirmChangesPopup"
      @change="$emit('updateIsFormDirty', state.is_form_initialized); onUpdate()"
      @updated="onUpdate"
    >
      <div class="col-span-12">
        <div class="flex justify-between items-start mb-10">
          <div class="w-80">
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Generate documents') }}
            </div>
            <div class="text-xs text-gray-600">
              {{ $t('Export form data as a PDF using a custom layout/structure.') }}
            </div>
          </div>
          <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
            <IconHawkXClose class="text-gray-500" />
          </HawkButton>
        </div>
        <div :class="{ 'pointer-events-none': is_disabled }">
          <TextElement :readonly="is_disabled" name="name" :label="$t('Name')" class="mb-5" rules="required" @mounted="(el$) => el$.load('Rollback')" />
          <TextareaElement :readonly="is_disabled" name="description" :label="$t('Description')" :placeholder="$t('Enter description')" class="mb-5" />
          <DocumentGenerateTrigger
            :form_data="
              {
                ...(form$?.data || {}),
                document: state.document,
              }"
            :step_number="step_number" :required="true"
          />
          <TextElement
            name="wait"
            input-type="number"
            class="mb-5"
            :default="0"
            :rules="[
              'integer',
              'min:0',
              'max:300',
            ]"
            autocomplete="off"
            :label="$t('Wait')"
            :description="$t('Number of seconds to wait for thumbnails before generating the document.')"
          >
            <template #addon-after>
              {{ $t('seconds') }}
            </template>
          </TextElement>
          <hr class="my-6">
          <div v-if="!is_disabled" class="flex justify-end">
            <ButtonElement
              name="cancel" :button-label="$t('Cancel')" class="mr-3" :secondary="true"
              @click="$emit('close')"
            />
            <ButtonElement name="save" :button-label="$t('Save')" :submits="true" />
          </div>
        </div>
      </div>
    </Vueform>
  </div>
</template>
