<script setup>
import { every, isNil } from 'lodash-es';
// --------------------------------- Imports -------------------------------- //
import { computed, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import FieldMapPopup from '~/forms/components/form-workflows/sidebar/sync-asset-block/field-map-popup.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
    default: -1,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});

// ---------------------------------- Emits --------------------------------- //
defineEmits(['save', 'close']);

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');

// ------------------- Variables - Local - consts and lets ------------------ //
const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();
const { open: open_field_map_popup, close: close_field_map_popup, patchOptions: patch_field_map_popup } = useModal({
  component: FieldMapPopup,
  attrs: {
    step_number: props.step_number,
    onClose() {
      close_field_map_popup();
    },
  },
});

// ------------------------ Variables - Local - refs ------------------------ //
const form_data = ref({});
const field_map_data = ref({
  field_mapping: [{
    form_field: null,
    operator: null,
    asset_field: null,
  }],
});

// --------------------------- Computed properties -------------------------- //
const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        name: null,
        description: null,
        field_mapping: field_map_data.value.field_mapping,
      };
});
const has_field_mappings = computed(() => every(field_map_data.value.field_mapping, field_map => every(Object.values(field_map || {}), value => !isNil(value))));

// -------------------------------- Functions ------------------------------- //
async function save() {
  const data = {
    ...form_data.value,
    ...({ field_mapping: has_field_mappings.value ? field_map_data.value.field_mapping : default_data.value.field_mapping }),
  };

  if (props.submit_function)
    await props.submit_function(data);
}

function init() {
  form_data.value = default_data.value;
}
init();
</script>

<template>
  <div>
    <Vueform
      v-model="form_data" :sync="true" size="sm" :messages="{ required: $t('This field is required') }" :display-errors="false" :endpoint="save"
      @change="form_workflow_store.is_sidebar_form_dirty = true"
    >
      <div class="col-span-12">
        <div class="flex justify-between items-start mb-10">
          <div class="w-80">
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Sync asset metadata') }}
            </div>
            <div class="text-xs text-gray-600">
              {{ $t('Sync information from forms to one or more asset metadata fields') }}
            </div>
          </div>
          <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
            <IconHawkXClose class="text-gray-500" />
          </HawkButton>
        </div>
        <div :class="{ 'pointer-events-none': is_disabled }">
          <TextElement :disabled="is_disabled" name="name" :label="$t('Name')" class="mb-5" rules="required" :placeholder="$t('Add block name')" />
          <TextareaElement :disabled="is_disabled" name="description" :label="$t('Description')" :placeholder="$t('Enter description')" class="mb-5" />

          <div class="grid grid-cols-12 mb-5">
            <div class="cols-span-12 sm:col-span-6 text-sm font-medium text-gray-700">
              {{ $t('Field Mapping') }}
            </div>
            <div class="cols-span-12 sm:col-span-6">
              <HawkButton
                type="outlined" :class="[is_disabled ? 'pointer-events-none' : 'pointer-events-auto']" @click="() => {
                  patch_field_map_popup({
                    attrs: {
                      form: { field_mapping: has_field_mappings ? field_map_data.field_mapping : default_data.field_mapping },
                      onSave(payload) {
                        field_map_data = payload;
                      },
                    },
                  });
                  open_field_map_popup();
                }
                "
              >
                {{ $t('Map Fields') }}
              </HawkButton>
            </div>
          </div>
          <div class="flex">
            <HawkText
              class="text-sm font-semibold mr-1"
              :content="`${$t('Note')}:`"
            />
            <HawkText
              length="1000"
              class="text-sm"
              :content="$t('It will only be synced to the form created in that asset.')"
            />
          </div>
          <hr class="my-6">
          <div v-if="!is_disabled" class="flex justify-end">
            <ButtonElement
              name="cancel" :button-label="$t('Cancel')" class="mr-3" :secondary="true"
              @click="$emit('close')"
            />
            <ButtonElement name="save" :button-label="$t('Save')" :submits="true" />
          </div>
        </div>
      </div>
    </Vueform>
  </div>
</template>
