<script setup>
// --------------------------------- Imports -------------------------------- //
import { IconHawkCalendar, IconHawkCheckboxBase, IconHawkCheckSquare, IconHawkCoinsStackedTwo, IconHawkEditTwo, IconHawkFileFive, IconHawkFilePlusTwo, IconHawkFileTwo, IconHawkGlobeTwo, IconHawkMailThree, IconHawkNumber, IconHawkPhone, IconHawkPlus, IconHawkSignature, IconHawkToggleThreeRight, IconHawkUploadOne, IconHawkUsersOne } from '~/common/components/molecules/hawk-icons/icons.js';
// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  update: {
    type: Function,
    require: true,
  },
  selected_items: {
    type: Array,
    required: true,
  },
});
// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['addColumn', 'close', 'editColumn']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const field_type_icon_map = {
  'short_text': IconHawkFileFive,
  'number': IconHawkNumber,
  'long_text': IconHawkFileTwo,
  'yes-no': IconHawkToggleThreeRight,
  'checkbox': IconHawkCheckSquare,
  'radio': IconHawkCheckboxBase,
  'dropdown': IconHawkChevronDown,
  'members': IconHawkUsersOne,
  'attachment': IconHawkUploadOne,
  'date_time': IconHawkCalendar,
  'email': IconHawkMailThree,
  'phone': IconHawkPhone,
  'money': IconHawkCoinsStackedTwo,
  'signature': IconHawkSignature,
  'url': IconHawkGlobeTwo,
  'multi_text': IconHawkFilePlusTwo,
  'formula': IconHawkCalendar,
};

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <HawkFieldsSelector
    :texts="{
      heading: $t('Configure columns'),
    }"
    :selected_items="selected_items"
    :is_nested="false"
    :immediate="false"
    :hidden_features="['arrow-left', 'arrow-right', 'left-search', 'right-search', 'clear-all', 'left-column']"
    :update="update"
    @close="emit('close')"
  >
    <template #right_item="{ label, is_selected, item }">
      <div
        class="p-1 text-sm font-medium flex items-center justify-between text-gray-700 cursor-pointer hover:bg-gray-50 group"
        :class="{
          'bg-gray-100 hover:!bg-gray-100 selected-active-field': is_selected,
        }"
      >
        <div class="flex gap-2 items-center">
          <component :is="field_type_icon_map[item?.config?.type === 'formula' ? item.config.type : item.properties.type]" class="w-5" />
          <HawkText :content="label" :length="25" />
        </div>
        <HawkButton class="invisible group-hover:visible" icon type="plain" @click="() => emit('editColumn', item)">
          <IconHawkEditTwo class="w-5" />
        </HawkButton>
      </div>
    </template>
    <template #additional_content>
      <HawkButton class="mt-3" type="text" @click="() => emit('addColumn')">
        <IconHawkPlus />
        {{ $t('New column') }}
      </HawkButton>
    </template>
  </HawkFieldsSelector>
</template>
