<script setup>
import { jwtDecode } from 'jwt-decode';
import { inject, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { openJoinOrganizationInfo } from '~/auth/utils/auth.utils.js';
import { IconHawkCheckCircle } from '~/common/components/molecules/hawk-icons/icons.js';

const route = useRoute();
const router = useRouter();
const $services = inject('$services');
const $toast = inject('$toast');
const state = reactive({
  is_loading: true,
});
logger.log(route);
async function handleInviteLink() {
  try {
    state.is_loading = true;
    if (route.params.org_id) {
      const response = await $services.organizations.get({
        id: route.params.org_id,
        attribute: 'create-invite-link',
        query: { p: route.query?.p },
      });
      logger.log(response);
      window.location.href = response.data.url;
    }
    else { router.push('/'); }
    state.is_loading = false;
  }
  catch (error) {
    logger.log('error', error);
    if (error?.data?.code === 'CORE_25') {
      await openJoinOrganizationInfo(route.params.org_id, jwtDecode(route.query.p).organization_name, IconHawkCheckCircle, () => router.push('/'));
    }
    else {
      $toast({
        title: error?.data?.title || 'Error',
        text: (error?.data?.message || error?.message),
        type: 'error',
      });
      router.push('/');
    }
    state.is_loading = false;
  }
}
handleInviteLink();
</script>

<template>
  <div>
    <HawkLoader v-if="state.is_loading" container_class="h-screen" />
  </div>
</template>
