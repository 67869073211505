<script setup>
import { IconHawkChevronDown } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  selected_items: {
    type: Array,
    default: () => [],
  },
  bulk_action_options: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['clear']);
</script>

<template>
  <div class="w-full flex items-center justify-between">
    <div class="flex items-center">
      <HawkMenu :items="bulk_action_options">
        <template #trigger>
          <HawkButton type="outlined">
            {{ $t('Actions') }}
            <IconHawkChevronDown class="text-gray-600" />
          </HawkButton>
        </template>
      </HawkMenu>
      <p class="text-gray-700 text-sm mx-4">
        {{ `${selected_items.length} ${$t('selected')}` }}
      </p>
      <p
        class="font-semibold text-sm text-gray-600 cursor-pointer"
        @click="emit('clear')"
      >
        {{ $t('Clear') }}
      </p>
    </div>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>
