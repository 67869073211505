<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep } from 'lodash-es';
import { computed, ref } from 'vue';
import { IconHawkPlus, IconHawkTrashThree } from '~/common/components/molecules/hawk-icons/icons.js';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  ftg: {
    type: Object,
  },
  features_object: {
    type: Object,
  },
  properties: {
    type: Array,
    default: () => [],
  },
  on_submit: {
    type: Function,
    required: true,
  },
  disable_feature_select: {
    type: Boolean,
    default: false,
  },
  is_bulk_select: {
    type: Boolean,
    default: false,
  },
});
const { getStyles, getValidationRulesForPropertyKey } = useTerraHelperComposable();
const { checkPropertyKey } = getValidationRulesForPropertyKey();

const form = ref({});

const feature_value = computed(() => {
  return props.features_object[form.value.featureTypeId] || null;
});
const feature_types = computed(() => {
  return props.ftg.reduce((acc, curr) => {
    if (curr.featureTypes) {
      return acc.concat(curr.featureTypes.filter(val => val.uid).map((val) => {
        return { value: val.id, label: val.name, color: val.properties.color, feature: val };
      }));
    }
    return acc;
  }, []);
});
const disable_add_field = computed(() => {
  if (form.value.extraPropertiesArray.length >= 1) {
    const last_field = form.value.extraPropertiesArray.slice(-1)[0];
    return !(last_field.key && last_field.value);
  }
  return false;
});

function updateForm() {
  form.value = cloneDeep(props.properties);
  form.value.extraPropertiesArray = Object.keys(form.value.extraProperties || {}).map(key => ({ key, value: form.value.extraProperties[key] }));
}
function updateFeature(val) {
  form.value.oldfeatureTypeId = form.value.featureTypeId;
  form.value.featureType = val.uid;
  form.value.featureTypeId = val.id;
}
function addField() {
  if (!disable_add_field.value)
    form.value.extraPropertiesArray.push({ key: '', value: '' });
}
async function save() {
  const payload = cloneDeep(form.value);
  if (!props.is_bulk_select) {
    payload.extraProperties = payload.extraPropertiesArray.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
  }
  delete payload.extraPropertiesArray;
  await props.on_submit(payload);
}
updateForm();
</script>

<template>
  <hawk-modal-container content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" :sync="true"
      size="sm"
      :should_validate_on_mount="false" :display-errors="false"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
      :add-classes="{
        ElementLabel: {
          container: 'font-medium',
        },
      }"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('close')">
          <template #title>
            {{ $t('Edit Properties') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-[calc(100vh-250px)] overflow-auto scrollbar">
          <TextElement v-if="!is_bulk_select" name="name" :label="$t('Name')" :placeholder="$t('Enter name')" class="mb-4" />
          <SelectElement
            name="featureTypeId"
            :label="$t('Type')"
            :placeholder="$t('Select feature')"
            :native="false"
            :items="feature_types"
            :can-clear="false"
            class="mb-4"
            :disabled="disable_feature_select"
            :add-classes="{
              SelectElement: {
                select: {
                  dropdown: '!overflow-y-auto',
                  options: '!none !hidden !h-0',
                  noOptions: '!none !hidden !h-0',
                  noResults: '!none !hidden !h-0',
                },
              },
            }"
          >
            <template #before-list>
              <FeatureTypes type="dropdown" :dropdown_value="feature_value" @select="updateFeature($event)" />
            </template>
            <template #single-label="{ value }">
              <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                <div
                  class="cursor-pointer w-3.5 h-3.5 rounded-full bg-gray-400"
                  :style="getStyles(value.feature)"
                />

                <p
                  class="is-pointer whitespace-nowrap text-[13px] text-gray-900 ml-2"
                >
                  {{ value.label }}
                </p>
              </div>
            </template>
          </SelectElement>
          <TextareaElement v-if="!is_bulk_select" name="description" :label="$t('Description')" class="mb-4" />
          <template v-if="!is_bulk_select">
            <div class="col-span-12 text-gray-700 text-[14px] font-medium mb-2">
              <div class="grid grid-cols-12">
                <div class="col-span-4 pr-4">
                  {{ $t('Key') }}
                </div>
                <div class="col-span-7">
                  {{ $t('Value') }}
                </div>
              </div>
            </div>
            <ListElement
              class="mb-2"
              name="extraPropertiesArray"
              :controls="{ add: false, remove: false, sort: false }"
            >
              <template #default="{ index }">
                <ObjectElement
                  :name="index"
                >
                  <div class="col-span-12">
                    <div class="grid grid-cols-12 -mb-3">
                      <div class="col-span-4 pr-4">
                        <TextElement name="key" :placeholder="$t('Enter key')" class="mb-4" :rules="['required', checkPropertyKey]" />
                      </div>
                      <div class="col-span-7">
                        <TextElement name="value" :placeholder="$t('Enter value')" class="mb-4" rules="required" />
                      </div>
                      <div class="col-span-1 mt-2 px-5">
                        <div
                          class="cursor-pointer"
                          @click="form.extraPropertiesArray.splice(index, 1)"
                        >
                          <IconHawkTrashThree class="w-4.5 h-4.5 text-gray-700" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ObjectElement>
              </template>
            </ListElement>
            <HawkButton class="my-2" :disabled="disable_add_field" type="plain" @click="addField">
              <IconHawkPlus />
              <span>
                {{ $t('Add Field') }}
              </span>
            </HawkButton>
          </template>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <hawk-button
                class="mr-4 font-bold"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                :button-label="$t('Save')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
