<script>
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import { IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

export default {
  components: {
    HawkLoader,
  },
  data() {
    return {
      viewer_url: '',
      is_loaded: false,
      pattern_generator_url: import.meta.env.VITE_APP_PATTERN_GENERATOR,
      pattern: '',
    };
  },
  beforeUnmount() {
    window.removeEventListener('message', this.updatePattern);
  },
  created() {
    window.addEventListener('message', this.updatePattern);
  },
  methods: {
    loaded() {
      this.is_loaded = true;
    },
    updatePattern(event) {
      this.pattern = event.data.data;
    },
    applyPattern() {
      this.$emit('close');
      this.$emit('apply', this.pattern);
    },
  },
};
</script>

<template>
  <hawk-modal-container :width="370" content_class="rounded-lg !w-[370px] !min-w-[370px]">
    <div class="h-[455px]" :class="{ 'flex items-center justify-center': !is_loaded }">
      <HawkLoader v-if="!is_loaded" />

      <div v-show="is_loaded">
        <header class="flex items-center justify-between p-4 border-b">
          <div class="text-[16px] font-semibold">
            {{ $t('Generate Pattern') }}
          </div>
          <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center" @click="$emit('close')">
            <IconHawkX class="text-lg" />
          </div>
        </header>
        <div class="p-5 relative h-[330px] w-[370px]">
          <iframe
            id="pattern-viewer"
            class="h-[330px] w-[370px] absolute"
            title="Pattern generator"
            :src="pattern_generator_url"
            @load="loaded()"
          />
        </div>
        <div class="flex justify-end items-center p-4 border-t border-t-gray-200">
          <hawk-button
            color="primary"
            @click="applyPattern"
          >
            {{ $t('Apply') }}
          </hawk-button>
        </div>
      </div>
    </div>
  </hawk-modal-container>
</template>
