<script setup>
import { computed, inject } from 'vue';
import { IconHawkFilterFunnelOne, IconHawkFilterFunnelRed } from '~/common/components/molecules/hawk-icons/icons.js';
import { formatFields } from '~/system-model/utils/helper';
import FeatureExtraProperties from '~/terra/components/feature-details/feature-extra-properties.vue';

const props = defineProps({
  properties: {
    type: Array,
    default: () => [],
  },
  fields: {
    type: Array,
    default: () => [],
  },
  extra_properties: {
    type: Array,
    default: () => [],
  },
  has_quick_filter: {
    type: Boolean,
    default: false,
  },
});

const $toast = inject('$toast');
const check_status_handler = ref(null);
const toggle_filter_handler = ref(null);
const formatted_fields = computed(() => [...formatFields(props.fields, props.properties)]);

function downloadAttachment(file) {
  if (file?.pre_signed_url) {
    window.open(file.pre_signed_url, '_blank');
  }
  else {
    $toast({
      text: 'File not available, please try again later',
      type: 'warning',
      position: 'bottom-right',
    });
  }
}
</script>

<template>
  <div v-if="!(formatted_fields.length || extra_properties.length)" class="text-sm text-gray-500 mt-4">
    {{ $t('No properties available') }}
  </div>
  <div v-else>
    <template v-for="field of formatted_fields" :key="field.uid">
      <div class="flex py-2 text-sm" :class="{ 'group cursor-pointer': has_quick_filter }">
        <div class="shrink-0 w-48 text-gray-500 font-medium break-all mr-1">
          {{ field.name }}
        </div>
        <div class="text-gray-900 flex-auto">
          <div v-if="field.type === 'file'">
            <HawkAttachmentsList :items="field.value" :can_delete="false" :show_file_size="true" @download="downloadAttachment" />
          </div>
          <div v-else class="flex items-center justify-between">
            <span class="break-all">
              {{ field.value }}
            </span>
            <span v-if="has_quick_filter && field.value && field.type !== 'url'" :class="{ invisible: check_status_handler?.(field) }" class="group-hover:visible" @click="toggle_filter_handler?.(field)">
              <IconHawkFilterFunnelOne v-if="check_status_handler?.(field)" v-tippy="$t('Add to filters')" class="w-4 h-4" />
              <IconHawkFilterFunnelRed v-else class="w-4 h-4" />
            </span>
          </div>
        </div>
      </div>
    </template>
    <FeatureExtraProperties
      v-if="extra_properties.length"
      :is_feature_slider="true"
      @check-status-handler="check_status_handler = $event"
      @toggle-filter-handler="toggle_filter_handler = $event"
    />
  </div>
</template>
