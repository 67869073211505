<script setup>
import DOMPurify from 'dompurify';
import { markRaw } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkCalendar, IconHawkCheckboxBase, IconHawkChevronDown, IconHawkCoinsStackedTwo, IconHawkFileFive, IconHawkFilePlusTwo, IconHawkFileTwo, IconHawkGlobeTwo, IconHawkMailThree, IconHawkMessageCheckSquare, IconHawkNumber, IconHawkPhone, IconHawkSignature, IconHawkToggleThreeRight, IconHawkUploadOne, IconHawkUsersOne } from '~/common/components/molecules/hawk-icons/icons.js';
import { all_countries } from '~/common/constants/all-countries.js';
import { useFormBuilderAllFields } from '~/forms/composables/form-builder-all-fields.composable.js';
import { useFieldConfiguration } from '~/forms/composables/form-builder-field-schema.composable.js';
import { createListOnPaste } from '~/system-model/utils/helper';

const props = defineProps({
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  fields: {
    type: Array,
    default: () => [],
  },
  section: { type: Object },
  selected_column: {
    type: Object,
  },
  previousField: {
    type: String,
  },
});

const emit = defineEmits(['close', 'delete']);

const auth_store = useAuthStore();

const $t = inject('$t');
const $track_event = inject('$track_event');

const form$ = ref(null);

const $services = inject('$services');
const $toast = inject('$toast');
const is_deleting = ref(false);

const selected_field = props.selected_column ? { ...props.selected_column } : null;

function get_fields_info() {
  const data = $t('Fields letter Assignment');
  let added_data = data;
  const fields = props.section?.fields.filter(field => field.status === 'active');
  for (let i = 0; i < fields.length; i++)
    added_data += `<div>${fields[i].name} - ${String.fromCharCode(65 + i)}</div> `;

  return DOMPurify.sanitize(added_data);
}

const restricted_fields_types = [{
  label: $t('Text Field'),
  value: 'short_text',
  icon: markRaw(IconHawkFileFive),
  data: {
    mandatory: true,
    name: 'Text Field',
    type: 'short_text',
    properties: { type: 'short_text' },
    config: { name: 'text' },
  },
}, {
  label: $t('Yes / No'),
  value: 'yes-no',
  icon: markRaw(IconHawkToggleThreeRight),
  data: {
    mandatory: true,
    name: 'Yes/No field',
    type: 'dropdown',
    properties: { type: 'yes-no' },
    config: [{ name: 'Yes' }, { name: 'No' }, { name: 'N/A' }],
  },
}, {
  label: $t('Dropdown'),
  value: 'dropdown',
  icon: markRaw(IconHawkChevronDown),
  data: {
    mandatory: true,
    name: 'Dropdown field',
    type: 'dropdown',
    properties: { type: 'dropdown' },
    component: 'dropdown-field',
  },
}, {
  label: $t('Number'),
  value: 'number',
  icon: markRaw(IconHawkNumber),
  data: {
    mandatory: true,
    name: 'Number',
    type: 'number',
    properties: { type: 'number' },
    component: 'number-field',
    config: { name: 'number', representation: 'Default' },
  },
}, {
  label: $t('Date'),
  value: 'date_time',
  icon: markRaw(IconHawkCalendar),
  data: {
    mandatory: true,
    name: 'Date',
    type: 'date_time',
    properties: { type: 'date_time' },
    component: 'date-time-field',
    config: {
      name: 'date',
      type: 'date',
      dateplaceholder: 'Select date',
      canSelectPastDates: true,
    },
  },
}, {
  label: $t('Money'),
  value: 'money',
  icon: markRaw(IconHawkCoinsStackedTwo),
  data: {
    mandatory: true,
    name: 'Money',
    properties: { type: 'money' },
    type: 'decimal',
    component: 'money-field',
    config: { name: 'money' },
  },
}, {
  label: $t('Formula'),
  value: 'formula',
  icon: markRaw(IconHawkCalendar),
  data: {
    mandatory: true,
    name: 'Formula field',
    type: 'short_text',
    properties: { type: 'short_text' },
    config: { formula: '', prefix: '', type: 'formula' },
  },
}];

const field_types = !auth_store.check_split('all_table_fields') ? restricted_fields_types : [{
  label: $t('Text Field'),
  value: 'short_text',
  icon: markRaw(IconHawkFileFive),
  data: {
    mandatory: true,
    name: 'Text Field',
    type: 'short_text',
    properties: { type: 'short_text' },
    config: { name: 'text' },
  },
}, {
  label: $t('Number'),
  value: 'number',
  icon: markRaw(IconHawkNumber),
  data: {
    mandatory: true,
    name: 'Number',
    type: 'number',
    properties: { type: 'number' },
    component: 'number-field',
    config: { name: 'number', representation: 'Default' },
  },
}, {
  label: $t('Text Area'),
  value: 'long_text',
  icon: markRaw(IconHawkFileTwo),
  data: {
    mandatory: true,
    name: 'Text Area',
    type: 'long_text',
    component: 'rich-text',
    config: { name: 'long_text' },
    properties: { type: 'long_text' },
  },
}, {
  label: $t('Yes / No'),
  value: 'yes-no',
  icon: markRaw(IconHawkToggleThreeRight),
  data: {
    mandatory: true,
    name: 'Yes/No field',
    type: 'dropdown',
    properties: { type: 'yes-no' },
    config: [{ name: 'Yes' }, { name: 'No' }, { name: 'N/A' }],
  },
}, {
  label: $t('Checkbox'),
  value: 'checkbox',
  icon: markRaw(IconHawkMessageCheckSquare),
  data: {
    mandatory: true,
    name: 'Checkbox',
    type: 'label',
    component: 'checkbox-field',
    config: [{ name: 'Option' }],
    properties: { type: 'checkbox' },
  },
}, {
  label: $t('Radio'),
  value: 'radio',
  icon: markRaw(IconHawkCheckboxBase),
  data: {
    mandatory: true,
    name: 'Radio',
    type: 'dropdown',
    component: 'radio-field',
    config: [{ name: 'Option-1' }],
    properties: { type: 'radio' },
  },
}, {
  label: $t('Dropdown'),
  value: 'dropdown',
  icon: markRaw(IconHawkChevronDown),
  data: {
    mandatory: true,
    name: 'Dropdown field',
    type: 'dropdown',
    properties: { type: 'dropdown' },
    component: 'dropdown-field',
  },
}, {
  label: $t('Member'),
  value: 'members',
  icon: markRaw(IconHawkUsersOne),
  data: {
    mandatory: true,
    name: 'Member',
    type: 'member',
    component: 'member-field',
    config: {
      allow_multiple_selection: false,
      allowed_members: {},
    },
    properties: { type: 'members' },
  },
}, {
  label: $t('File Upload'),
  value: 'attachment',
  icon: markRaw(IconHawkUploadOne),
  data: {
    mandatory: true,
    name: 'File Upload',
    type: 'attachment',
    component: 'upload-field-multiple',
    config: {
      type: ['Video', 'Document', 'Spreadsheet', 'Image', 'Audio'],
      custom: 'all',
      uploadOptions: 'both',
      option: '10',
      limitNoOfFiles: '-1',
    },
    properties: { type: 'attachment' },
  },
}, {
  label: $t('Date'),
  value: 'date_time',
  icon: markRaw(IconHawkCalendar),
  data: {
    mandatory: true,
    name: 'Date',
    type: 'date_time',
    properties: { type: 'date_time' },
    component: 'date-time-field',
    config: {
      name: 'date',
      type: 'date',
      dateplaceholder: 'Select date',
      canSelectPastDates: true,
    },
  },
}, {
  label: $t('Email'),
  value: 'email',
  icon: markRaw(IconHawkMailThree),
  data: {
    mandatory: true,
    name: 'Email',
    type: 'email',
    component: 'email-field',
    config: { name: 'email' },
    properties: { type: 'email' },
  },
}, {
  label: $t('Phone number'),
  value: 'phone',
  icon: markRaw(IconHawkPhone),
  data: {
    mandatory: true,
    name: 'Phone number',
    type: 'phone_number', // backend dataType
    component: 'phone-field',
    config: { name: 'phone' },
    properties: { type: 'phone' }, // front end type
  },
}, {
  label: $t('Money'),
  value: 'money',
  icon: markRaw(IconHawkCoinsStackedTwo),
  data: {
    mandatory: true,
    name: 'Money',
    properties: { type: 'money' },
    type: 'decimal',
    component: 'money-field',
    config: { name: 'money' },
  },
}, {
  label: 'Signature',
  value: 'signature',
  icon: markRaw(IconHawkSignature),
  data: {
    mandatory: true,
    name: 'Signature',
    type: 'attachment',
    component: 'signature-field',
    properties: {
      type: 'signature',
    },
  },
}, {
  label: $t('Website'),
  value: 'url',
  icon: markRaw(IconHawkGlobeTwo),
  data: {
    mandatory: true,
    name: 'Website',
    type: 'url',
    component: 'website-field',
    config: { name: 'website' },
    properties: { type: 'url' },
  },
}, ...(auth_store.check_split('multi_text_field')
  ? [
      {
        label: $t('Multi text'),
        value: 'multi_text',
        icon: IconHawkFilePlusTwo,
        data: {
          mandatory: true,
          name: 'Multi text',
          type: 'multi_text',
          properties: { type: 'multi_text' },
        },
      },
    ]
  : []), {
  label: $t('Formula'),
  value: 'formula',
  icon: markRaw(IconHawkCalendar),
  data: {
    mandatory: true,
    name: 'Formula field',
    type: 'short_text',
    properties: { type: 'short_text' },
    config: { formula: '', prefix: '', type: 'formula' },
  },
}];

const formula_schema = {
  formula: {
    prefix: {
      type: 'text',
      label: $t('Prefix'),
    },
    formula: {
      type: 'text',
      label: $t('Formula'),
      rules: ['required'],
      info: { content: { content: get_fields_info(), allowHTML: true } },
    },
  },
};

const group_element_schema = ref({});

get_schema_for_field(getFieldType());

function get_schema_for_field(new_value, old_value, el$) {
  group_element_schema.value = {};
  nextTick(() => {
    const field_map = { ...useFieldConfiguration(selected_field || new_value, 'table', props.section, { $t }), ...formula_schema };
    group_element_schema.value = field_map[new_value];
  });
}

async function updateField(form) {
  try {
    const payload = { ...form.data, properties: selected_field.properties };
    if (form.data.type === 'yes-no')
      payload.config = selected_field.config;
    else if (form.data.type === 'formula')
      payload.config = { type: 'formula', prefix: form.data.prefix, formula: form.data.formula };
    else if (form.data.type === 'checkbox')
      payload.properties = { ...selected_field.properties, ...form.data.properties };
    else
      payload.config = form.data.config;
    const response = await $services.forms.patch({
      attribute: `fields/${selected_field.uid}`,
      body: { field: payload },
    });
    props.submit(response.data.field);
  }
  catch (e) {
    logger.log(e);
    $toast({ text: e?.data?.message || 'Field Updating failed!', type: 'error' });
  }
}

async function addField(form) {
  try {
    const file_type = field_types.find(type => type.value === form.data.type);
    const payload = { ...file_type.data, ...form.data, type: file_type.data?.type };

    payload.name = form.data.name;
    payload.section = props.section.uid;
    payload.previous_field = props.previousField;
    if (form.data.type === 'yes-no')
      payload.config = [{ name: 'Yes' }, { name: 'No' }, { name: 'N/A' }];
    else if (form.data.type === 'formula')
      payload.config = { type: 'formula', prefix: form.data.prefix, formula: form.data.formula };
    else
      payload.config = form.data.config;
    const response = await $services.forms.post({
      attribute: 'fields',
      body: { field: payload },
    });
    props.submit(response.data.field);
    const { field_map } = useFormBuilderAllFields();
    $track_event('add_field', {
      type: field_map[response.data.field?.properties?.type]?.name,
      template_used: response.data.field?.is_template,
    });
  }
  catch (e) {
    logger.log(e);
    $toast({ text: e?.data?.message || 'Field Updating failed!', type: 'error' });
  }
}

async function deleteField() {
  try {
    is_deleting.value = true;
    await $services.forms.delete({
      attribute: `fields/${selected_field.uid}`,
    });
    emit('delete', selected_field);
  }
  catch (e) {
    $toast({ text: e || 'Field deletion failed!', type: 'error' });
  }
  is_deleting.value = false;
}

function getFieldType() {
  return (selected_field?.config?.type === 'formula' ? 'formula' : selected_field?.properties?.type) || 'short_text';
}

function get_selected_field_value(selected_field) {
  return {
    name: selected_field.name,
    mandatory: selected_field.mandatory,
    type: getFieldType(),
    config: selected_field?.config,
    code: selected_field?.config?.code,
    prefix: selected_field?.config?.prefix,
    formula: selected_field?.config?.formula,
    properties: selected_field?.properties,
  };
}

async function pasteHandler(event) {
  const columns = createListOnPaste(event);
  const items = columns.map((item) => {
    return { name: item.split(/[\t,]+/)[0] };
  });
  const merged_component = [...(form$.value.data.config?.filter(c => c.name) || []), ...items];
  const config$ = form$.value.el$('group.config');
  if (!config$)
    return;
  config$.clear();
  setTimeout(() => {
    config$.update(merged_component);
  }, 10);
}

async function submitForm(form) {
  if (selected_field?.uid)
    await updateField(form);
  else
    await addField(form);
}
const focusElement = () => form$.value.elements$.name.input.focus();

function formatData(data, type) {
  if (type === 'phone') {
    if (selected_field && !selected_field.editable) {
      delete data.config;
      return data;
    }
    const country = all_countries.find(country => country.iso2 === data?.config?.iso);
    if (country)
      data.config.code = country.dialCode;
    return data;
  }
  return data;
}
</script>

<template>
  <hawk-modal-container :options="{ 'focus-trap': { allowOutsideClick: true, onPostActivate: focusElement } }" @click.stop>
    <Vueform
      id="add-field-popup"
      ref="form$" size="sm" :display-errors="false" :endpoint="(form_data, form) => submitForm(form)"
      name="table_field_popup"
      :add-classes="{ FormElements: { container: 'gap-[20px]' }, ObjectElement: { wrapper: 'gap-[20px]' }, GroupElement: { wrapper: 'gap-[20px]' } } "
      :remove-classes="{ FormElements: { container_sm: 'form-gap-y-gutter-sm' }, ObjectElement: { wrapper_sm: 'form-gap-gutter-sm' }, GroupElement: { wrapper_sm: 'form-gap-gutter-sm' } }"
      :columns="{
        default: { container: 12, wrapper: 12, label: 6 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :form-data="form$ => formatData(form$.data, form$.data.type)"
      @paste="pasteHandler"
      @mounted="form$ => selected_field ? form$.load(get_selected_field_value(selected_field), true) : ''"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div v-if="selected_field">
              {{ $t('Update Field') }}
            </div>
            <div v-else>
              {{ $t('Add Field') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-[70vh] min-h-[25rem] overflow-y-scroll scrollbar w-[600px]">
          <div class="grid gap-4">
            <TextElement :label="$t('Field Name')" name="name" :rules="['required']" />
            <SelectElement name="type" :label="$t('Field Type')" :items="field_types" :native="false" :default="getFieldType()" :rules="['required']" :disabled="!!selected_field" @change="get_schema_for_field">
              <template #option="{ option }">
                <component :is="option.icon" class="mr-2" />
                {{ option.label }}
              </template>
              <template #single-label="{ value }">
                <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3 mr-4">
                  {{ value.label }}
                </div>
              </template>
            </SelectElement>
            <GroupElement
              name="group"
              :schema="group_element_schema"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-between w-full">
                <div>
                  <template v-if="auth_store.check_split('forms_hands_on_table')">
                    <HawkButton v-if="fields.length > 1 && selected_field" color="error" type="plain" class="mr-4" :loading="is_deleting" @click="deleteField">
                      {{ $t('Remove') }}
                    </HawkButton>
                  </template>
                  <template v-else>
                    <HawkButton v-if="selected_field" color="error" type="plain" class="mr-4" :loading="is_deleting" @click="deleteField">
                      {{ $t('Remove') }}
                    </HawkButton>
                  </template>
                </div>
                <div class="flex">
                  <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                    {{ $t('Cancel') }}
                  </HawkButton>
                  <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
