<script setup>
import { IconHawkAlertCircle, IconHawkChevronDown, IconHawkChevronUp, IconHawkEditTwo, IconHawkFileMinusTwo } from '~/common/components/molecules/hawk-icons/icons.js';
import SelectBlockColor from '~/forms/atoms/select-block-color.vue';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  step_number: {
    type: Number,
    default: -1,
  },
  payload_data: {
    type: Object,
  },
  creating_step: {
    type: Boolean,
    default: false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  block_create: {
    type: Boolean,
    default: false,
  },
  is_initial_block_saved: {
    type: Boolean,
    default: false,
  },
});

const form_workflow_store = useFormWorkflowStore();
const get_member_fields = computed(() => {
  if (props.step_number) {
    return form_workflow_store.get_fields(props.step_number, ['member']).reduce((acc, field) => {
      if (props.block_create || props.step_number > field.step.index) {
        acc.push({
          label: field.name,
          field_uid: field.uid,
          type: 'exclusive',
        });
      }
      return acc;
    }, []);
  }
  return [];
});
</script>

<template>
  <div>
    <TextElement name="name" :label="$t('Name')" class="mb-5" rules="required" :readonly="is_disabled" />
    <TextareaElement name="description" :label="$t('Description')" :placeholder="$t('Add description')" class="mb-5" :readonly="is_disabled" />
    <SelectBlockColor :key="payload_data.color" wrapper_class="mb-5" :is_disabled="is_disabled" />
    <template v-if="payload_data.step !== 1">
      <hawk-assignee-input
        class="mb-5"
        :multi="true"
        format_load
        :truncate_tag_length="20"
        :options="{
          name: 'assignees',
          has_teams: true,
          label: $t('Assignees'),
          placeholder: $t('Select Assignees'),
          rules: ['required'],
          messages: {
            required: $t('At least one member should be assigned to the step'),
          },
        }"
      >
        <template #description>
          <HawkText :content="$t('Choose assignees for the form')" :length="100" />
        </template>
      </hawk-assignee-input>
      <SelectElement
        v-show="get_member_fields.length"
        name="dynamic_assignees"
        class="mb-5"
        placeholder="Select field"
        :label="$t('Fields')"
        :items="get_member_fields"
        :native="false"
        :object="true"
        label-prop="label"
        value-prop="field_uid"
      >
        <template #description>
          <HawkText :content="$t('The field value will override the above selected members if a value is available for the field.')" :length="100" />
        </template>
      </SelectElement>
      <TextElement
        name="days"
        input-type="number"
        class="mb-5"
        :default="1"
        :rules="[
          'required',
          'integer',
          'min:1',
        ]"
        autocomplete="off"
        :label="$t('Duration')"
        @input="$event => $emit('validateNumberInput', { name: 'days', e: $event })"
      >
        <template #addon-after>
          {{ $t('days') }}
        </template>
        <template #description>
          <HawkText :content="$t('Number of days to submit the form')" :length="100" />
        </template>
      </TextElement>
    </template>
    <div class="rounded-xl border px-4 py-5 text-gray-900" :class="!block_create && is_initial_block_saved ? 'border-primary-300 bg-primary-25' : 'border-warning-300 bg-warning-25'">
      <div class="flex items-start">
        <div class="mr-2">
          <div class="w-10 h-10 rounded-full flex justify-center items-center mr-2" :class="!block_create && is_initial_block_saved ? 'bg-primary-100' : ' bg-warning-100'">
            <IconHawkEditTwo v-if="!block_create && is_initial_block_saved" class="w-5 h-5 text-primary-600" />
            <IconHawkAlertCircle v-else class="w-5 h-5 text-warning-600" />
          </div>
        </div>
        <div>
          <h4 class="mb-1 text-[14px] font-medium leading-6 font-sans">
            {{ !block_create && is_initial_block_saved ? $t('Update Form') : $t('Design form') }}
          </h4>
          <p class="text-[12px] text-gray-600 font-sans">
            {{ $t('Add sections/fields to form for data collection') }}
          </p>
        </div>
      </div>
      <HawkButton v-if="is_disabled || !is_initial_block_saved || payload_data.step" block class="mt-3 pointer-events-auto" :disabled="invalid" :loading="creating_step" type="outlined" @click="$emit('openFormBuilder')">
        <IconHawkFileMinusTwo class="w-5 h-5 mr-0.25" />
        <template v-if="is_disabled">
          {{ $t('View form') }}
        </template>
        <template v-else-if="!is_initial_block_saved || block_create">
          {{ $t('Build form') }}
        </template>
        <template v-else>
          {{ $t('Update form') }}
        </template>
      </HawkButton>
      <hawk-menu
        v-else
        :items="[
          { uid: 'Build from scratch', label: 'Build from scratch', on_click: () => $emit('openFormBuilder') },
          { uid: 'Clone existing', label: 'Clone existing', on_click: () => $emit('cloneStep') },
        ]"
        additional_dropdown_classes="!top-12 !mt-1 !bottom-auto w-[360px] focus-within:border-gray-200 border"
        additional_trigger_classes="!ring-0 w-[360px]"
        position="bottom"
      >
        <template #trigger="{ open }">
          <HawkButton block class="mt-3 pointer-events-auto" :disabled="invalid" :loading="creating_step" type="outlined">
            <IconHawkFileMinusTwo class="w-5 h-5 mr-0.25" />
            {{ $t('Build form') }}
            <IconHawkChevronDown v-if="!open" class="absolute right-[10px]" />
            <IconHawkChevronUp v-else class="absolute right-[10px]" />
          </HawkButton>
        </template>
      </hawk-menu>
    </div>
  </div>
</template>
