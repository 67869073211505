<script setup>
import { IconHawkCross } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  allow_multiple: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Object,
    default: () => {},
  },
  clear_on_confirm: {
    type: Boolean,
    default: false,
  },
  message_text: {
    type: String,
    default: 'items',
  },
});

const emit = defineEmits(['create', 'clear', 'keydown']);

const items_list = ref([]);
const multiple_items_created = ref(false);

function pasteHandler(e) {
  const pasted_text = e.clipboardData.getData('text');
  if (pasted_text.includes('\n'))
    items_list.value = pasted_text.split('\n').filter(t => t);
  else
    items_list.value = pasted_text.split(',').filter(t => t);
}
// Clears field if name is modified when multiple lines are detected
function clearField(e) {
  emit('keydown', e);
  if (items_list.value.length > 1) {
    const element_id = props.options?.name ? props.options.name : 'name';
    items_list.value = [];
    multiple_items_created.value = false;
    document.getElementById(element_id).removeEventListener('click', () => clearField());
    emit('clear');
  }
}
function enableMultiMode() {
  emit('create', items_list.value);
  if (props.clear_on_confirm) {
    items_list.value = [];
    return;
  }
  const element_id = props.options?.name ? props.options.name : 'name';
  multiple_items_created.value = true;
  document.getElementById(element_id).addEventListener('click', () => clearField());
}

const add_classes = computed(() => {
  if (items_list.value.length > 1) {
    return {
      TextElement: {
        input: 'cursor-pointer',
      },
    };
  }
  return {};
});
const remove_classes = computed(() => {
  if (items_list.value.length > 1)
    return {};
  return {
    TextElement: {
      input: 'cursor-pointer',
    },
  };
});
</script>

<template>
  <TextElement
    v-bind="{
      name: 'name',
      rules: 'required|nullable:debounce=1000',
      label: $t('Name'),
      placeholder: $t('Task name'),
      autocomplete: 'off',
      addClasses: add_classes,
      removeClasses: remove_classes,
      attrs: { autofocus: true },
      minRows: 9,
      ...options,
    }"
    @paste="pasteHandler"
    @keydown="clearField"
  >
    <template #between>
      <div v-if="allow_multiple && items_list.length > 1 && !multiple_items_created" class="px-3 py-4 absolute bg-white z-10 shadow-lg rounded-lg w-60">
        <div class="flex justify-between items-center mb-5">
          <p class="text-[#475467]">
            {{ $t('Multiple lines detected') }}
          </p>
          <IconHawkCross class="cursor-pointer h-3 text-gray-500" @click="items_list = []" />
        </div>
        <div class="flex justify-end">
          <hawkButton @click="enableMultiMode">
            {{ $t('Create') }} {{ items_list.length }} {{ $t(message_text) }}
          </hawkButton>
        </div>
      </div>
    </template>
    <template v-if="$slots.addonBefore" #addon-before>
      <slot name="addon-before" />
    </template>
    <template #after>
      <slot name="after">
        <p v-if="multiple_items_created && items_list.length > 1" class="text-gray-500 text-sm">
          {{ items_list.length }} {{ $t(message_text) }} {{ $t('added') }}
        </p>
      </slot>
    </template>
  </TextElement>
</template>
