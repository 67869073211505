<script setup>
import { every, isNil, keyBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
// --------------------------------- Imports -------------------------------- //
import { computed, ref } from 'vue';
import { IconHawkArrowRight, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['save', 'close']);

const FormFieldTree = defineAsyncComponent(() => import('~/forms/molecules/form-field-tree.vue'));

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //
const common_store = useCommonStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //
const { assets_custom_fields } = storeToRefs(common_store);

// ------------------- Variables - Local - consts and lets ------------------ //
const form_workflow_store = useFormWorkflowStore();

const operators = {
  set: {
    label: 'Set',
    value: 'set',
  },
  add: {
    label: 'Add',
    value: 'add',
  },
  subtract: {
    label: 'Subtract',
    value: 'subtract',
  },
};

// INFO: asset field types can be found in asset-custom-field-form-modal.vue -> field_types_map
const form_to_asset_field_map = {
  short_text: {
    operator_list: [operators.set],
    asset_field_types: ['text'],
  },
  number: {
    operator_list: [operators.set, operators.add, operators.subtract],
    asset_field_types: ['number', 'percentage', 'planned_actual'],
  },
  decimal: {
    operator_list: [operators.set, operators.add, operators.subtract],
    asset_field_types: ['number', 'percentage', 'planned_actual'],
  },
  dropdown: {
    // For Dropdown, Yes/No, Radio
    operator_list: [operators.set],
    asset_field_types: ['text'],
  },
  label: {
    // For Checkbox
    operator_list: [operators.set],
    asset_field_types: ['text'],
  },
  member: {
    operator_list: [operators.set, operators.add, operators.subtract],
    asset_field_types: ['members'],
  },
  attachment: {
    operator_list: [operators.set, operators.add],
    asset_field_types: ['files'],
  },
  date_time: {
    operator_list: [operators.set],
    asset_field_types: ['date', 'date_range', 'datetime'],
  },
};

const field_options = form_workflow_store.get_fields(props.step_number);

// ------------------------ Variables - Local - refs ------------------------ //
const form_data = ref({});
const form_field_selected_type = ref({});
const asset_operator = ref([]);

// --------------------------- Computed properties -------------------------- //
const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {};
});

const has_field_mappings = computed(() => every(form_data.value.field_mapping, field_map => every(Object.values(field_map || {}), value => !isNil(value))));

// -------------------------------- Functions ------------------------------- //
function getOperatorOptions(field_uid) {
  const selected_field_type = form_field_selected_type.value[field_uid];
  return form_to_asset_field_map[selected_field_type]?.operator_list || [];
}

function getAssetMetaDataOptions(field_uid) {
  const selected_field_type = form_field_selected_type.value[field_uid];
  const valid_asset_field_types = form_to_asset_field_map[selected_field_type]?.asset_field_types || [];

  const asset_meta_data_options = assets_custom_fields.value.reduce((acc, item) => {
    if (valid_asset_field_types.includes(item.type)) {
      if (item.type === 'planned_actual') {
        acc.push({
          label: `${item.name} [Planned]`,
          type: item.type,
          sub_type: 'planned',
          value: item.uid,
        });
        acc.push({
          label: `${item.name} [Actual]`,
          type: item.type,
          sub_type: 'actual',
          value: item.uid,
        });
      }
      else if (item.type === 'date_range') {
        acc.push({
          label: `${item.name} [Start]`,
          type: item.type,
          sub_type: 'start',
          value: item.uid,
        });
        acc.push({
          label: `${item.name} [End]`,
          type: item.type,
          sub_type: 'end',
          value: item.uid,
        });
      }
      else {
        acc.push({
          label: item.name,
          type: item.type,
          value: item.uid,
        });
      }
    }

    return acc;
  }, []);

  return asset_meta_data_options;
}

function handleFormFieldSelect(selected_field_data, index) {
  form_field_selected_type.value[selected_field_data.slug] = selected_field_data.type;
  if (!form_data.value.field_mapping[index])
    form_data.value.field_mapping[index] = {};
  form_data.value.field_mapping[index].form_field = selected_field_data.slug;
  form_data.value.field_mapping[index].operator = null;
  form_data.value.field_mapping[index].asset_field = null;
}

function removeFieldMap(index) {
  form_data.value.field_mapping = form_data.value.field_mapping.filter((_field_map, field_index) => field_index !== index);
}

function onSave() {
  emit('save', form_data.value);
  emit('close');
}

function init() {
  form_data.value = default_data.value;
  const field_slug_map = keyBy(field_options, 'slug');

  if (has_field_mappings.value) {
    form_data.value.field_mapping.forEach((field_map) => {
      form_field_selected_type.value[field_map.form_field] = field_slug_map[field_map.form_field].type;
    });
  }
}
init();
</script>

<template>
  <HawkModalContainer content_class="w-[900px]">
    <HawkModalHeader @close="$emit('close')">
      <template #title>
        <div class="flex items-center">
          {{ $t('Field Mapping') }}
        </div>
      </template>
    </HawkModalHeader>
    <HawkModalContent>
      <div>
        <div v-if="form_data.field_mapping?.length" class="grid grid-cols-12 mb-3 items-center text-xs text-gray-500">
          <div class="col-span-5 mr-2">
            {{ $t('Form field') }}
          </div>
          <div class="flex col-span-3 mr-4">
            <IconHawkArrowRight class="text-gray-600 h-5 w-5 mr-2 invisible" />
            {{ $t('Operator') }}
          </div>
          <div class="col-span-4 mr-4">
            {{ $t('Asset meta data field') }}
          </div>
        </div>

        <Vueform
          v-model="form_data"
          :sync="true"
          :display-errors="false"
          :columns="{
            lg: {
              wrapper: 12,
              container: 12,
              label: 12,
            },
          }"
        >
          <div class="col-span-12">
            <ListElement
              :controls="{ add: true, remove: false, sort: false }"
              name="field_mapping"
              :add-text="`+ ${$t('Add')} ${$t('field')}`"
              :presets="['repeatable_list']"
              rules="required"
              :add-classes="{
                ListElement: {
                  add: ['!text-blue-600'],
                },
              }"
            >
              <template #default="{ index }">
                <ObjectElement
                  :name="index"
                >
                  <div class="col-span-12">
                    <div class="grid grid-cols-12 mb-4 items-center">
                      <div class="col-span-5 mr-2">
                        <FormFieldTree
                          :key="form_data.field_mapping[index]?.form_field || 0"
                          :data="field_options"
                          option_id="slug"
                          :tree_config="{
                            options: {
                              name: 'form_field',
                              placeholder: $t('Select form field'),
                              object: false,
                              search: true,
                              native: false,
                              canClear: false,
                              required: true,
                              canDeselect: false,
                            },
                            select_type: 'LEAF_PRIORITY',
                            select_view: 'SELECT',
                            show_expanded_search: true,
                          }"
                          @on-select="handleFormFieldSelect($event[0], index)"
                        />
                      </div>
                      <div class="flex items-center col-span-3 mr-4">
                        <IconHawkArrowRight class="text-gray-600 h-5 w-5 mr-2" />
                        <SelectElement
                          name="operator"
                          class="w-full"
                          :placeholder="$t('Select')"
                          :native="false"
                          :can-deselect="false"
                          :can-clear="false"
                          :required="true"
                          :items="getOperatorOptions(form_data.field_mapping[index]?.form_field)"
                        />
                      </div>
                      <div class="flex items-center col-span-4 mr-4">
                        <SelectElement
                          class="w-full"
                          name="asset_field"
                          :placeholder="$t('Select asset meta data')"
                          :native="false"
                          :search="true"
                          :object="true"
                          :can-deselect="false"
                          :can-clear="false"
                          :required="true"
                          :items="getAssetMetaDataOptions(form_data.field_mapping[index]?.form_field)"
                        />
                        <div
                          class="cursor-pointer"
                          @click="() => removeFieldMap(index)"
                        >
                          <IconHawkXClose class="text-gray-600 h-5 w-5 ml-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ObjectElement>
              </template>
            </ListElement>
          </div>
        </Vueform>
      </div>
    </HawkModalContent>

    <HawkModalFooter class="flex items-center justify-between">
      <template #left>
        <HawkButton
          color="gray"
          type="plain"
          @click="form_data.field_mapping = [{
            form_field: null,
            operator: null,
            asset_field: null,
          }]"
        >
          {{ $t('Clear mapping') }}
        </HawkButton>
      </template>
      <template #right>
        <div class="flex items-center">
          <HawkButton
            class="mr-3"
            type="outlined"
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton
            color="primary"
            :disabled="!has_field_mappings"
            @click="onSave"
          >
            {{ $t('Apply') }}
          </HawkButton>
        </div>
      </template>
    </HawkModalFooter>
  </HawkModalContainer>
</template>
