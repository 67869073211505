<script setup>
import { sortBy } from 'lodash-es';
import { Pane, Splitpanes } from 'splitpanes';
import { computed, ref, watch } from 'vue';

import { useRoute } from 'vue-router';
import { IconHawkChevronDown, IconHawkChevronLeft, IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import GroupsList from '~/terra/components/layers/groups-list.vue';
import { useTerraStore } from '../store/terra.store';

import 'splitpanes/dist/splitpanes.css';

const props = defineProps({
  terra_store_name: {
    type: String,
    default: 'terra',
  },
  side: {
    type: String,
    default: 'left',
  },
  type: {
    type: String,
    default: 'default',
  },
});
const emit = defineEmits(['featureTypeToggle', 'toggleEnlargedSidebar', 'active-project-change', 'project-toggled', 'group-toggled', 'containerChange', 'sidebarToggled']);
const route = useRoute();
const terra_store = useTerraStore(props.terra_store_name);
const common_store = useCommonStore();

const $services = inject('$services');
const edit_ftg = ref(false);
const expand_sidebar = ref(true);

const asset_name = computed(() => common_store.active_asset?.name || 'NA');
const container = computed(() => terra_store.container);
const filtered_data = computed(() => {
  const list = common_store.assets_map?.[route.params.asset_id]?.terra_list || [];
  const sorted_list = sortBy(list, item => item.name);
  return sorted_list.map((f) => {
    return {
      label: f.name,
      uid: f.uid,
      on_click: () => {
        if (f.uid !== container.value.uid)
          emit('containerChange', f);
      },
    };
  });
});

async function getData() {
  if (!common_store.assets_map?.[route.params.asset_id]?.terra_list) {
    const terraResponse = await $services.terra_view_service.getAll({
      query: {
        asset: route.params.asset_id,
      },
    });
    const terra = terraResponse.data;
    if (terra.length) {
      common_store.set_terra_therm_list_in_assets({
        asset_id: route.params.asset_id,
        data: terra,
        key: 'terra_list',
      });
    }
  }
}

watch(() => common_store.assets_map, (val) => {
  if (!val?.[route.params.asset_id]?.terra_list)
    getData();
}, { immediate: true });
</script>

<template>
  <div class="relative">
    <div class="h-[calc(100vh-64px)] transition-all duration-200 ease-in-out fixed z-2  pointer-events-auto w-screen bg-white" :class="[expand_sidebar ? (edit_ftg ? 'max-w-[330px]' : 'max-w-[290px]') : 'max-w-[44px]', side === 'right' ? 'right-0' : 'left-0']">
      <div
        class="terra-left-sidebar__expand-icon shadow-xl  z-1 top-[20px] rounded-[50%] absolute bg-white cursor-pointer p-1"
        :class="[side === 'right' ? '-left-[15px]' : '-right-[10px]']"
        @click="() => {
          expand_sidebar = !expand_sidebar;
          emit('sidebarToggled');
        }"
      >
        <IconHawkChevronRight v-if="(!expand_sidebar && side === 'left') || (side === 'right' && expand_sidebar)" />
        <IconHawkChevronLeft v-else />
      </div>

      <div
        v-if="expand_sidebar"
        class="flex h-full flex-col bg-white shadow-xl mt-px border-t"
      >
        <div class="p-4">
          <div v-if="container">
            <div class="text-[14px] text-gray-500 leading-4 mb-1">
              {{ asset_name }}
            </div>
            <HawkMenu
              :items="filtered_data"
              :has_bordered_trigger="false"
              class="w-full"
              :active_item="container.uid"
              additional_trigger_classes="!focus:ring-0 !active:ring-0 w-full"
              additional_item_classes="!w-[246px]"
            >
              <template #trigger>
                <div class="w-full py-1">
                  <div class="flex justify-between items-center">
                    <div class="text-[14px] font-medium">
                      <HawkText :content="container.name" length="32" />
                    </div>
                    <IconHawkChevronDown v-show="filtered_data.length" />
                  </div>
                </div>
              </template>
            </HawkMenu>
          </div>
        </div>
        <div class="border-t border-gray-200" />
        <Splitpanes class="default-theme overflow-hidden h-[calc(100vh-150px)]" horizontal>
          <Pane
            class="!bg-white"
            size="40"
            min-size="20"
            style="overflow: hidden"
          >
            <div class="h-full scrollbar">
              <GroupsList
                :side="side"
                :terra_store_name="terra_store_name"
                @project-toggled="$emit('projectToggled')"
                @group-toggled="$emit('groupToggled')"
              />
            </div>
          </Pane>
          <Pane
            class="!bg-white"
            size="60"
            min-size="30"
            style="overflow: auto"
          >
            <div class="h-full scrollbar">
              <FeatureTypes
                :terra_store_name="terra_store_name"
                @feature-type-toggle="$emit('featureTypeToggle')"
              />
            </div>
          </Pane>
        </Splitpanes>
      </div>
      <div v-else class="flex justify-center items-center w-full h-full -rotate-90 m-auto whitespace-nowrap font-medium">
        <span class="text-sm">{{ asset_name || "" }} </span>
        <span v-if="asset_name">
          <IconHawkChevronRight class="mx-2" />
        </span>
        <span v-if="container" class="text-sm">
          {{ container.name }}
        </span>
      </div>
    </div>
  </div>
</template>
