<script setup>
import { sortBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import TaskComment from '~/tasks/pages/task-details-new/task-comments.vue/comment/task-comment.vue';

const chat_store = useChatStore();
const auth_store = useAuthStore();
const project_management_store = useProjectManagementStore();
const { is_fetching_comments_data } = storeToRefs(chat_store);
const { active_task } = storeToRefs(project_management_store);
const user = auth_store?.logged_in_user_details?.user_id;

const state = reactive({
  is_comment_input_visible: false,
});

async function setupCommentsStream() {
  await chat_store.set_data(`pm-${active_task.value.uid}`, {
    name: active_task.value.name,
    members: [user],
  });

  chat_store.chat_channel.on('message.new', setTimeout(() => {
    const element = document.getElementById('comments');
    if (element)
      element.scrollTop = element.scrollHeight;
  }, 20));
}

async function sendComment({ text, mentions }) {
  await chat_store.sendMessage({
    text,
    service: 'pm',
    mentioned_user_list: mentions,
  });
  state.is_comment_input_visible = false;
}

const comments = computed(() => {
  return sortBy(
    [...(chat_store?.comments || [])],
    d => (d?.createdAt ?? d?.created_at?.toISOString()),
  );
});

onMounted(setupCommentsStream);
</script>

<template>
  <div class="flex h-[calc(100%-55px)] w-full px-4 relative">
    <div v-if="is_fetching_comments_data" class="flex w-full flex-row items-center justify-center h-full">
      <hawk-loader />
    </div>
    <div v-else class="h-full w-full">
      <div id="comments" class="scrollbar" :class="state.is_comment_input_visible ? 'h-[calc(100%-110px)]' : 'h-[calc(100%-30px)]'">
        <div v-for="item in comments" :key="item">
          <TaskComment :comment="item" task_uid="item.id" module="pm" />
        </div>
      </div>
      <div v-if="state.is_comment_input_visible" class="absolute !bottom-0 left-4 right-4">
        <HawkCommentInput
          :autofocus="true"
          :disabled_controls="['attach']"
          class="bg-white w-full max-h-[250px]"
          @send="sendComment"
        />
      </div>
      <div
        v-else
        class="absolute bottom-2 text-sm font-semibold flex cursor-pointer hover:bg-gray-100 h-fit w-fit p-2 rounded-lg"
        @click="state.is_comment_input_visible = true"
      >
        <IconHawkPlus class="mr-1" />
        {{ $t('New comment') }}
      </div>
    </div>
  </div>
</template>
