<script setup>
import { storeToRefs } from 'pinia';
import { VirTree } from 'vue-virtual-tree';
import { IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { getRandomKey } from '~/common/utils/common.utils';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  tree_items: {
    type: Array,
    default: () => [],
  },
  context_menu: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['selectMenuItem']);

const { route, router } = useCommonImports();

const system_model_store = useSystemModelStore();
const { active_instance, active_component } = storeToRefs(system_model_store);

const default_expanded = ref([]);
const virtual_tree = ref(null);
const tree_data = ref([]);
const active_item_key = ref(null);

onBeforeMount(() => {
  if (!active_instance.value) {
    tree_data.value = modifyComponentTree(props.tree_items); // modified tree
    default_expanded.value = getAllUids(tree_data.value, active_component.value.uid); // expand all by default
  }
  else {
    tree_data.value = buildTree(props.tree_items); // it has flat data
    default_expanded.value = findParentIds(tree_data.value, active_instance.value.uid);
  }
});

onMounted(() => {
  setActiveItemCss();
});

async function loadData(node, callback) {
  const uid = node.key.split('-')[0];
  const children = await system_model_store.set_instance_children({
    instance_id: uid,
  });
  const result = children.map(item => ({ ...item, ...{ nodeKey: randomNodeKey(item.uid), hasChildren: item.has_children } }));
  setTimeout(() => {
    callback(result);
  }, 500);
}

function randomNodeKey(id) {
  return `${id}-${getRandomKey()}`;
}

function buildTree(flatData, parentId = null) {
  const tree = [];
  for (const item of flatData) {
    if (item.parent === parentId) {
      item.nodeKey = randomNodeKey(item.uid);
      item.hasChildren = item.has_children;
      const children = buildTree(flatData, item.uid);
      if (children.length > 0) {
        const unique_child = Array.from(new Set(children.map(item => item.uid)))
          .map(uid => children.find(item => item.uid === uid));
        item.children = unique_child;
      }
      tree.push(item);
    }
  }
  return tree;
}

function getAllUids(nestedData, activeId) {
  const tree_data = [...nestedData];
  const uids = [];
  while (tree_data.length > 0) {
    const item = tree_data.pop();
    if (item.uid === activeId)
      active_item_key.value = item.nodeKey;
    uids.push(item.nodeKey);
    if (item.hasChildren)
      tree_data.push(...item.children);
  }
  return uids;
}

function findParentIds(nestedData, id, parentIds = []) {
  for (const item of nestedData) {
    if (item.uid === id) {
      active_item_key.value = item.nodeKey;
      return parentIds;
    }
    if (item.children) {
      const result = findParentIds(item.children, id, [...parentIds, item.nodeKey]);
      if (result)
        return result;
    }
  }
  return null;
}

function modifyComponentTree(components, parent = null) {
  return components.map((item) => {
    const new_item = { ...item };
    new_item.hasChildren = new_item.children && new_item.children.length > 0;
    new_item.parent = parent;
    new_item.nodeKey = randomNodeKey(item.uid);
    if (new_item.hasChildren)
      new_item.children = modifyComponentTree(new_item.children, item.uid);
    return new_item;
  });
}

function findDataById(data, id) {
  for (const item of data) {
    if (item.uid === id)
      return item;
    if (item.children) {
      const foundItem = findDataById(item.children, id);
      if (foundItem)
        return foundItem;
    }
  }
  return null;
}

function setActiveItemCss() {
  setTimeout(() => {
    const prev_selected_node = document.querySelector('.vir-tree-node.active-node');
    if (prev_selected_node)
      prev_selected_node.classList.remove('active-node');

    const selected_item = document.querySelector('.selected_branch')?.parentElement?.parentElement;
    if (selected_item)
      selected_item.classList.add('active-node');
  }, 50);
}

function handleItemEvent(node) {
  active_item_key.value = node.key;
  const item_uid = node.key.split('-')[0];
  // component
  if (!active_instance.value) {
    const selected_item = findDataById(tree_data.value, item_uid);
    if (selected_item) {
      system_model_store.set_active_component(selected_item);
      router.replace({ query: { ...route.query, component: selected_item.uid } });
    }
  }
  else {
    // instance
    const instance_list = system_model_store.active_instance_hierarchy;
    const selected_item = instance_list.find(item => item.uid === item_uid);
    if (selected_item) {
      const component = system_model_store.templates_components_map[selected_item.component];
      system_model_store.set_active_component(component);
      system_model_store.set_active_instance(selected_item);
      router.replace({ query: { ...route.query, component: component.uid, instance: selected_item.uid } });
    }
  }
  setActiveItemCss();
}

function toggleExpanded(params) {
  if (params.state)
    setActiveItemCss();
}

function getContextMenu(node) {
  if (!node?.parentKeys?.length)
    return props.context_menu?.length && props.context_menu.filter(item => item.action !== 'detach');
  return props.context_menu;
}
</script>

<template>
  <VirTree
    ref="virtual_tree"
    :source="tree_data"
    :default-expanded-keys="default_expanded"
    :load-data="active_instance ? loadData : undefined"
    :default-selected-key="active_item_key"
    :virtual="{ size: 35 }"
    @expand-change="toggleExpanded"
  >
    <template #icon="{ loading }">
      <div class="text-gray-500 custom-icons flex items-center justify-center">
        <hawk-loader v-if="loading" container_class="" :height="4" :width="4" />
        <template v-else>
          <IconHawkChevronRight class="text-base" />
        </template>
      </div>
    </template>
    <template #node="{ node }">
      <div
        class="flex justify-between group"
        :class="[node.key === active_item_key ? 'selected_branch' : null]"
        :data-key="node.key"
      >
        <div
          v-tippy="node?.name?.length > 15 ? node.name : ''"
          class="flex-1 text-sm font-medium item-title"
          @click="handleItemEvent(node)"
        >
          {{ node.name }}
        </div>
        <div v-if="context_menu.length" class="h-5">
          <hawk-menu
            :items="getContextMenu(node)"
            position="fixed"
            additional_trigger_classes="!ring-0 !border-0"
            class="h-5 tree-item-context-menu items-center flex invisible group-hover:visible"
            @select="emit('selectMenuItem', $event, node)"
          />
        </div>
      </div>
    </template>
  </VirTree>
</template>

<style lang="scss">
@import url("vue-virtual-tree/style.css");
</style>

<style scoped lang="scss">
:deep(.vir-tree-wrap) {
  @apply scrollbar overflow-x-auto;
}
:deep(.vir-tree-node) {
  @apply py-2 px-4 mb-1 flex items-center hover:bg-gray-100 hover:text-gray-700 text-gray-700 rounded-lg w-max;
  .node-content {
    @apply w-full;
  }
  .node-arrow {
    min-width: 25px;
  }
  &.active-node {
    background-color: #344054;
    .item-title {
      color: white;
    }
    .custom-icons {
      svg {
        path{
          stroke: white;
        }
      }
    }
    button svg {
      color: white;
    }
  }
}
:deep(.node-title) {
  color: unset;
}
</style>
