<script setup>
import { groupBy, map, omit, sortBy, uniq, uniqBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { IconHawkDotsVertical } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import useAbortController from '~/common/composables/abort-controller.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import useEmitter from '~/common/composables/useEmitter';
import { csvInjectionProtector, sanitizeAoA, treeToList } from '~/common/utils/common.utils';
import SmTemplateDuplicateForm from '~/system-model/components/forms/sm-template-duplicate-form.vue.vue';
import SmTemplateForm from '~/system-model/components/forms/sm-template-form.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  template: {
    type: Object,
    default: null,
  },
  additional_trigger_classes: {
    type: String,
    default: '',
  },
});
const $t = inject('$t');
const $services = inject('$services');

const emitter = useEmitter();
const { route, router } = useCommonImports();
const system_model_store = useSystemModelStore();
const controllers = useAbortController();

const state = reactive({
  is_exporting_template: false,
});

const template_modal = useModal({
  component: SmTemplateForm,
});
const template_duplicate_modal = useModal({
  component: SmTemplateDuplicateForm,
});

const template_delete_modal = useModal({
  component: HawkDeletePopup,
});

function openDeleteTemplateModal(template_uid) {
  template_delete_modal.patchOptions({
    attrs: {
      onClose() {
        template_delete_modal.close();
      },
      header: $t('Delete Template'),
      content: 'Are you sure you want to delete?',
      confirm: async () => {
        try {
          await system_model_store.delete_template({
            uid: template_uid,
          });
          if (route.name === 'sm-template-details') {
            router.push({
              name: 'sm-templates',
              params: {
                asset_id: route.params.asset_id,
              },
            });
          }

          template_delete_modal.close();
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  });
  template_delete_modal.open();
}

function duplicateTemplate(template_uid) {
  template_duplicate_modal.patchOptions({
    attrs: {
      onClose() {
        template_duplicate_modal.close();
      },
      template_uid,
    },
  });
  template_duplicate_modal.open();
}

async function openEditTemplateModal(template) {
  template_modal.patchOptions({
    attrs: {
      template,
      onClose() {
        template_modal.close();
      },
      onClosed: () => {
        const attrs = omit(template_modal.options.attrs, ['template']); // reset attrs
        template_modal.options.attrs = attrs;
      },
    },
  });
  template_modal.open();
}

function getMenuItems(template) {
  const menu = [
    { label: $t('Duplicate'), on_click: () => duplicateTemplate(template.uid) },
    { label: $t('Rename'), on_click: () => openEditTemplateModal(template) },
  ];

  if (template?.default) {
    menu.push({
      label: $t('Sync changes'),
      on_click: () => {
        emitter.emit('template_version_syncing', { template_uid: template.uid, is_sync: true });
      },
    });
    if (system_model_store.templates.length === 1)
      menu.push({ label: $t('Delete'), on_click: () => openDeleteTemplateModal(template.uid) });
  }
  else {
    menu.push({
      label: $t('Mark as current version'),
      on_click: () => {
        emitter.emit('template_version_syncing', { template_uid: template.uid });
      },
    }, { label: $t('Delete'), on_click: () => openDeleteTemplateModal(template.uid) });
  }
  if (route.name === 'sm-template-details' && !route?.query?.instance) {
    menu.splice(-1, 0, {
      label: $t('Export'),
      on_click: () => {
        state.is_exporting_template = true;
      },
    });
  }

  return menu;
}
function modifyFieldValue(data) {
  if (!data.length || !data[0]?.pre_signed_url)
    return data.join(', ');
  if (data?.length === 1 && data[0]?.pre_signed_url) {
    const { file_name, pre_signed_url } = data[0];
    return {
      text: file_name,
      hyperlink: pre_signed_url,
    };
  }
  return (data || []).map(file => file.file_name).join(', ');
}

function isUrl(url) {
  const url_regex = /^(https?|ftp):\/\/[^\s/$.?#].\S*$/i;
  return url_regex.test(url);
}

function getFieldValues(data) {
  const result = {};
  for (const obj of data) {
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        result[key] = result[key] ? [...result[key], ...value] : [...value];
        result[key] = modifyFieldValue(result[key]);
      }
      else {
        result[key] = isUrl(value)
          ? {
              text: value,
              hyperlink: value,
              tooltip: value,
            }
          : value;
      }
    }
  }
  return result;
}

function getAllFieldsValue(data, uniq_fields) {
  return data.reduce((fields, item) => {
    const field_values = getFieldValues(item.field_values);
    uniq_fields.forEach((field) => {
      fields[item.uid] = {
        ...(fields?.[item?.uid] || {}),
        [field]: field_values[field] || '-',
      };
    });
    return fields;
  }, {});
}

function getCategoryFieldNames(categories) {
  return categories.reduce((fields, uid) => {
    const field_names = system_model_store.categories_map[uid].fields?.map(field => field.name);
    return [...fields, ...field_names];
  }, []);
}

function getSelfRecordValues(components) {
  return components.reduce((records, item) => {
    if (item?.fields?.length) {
      const fields = item.fields.filter(field => field.record_self_values).map(field => field.name);
      records = [...records, ...fields];
    }
    return [...new Set(records)];
  }, []);
}

async function exportTemplate() {
  controllers.add('export_template');
  let sidebar_hierarchy = system_model_store?.sidebar_hierarchy;
  if (system_model_store.is_hierarchy_dirty) {
    const response = await $services.sm_components.getAll({
      attribute: `template/${route.params.template_id}/paths`,
      query: {
        'include[]': 'fields.*',
        'invalidate': true,
      },
    });
    sidebar_hierarchy = response?.data?.components || [];
    system_model_store.is_hierarchy_dirty = false;
  }

  const template_details = system_model_store.get_template_details(route.params.template_id);
  const flat_component_list = uniqBy(sortBy(treeToList(sidebar_hierarchy), obj => obj?.type?.toLowerCase()), 'name');
  const self_record_values = getSelfRecordValues(flat_component_list);
  const component_group_by_category = groupBy(flat_component_list, 'type');
  const categories_uid = uniq(map(flat_component_list, 'category'));
  const category_fields = getCategoryFieldNames(categories_uid);
  const uniq_fields = [...new Set(sortBy([...category_fields, ...self_record_values], name => name.toLowerCase()))];
  const fields_values = getAllFieldsValue(flat_component_list, uniq_fields);

  const ExcelJS = await import('exceljs');
  const { saveAs } = await import('file-saver');

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Components');

  const sanitized_fields = sanitizeAoA([uniq_fields || []]);
  const sheet_data = ['Component', 'Count', ...(sanitized_fields?.[0] || [])];
  worksheet.addRow(sheet_data);
  const first_row = worksheet.getRow(1);
  first_row.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b5394' },
    };
    cell.font = {
      color: { argb: 'FFFFFF' },
      bold: true,
    };
  });

  for (const [category, components] of Object.entries(component_group_by_category)) {
    const cat_row = worksheet.addRow([csvInjectionProtector(category)]);
    const current_row_idx = worksheet.rowCount;
    const end_column_idx = worksheet.columnCount;

    worksheet.mergeCells(current_row_idx, 1, current_row_idx, end_column_idx);
    cat_row.eachCell((cell) => {
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'bdbdbd' },
        },
        font: {
          bold: true,
        },
      };
    });
    const sorted_component = sortBy(components, obj => obj.name.toLowerCase());
    sorted_component.forEach((item) => {
      const instance_count = system_model_store.templates_components_map?.[item?.uid]?.instanceCount || '-';
      const values = [item.name, instance_count];
      if (fields_values[item?.uid])
        values.push(...Object.values(fields_values[item?.uid]));
      worksheet.addRow(...sanitizeAoA([values]));
    });
  }
  if (state.is_exporting_template) {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${template_details.name}.xlsx`);
  }
}
function cancelTemplateExport() {
  state.is_exporting_template = false;
  controllers.abort('export_template');
}
</script>

<template>
  <HawkExportToast
    v-if="state.is_exporting_template"
    :submit="exportTemplate"
    progress_text="Exporting Template"
    completed_text="Exported"
    @close="state.is_exporting_template = false"
    @cancel="cancelTemplateExport"
  />
  <hawk-menu
    class="flex justify-end w-full"
    position="fixed"
    :additional_trigger_classes="additional_trigger_classes"
    :items="getMenuItems(props.template)"
  >
    <template #trigger>
      <slot name="trigger_button">
        <IconHawkDotsVertical class="text-gray-600 h-5" />
      </slot>
    </template>
  </hawk-menu>
</template>
