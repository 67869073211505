<!-- eslint-disable vue/prop-name-casing -->
<script>
import { FlexRender } from '@tanstack/vue-table';
import { useModal } from 'vue-final-modal';
// convert this file to VUE 3 (composition API)
import { IconHawkArrowDown, IconHawkArrowUp, IconHawkCheck, IconHawkDragTableIcon, IconHawkEditTwo, IconHawkPinnedOne, IconHawkPinTwoInactive } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const draggable = defineAsyncComponent(() => import('vuedraggable'));

export default {
  components: {
    FlexRender,
    Draggable: draggable,
  },
  props: {
    table: {
      type: Object,
    },
    disable_resize: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Object,
    },
    table_sort: {
      type: Array,
      default: () => [],
    },
    table_col_order: {
      type: Array,
    },
    frozen_col_id: {
      type: String,
    },
    non_sortable_columns: {
      type: Array,
    },
    dragging_column: {
      type: Boolean,
    },
    show_menu_header: {
      type: Boolean,
    },
    enable_dnd: {
      type: Boolean,
      default: false,
    },
    is_resizing: {
      type: Boolean,
      default: false,
    },
    show_column_borders: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
    },
    update_view: {
      type: Function,
    },
    has_columns_menu: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { $t: translate } = useCommonImports();
    return {
      translate,
    };
  },
  data() {
    return {
      form: { all: false },
    };
  },
  computed: {
    is_column_freezed() {
      if (this.header.id.split('_').length > 0) {
        const freezed_col_split = this.frozen_col_id.split('_');
        const header_id_split = this.header.id.split('_').slice(-1 * freezed_col_split.length);
        return header_id_split.filter((val, i) => freezed_col_split[i] !== val).length === 0;
      }
      return false;
    },
    get_parent() {
      if (this.enable_dnd)
        return this.$parent.$parent;
      else
        return this.$parent;
    },
    selected_status() {
      const checked = Object.keys(this.get_parent.selected_rows).filter(col_id => this.get_parent.selected_rows[col_id]);
      let all_rows = this.table.getRowModel().rows;
      if ((!this.get_parent.manual_pagination || this.get_parent.enable_infinite_scroll) && this.get_parent.pagination_config)
        all_rows = all_rows.slice(this.get_parent.enable_infinite_scroll ? 0 : this.get_parent.pagination_state.pageIndex * this.get_parent.pagination_state.pageSize, (this.get_parent.pagination_state.pageIndex + 1) * this.get_parent.pagination_state.pageSize);
      const all_selected = all_rows.every(row => checked.includes(row.id));
      if (all_selected && all_rows.length > 0)
        return 'checked';
      return checked.length !== 0 ? 'intermediate' : 'unchecked';
    },
    disable_toggle_visibility() {
      return this.get_parent.get_visible_columns.filter(col_id => this.table.getColumn(col_id).columnDef.header !== '').length === 1;
    },
    is_checkbox_disabled() {
      return this.table.getRowModel().rows.length === 0;
    },
    context_menu_items() {
      return [
        {
          label: this.translate('Columns'),
          uid: 'columns',
          on_click: () => {
            this.handleColumnsConfiguration();
          },
        },
        ...(this.get_parent.header_context_menu_config?.additional_items || []),
      ];
    },
  },
  watch: {
    is_resizing: {
      handler(value) {
        this.get_parent.draggingColumn(value);
        const header_id = this.get_parent.getColumnById[this.header.column.id]?.id;
        if (header_id && !this.header.column.getIsResizing()) {
          const result = {};
          const current_header = {};
          const recursiveFunc = (array, val) => {
            array.forEach((item) => {
              const { id, columns } = item;
              val[id] = { size: item.getSize(), id };
              if (columns && columns.length > 0) {
                val[id].children = {};
                recursiveFunc(columns, val[id].children);
              }
            });
          };
          recursiveFunc(this.get_parent.table.getAllColumns(), result);
          recursiveFunc([this.get_parent.table.getColumn(header_id)], current_header);
          this.get_parent.$emit('columnResized', current_header[header_id], result);
          this.updateViewConfiguration(result);
          logger.log('columnResized', current_header[header_id], result);
        }
      },
      deep: true,
    },
  },
  methods: {
    toggleAll(e) {
      if (this.selected_status === 'checked') {
        this.get_parent.selected_rows = {};
        this.get_parent.$emit('selectRow', []);
      }
      else { this.table.getToggleAllRowsSelectedHandler()?.(e); }
    },
    updateViewConfiguration(columns_widths = {}) {
      if (this.get_parent.is_custom_view_enabled) {
        const visible_column_data = this.table.getAllColumns().filter(column => column.getIsVisible()).map(column => ({ id: column.id, size: columns_widths?.[column.id]?.size }));
        this.update_view({ columns: visible_column_data });
      }
    },
    handleColumnsConfiguration() {
      const columns_configuration = this.table_col_order.reduce((acc, id) => {
        if (['select', 'context_menu'].includes(id))
          return acc;
        const column = this.table.getColumn(id);
        const column_data = { name: column.columnDef.header, uid: id };
        if (column_data.uid === this.frozen_col_id)
          column_data.pin = true;
        if (column.columnDef.header) {
          if (column.getIsVisible())
            acc.selected_items.push(column_data);
          acc.items.push(column_data);
        }
        return acc;
      }, { items: [], selected_items: [] });
      const configure_columns_popup = useModal({
        component: HawkFieldsSelector,
        attrs: {
          min_selected_items: 1,
          ...columns_configuration,
          onClose() {
            configure_columns_popup.close();
          },
          texts: {
            heading: this.translate('Customize columns'),
            left_heading: this.translate('Hidden Columns'),
            right_heading: this.translate('Visible Columns'),
          },
          has_field_pin_option: true,
          close_on_save: true,
          update: (data) => {
            if (this.get_parent.is_custom_view_enabled)
              this.update_view({ columns: data.map(column => ({ id: column.uid })) });
            else
              this.get_parent.$emit('updateColumnsConfiguration', data);
          },
        },
      });
      configure_columns_popup.open();
    },
  },
};
</script>

<template>
  <th
    :style="{
      minWidth: `${header.getSize()}px`,
      position: 'relative',
      ...((is_column_freezed) && { position: 'sticky', left: 0, zIndex: 1, ...(get_parent.scroll_x >= 40 && { filter: 'drop-shadow(16px 0 16px rgb(0 0 0 / 0.05))' }) }),
      ...((header.id === 'select' || header.id === 'context_menu') && { minWidth: '40px', maxWidth: '40px', overflow: 'hidden' }),
      ...((get_parent.getColumnById[header.id]?.customStyle || {})),
      ...get_parent.header_styles,
      ...((get_parent.header_context_menu_config?.is_menu_fixed && header.id === 'context_menu' && !get_parent.dragging_column) && { position: 'sticky', right: 0, zIndex: 1 }),
    }"
    class="font-medium py-3 text-left border-gray-200 text-xs text-gray-800 bg-gray-50 first:border-l last:border-r"
    :class="[
      header.column.getCanSort()
        ? 'cursor-pointer select-none'
        : '', header.id === 'select' ? 'px-0' : 'px-6', enable_dnd && !header?.column?.columnDef?.disable_dnd ? 'is_draggable' : '', get_parent.getColumnById[header.id]?.customClass || '',
      (show_column_borders || get_parent.header_grid_lines?.vertical) && header.subHeaders.length >= 2 ? 'border-x' : '',
      (show_column_borders || get_parent.header_grid_lines?.vertical) && header.subHeaders.length === 0 ? 'border-r' : '',
      (!get_parent.header_grid_lines?.horizontal ? '!border-y-0' : 'border-y'),
    ]
    "
    :colSpan="header.colSpan"
    scope="col"
    @click="!non_sortable_columns.includes(header.id) && !dragging_column ? header.column.getToggleSortingHandler()?.($event) : null"
  >
    <div v-if="header.id === 'select'">
      <div class="flex h-5 items-center justify-center">
        <Vueform>
          <v-tristate-checkbox
            :key="selected_status"
            :disabled="is_checkbox_disabled"
            :add-classes="{
              ElementLayout: {
                container: 'absolute left-[35%] -my-2.5',
              },
            }"
            :options="{ initial_state: selected_status }"
            @input="toggleAll"
          />
        </Vueform>
      </div>
    </div>
    <template v-else-if="show_menu_header && header.id === 'context_menu'">
      <HawkMenu
        :items="context_menu_items"
        additional_trigger_classes="!ring-0 !focus:ring-0 !border-0 !-ml-5 !w-14 !text-lg"
        additional_dropdown_classes="w-56 max-h-80 scrollbar" position="fixed"
        @open="$emit('dropdownOpened')"
        @close="$emit('dropdownClosed')"
      >
        <template v-if="!get_parent.header_context_menu_config?.column_settings" #content>
          <slot name="context-menu-header" />
          <Draggable
            :list="table_col_order" tag="div" draggable=".is_draggable"
            handle=".move"
            class="p-1"
            @start="get_parent.$emit('colDragStart', $event)"
            @end="get_parent.updateColumnOrder($event, true)"
            @move="get_parent.$emit('colMoving', $event)"
          >
            <template #item="{ element: col_id }">
              <div
                v-if="table.getColumn(col_id).columnDef.header !== ''"
                class="group flex items-center justify-between pl-[10px] pr-[10px] pt-[10px] pb-[8px] text-sm cursor-pointer hawk_table_menu_item rounded-lg   hover:bg-gray-50"
                :class="[(frozen_col_id !== col_id) ? 'is_draggable' : '']"
                @click.stop="(!disable_toggle_visibility || !table.getColumn(col_id).getIsVisible()) && table.getColumn(col_id).toggleVisibility() || updateViewConfiguration()"
              >
                <div class="flex items-center text-sm">
                  <div>
                    <IconHawkDragTableIcon :class="{ 'opacity-0 pointer-events-none': frozen_col_id === col_id }" class="cursor-move flex move pr-1 invisible group-hover:visible w-4 h-4" />
                  </div>
                  <div class="flex break-all">
                    {{ table.getColumn(col_id).columnDef.header }}
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="pl-2  text-base pin" :class="[frozen_col_id === col_id ? 'visible' : 'invisible  group-hover:visible']" @click.stop="get_parent.pinColumn(col_id)">
                    <IconHawkPinnedOne v-if="frozen_col_id === col_id" class="w-4 h-4" />

                    <IconHawkPinTwoInactive v-else class="w-4 h-4" />
                  </div>

                  <IconHawkEditTwo v-if="table.getColumn(col_id).columnDef?.column_field_edit" class=" ml-2 w-[14px] h-[14px] invisible  group-hover:visible" @click.stop="get_parent.$emit('columnFieldEditClick', col_id)" />
                  <div v-show="table.getColumn(col_id).getIsVisible()" class="pl-2">
                    <IconHawkCheck class="text-blue-600 w-[14px] h-[14px]" />
                  </div>
                </div>
              </div>
              <div v-else />
            </template>
          </Draggable>
        </template>
      </HawkMenu>
    </template>
    <div :class="[index === 0 && show_column_borders ? 'flex justify-center' : '']">
      <div class="flex items-center group" :class="{ 'justify-center': header?.column?.columnDef?.is_header_center }">
        <slot :name="`${header.id}Header`">
          <FlexRender
            v-if="!header.isPlaceholder && !header.id !== 'select'"
            :render="get_parent.getColumnById[header.id]?.header || header.column.columnDef.header"
            :props="header.getContext()"
          />
        </slot>

        <span v-if="!header.isPlaceholder && header.id !== 'select'" class="ml-1">
          <IconHawkArrowUp v-if="table_sort[0]?.id === header.id && table_sort[0]?.desc" class="text-gray-800" />
          <IconHawkArrowDown v-else-if="table_sort[0]?.id === header.id && !table_sort[0]?.desc" class="text-gray-800" />
        </span>
        <IconHawkDragTableIcon v-if="enable_dnd && !header.isPlaceholder && !header?.column?.columnDef?.disable_dnd && header.column.columnDef.header?.length" class="cursor-move handle invisible group-hover:visible text-sm text-gray-400 w-4 h-4 ml-auto" />
      </div>
    </div>
    <div
      v-if="!(header.id === 'select' || header.id === 'context_menu') && !disable_resize"
      class="resizer"
      :class="{ isResizing: header.column.getIsResizing() }"
      @click.stop=""
      @mousedown.stop=" header.getResizeHandler()?.($event)"
    />
  </th>
</template>
