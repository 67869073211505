<script setup>
import { entries } from 'lodash-es';
import { onMounted } from 'vue';
import { IconHawkSearchMd } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  data: {
    type: Array,
  },
});

const $t = inject('$t');

const table_data = ref([]);
const search = ref('');
const is_loading = ref(false);

const columns = computed(() => ([
  {
    accessorKey: 'key',
    header: $t('Index'),
    id: 'key',
  },
  {
    accessorKey: 'value',
    header: $t('Serial Num'),
    id: 'value',
  },
]));
const get_table_data = computed(() => {
  if (search.value.length) {
    return table_data.value.filter((val) => {
      const filter = search.value.toLowerCase();
      return val.key.toLowerCase().includes(filter) || val.value.toLowerCase().includes(filter);
    });
  }
  return table_data.value;
});

onMounted(() => {
  is_loading.value = true;
  if (typeof props.data == 'string')
    table_data.value = JSON.parse(props.data);
  table_data.value = entries(props.data).map((obj) => {
    return { key: obj[0], value: obj[1] };
  });
  is_loading.value = false;
});
</script>

<template>
  <hawk-modal-container width="800" content_class="w-[800px]">
    <hawk-modal-content class="!p-0 min-h-[400px]" :is_scroll="false">
      <div class="flex h-14 items-center mx-6">
        <IconHawkSearchMd class="h-5 w-5 text-gray-600 mr-2" aria-hidden="true" />
        <input
          v-model="search"
          type="text"
          class="w-full placeholder-gray-500 text-base font-light"
          :placeholder="$t('Search this table')"
        >
      </div>
      <hr>
      <div class="scrollbar max-h-[calc(100vh-220px)] my-4 px-6">
        <HawkTable
          :key="get_table_data.length"
          is_gapless
          :pagination_config="{
            totalRows: get_table_data.length,
            pageSize: 25,
          }"
          :show_menu_header="false"
          :data="get_table_data"
          :columns="columns"
        />
      </div>
    </hawk-modal-content>
  </hawk-modal-container>
</template>
