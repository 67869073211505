<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { IconHawkInfoCircle, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  _t: {},
  save_view: {
    type: Function,
    default: null,
  },
  texts: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['onClose']);

const _texts = computed(() => {
  return {
    title: props._t('Changes detected'),
    content: props._t('The view has changed. Do you want to save this view for all users?'),
    save_button: props._t('Save'),
    dismiss_button: props._t('Dismiss'),
    ...(props.texts || {}),
  };
});

const state = reactive({
  loading: false,
});

async function save() {
  try {
    if (props.save_view) {
      state.loading = true;
      await props.save_view();
      state.loading = false;
      emit('onClose');
    }
  }
  catch (err) {
    state.loading = false;
  }
}
</script>

<template>
  <div class="p-4 w-[400px] shadow-lg border rounded-lg relative text-gray-900">
    <div class="absolute right-2 top-2">
      <HawkButton icon type="text" class="flex-shrink-0" @click="emit('onClose')">
        <IconHawkXClose />
      </HawkButton>
    </div>
    <div class="flex gap-4">
      <div>
        <HawkFeaturedIcon theme="light-circle-outline" color="warning">
          <IconHawkInfoCircle />
        </HawkFeaturedIcon>
      </div>
      <div class="mr-10">
        <div class="mb-1 text-sm font-semibold">
          {{ _texts.title }}
        </div>
        <div class="mb-4 text-sm text-gray-600">
          {{ _texts.content }}
        </div>
        <div class="flex gap-3">
          <HawkButton type="link" color="gray" class="font-semibold !h-auto text-gray-600 hover:text-gray-800" @click="emit('onClose')">
            {{ _texts.dismiss_button }}
          </HawkButton>
          <HawkButton type="link" class="font-semibold !h-auto" :loading="state.loading" @click="save">
            {{ _texts.save_button }}
          </HawkButton>
        </div>
      </div>
    </div>
  </div>
</template>
