<script setup>
import { IconHawkAlertTriangle, IconHawkFileMinusTwo, IconHawkInfoCircle } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  disabled: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['click']);
</script>

<template>
  <div>
    <div class="flex ">
      <div>
        <div v-if="disabled" class="mr-4 text-warning-600 p-3 bg-warning-100 rounded-full">
          <IconHawkAlertTriangle />
        </div>
        <div v-else class="mr-4 text-primary-600 p-3 bg-primary-100 rounded-full">
          <IconHawkInfoCircle />
        </div>
      </div>
      <div>
        <div class="text-gray-900 text-md font-semibold">
          <slot name="title" />
        </div>
        <div class="text-xs text-gray-600">
          <slot name="subtitle" />
        </div>
        <slot name="info" />
      </div>
    </div>
    <HawkButton :disabled="disabled" class="mt-4" type="outlined" block @click="emit('click')">
      <IconHawkFileMinusTwo />
      <slot name="cta_title" />
    </HawkButton>
  </div>
</template>
