<script setup>
import { useModal, useModalSlot } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import { IconHawkMinus, IconHawkPlus, IconHawkRefreshCcwOne } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { load_js_css_file } from '~/common/utils/load-script.util';
import BlockDeletePopup from '~/forms/components/form-workflows/delete-block-popup.vue';
import FormStepBuilderPopup from '~/forms/components/form-workflows/form-step-builder-popup.vue';
import SupportedBlocksPopup from '~/forms/components/form-workflows/supported-blocks-popup.vue';
import { AddButtonNodeShape, button_markups, EndNodeShape, LinkLabelShape, LinkShape, NodeShape, StartNodeShape } from '~/forms/composables/form-workflow-shapes.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  form: {
    type: Object,
  },
});
const emit = defineEmits(['showLoader', 'hideLoader']);

const route = useRoute();
const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();
const $t = inject('$t');
const $toast = inject('$toast');

const add_blocks_popup = useModal({
  component: SupportedBlocksPopup,
  attrs: {
    onClose() {
      add_blocks_popup.close();
    },
  },
});

const form_builder_popup = useModal({
  component: FormStepBuilderPopup,
  attrs: {
    onCancel() {
      form_builder_popup.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    can_delete: true,
    onClose() {
      delete_popup.close();
    },
  },
});

const canvas = ref(null);
const tooltip_info = ref(null);
const zoom_tools = computed(() => {
  return [
    {
      tooltip: $t('Zoom in'),
      icon: IconHawkPlus,
      event: 'zoom-in',

      action: () => form_workflow_store.panzoom.zoom(0.4),
    },
    {
      tooltip: $t('Zoom out'),
      icon: IconHawkMinus,
      event: 'zoom-out',

      action: () => form_workflow_store.panzoom.zoom(-0.2, { min: 0.2 }),
    },
    {
      tooltip: $t('Reset'),
      icon: IconHawkRefreshCcwOne,
      event: 'fit-to-content',

      action: () => form_workflow_store.panzoom.zoomToFit({
        useModelGeometry: true,
        padding: 200,
      }),
    },
  ];
});

async function load_styles() {
  // Without this jointjs wont work
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/lodash@4.17.21/lodash.min.js',
    'lodash',
    'js',
  );
  //
  await load_js_css_file(
    'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.js',
    'jquery',
    'js',
  );
  await load_js_css_file(
    'https://cdnjs.cloudflare.com/ajax/libs/backbone.js/1.4.1/backbone.js',
    'backbone',
    'js',
  );
  await load_js_css_file(
    'https://dagrejs.github.io/project/dagre/latest/dagre.min.js',
    'dagre',
    'js',
  );
  await load_js_css_file(
    'https://dagrejs.github.io/project/graphlib/latest/graphlib.min.js',
    'graphlib',
    'js',
  );

  await load_js_css_file(
    'https://unpkg.com/panzoom@9.4.0/dist/panzoom.min.js',
    'panzoom',
    'js',
  );

  await load_js_css_file(
    'https://cdn.jsdelivr.net/gh/sensehawk/cdn/jointJs+/rappid.js',
    'joint',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/gh/sensehawk/cdn/jointJs+/rappid.css',
    'jointJsCss',
    'css',
  );
}

function initializePanZoom() {
  try {
    const paperScroller = new joint.ui.PaperScroller({
      paper: form_workflow_store.paper,
      cursor: 'grab',
      baseWidth: 1,
      autoResizePaper: true,
      baseHeight: 1,
      contentOptions: {
        padding: 20,
        allowNewOrigin: 'any',
      },
    });

    paperScroller.render().$el.appendTo(canvas.value);

    form_workflow_store.paper.on('paper:pinch', (evt, ox, oy, scale) => {
      evt.preventDefault();

      paperScroller.zoom((scale - 1) * 2, { min: 0.2, max: 5, oy, ox });
    });
    form_workflow_store.paper.on({
      'cell:mouseenter': (linkView) => {
        if (!['workflow.EndNode', 'workflow.LinkLabelNode', 'workflow.StartNode'].includes(linkView.model.attributes.type)) {
          linkView.model.attr({
            body: {
              stroke: '#101828',
              opacity: 0.2,
              filter: {
                name: 'dropShadow',
                args: {
                  dx: 0,
                  dy: 1,
                  blur: 1.5,
                },
              },
            },
          });
        }
      },
    });

    form_workflow_store.paper.on({

      'cell:mouseleave': (linkView) => {
        if (!['workflow.EndNode', 'workflow.LinkLabelNode', 'workflow.StartNode'].includes(linkView.model.attributes.type)) {
          linkView.model.attr({
            body: {
              stroke: '#EAECF0',
              opacity: 1,
              filter: {
                name: 'dropShadow',
                args: {
                  dx: 0,
                  dy: 0,
                  blur: 0,
                },
              },
            },
          });
          if (linkView.model.id === form_workflow_store.selected_node?.model?.id) {
            linkView.model.attr({
              body: {
                stroke: '#1676EE',
              },
            });
          }
        }
      },
    });
    form_workflow_store.panzoom = paperScroller;
  }
  catch (err) {
    logger.log(err);
  }
}

function init() {
  Object.assign(joint.shapes, {
    workflow: {
      Node: NodeShape(joint.dia),
      Link: LinkShape(joint.dia),
      StartNode: StartNodeShape(joint.dia),
      EndNode: EndNodeShape(joint.dia),
      LinkLabelNode: LinkLabelShape(joint.dia),
      AddButtonNode: AddButtonNodeShape(joint.dia),
    },
  });
  form_workflow_store.graph = new joint.dia.Graph({}, { cellNamespace: joint.shapes });
  form_workflow_store.paper = new joint.dia.Paper({
    cellViewNamespace: joint.shapes,
    width: '100%',
    model: form_workflow_store.graph,
    gridSize: 2,
    async: true,
    clickThreshold: 10,
    perpendicularLinks: true,
    defaultConnectionPoint: { name: 'boundary' },
    interactive: { elementMove: false, labelMove: false, linkMove: false },
    defaultLink: () => new joint.shapes.workflow.Link(),
  });
  initializePanZoom();
  initializePaperEvents();
}

/* To show dialog box to user for getting confirmation of losing changes */
function revertChanges(node_id = '') {
  const onProceed = async () => {
    try {
      if (form_workflow_store.new_node) {
        const step_index = form_workflow_store.new_node?.form_step_index;
        await form_workflow_store.deleteNode({ block: null, cell_model: form_workflow_store.new_node });
        form_workflow_store.selected_node = form_workflow_store.paper.findViewByModel(node_id) || null;
        if (step_index)
          await form_workflow_store.deleteStep(step_index);
      }
      else if (form_workflow_store.is_sidebar_form_dirty) {
        form_workflow_store.selected_node = form_workflow_store.paper.findViewByModel(node_id) || null;
      }
    }
    catch (err) {
      logger.log(err);
    }
  };
  if (form_workflow_store.new_node || (form_template_detail_store?.form_template_detail.status !== 'published' && form_workflow_store.is_sidebar_form_dirty))
    onConfirm($t('Form Workflow'), $t('Progress would be lost, are you sure?'), onProceed);
  else
    form_workflow_store.selected_node = form_workflow_store.paper.findViewByModel(node_id) || null;
}
function checkFieldsInStepBlock(step_number) {
  const steps = form_workflow_store.getSteps(step_number);
  return form_template_detail_store.sections.find(section => steps.includes(+section.step_index) && section.status === 'active' && section.fields.find(field => field.status === 'active'));
}

/* Called when a new block is being created */
async function addNewBlock(
  block,
  label = false,
  concurrent_block = false,
  concurrent_block_props = {},
) {
  if (form_workflow_store.new_node === null) {
    const link = {
      source:
              concurrent_block_props.label_id ?? form_workflow_store.selected_node?.label_id,
      label: concurrent_block_props.label ?? form_workflow_store.selected_node?.label,
      rule_id:
              concurrent_block_props?.rule_id ?? form_workflow_store.selected_node?.rule_id,
      siblingRank: form_workflow_store.paper.findViewByModel(concurrent_block_props.label_id).model.attributes.siblingRank,
    };
    const parent_block = form_workflow_store.getBlockByNodeId[
      concurrent_block
        ? concurrent_block_props.cell_model_parent
        : form_workflow_store.selected_node?.parent_id
    ];
    if (block.name === 'End') {
      const onProceed = async () => {
        emit('showLoader');
        try {
          const child = await form_workflow_store.addNode({ block, link });
          if (form_workflow_store.selected_node)
            form_workflow_store.selected_node.model.remove();
          form_workflow_store.refreshGraphLayout();
          let outcome = link.label.toLowerCase();
          if (parent_block.type === 'conditionalLogic')
            outcome = 'passed';
          else if (parent_block.type === 'approvalFlow')
            outcome = link.siblingRank ? 'deny' : 'approve';
          await form_workflow_store.saveAndUpdateNode({
            payload: {
              source: parent_block?.uid,
              sourceOutcome: outcome,
              step: parent_block.step,
              type: block.type,
              properties: {
                node_id: child.id,
                ...(parent_block.type === 'conditionalLogic' && {
                  rule_id: link.rule_id,
                }),
              },
            },
            data: {
              block_id: null,
            },
          });
        }
        catch (err) {
          logger.log(err);
          if (err.status === 400)
            $toast({ title: 'Error', text: err?.data?.message || 'End block creation failed', type: 'error' });
        }
        emit('hideLoader');
      };
      onConfirm($t('Form Workflow'), $t('Are you sure you want end the flow?'), onProceed);
    }
    else {
      const child = await form_workflow_store.addNode({ block, link });
      if (block.type === 'approvalFlow') {
        let label_rect = await form_workflow_store.addNode({
          block: { type: 'label', name: 'Approved' },
          link: { source: child.id },
          addButtonTool,
        });
        await form_workflow_store.addNode({
          block: { type: 'add' },
          link: { source: label_rect.id },
          addButtonTool,
        });
        label_rect = await form_workflow_store.addNode({
          block: { type: 'label', name: 'Rejected' },
          link: { source: child.id },
          addButtonTool,
        });
        await form_workflow_store.addNode({
          block: { type: 'add' },
          link: { source: label_rect.id },
          addButtonTool,
        });
      }
      if (!label) {
        if (form_workflow_store.selected_node && !concurrent_block)
          form_workflow_store.selected_node.model.remove();
      }

      form_workflow_store.refreshGraphLayout();
      const child_model = form_workflow_store.paper.findViewByModel(child);
      let outcome = link.label;
      if (parent_block.type === 'approvalFlow')
        outcome = link.siblingRank ? 'deny' : 'approve';
      form_workflow_store.new_node = {
        ...child_model,
        parent_id: concurrent_block
          ? concurrent_block_props.cell_model_parent
          : form_workflow_store.selected_node.parent_id,
        outcome,
        rule_id: link.rule_id,
        block,
      };
      form_workflow_store.selected_node = child_model;
    }
  }
}

function onConfirm(title_text, default_text, proceed_function) {
  const { open: openConfirm, close: closeConfirm } = useModal({
    component: HawkModalTemplate,
    attrs: {
      footer_buttons: [
        { color: 'primary', type: 'outlined', text: $t('Cancel'), class: 'mr-3', emit: 'close' },
        { color: 'primary', text: $t('Proceed'), emit: 'proceed' },
      ],
      onClose() {
        closeConfirm();
      },
      async onProceed() {
        await proceed_function();
        closeConfirm();
      },
    },
    slots: {
      title_text,
      default: default_text,
    },

  });
  openConfirm();
}

function onDeletePopup(metrics, block, cell_model) {
  let is_close = true;
  const { open: openDeletePopup, close: closeDeletePopup } = useModal({
    component: HawkDeletePopup,
    attrs: {
      can_delete: true,
      header: $t('Delete Node'),
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        emit('showLoader');
        try {
          await form_workflow_store.deleteNode({
            block,
            cell_model,
            payload: { action: is_close ? 'close' : 'continue' },
          });
          closeDeletePopup();
        }
        catch (err) {
          logger.log(err);
        }
        emit('hideLoader');
      },
    },
    slots: {
      content: useModalSlot({
        component: BlockDeletePopup,
        attrs: {
          metrics,
          get_block_by_uid: form_workflow_store.getBlockByUid,
          onChange(val) {
            if (typeof val === 'boolean')
              is_close = val;
          },
        },
      }),
    },
  });
  openDeletePopup();
}

/* Called to when clicked on the delete button tool */
async function deleteNodePopup(cell_model) {
  const block = form_workflow_store.getBlockByNodeId[cell_model.model.id];

  if (block && block.initial_block) {
    $toast({
      text: $t('Cannot delete parent node'),
      type: 'error',
    });
  }
  else {
    const metrics = await form_workflow_store.getAffectedMetrics({
      block_uid: block?.uid,
    });
    if (metrics.count > 0) {
      onDeletePopup(metrics, block, cell_model);
    }
    else {
      delete_popup.patchOptions({
        attrs: {
          header: $t('Delete node'),
          content: $t('This action cannot be undone. Are you sure you want to delete?'),
          confirm: async () => {
            emit('showLoader');
            try {
              await form_workflow_store.deleteNode({ block, cell_model });
            }
            catch (err) {
              logger.log(err);
            }
            emit('hideLoader');
            delete_popup.close();
          },
        },
      });
      delete_popup.open();
    }
  }
}

/* Called to add button tool to node */
function addButtonTool(
  cell,
  options = { add: false, edit: false, delete: false },
  label = false,
  type = '',
) {
  const cell_model = form_workflow_store.paper.findViewByModel(cell);
  const cell_model_parent = form_workflow_store.paper.model.getNeighbors(
    cell_model.model,
    {
      inbound: true,
    },
  )[0];
  let block = null;
  try {
    block = form_workflow_store.getBlockByNodeId[cell_model.model.id];
  }
  catch (e) {
    logger.log(e);
  }
  const tools = [];
  if (
    options.add
    && (props.form.status !== 'published'
      || !options.show_add_on_node)
  ) {
    tools.push(
      new joint.elementTools.Button({
        markup: button_markups.add,
        x: '50%',
        y: '100%',
        action: async (e, node) => {
          if (props.form.status === 'published') {
            $toast({
              text: $t('Unpublish form to add blocks'),
              type: 'error',
            });
            return;
          }
          if (!form_workflow_store.new_node) {
            const parent_label_block = form_workflow_store.paper.model.getNeighbors(
              node.model,
              {
                inbound: true,
              },
            )[0];
            const parent = form_workflow_store.paper.model.getNeighbors(
              form_workflow_store.paper.findViewByModel(parent_label_block.id).model,
              { inbound: true },
            )[0];
            if (!options.show_add_on_node) {
              form_workflow_store.selected_node = {
                ...cell_model,
                parent_id: parent.id,
                label_id: parent_label_block.id,
                label: parent_label_block?.attributes?.attrs?.label.text,
                rule_id:
                        parent_label_block.attributes?.attrs?.rule_id?.text,
              };
            }
            const hash = {
              true: cell_model.model,
              false: parent_label_block,
            };
            const node_object = hash[Boolean(options.show_add_on_node)];
            const popup_props = {
              rule_id: options.show_add_on_node
                ? cell_model.model.attributes?.attrs?.rule_id?.text
                : parent_label_block.attributes?.attrs?.rule_id?.text,
              source_outcome: node_object?.attributes?.attrs?.rule_id
                ?.text
                ? 'passed'
                : hash[
                  Boolean(options.show_add_on_node)
                ]?.attributes.attrs.label.text?.toLowerCase(),
              cell_model_parent: cell_model_parent.id,
              concurrent_node_click: options.show_add_on_node,
              label_id: node_object.id,
              label: node_object?.attributes?.attrs?.label.text,
            };
            try {
              const parent_block = form_workflow_store.getBlockByNodeId[cell_model_parent.id] || form_workflow_store.getBlockByNodeId[parent.id];
              if (!checkFieldsInStepBlock(parent_block.step)) {
                $toast({ text: 'No fields in the above form step', type: 'error' });
                return;
              }
            }
            catch (e) {
              logger.log(e);
            }

            add_blocks_popup.patchOptions({
              attrs: {
                ...popup_props,
                map_icons: form_workflow_store.map_icons,
                onAddBlock: (block) => {
                  addNewBlock(
                    block,
                    label,
                    options.show_add_on_node,
                    popup_props,
                  );
                  add_blocks_popup.close();
                },
              },
            });
            add_blocks_popup.open();
          }
        },
      }),
    );
  }

  let edit_icon_x_position = '85%';

  if (block?.initial_block || props.form.status === 'published')
    edit_icon_x_position = '92%';
  else if (type === 'workflow.EndNode')
    edit_icon_x_position = '30%';

  if (options.edit) {
    tools.push(
      new joint.elementTools.Button({
        markup: button_markups.edit,
        x: edit_icon_x_position,
        y: 10,
        action: () => {
          if (form_workflow_store.new_node)
            revertChanges(cell_model.model.id);
          else
            form_workflow_store.selected_node = cell_model;
        },
      }),
    );
  }
  if (
    options.delete
    && props.form.status !== 'published'
    && !block?.initial_block
  ) {
    tools.push(
      new joint.elementTools.Button({
        markup: button_markups.delete,
        x: '92%',
        y: type === 'workflow.EndNode' ? -5 : 10,
        action: () => {
          if (form_workflow_store.new_node)
            revertChanges(cell_model.model.id);
          else deleteNodePopup(cell_model);
        },
      }),
    );
  }
  cell_model.addTools(
    new joint.dia.ToolsView({
      tools,
    }),
  );
}

function highlightAncestors() {
  form_workflow_store.paper.on('cell:mouseover', (cellView) => {
    const links = form_workflow_store.paper.model.getConnectedLinks(cellView.model, {
      deep: true,
      includeEnclosed: true,
      inbound: true,
      outbound: true,
    });
    const neighbors = form_workflow_store.paper.model.getPredecessors(cellView.model, {
      deep: true,
    });
    if (
      ['workflow.AddButtonNode', 'workflow.LinkLabelNode'].includes(
        cellView.model.get('type'),
      )
    ) {
      return;
    }
    cellView.highlight();
    neighbors.forEach((element) => {
      const viewElement = form_workflow_store.paper.findViewByModel(element);
      if (
        ['workflow.AddButtonNode', 'workflow.LinkLabelNode'].includes(
          viewElement.model.get('type'),
        )
      ) {
        return;
      }
      viewElement.highlight();
    });
    links.forEach((link) => {
      const viewLink = form_workflow_store.paper.findViewByModel(link);
      viewLink.highlight();
    });
  });

  form_workflow_store.paper.on('cell:mouseout', (cellView) => {
    const links = form_workflow_store.paper.model.getConnectedLinks(cellView.model, {
      deep: true,
      includeEnclosed: true,
      inbound: true,
      outbound: true,
    });
    const neighbors = form_workflow_store.paper.model.getPredecessors(cellView.model, {
      deep: true,
    });
    cellView.unhighlight();
    neighbors.forEach((element) => {
      const viewElement = form_workflow_store.paper.findViewByModel(element);
      viewElement.unhighlight();
    });
    links.forEach((link) => {
      const viewLink = form_workflow_store.paper.findViewByModel(link);
      viewLink.unhighlight();
    });
  });
}

function initializePaperEvents() {
  form_workflow_store.paper.on('element:pointerclick', (node, e) => {
    if (node.model.get('type') === 'workflow.LinkLabelNode' && node?.model?.attributes?.attrs?.label?.text === 'Otherwise')
      return;
    if (form_workflow_store.disable_outside_click)
      return;
    form_workflow_store.panzoom.centerElement(node.model);

    if (
      [
        'workflow.AddButtonNode',
        'workflow.EndNode',
        'workflow.LinkLabelNode',
        'workflow.StartNode',
      ].includes(node.model.get('type'))
    ) {
      revertChanges();
    }
    else if (form_workflow_store.new_node) {
      if (node.model.id === form_workflow_store.new_node.model.id)
        form_workflow_store.selected_node = form_workflow_store.new_node;
      else revertChanges(node.model.id);
    }

    else {
      revertChanges(node.model.id);
    }

    if (
      node.model.get('type') === 'workflow.LinkLabelNode'
      && node?.model?.attributes?.attrs?.rule_id?.text
    ) {
      const view_node = form_workflow_store.paper.model.getNeighbors(node?.model, {
        inbound: true,
      })[0];

      if (view_node) {
        setTimeout(() => {
          form_workflow_store.rule_id_to_open = node?.model?.attributes?.attrs?.rule_id?.text;
          form_workflow_store.selected_node = form_workflow_store.paper.findViewByModel(view_node.id);
        }, 0);
      }
    }
  });
  form_workflow_store.paper.on('blank:pointerclick', () => {
    if (form_workflow_store.disable_outside_click)
      return;

    revertChanges();
  });

  form_workflow_store.paper.on('blank:pointerdown', form_workflow_store.panzoom.startPanning);

  form_workflow_store.paper.on('element:mouseenter', (cellView) => {
    if (cellView.model.get('type') === 'workflow.LinkLabelNode') {
      const text = cellView.model?.attributes?.attrs?.label?.text || '';
      if (text.length > 12) {
        tooltip_info.value = new joint.ui.Tooltip({
          target: cellView.el,
          trigger: 'hover',
          hideTrigger: 'mouseout',
          position: 'bottom',
          positionSelector: 'bottom',
        });
        for (let i = 0; i < tooltip_info.value.el?.children?.length; i++) {
          const node = tooltip_info.value.el.children[i];
          if (node?.classList.contains('tooltip-content')) {
            node.classList.add('roboto-regular-12');
            node.innerHTML = text;
          }
        }
      }
    }

    if (['workflow.AddButtonNode'].includes(cellView.model.get('type')))
      return;
    const outboundNeighbor = form_workflow_store.graph.getNeighbors(cellView.model, {
      outbound: true,
    })[0];
    addButtonTool(
      cellView.model.id,
      {
        add:
              outboundNeighbor
              && outboundNeighbor.attributes.type
              !== 'workflow.AddButtonNode'
              && !['workflow.Node', 'workflow.StartNode'].includes(cellView.model.get('type')),
        edit: !['workflow.EndNode', 'workflow.LinkLabelNode', 'workflow.StartNode'].includes(
          cellView.model.get('type'),
        ),
        delete: !['workflow.LinkLabelNode', 'workflow.StartNode'].includes(
          cellView.model.get('type'),
        ),
        show_add_on_node: true,
      },
      Boolean(cellView?._toolsView?.options?.tools[0]),
      cellView.model.get('type'),
    );
  });

  form_workflow_store.paper.on('element:mouseleave', (cellView) => {
    if (cellView.model.get('type').includes('AddButton'))
      return;
    addButtonTool(cellView.model.id, {
      add: false,
      edit: false,
      delete: false,
      show_add_on_node: true,
    });
  });
}

async function getData() {
  try {
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    await form_workflow_store?.getFormWorkflow({ workflow_id: props.form.workflow?.uid, form_id: props.form.uid });
    await form_workflow_store?.getAllBlocks();
  }
  catch (err) {
    logger.log(err);
  }
}

async function initializeData() {
  if (
    !Object.keys(form_workflow_store.workflow?.properties || {}).includes(
      'workflow_structure',
    )
  ) {
    try {
      const start = await form_workflow_store.addNode({ block: { type: 'start' } });
      const node = await form_workflow_store.addNode({
        block: {
          name: props.form.name,
          description: props.form.description || '',

          type: 'step',
          step: 1,
        },
        link: { source: start.id },
      });

      await form_workflow_store.updateBlock({
        payload: {
          name: props.form.name,
          assignees: [],
          days: 1,
          access_controls: {
            type: 'default',
          },
          properties: {
            assignees: [],
            days: 1,
            access_controls: {
              type: 'default',
            },
            node_id: node.id,
            escalations: {
              is_enabled: false,
              email: {
                subject: '',
                body: '',
                customize_email: false,
              },
              wait: 1,
              members: {},
              escalationType: 'add',
            },
            reminders: {
              is_enabled: false,
              interval: 1,
              count: 1,
              wait: 1,
              sent: 1,
              email: {
                subject: '',
                body: '',
                customize_email: false,
              },
            },
            name: props.form.name,
            description: props.form.description || '',
            step: 1,
          },
        },
        block_id: form_workflow_store.blocks[0]?.uid,
      });
      await form_workflow_store.updateFormWorkflow({
        payload: {
          properties: {
            workflow_structure: form_workflow_store.graph.toJSON(),
          },
        },
      });
    }
    catch (err) {
      logger.log(err);
    }
  }

  else {
    form_workflow_store.loadFormWorkflowJson();
  }

  form_workflow_store.panzoom?.positionContent('center', { useModelGeometry: true });
}

function selectFirstBlock() {
  if (!form_workflow_store.is_initial_block_saved) {
    const node_id = form_workflow_store.blocks[0].properties.node_id;
    if (node_id)
      form_workflow_store.selected_node = form_workflow_store.paper.findViewByModel(node_id);
  }
}

function updateIconImageUrlForFirstBlock() {
  const node_id = form_workflow_store.blocks[0]?.properties?.node_id;
  if (node_id) {
    const node = form_workflow_store.paper.findViewByModel(node_id);
    const url = node?.model?.attributes?.attrs?.iconImage?.xlinkHref;
    if (url)
      node.model.attr('iconImage', { xlinkHref: url.replace('https://api-new', 'https://api-dev') });
  }
}

onMounted(async () => {
  emit('showLoader');
  await load_styles();
  canvas.value = document.getElementById('workflow-graph');
  init();
  await getData();
  await initializeData();
  form_workflow_store.addButtonTool = addButtonTool;
  emit('hideLoader');
  selectFirstBlock();
  updateIconImageUrlForFirstBlock();
});

onUnmounted(() => {
  form_workflow_store.$reset();
});

watch(() => form_workflow_store.selected_node, (current_node, previous_node) => {
  if (previous_node) {
    previous_node.model.attr('body', {
      stroke: '#E1E2E7',
      strokeDasharray: '0,0',
      strokeWidth: 1,
    });
  }

  if (current_node) {
    current_node.model.attr('body', {
      stroke: '#1676EE',
      strokeDasharray: '10,4',
      strokeWidth: 2,
    });
  }
  else {
    form_workflow_store.rule_id_to_open = null;
  }
});

watch(() => form_workflow_store.add_btn_trigger, (val) => {
  if (val) {
    /* Used to add tool to the workflow graph */
    form_workflow_store.workflow.properties.workflow_structure.cells.forEach((cell) => {
      if (cell.type.includes('AddButtonNode'))
        addButtonTool(cell.id, { add: true });
    });
    form_workflow_store.add_btn_trigger = false;
  }
}, { immediate: true });
</script>

<template>
  <div class="min-h-full border-2 border-gray-200 rounded-lg">
    <div class="relative">
      <div class="absolute rounded-lg left-1.5 top-1.5 border z-1">
        <MapToolIcons
          style="position: relative"
          :tools="zoom_tools"
          :active_tool="active_tool"
          :disabled="disabled"
          :active_tools="active_tools"
        />
      </div>
      <div id="workflow-graph" class="workflow-graph rounded-lg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .workflow-graph {
    height: calc(100vh - 150px) !important;
    width: 100%;
    background: #fff;
    z-index: -1;
  }
  body {
    overflow: hidden;
  }
  :deep(.joint-paper-scroller) {
    overflow: auto;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: transparent;
      background-clip: padding-box;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #D0D5DD;
    }
    &:hover::-webkit-scrollbar-thumb:hover {
      background: #98A2B3;
    }
  }
 :deep(.joint-paper)  {
    background: #fff !important;
  }
 :deep(.joint-paper-background) ,
 :deep(.joint-paper-grid)  {
    display: none !important;
  }
 :deep(.joint-cell)  {
    cursor: pointer;
  }
</style>
