<script setup>
import { IconHawkClockHistory } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  restore_view_config: {
    type: Object,
  },
});
const emits = defineEmits(['handleRestore']);
const state = reactive({
  restore_view_config: null,
  hide_restore_view: true,
  checkbox: false,
});

function onApply() {
  localStorage.setItem(props.restore_view_config.key, JSON.stringify({ hide_restore_view: true }));
  state.hide_restore_view = true;
}

function onRestore() {
  emits('handleRestore', state.restore_view_config.data);
  localStorage.removeItem(props.restore_view_config.key);
  state.hide_restore_view = true;
}

function onCancel() {
  localStorage.removeItem(props.restore_view_config.key);
  state.hide_restore_view = true;
}

watch(() => props.restore_view_config, (val, old_val) => {
  if (!val || old_val?.key === val?.key)
    return;
  state.restore_view_config = JSON.parse(localStorage.getItem(props.restore_view_config.key));
  state.hide_restore_view = !state.restore_view_config?.data || state.restore_view_config?.hide_restore_view;
});
</script>

<template>
  <div v-if="!state.hide_restore_view" class="fixed z-[100] w-[400px] h-[176px] bg-gray-800 border rounded-lg right-2 top-[70px] p-4 flex flex-col justify-between">
    <div class="flex flex-col gap-2">
      <div>
        <HawkFeaturedIcon color="gray" class="bg-gray-700" theme="light-circle" size="md">
          <IconHawkClockHistory class="!text-gray-300" />
        </HawkFeaturedIcon>
      </div>
      <div>
        <div class="text-sm font-semibold text-white">
          {{ $t('Restore View') }}
        </div>
        <div class="text-sm text-gray-300">
          {{ $t('Do you want to restore the previous session?') }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <HawkCheckbox id="labels" :model-value="state.checkbox" class="text-gray-300" @input="state.checkbox = !state.checkbox">
        {{ $t("Don't show this again") }}
      </HawkCheckbox>
      <div class="flex items-center gap-x-2">
        <HawkButton type="text" class="text-white" @click="onCancel">
          {{ $t('Cancel') }}
        </HawkButton>
        <HawkButton v-if="state.checkbox" type="dark" @click="onApply">
          {{ $t('Apply') }}
        </HawkButton>
        <HawkButton v-else type="dark" @click="onRestore">
          {{ $t('Restore') }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
