<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { IconHawkPlusWhite } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SmComponentForm from '~/system-model/components/forms/sm-component-form.vue';
import SmBreadcrumb from '~/system-model/components/sm-breadcrumb.vue';
import SmComponentDetails from '~/system-model/components/sm-component-details.vue';
import SmInstanceDetails from '~/system-model/components/sm-instance-details.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  is_loading: {
    type: Boolean,
  },
});

const system_model_store = useSystemModelStore();
const { can_modify_resources, active_instance } = storeToRefs(system_model_store);
const { router, route, $t } = useCommonImports();

const state = reactive({
  loading: false,
  is_exporting: false,
});

const has_components = computed(() => !system_model_store?.sidebar_hierarchy?.length);

onMounted(async () => {
  state.loading = true;
  await system_model_store.set_active_component_hierarchy({ template_id: route?.params?.template_id });
  state.loading = false;
});

const component_modal = useModal({
  component: SmComponentForm,
  attrs: {
    onClose() {
      component_modal.close();
    },
  },
});

onBeforeUnmount(() => {
  system_model_store.$patch({
    active_tab: 'SmComponentDetails',
    active_component_details: {},
    active_component_hierarchy: [],
    active_component: null,
  });
});
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="mb-4">
      <template #left>
        <SmBreadcrumb />
      </template>
      <template #right>
        <hawkButton
          v-if="can_modify_resources"
          color="primary"
          @click="component_modal.open()"
        >
          <IconHawkPlusWhite class="w-3 h-3" /> {{ $t('New component') }}
        </hawkButton>
      </template>
    </HawkPageSecondaryHeader>
    <hawk-loader v-if="is_loading || state.loading" />
    <HawkIllustrations v-else-if="has_components && !active_instance" type="no-data" for="generic" />
    <div v-else>
      <SmComponentDetails v-if="!active_instance" />
      <SmInstanceDetails v-else />
    </div>
  </div>
</template>
