<script setup>
import { useElementSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkInfoCircle } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkActivities from '~/common/components/organisms/hawk-activities/hawk-activities.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  extra_query: {
    type: Object,
    default: () => { },
  },
  content_height: {
    type: Number,
  },

});
const form_template_detail_store = useFormTemplateDetailStore();
const $services = inject('$services');

const formatted_data = computed(() => {
  return data.map((d) => {
    return { uid: d.uid, user: d.actor.split(':')[1], createdAt: d.createdAt, text: d.verb, classes: 'border-red-600', status: d.status };
  });
});

const $t = inject('$t');

const authStore = useAuthStore();
const dashboard_store = useDashboardStore();

const route = useRoute();

const form_widget_header = ref(null);
const { height } = useElementSize(form_widget_header);

function getHeight() {
  if (props.id === 'preview')
    return ((props.data.h || 22) * 20) - 90;
  else if (props.id === route.params.template_uid)
    return window.innerHeight - 210;
  else if (!props.id && window.innerWidth < 1300)
    // fix for form list drilldown on smaller screens
    return 360;
  else
    return (props?.data?.h * 24.5) - (height.value + 40);
}
const active_filter = computed(() => {
  return props.extra_query?.activity_history_filters || (props.data?.data?.include_filters ? props.data?.data?.activity_history_filters : 'all');
});
const filter_query = computed(() => {
  const resource_path = form_template_detail_store?.form_template_detail?.uid;
  let query;
  switch (active_filter.value) {
    case 'form':
      query = { resource_path, blacklist_verbs: ['APPROVAL_STARTED', 'APPROVAL_REVIEWED', 'APPROVAL_ESCALATED', 'APPROVAL_COMPLETED'] };
      break;
    case 'approvals':
      query = { resource_path, filtered_verbs: ['APPROVAL_STARTED', 'APPROVAL_REVIEWED', 'APPROVAL_ESCALATED', 'APPROVAL_COMPLETED'] };
      break;
    case 'template':
      query = { resource_path };
      break;
    default:
      query = { resource_path, all_forms_activity: true };
  }
  query = { ...query, ...{ sort: '-createdAt' } };
  return query;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props?.data?.data?.name,
    renderType: 'forms-list',
    dimensions: {
      x: props?.value?.x?.label,
      y: props?.value?.y?.label,
    },
  });
}
const resource_type = computed(() => {
  if (['form', 'approvals'].includes(active_filter.value))
    return 'form';
  else
    return 'form_template';
});
const resource_uid = computed(() => route.params.template_uid);
</script>

<template>
  <div>
    <div
      v-if="$slots['header-title'] || $slots['header-actions']"
      ref="form_widget_header"
      class="widget-header group"
    >
      <div class="font-medium truncate flex item-center">
        {{ props.data.data.name }}
        <IconHawkInfoCircle v-tippy="$t('Filters are not applied to this widget')" class="text-warning-600 ml-2 cursor-pointer" />
      </div>
      <slot name="header-actions" />
    </div>
    <div class="block w-full">
      <HawkActivities
        module="forms"
        :query="filter_query"
        :resource_uid="resource_uid"
        :resource_type="resource_type"
        :lazy_load="true"
        container_class="overflow-auto h-[calc(100vh-140px)]"
      />
    </div>
  </div>
</template>
