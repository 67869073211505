<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { IconHawkChevronLeft, IconHawkChevronRight, IconHawkDownloadOne } from '~/common/components/molecules/hawk-icons/icons.js';
import { useMap } from '~/common/composables/mapbox/maps';
import { useThermStore } from '../store/therm.store';

const props = defineProps({
  active_image: {
    type: Number,
    default: 0,
  },

});

const emit = defineEmits(['close']);

const therm_store = useThermStore();
const { initMapbox } = useMap({});

const active_tab = ref(0);
const marker = ref(null);
const raw_image_total = ref(null);
const raw_image_active = ref(0);
const is_marker = ref(true);
const marker_element = ref(null);
const raw_images = computed(() => therm_store.raw_images);
const raw_images_map = ref(null);
onMounted(() => {
  setTimeout(() => {
    loadMap();
  }, 0);
});
onUnmounted(() => {
  raw_images_map.value?.remove();
});

async function loadMap() {
  raw_image_total.value = raw_images.value.length;
  raw_image_active.value = props.active_image;

  if (!raw_images_map.value) {
    try {
      const map = await initMapbox({
        container_id: 'rawImageMap',
        style: 'mapbox://styles/mapbox/satellite-v8',
        center: [-75.789, 41.874],
        zoom: 3,
        maxZoom: 24,

        attributionControl: false,
      });

      raw_images_map.value = map;
      raw_images_map.value.on('style.load', () => {
        raw_images_map.value.removeLayer('satellite');
        raw_images_map.value.removeLayer('background');
        raw_images_map.value.resize();
        if (raw_images.value && raw_images.value.length)
          changeImage(0);
      });
    }
    catch (err) {
      logger.log(err);
    }
  }
}

function calculateImageDimensions(img_width, img_height) {
  const available_width = 850;
  const available_height = 400;

  const width_scale_factor = available_width / img_width;
  const height_scale_factor = available_height / img_height;

  const scale_factor = Math.min(width_scale_factor, height_scale_factor);

  const updated_width = img_width * scale_factor;
  const updated_height = img_height * scale_factor;
  return { updated_width, updated_height, scale_factor };
}

function changeImage(n) {
  try {
    raw_image_active.value = raw_image_active.value + n;
    if (marker.value)
      marker.value.remove();

    if (raw_images_map.value.getLayer('overlay'))
      raw_images_map.value.removeLayer('overlay');
    if (raw_images_map.value.getSource('myImageSource'))
      raw_images_map.value.removeSource('myImageSource');

    const image = new Image();
    image.src = raw_images.value[raw_image_active.value].src;
    image.setAttribute('crossorigin', 'anonymous');
    image.onload = () => {
      const map = raw_images_map.value;
      const img_width = image.width;
      const img_height = image.height;

      const { updated_width, updated_height, scale_factor } = calculateImageDimensions(img_width, img_height);
      const nw = map.unproject([0, 0]);
      const ne = map.unproject([updated_width, 0]);
      const se = map.unproject([updated_width, updated_height]);
      const sw = map.unproject([0, updated_height]);
      map.addSource('myImageSource', {
        type: 'image',
        url: image.src,
        coordinates: [
          [nw.lng, nw.lat],
          [ne.lng, ne.lat],
          [se.lng, se.lat],
          [sw.lng, sw.lat],
        ],
      });
      map.addLayer({
        id: 'overlay',
        source: 'myImageSource',
        type: 'raster',
      });

      const el = document.createElement('div');
      marker_element.value = el;
      el.innerHTML = `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12Z" fill="#F04438"/>
      <path d="M9 21C13 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 5 17 9 21Z" fill="#F04438"/>
      <path d="M9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 21C13 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 5 17 9 21Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;
      el.classList.add('marker');
      if (!is_marker.value)
        marker_element.value.style.display = 'none';
      el.addEventListener('click', () => (active_tab.value = 0));

      const location = raw_images.value[raw_image_active.value].location;
      const center = map.unproject([location[0] * scale_factor, location[1] * scale_factor + 5]);
      marker.value = new mapboxgl.Marker(el).setLngLat(center).addTo(map);

      const bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(sw.lng, sw.lat),
        new mapboxgl.LngLat(ne.lng, ne.lat),
      );
      map.fitBounds(bounds, {
        duration: 0,
      });
      map.resize();
    };
  }
  catch (err) {
    logger.log(err);
  }
}

function forceDownload(url) {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = '';
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }
  catch (err) {
    logger.error(err);
    window.open(url, '_blank');
  }
}
</script>

<template>
  <hawk-modal-container :width="1300" content_class="rounded-lg min-w-[900px] min-h-[550px]">
    <hawk-modal-header @close="emit('close')">
      <template #title>
        <p class="text-gray-900 font-semibold">
          Images
        </p>
      </template>
      <template #right>
        <IconHawkDownloadOne class="text-gray-500 cursor-pointer" @click=" () => forceDownload(raw_images[raw_image_active].src)" />
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <div id="rawImageMap" class="h-[400px] rounded-lg border" />
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-center items-center">
      <template #right>
        <div class="flex items-center gap-2">
          <HawkButton class="w-9 h-9 flex justify-center" :disabled="raw_image_active <= 0" type="outlined" @click=" () => changeImage(-1)">
            <IconHawkChevronLeft class="text-gray-700" />
          </HawkButton>
          <p class="text-sm text-gray-700 font-medium">
            {{ `${raw_image_active + 1} / ${raw_image_total}` }}
          </p>
          <HawkButton class="w-9 h-9 flex justify-center" type="outlined" :disabled="raw_image_active + 1 >= raw_image_total" @click="() => changeImage(1)">
            <IconHawkChevronRight class="text-gray-700" />
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>

<style lang="scss">
  .popup-modal {
    .controls {
      > * {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    #rawImageMap {
      position: relative;
      min-height: 600px;
      width: 100%;
      border-radius: 8px;
    }
    .marker {
      img {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
    .mapboxgl-ctrl-logo {
      display: none !important;
    }
  }
</style>
