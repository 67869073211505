<script setup>
import { keyBy } from 'lodash-es';
import { computed, inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IconHawkMinusCircle, IconHawkPlusCircle } from '~/common/components/molecules/hawk-icons/icons.js';
import TaskNotification from '~/notifications/components/task-notifications.vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const $services = inject('$services');
const route = useRoute();
const router = useRouter();

const expanded = ref(true);
const subtasks = ref([]);

const filter_items = computed(() => {
  return props?.item?.activities.reduce((acc, activity) => {
    const { verb, meta } = activity;
    if (verb === 'SUBTASK_ADDED') {
      if (subtasks.value?.[meta?.uids?.[0]])
        acc.push(activity);
    }
    else {
      acc.push(activity);
    }
    return acc;
  }, []);
});

async function getSubTasks() {
  const uids = [];
  props.item.activities.forEach(async (activity) => {
    if (activity?.verb === 'SUBTASK_ADDED' && activity?.meta?.uids?.length)
      uids.push(activity.meta.uids[0]);
  });
  if (uids?.length) {
    const { data } = await $services.tasks.getAll({
      query: { task_uid: uids },
    });
    subtasks.value = keyBy(data.tasks, 'uid');
  }
}

function itemDetails(item) {
  router.replace({
    ...route,
    query: {
      ...route.query,
      task: btoa(JSON.stringify({ id: item?.name?.split(':')?.[1] })),
    },
  });
}

onMounted(() => {
  getSubTasks();
});
</script>

<template>
  <div class="text-sm">
    <div class="bg-gray-100 m-4 p-2 rounded-lg flex items-center gap-3 font-semibold cursor-pointer" @click="expanded = !expanded">
      <IconHawkMinusCircle v-if="expanded" class="text-gray-600 h-4 w-4" />
      <IconHawkPlusCircle v-else class="text-gray-600 h-4 w-4" />
      <div @click.stop="itemDetails(item?.feed)">
        {{ item?.feed?.ticketName || "Module crack needs replacement" }}
      </div>
    </div>
    <div v-if="expanded && filter_items?.length">
      <div
        v-for="activity in filter_items"
        :key="activity?.id" class="border-b py-3 pl-[52px] pr-6"
      >
        <TaskNotification :activity="activity" />
      </div>
    </div>
  </div>
</template>
