<script setup>
import dayjs from 'dayjs';
import { IconHawkInfoCircle } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { setCookie } from '~/common/utils/common.utils.js';

const emit = defineEmits(['close']);
const { $t, $toast, auth_store, common_store } = useCommonImports();

const state = reactive({
  is_loading: false,
});

function onClose() {
  setCookie('defer_tz_change', dayjs().add(7, 'day').valueOf());
  emit('close');
}

async function save() {
  try {
    state.is_loading = true;
    const { data } = await common_store.update_data({
      type: 'patch_update',
      id: auth_store.logged_in_user_details.user_id,
      data: { user: { timezone: dayjs.tz.guess() } },
      service: 'users',
      response_key: 'user',
      append_data: true,
      state_prop: 'internal_users_map',
      update_state: true,
    });

    if (data?.user) {
      $toast({
        title: $t('Success'),
        text: $t('Timezone updated successfully'),
        type: 'success',
      });
      setCookie('defer_tz_change', dayjs().valueOf());
      location.reload();
    }
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    state.is_loading = false;
  }
}
</script>

<template>
  <hawk-modal-container>
    <div class="grid gap-6 px-6 pt-6 pb-4">
      <HawkFeaturedIcon theme="light-circle-outline" size="lg" color="warning">
        <IconHawkInfoCircle />
      </HawkFeaturedIcon>
      <div>
        <div class="mb-1 text-lg font-semibold">
          {{ $t("New location detected") }}
        </div>
        <div class="text-sm text-gray-600">
          {{ $t("We detected a location change. Do you want to change the timezone to this location") }}?
        </div>
      </div>
      <div>
        <div class="text-xs text-gray-600">
          {{ $t("New timezone") }}
        </div>
        <div class="text-sm font-medium">
          {{ `(GMT ${dayjs().format("Z")}) ${dayjs.tz.guess()}` }}
        </div>
      </div>
    </div>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-4">
          <HawkButton type="outlined" @click="onClose()">
            {{ $t("Cancel") }}
          </HawkButton>
          <HawkButton :loading="state.is_loading" @click="save()">
            {{ $t("Yes, change my timezone") }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
