<script setup>
import { keyBy } from 'lodash-es';
import { onMounted } from 'vue';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import { IconHawkChevronDown, IconHawkChevronUp, IconHawkGlobe, IconHawkUsersShare, IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';
import { doesThisLookLikeAnEmail } from '~/common/utils/common.utils.js';

const props = defineProps({
  // Array of objects containing the uids and level of access of users.
  // Structure of each object is `{ uid: String, access: String ('read', 'write', or 'manage') }`.
  members: {
    type: Array,
    required: false,
    default: () => [],
  },
  ignored_members: {
    type: Array,
    required: false,
    default: () => [],
  },
  // Array of objects containing the uids and level of access of teams.
  // Structure of each object is `{ uid: String, access: String ('read', 'write', or 'manage') }`.
  teams: {
    type: Array,
    required: false,
    default: () => [],
  },
  // Array of access levels that can be assigned to users and teams.
  access_levels: {
    type: Array,
    required: false,
    default: () => {
      const $t = inject('$t');
      return [
        { name: 'read', label: $t('Can view'), description: $t('Can view') },
        { name: 'write', label: $t('Can edit'), description: $t('Can view and edit') },
        { name: 'manage', label: $t('Can manage'), description: $t('Can view, edit, and manage') },
      ];
    },
  },
  // Array of access levels that can be assigned to external peeps.
  external_user_access_levels: {
    type: Array,
    required: false,
    default: () => [],
  },
  // Array of access levels that can be set as the global access level.
  global_access_levels: {
    type: Array,
    required: false,
    default: () => {
      const $t = inject('$t');
      return [
        { name: 'no_access', label: $t('No access'), description: $t('No access') },
        { name: 'read', label: $t('Can view'), description: $t('Can view') },
      ];
    },
  },
  // Specifies the 'name' property of the current level in the global_access_levels
  global_access_level: {
    type: String,
    default: undefined,
    required: false,
  },
  // If this uid is provided, an additional line denoting the owner of the object is displayed.
  owner_uid: {
    type: String,
    required: false,
    default: '',
  },
  // Defines whether modifications should be allowed.
  is_editable: {
    type: Boolean,
    required: false,
    default: true,
  },
  // For some objects, there's a concept of global access.
  // This prop defines if the control for global access should be displayed.
  is_global_access_visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  // Defines if this component should be displayed as a modal (you'll still need code to launch it) or not.
  is_modal: {
    type: Boolean,
    default: false,
  },
  // In the modal mode, this function is called when the primary action button is pressed.
  // In non-modal mode, this function is called as soon as there is any change in data.
  get_share_data: {
    type: Function,
    default: null,
  },
  //
  share_action_text: {
    type: String,
    default: () => {
      const $t = inject('$t');
      return $t('Share');
    },
  },
  // Hid 'people with access' text when the block is empty'
  hide_empty: {
    type: Boolean,
    default: false,
  },
  has_teams: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    required: false,
    default: () => {
      const $t = inject('$t');
      return $t('Share with people');
    },
  },
  description: {
    type: String,
    required: false,
    default: () => {
      const $t = inject('$t');
      return $t('The following people have access to this project:');
    },
  },
  has_icon: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['close', 'input']);
const $t = inject('$t');

const state = reactive({
  is_adding_external: false,
});

// These two are the vars where the final user and team uids along with their selected levels of access are stored.
const form$ = ref(null);
const is_initially_shared = ref(false);
const added_teams = ref([...props.teams]);
const added_users = ref([...props.members]);
const selected_access_level = ref(props.access_levels[0]);
const global_access_level = ref(props.global_access_level ?? props.global_access_levels[0].name);

const added_user_uids = computed(() => (added_users.value.map(item => item.uid) || []));
const added_team_uids = computed(() => (added_teams.value.map(item => item.uid) || []));
const access_level_labels_map = computed(() => keyBy(props.access_levels, 'name'));
const global_access_level_labels_map = computed(() => keyBy(props.global_access_levels, 'name'));
const external_user_access_level_labels_map = computed(() => keyBy(props.external_user_access_levels, 'name'));
const share_button_disabled = computed(() => (props.is_editable && !added_users.value?.length && !added_teams.value?.length && !is_initially_shared.value));

function onAssigneeInputChange(items) {
  state.is_adding_external = items.some(item => doesThisLookLikeAnEmail(item.uid));
}

function onAdd() {
  const form_data = form$.value.data;
  form_data.assignees.forEach((item) => {
    if (doesThisLookLikeAnEmail(item.uid)) {
      added_users.value.push({ email: item.uid, access: props.external_user_access_levels[0].name });
    }
    else if (item.uid !== item.name) {
      const target = item.member ? added_users : added_teams;
      target.value.push({ uid: item.uid, access: selected_access_level.value.name });
    }
  });
  form$.value.reset();
}

function onShare() {
  const data = {
    users: added_users.value,
    teams: added_teams.value,
    ...(props.is_global_access_visible && { global_access_level: global_access_level.value }),
  };
  props.get_share_data?.(data);
  emit('input', data);
  if (props.is_modal)
    emit('close');
}

function setSelectedAccessLevel(access) {
  selected_access_level.value = props.access_levels.find(item => item.name === access.name);
}

function updateAccessLevel(type, access, index) {
  const target = type === 'user' ? added_users : added_teams;
  target.value[index].access = access.name;
}

function removeAccess(type, index) {
  const target = type === 'user' ? added_users : added_teams;
  target.value.splice(index, 1);
}

onMounted(() => {
  if (added_teams.value.length || added_users.value.length)
    is_initially_shared.value = true;
});

watch([added_users, added_teams, global_access_level], () => !props.is_modal && onShare(), { deep: true });
</script>

<template>
  <component :is="is_modal ? HawkModalTemplate : 'div'">
    <template v-if="is_modal" #header>
      <div class="flex items-center p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800">
        <div class="flex items-center">
          <div v-if="has_icon" class="flex items-center border rounded-xl p-3 mr-4 border-gray-200">
            <IconHawkUsersShare />
          </div>
          <div class="flex flex-col justify-start">
            {{ title }}
            <span class="font-normal text-sm text-gray-600">
              {{ description }}
            </span>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="$emit('close')">
            <IconHawkXClose class="w-6 h-6" />
          </div>
        </div>
      </div>
    </template>

    <Vueform
      ref="form$"
      size="sm"
      class="w-[600px]"
      :add-classes="{
        ElementLabel: {
          container: 'hidden',
        },
      }"
    >
      <template v-if="is_editable">
        <div class="col-span-8">
          <hawk-assignee-input
            class="max-w-[400px]"
            :multi="true"
            :columns="{
              default: { container: 12, label: 0, wrapper: 12 },
              sm: { container: 12, label: 0, wrapper: 12 },
              md: { container: 12, wrapper: 12, label: 0 },
            }"
            :display_other_items="!state.is_adding_external"
            :options="{
              existing_users: [...added_user_uids, ...ignored_members],
              existing_teams: added_team_uids,
              name: 'assignees',
              has_teams: props.has_teams,
              placeholder: $t('Add members and teams'),
              allow_create: !!props.external_user_access_levels?.length,
            }"
            @change="onAssigneeInputChange"
          />
          <slot name="after-input" />
        </div>
        <div class="col-span-4 flex items-center justify-evenly">
          <div v-if="state.is_adding_external" class="py-1.25 text-sm font-semibold text-gray-600">
            {{ props.external_user_access_levels[0].label }}
          </div>
          <HawkMenu
            v-else
            :items="access_levels"
            additional_dropdown_classes="!w-60 !max-h-48 scrollbar"
            position="fixed"
            @select="setSelectedAccessLevel"
          >
            <template #item="{ item }">
              <div class="flex flex-col">
                <span class="text-sm">
                  {{ item.label }}
                </span>
                <span class="text-xs font-normal">
                  {{ item.description }}
                </span>
              </div>
            </template>
            <template #trigger="{ open }">
              <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold text-gray-600">
                {{ selected_access_level.label }}
                <IconHawkChevronUp v-if="open" class="text-lg" />
                <IconHawkChevronDown v-else class="text-lg" />
              </div>
            </template>
          </HawkMenu>
          <hawk-button
            type="outlined"
            :disabled="!form$?.data?.assignees?.length"
            @click="onAdd"
          >
            {{ $t('Add') }}
          </hawk-button>
        </div>
      </template>
      <div
        v-if="hide_empty ? (added_users?.length || added_teams.length || owner_uid) : true" class="font-semibold text-sm col-span-12"
        :class="{ 'mt-5': is_editable }"
      >
        {{ $t('People with access') }}
      </div>
      <div class="col-span-12 max-h-[250px] overflow-auto scrollbar">
        <div v-if="owner_uid" class="flex items-center justify-between">
          <div class="my-2">
            <HawkMembers :members="owner_uid" size="xs" type="label" />
          </div>
          <div class="text-sm mr-3 font-semibold text-gray-300">
            {{ $t('Owner') }}
          </div>
        </div>
        <div v-for="(user, index) of added_users" :key="`${user?.uid}${user?.email}`" class="col-span-12 flex items-center" :class="{ 'pointer-events-none opacity-50': user.disabled }">
          <div class="my-2">
            <div v-if="user?.email" class="flex items-center text-sm text-gray-700 font-medium my-1">
              <div class="mr-2 h-6 w-6 rounded-full bg-gray-50 border border-gray-300 border-dashed" />
              {{ user.email }}
              <span class="ml-2 px-2 py-1 bg-gray-100 rounded-xl text-xs">
                {{ $t('External') }}
              </span>
            </div>
            <HawkMembers v-else :members="user.uid" size="xs" type="label" />
          </div>
          <HawkMenu
            v-if="is_editable"
            class="ml-auto mr-2"
            position="fixed"
            additional_dropdown_classes="!w-60 !max-h-48 scrollbar -mt-3"
            :items="user?.email ? props.external_user_access_levels : access_levels"
            @select="access => updateAccessLevel('user', access, index)"
          >
            <template #item="{ item }">
              <div class="flex flex-col">
                <span class="text-sm">
                  {{ item.label }}
                </span>
                <span class="text-xs font-normal">
                  {{ item.description }}
                </span>
              </div>
            </template>
            <template #trigger="{ open }">
              <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold text-gray-600">
                {{ user?.email ? external_user_access_level_labels_map[user.access]?.label : access_level_labels_map[user.access]?.label }}
                <IconHawkChevronUp v-if="open" class="text-lg" />
                <IconHawkChevronDown v-else class="text-lg" />
              </div>
            </template>
            <template #footer>
              <div
                class="flex cursor-pointer items-center rounded hover:bg-gray-50 py-2 px-3 font-medium text-sm"
                @click="removeAccess('user', index)"
              >
                {{ $t('Remove Access') }}
              </div>
            </template>
          </HawkMenu>
          <div v-else class="ml-auto mr-2 flex items-center rounded py-1.25 text-sm">
            {{ user?.email ? external_user_access_level_labels_map[user.access]?.label : access_level_labels_map[user.access]?.label }}
          </div>
        </div>
        <div v-for="(team, index) of added_teams" :key="team.uid" class="col-span-12 flex items-center">
          <div class="flex my-2">
            <HawkMembers v-if="team.uid" :members="team.uid" type="label" size="xs" />
          </div>
          <HawkMenu
            v-if="is_editable"
            class="ml-auto mr-2"
            position="fixed"
            additional_dropdown_classes="!w-60 !max-h-48 scrollbar -mt-3"
            :items="access_levels"
            @select="access => updateAccessLevel('team', access, index)"
          >
            <template #item="{ item }">
              <div class="flex flex-col">
                <span class="text-sm">
                  {{ item.label }}
                </span>
                <span class="text-xs font-normal">
                  {{ item.description }}
                </span>
              </div>
            </template>
            <template #trigger="{ open }">
              <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold text-gray-600">
                {{ access_level_labels_map[team.access]?.label }}
                <IconHawkChevronUp v-if="open" class="text-lg" />
                <IconHawkChevronDown v-else class="text-lg" />
              </div>
            </template>
            <template #footer>
              <div
                class="flex cursor-pointer items-center rounded hover:bg-gray-50 py-2 px-3 font-medium text-sm"
                @click="removeAccess('team', index)"
              >
                {{ $t('Remove Access') }}
              </div>
            </template>
          </HawkMenu>
          <div v-else class="ml-auto mr-2 flex items-center rounded py-1.25 text-sm">
            {{ access_level_labels_map[team.access]?.label }}
          </div>
        </div>
        <hr v-if="is_global_access_visible" class="border-gray-200 my-4">
        <div v-if="is_global_access_visible" class="flex items-center">
          <slot name="global_access">
            <div class="flex items-center my-1">
              <div class="rounded-full pr-1">
                <IconHawkGlobe class="h-6 w-6 text-gray-600 text-[31px] mr-0.5" />
              </div>
              <div class="my-2 text-sm font-medium text-gray-700">
                <span class="text-gray-900">{{ $t('Anyone') }}</span>
                {{ $t('added in this project') }}
              </div>
            </div>
          </slot>
          <HawkMenu
            v-if="is_editable"
            class="ml-auto mr-2"
            position="fixed"
            :items="global_access_levels"
            @select="access => global_access_level = access.name"
          >
            <template #item="{ item }">
              <div class="flex flex-col">
                <span class="text-sm">
                  {{ item.label }}
                </span>
                <span class="text-xs font-normal">
                  {{ item.description }}
                </span>
              </div>
            </template>
            <template #trigger="{ open }">
              <div class="flex cursor-pointer items-center rounded py-1.25 text-sm font-semibold  text-gray-600">
                {{ global_access_level_labels_map[global_access_level]?.label }}
                <IconHawkChevronUp v-if="open" class="text-lg" />
                <IconHawkChevronDown v-else class="text-lg" />
              </div>
            </template>
          </HawkMenu>
          <div v-else class="ml-auto mr-2 flex items-center rounded py-1.25 text-sm">
            {{ global_access_level_labels_map[global_access_level]?.label }}
          </div>
        </div>
      </div>
    </Vueform>

    <template v-if="is_modal" #footer>
      <Vueform size="sm" class="w-[600px]">
        <div class="col-span-12 flex items-center justify-end w-full">
          <hawk-button class="mr-2 border border-transparent" type="outlined" @click="$emit('close')">
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            v-if="is_editable"
            :disabled="share_button_disabled"
            @click="onShare"
          >
            {{ props.share_action_text }}
          </hawk-button>
        </div>
      </Vueform>
    </template>
  </component>
</template>
