<script setup>
import { cloneDeep } from 'lodash-es';
import { inject, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { IconHawkArrowLeft } from '~/common/components/molecules/hawk-icons/icons.js';
import { useMap } from '~/common/composables/mapbox/maps';
import TerraSidebar from '~/terra/components/terra-sidebar.vue';
import { useTerraStore } from '../store/terra.store';

const is_loading = ref(false);
const layers = [
  {
    id: 'point_feature_layer',
    type: 'circle',
    source: 'all_features_source',
    filter: ['==', '$type', 'Point'],
  },
  {
    id: 'polygon_feature_layer',
    type: 'fill',
    source: 'all_features_source',
    paint: {
      'fill-opacity': 0,
    },
  },
  {
    id: 'linestring_feature_layer',
    type: 'line',
    source: 'all_features_source',
    paint: {
      'line-width': 2,
    },
  },
];
const layersForSelfServe = [
  {
    id: 'point_feature_layer',
    type: 'circle',
    source: 'all_features_source',
    filter: ['==', '$type', 'Point'],
    paint: {

      'circle-color': ['coalesce', ['get', 'color'], '#222'],

    },
  },
  {
    id: 'polygon_feature_layer',
    type: 'fill',
    source: 'all_features_source',
    paint: {
      'fill-color': ['coalesce', ['get', 'fill_color'], 'transparent'],
      'fill-opacity': ['coalesce', ['get', 'opacity'], 0.0],
    },
  },
  {
    id: 'linestring_feature_layer',
    type: 'line',
    source: 'all_features_source',
    paint: {
      'line-width': 2,
      'line-color': ['coalesce', ['get', 'color'], '#222'],
    },
  },

  {
    id: 'symbol-layer-icon',
    type: 'symbol',

    source: 'symbol-source',
    layout: {
      'icon-image': ['coalesce', ['get', 'icon'], 'marker'],
      'icon-size': ['coalesce', ['get', 'icon_size'], 1],
      'icon-allow-overlap': true,
    },
  },
];
const compare = ref(null);

const compare_id_left = 'terra_compare_left';
const compare_id_right = 'terra_compare_right';
const terra_compare_left = useTerraStore(compare_id_left);
const left_sidebar_expanded = ref(true);
const terra_compare_right = useTerraStore(compare_id_right);

const route = useRoute();
const $services = inject('$services');
const { initMapbox, loadMapBoxPackage, addMapboxToken, loadMapboxCompare, removeMapboxInstance } = useMap();

onMounted(async () => {
  try {
    is_loading.value = true;
    await loadMapBoxPackage();

    await loadMapboxCompare();

    await setContainer();
    await init(compare_id_left);
    await init(compare_id_right);

    compare.value = new mapboxgl.Compare(
      terra_compare_left.map,
      terra_compare_right.map,
    );
    await setFtg();
    is_loading.value = false;
  }
  catch (err) {
    logger.log(err);
    is_loading.value = false;
  }
});
onUnmounted(() => {
  try {
    removeMapboxInstance(terra_compare_left.map);
    removeMapboxInstance(terra_compare_right.map);
    terra_compare_left.$reset();
    terra_compare_right.$reset();
    terra_compare_left.$dispose();
    terra_compare_right.$dispose();
  }
  catch (err) {
    logger.log(err);
  }
});
async function toggle_first_project() {
  const first_project = Object.values(
    Object.values(
      terra_compare_left.container?.groups || {},
    )[0]?.projects,
  )[0];

  await Promise.all([
    terra_compare_left.toggle_project(
      {
        project: cloneDeep(first_project),
        forceFly: true,
      },
    ),
    terra_compare_right.toggle_project(
      {
        project: cloneDeep(first_project),
        forceFly: true,
      },
    ),

  ]);
}
async function init(compare_id) {
  const stores = {
    terra_compare_left,
    terra_compare_right,
  };
  const map = await initMapbox({
    container_id: compare_id,
  });
  stores[compare_id].map = map;

  map.on('load', () => {
    if (!map.getSource('all_features_source')) {
      map.addSource('all_features_source', {
        type: 'geojson',
        data: {
          features: [],
          type: 'FeatureCollection',
        },
      });
    }
    layers.forEach((layer) => {
      if (!map.getLayer(layer.id))
        map.addLayer(layer);
    });
  });
}

async function setContainer() {
  try {
    const response = await $services.terra_view_service.get({
      id: route.params.id,
    });
    const container_left = await terra_compare_left.parse_container_data(
      {
        container: cloneDeep(response.data),
      },
    );
    const container_right = await terra_compare_right.parse_container_data(
      {
        container: cloneDeep(response.data),
      },
    );
    terra_compare_left.set_container({
      container: cloneDeep(container_left),
    });
    terra_compare_right.set_container({
      container: cloneDeep(container_right),
    });
  }
  catch (error) {
    logger.log(error);
  }
}
async function setFtg() {
  try {
    if (
      !terra_compare_left.ftg || !terra_compare_right.ftg
    ) {
      const response = await $services.feature_type_groups.getAll({
        query: {
          pk: true,
          container: route.params.id,
        },
      });
      terra_compare_left.set_ftg_and_update_features_styles({
        ftg: response.data,
      });
      terra_compare_right.set_ftg_and_update_features_styles({
        ftg: response.data,
      });
    }

    await toggle_first_project();
    terra_compare_left.update_features_styles();
    terra_compare_right.update_features_styles();
  }
  catch (err) {
    logger.log(err);
  }
}
</script>

<template>
  <div>
    <div v-if="is_loading" class="absolute w-full h-[calc(100vh-70px)] z-50 bg-gray-100 opacity-[0.9] flex justify-center items-center">
      <HawkLoader />
    </div>
    <router-link
      :to="{ name: 'terra-viewer', params: $route.params }"
      class="absolute  z-2"
      :class="left_sidebar_expanded ? 'left-[20rem]' : 'left-[5rem]'"
    >
      <HawkButton
        class="mt-4 flex items-center" type="outlined" block @click="terra_compare_left.terra_track_events('Compare disabled')"
      >
        <IconHawkArrowLeft />
        {{ $t('Back') }}
      </HawkButton>
    </router-link>
    <div class="is-relative">
      <TerraSidebar
        type="compare"
        class="left-0"
        side="left"
        :terra_store_name="compare_id_left"
        @sidebar-toggled="left_sidebar_expanded = !left_sidebar_expanded"
      />
    </div>
    <div class="is-relative">
      <div :id="compare_id_left" />
      <div :id="compare_id_right" />
    </div>
    <div class="is-relative">
      <TerraSidebar
        type="compare"
        side="right"
        class="right-0"
        :terra_store_name="compare_id_right"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #terra_compare_left,
  #terra_compare_right {
    position: absolute;
    bottom: 0;
    height: calc(100vh - 64px);
    width: 100%;
  }

  :deep(.mapboxgl-compare) {
    top: 0;
  }
</style>
