<script setup>
import { onKeyStroke, useFocus } from '@vueuse/core';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkArrowDown, IconHawkArrowUp, IconHawkCheck, IconHawkCornerDownLeft, IconHawkSearchMd } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { clearPersistedStore, currentOrganizationCookie } from '~/common/utils/common.utils.js';

const emit = defineEmits(['close']);
const auth_store = useAuthStore();
const commonStore = useCommonStore();
const state = reactive({
  is_loading: false,
});
const search = ref('');
const selected = ref({});
const key_pressed = ref(-1);
const searchInput = ref();

// on load of the popup, the search input should be focused
const { focused } = useFocus(searchInput, { initialValue: true });

const filter_organization = computed(() => {
  // filter the current organization from the list of organizations
  const organizations = commonStore.organizations.filter((item) => {
    return item.uid !== auth_store.current_organization?.uid;
  });

  // filter the list of organizations when the user search
  return organizations.filter(item =>
    item.name.toLowerCase().includes(search.value.toLowerCase()),
  );
});

onMounted(async () => {
  state.is_loading = !commonStore.organizations?.length;
  await commonStore.set_organization_list({});
  state.is_loading = false;
});

onKeyStroke('ArrowDown', () => {
  key_pressed.value = key_pressed.value === filter_organization.value.length - 1 ? 0 : key_pressed.value + 1;

  selected.value = key_pressed.value !== filter_organization.value.length ? filter_organization.value?.[key_pressed.value] : {};
  document?.getElementById(selected.value?.uid)?.scrollIntoView({
    block: 'end',
    behavior: 'smooth',
  });
});

onKeyStroke('ArrowUp', () => {
  key_pressed.value = key_pressed.value === -1 || key_pressed.value === 0 ? filter_organization.value.length - 1 : key_pressed.value - 1;

  selected.value = key_pressed.value !== -1 ? filter_organization.value?.[key_pressed.value] : {};
  document?.getElementById(selected.value?.uid)?.scrollIntoView({
    block: 'end',
    behavior: 'smooth',
  });
});

onKeyStroke('Enter', () => {
  if (Object.keys(selected?.value)?.length)
    setOrganization(selected?.value?.uid);
});

function setOrganization(e) {
  emit('close');
  const org = commonStore.organizations_map[e];
  if (auth_store.current_organization?.uid !== org.uid) {
    clearPersistedStore();
    currentOrganizationCookie('set', org.uid);
    window.location.href = window.location.origin;
  }
}

watch(() => search.value, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    selected.value = {};
    key_pressed.value = -1;
  }
});
</script>

<template>
  <hawk-modal-container class="organization-popup">
    <div class="col-span-12">
      <div class="flex h-14 items-center mx-4">
        <IconHawkSearchMd class="h-5 w-5 text-gray-600 mr-2" aria-hidden="true" />
        <input
          ref="searchInput"
          v-model="search"
          type="text"
          class="w-full placeholder-gray-500 text-base font-light"
          :placeholder="$t('Search organization')"
        >
      </div>
      <hr>
      <div class="h-96 overflow-auto m-2">
        <div
          class="flex text-sm font-medium items-center justify-between px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700 sticky top-0 bg-white"
        >
          {{ auth_store.current_organization?.name }}
          <IconHawkCheck class="text-primary-500 h-5 w-5" />
        </div>
        <div
          v-if="!filter_organization.length && search?.length"
          class="text-gray-400 text-sm mx-auto text-center mt-12"
        >
          <HawkLoader v-if="state.is_loading" />
          <template v-else>
            {{ $t("No results found for") }} '{{ search }}'
          </template>
        </div>
        <div v-else>
          <HawkLoader v-if="state.is_loading" />
          <div
            v-for="organization in filter_organization"
            v-else
            :id="organization.uid"
            :key="organization.uid"
            class="flex text-sm font-medium items-center justify-between px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
            :class="organization.uid === selected.uid ? 'bg-gray-100' : null"
            @click="
              organization.uid !== auth_store.current_organization?.uid
                ? setOrganization(organization.uid)
                : null
            "
          >
            {{ organization.name }}
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 bg-white border-t border-t-gray-200 rounded-b-lg px-4 py-2 text-sm hidden md:block">
        <div class="flex gap-4">
          <div class="flex items-center gap-2">
            <div class="bg-white border rounded-lg w-7 h-7 grid place-items-center">
              <IconHawkArrowUp class="h-4 w-4 text-gray-500" />
            </div>
            <div class="bg-white border rounded-lg w-7 h-7 grid place-items-center">
              <IconHawkArrowDown class="h-4 w-4 text-gray-500" />
            </div>
            <div class="text-gray-600 font-semibold">
              {{ $t('to navigate') }}
            </div>
          </div>
          <div class="flex items-center gap-2">
            <div class="bg-white border rounded-lg w-7 h-7 grid place-items-center">
              <IconHawkCornerDownLeft class="h-4 w-4 text-gray-500" />
            </div>
            <div class="text-gray-500 font-semibold">
              {{ $t('to select') }}
            </div>
          </div>
          <div class="flex items-center gap-2">
            <div class="bg-white border rounded-lg px-1.5 h-7 grid place-items-center text-gray-500 font-semibold">
              {{ $t('esc') }}
            </div>
            <div class="text-gray-500 font-semibold">
              {{ $t('to close') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </hawk-modal-container>
</template>

<style>
.organization-popup .vfm__content {
  @apply mx-5 min-w-[14rem] sm:!min-w-[36rem] max-w-xl;
}
</style>
