<script setup>
import { nextTick } from 'vue';
import { IconHawkCheckboxBaseBlue, IconHawkClipboard, IconHawkDataflowOne } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  on_submit: {
    type: Function,
    default: null,
  },
  fields: {
    type: Array,
    default: () => ['Template Name', 'Description', 'Category'],
  },
  show_type: {
    type: Boolean,
    default: false,
  },
  show_subtitle: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['close']);
const common_store = useCommonStore();

const forms$ = ref(null);
const form_type = ref('workflow');

async function onSave() {
  const { name, description, category } = forms$.value.data;
  const payload = {
    template: {
      name,
      description,
      category,
    },
  };
  await props.on_submit(payload, form_type.value);
}
async function formMounted() {
  nextTick(() => {
    forms$.value.elements$.name.input.focus();
  });
}

function setCreatedCategory(e) {
  forms$.value.elements$.category.load(e.uid);
}
</script>

<template>
  <hawk-modal-container content_class="!w-[700px]">
    <Vueform
      ref="forms$" size="sm" :display-errors="false"
      :presets="['']" :float-placeholders="false"
      :columns="{
        default: { container: 9 },
        sm: { container: 9, label: 2 },
        md: { container: 9, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("New template") }}
          </template>
          <template v-if="show_subtitle" #subtitle>
            {{ $t("Select an option to create your template") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <div v-if="show_type" class="flex justify-between">
            <div class="flex w-[318px] h-[126px] relative justify-start mr-3 border px-4 py-3 rounded-xl cursor-pointer" :class="{ 'border-primary-600': form_type === 'template' }" @click="form_type = 'template';formMounted()">
              <div>
                <HawkFeaturedIcon theme="light-circle-outline" size="lg" color="primary" class="mr-4">
                  <IconHawkClipboard />
                </HawkFeaturedIcon>
              </div>
              <div>
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <div class="font-medium text-sm">
                      {{ $t('Forms') }}
                    </div>
                    <div class="text-xs leading-5 pr-3">
                      {{ $t('Go paperless with forms by enabling easy creation with diverse fields, and seamlessly assign, prioritize, and collect data.') }}
                    </div>
                  </div>
                  <div :class="[form_type === 'template' ? 'visible' : 'invisible']">
                    <IconHawkCheckboxBaseBlue class="text-primary-600 absolute right-4 mt-0.75 w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-[318px] h-[126px] relative justify-start border px-4 py-3 rounded-2xl cursor-pointer" :class="{ 'border-primary-600': form_type === 'workflow' }" @click="form_type = 'workflow';formMounted()">
              <div>
                <HawkFeaturedIcon theme="light-circle-outline" size="lg" color="primary" class="mr-4">
                  <IconHawkDataflowOne />
                </HawkFeaturedIcon>
              </div>
              <div>
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <div class="font-medium text-sm">
                      {{ $t('Workflow') }}
                    </div>
                    <div class="text-xs leading-5">
                      {{ $t('Define your business processes and workflows and automate data collection, decision-making, establish sequences, approvals, and more.') }}
                    </div>
                  </div>
                  <div :class="[form_type === 'workflow' ? 'visible' : 'invisible']">
                    <IconHawkCheckboxBaseBlue class="text-primary-600 absolute right-4 mt-0.75 w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="form_type">
            <div v-for="(field, i) in fields" :key="i">
              <div class="pt-6">
                <TextElement
                  v-if="field === 'Template Name'"
                  :key="field"
                  autocomplete="off"
                  name="name"
                  :label="$t('Template name')"
                  :rules=" `${['required']}`"
                  :placeholder="$t('enter template name')"
                />
                <TextareaElement
                  v-if="field === 'Description'"
                  :key="field"
                  autocomplete="off"
                  name="description"
                  :label="$t('Description')"
                  :placeholder="$t('enter template description')"
                />
                <hawk-category-input
                  v-if="field === 'Category'"
                  :key="field"
                  class="mb-3"
                  :options="{
                    name: 'category',
                    label: $t('Category'),
                    placeholder: $t('Choose category'),
                    tags_removable: true,
                    create: true,
                    from: 'Forms',
                  }"
                  :setter_function="setCreatedCategory"
                  :multi="false"
                />
              </div>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button type="outlined" :class="{ 'mr-5': form_type }" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :class="{ hidden: !form_type }">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
