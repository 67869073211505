<script setup>
import { orderBy } from 'lodash-es';
import { ref } from 'vue';
import { IconHawkChevronLeft, IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '../store/therm.store';
import AnomaliesList from './therm-anomalies-list.vue';

const search_key = ref('');
const therm_store = useThermStore();
const {
  $t,
} = useCommonImports();
const expand_right_sidebar = computed(() => therm_store.expand_right_sidebar);
const selected_features = computed(() => therm_store.selected_features);
const features = computed(() => therm_store.features);

function set_search_key(value) {
  search_key.value = value;
  therm_store.set_search_key(value);
}

function toggleSidebar(value) {
  if (therm_store.map) {
    setTimeout(() => {
      therm_store.map.resize();
    }, 250);
  }
  therm_store.set_expand_right_sidebar(value);
}

const feature_types = computed(() => {
  return therm_store.feature_types;
});

const filtered_features = computed(() => {
  let filtered = search_key.value.length
    ? features.value.filter(item =>
      getDefectName(item).toLowerCase().includes(search_key.value.toLowerCase()),
    )
    : features.value;
  filtered = orderBy(filtered, filter => getDefectName(filter), ['asc']);
  return filtered;
});

function getDefectName(defect) {
  return (
    `${defect.properties.modified_string_number || defect.properties.string_number} ${
      feature_types.value[defect.properties?.featureTypeId]
        ? ` : ${feature_types.value[defect.properties?.featureTypeId]?.name}`
        : ''
    }`
  );
}

function getSidebarClass() {
  if (therm_store.gallery_view_state.is_active)
    return 'grow-0 shrink-0 basis-[390px]';
  if (expand_right_sidebar.value)
    return 'grow-0 shrink-0 basis-[360PX]';
  return 'grow-0 shrink-0 basis-11 w-11';
}
</script>

<template>
  <div
    class="therm-right-sidebar border-t border-gray-300 h-full bg-white transition-all duration-200 ease-in-out relative"
    :class="getSidebarClass()"
  >
    <div
      class="therm-right-sidebar__expand-icon absolute z-10 top-4 shadow-md rounded-[56px] bg-white"
      @click="toggleSidebar(!expand_right_sidebar)"
    >
      <div class="cursor-pointer w-6 h-6 flex items-center justify-center">
        <IconHawkChevronRight v-if="expand_right_sidebar" class="w-4 h-4 text-gray-600" />
        <IconHawkChevronLeft v-else class="w-4 h-4 text-gray-600" />
      </div>
    </div>
    <div v-if="expand_right_sidebar">
      <div
        v-if="!selected_features.length || selected_features.length > 1"
        class="py-5 px-6"
      >
        <span class="text-lg text-gray-900 font-semibold">
          {{ $t("Defects List") }}
        </span>
        <span class="text-lg text-gray-900 font-semibold"> ({{ filtered_features && filtered_features.length }})</span>
      </div>
      <div class="w-full px-6">
        <hawk-search-input
          v-if="!selected_features.length || selected_features.length > 1"
          v-model:model-value="search_key"
          :full_width="true"
          :placeholder="$t('Search')"
          @update:model-value="(e) => set_search_key(e)"
        />
      </div>

      <AnomaliesList class="mt-2" :filtered_features="filtered_features" />
    </div>
    <div v-else class="flex justify-center items-center w-full h-full rotate-90 m-auto whitespace-nowrap">
      <span class="text-gray-600 font-medium text-sm">{{ $t("Defects List") }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.therm-right-sidebar {
  &__expand-icon {
    left: -10px;
  }
}
</style>
