<script setup>
import { IconHawkChevronDown, IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  available_columns: {
    type: Array,
    required: true,
    default: () => [],
  },
  label_field_name: {
    type: String,
    required: true,
  },
  selected_available_columns: {
    type: Array,
    required: true,
    default: () => [],
  },
  active_groups: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  level: {
    type: Number,
    required: true,
    default: 0,
  },
});

const emit = defineEmits(['activateGroup', 'reject']);

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const emitter = useEmitter();

function toggleGroupVisibility(group_name) {
  emitter.emit('active_groups_changed', {
    type: props?.active_groups?.[props.level]?.includes(group_name) ? 'remove' : 'add',
    level: props.level,
    value: group_name,
  });
}

function onChange(event) {
  if (!event?.added?.element?._added)
    emit('reject', event);
}
</script>

<template>
  <draggable
    tag="div"
    :group="{ name: 'columns', pull: 'clone' }"
    :list="props.available_columns"
    item-key="name"
    draggable=".is_draggable_group"
    @change="onChange"
  >
    <template #item="{ element: field_group }">
      <div
        class="is_draggable_group"
      >
        <div
          class="flex items-center h-8 cursor-pointer"
          @click="toggleGroupVisibility(field_group.name)"
        >
          <IconHawkChevronDown v-if="props.active_groups[props.level]?.includes(field_group.name)" />
          <IconHawkChevronRight v-else />
          <span class="ml-2 text-sm font-medium text-gray-700 capitalize">
            {{ field_group?.[label_field_name] }}
          </span>
        </div>
        <div v-if="props.active_groups[props.level]?.includes(field_group.name)" class="py-1">
          <draggable
            :list="field_group.children"
            :group="{ name: 'columns', pull: 'clone' }"
            item-key="name"
            @change="onChange"
          >
            <template #item="{ element: column }">
              <div
                v-if="column?.[label_field_name] && !column?.children"
                class="py-2 pl-8 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50"
                :class="{ 'bg-gray-100 hover:!bg-gray-100': props.selected_available_columns.find(col => col.name === column.name) }"
                @click="$event => emitter.emit('selection_add', { event: $event, column })"
              >
                {{ column?.[label_field_name] }}
              </div>
              <HawkDraggableTree
                v-else-if="column?.children?.length"
                :available_columns="[column]"
                :label_field_name="label_field_name"
                :selected_available_columns="props.selected_available_columns"
                :active_groups="props.active_groups"
                :level="props.level + 1"
                class="pl-8"
                @reject="onChange"
              />
            </template>
          </draggable>
        </div>
      </div>
    </template>
  </draggable>
</template>
