<script setup>
import { computed } from 'vue';
import { IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['badge', 'tiny', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(value);
    },
  },
  member_details: {
    type: Object,
    default: () => {},
  },
  background_color: {
    type: String,
    default: '',
  },
  can_clear: {
    type: Boolean,
    default: false,
  },
  is_outlined: {
    type: Boolean,
    default: false,
  },
  has_avatar: {
    type: Boolean,
    default: true,
  },
  name_truncate_length: {
    type: Number,
    default: 30,
  },
});

defineEmits(['clear']);

const user_name_classes = computed(() => {
  const username = [];
  switch (props.size) {
    case 'badge':
    case 'xxs':
      username.push('text-xs');
      break;
    case 'xs':
      username.push('text-xs');
      break;
    case 'sm':
      username.push('text-xs');
      break;
    case 'md':
      username.push('text-sm');
      break;
    case 'lg':
      username.push('text-sm');
      break;
  }

  return username;
});

const user_classes = computed(() => {
  const username = [];
  switch (props.size) {
    case 'badge':
    case 'tiny':
    case 'xxs':
      username.push('p-[3px]');
      break;
    case 'xs':
      username.push('p-[3px]');
      break;
    case 'sm':
      username.push('p-[3px]');
      break;
    case 'md':
      username.push('p-1');
      break;
    case 'lg':
      username.push('p-1.5');
      break;
  }

  if (props.is_outlined)
    username.push('border border-gray-300 rounded-lg');

  else
    username.push('bg-gray-100 rounded-full');

  return username;
});
</script>

<template>
  <!-- Add support for team, sensehawk, unknown -->
  <div
    class="flex items-center h-fit"
    :class="[user_classes]"
  >
    <template v-if="has_avatar">
      <SensehawkAvatar
        v-if="member_details.type === 'sensehawk'"
        size="badge"
        class="flex-shrink-0"
      />
      <UnknownAvatar
        v-else-if="member_details.type === 'unknown'"
        size="badge"
        class="flex-shrink-0"
      />
      <TeamAvatar
        v-else-if="member_details.is_team"
        size="badge"
        class="flex-shrink-0"
      />
      <HawkAvatar
        v-else
        :name="member_details?.name"
        v-bind="props"
        :img_src="member_details?.display_picture"
        size="badge"
        class="flex-shrink-0"
      />
    </template>
    <div
      :class="user_name_classes"
      class="mx-1.5 text-gray-700 font-medium"
    >
      <HawkText :content="member_details?.name" :length="name_truncate_length" class="whitespace-nowrap" />
    </div>
    <IconHawkX
      v-if="can_clear"
      class="h-4 w-4 cursor-pointer hover:bg-gray-300 rounded-full"
      @click.stop="$emit('clear', uid)"
    />
  </div>
</template>
