<!-- eslint-disable vue/prop-name-casing -->
<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import { IconHawkChevronDown, IconHawkChevronRight, IconHawkDotsVertical } from '~/common/components/molecules/hawk-icons/icons.js';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  name: 'Tree',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selected_item: {
      type: Object,
      default: null,
    },
    context_menu: {
      type: Array,
      required: false,
      default: () => [],
    },
    context_menu_options: {
      type: Object,
      required: false,
    },
    expand_icon_on_right: {
      type: Boolean,
      default: true,
    },
    children_key: {
      type: String,
      default: 'children',
    },
    dynamic_size_key: {
      type: String,
      default: 'name',
    },
    expanded_items: {
      type: Array,
      default: () => [],
    },
    expanded_items_key: {
      type: String,
      default: 'uid',
    },
    selected_item_key: {
      type: String,
      default: 'uid',
    },
    value_key: {
      type: String,
      default: 'name',
    },
    key_field: {
      type: String,
      default: '',
    },
    tree_class: {
      type: String,
      default: 'border p-4',
    },
    item_class: {
      type: String,
      default: 'hover:bg-gray-100',
    },
    item_content_class: {
      type: String,
      default: 'font-semibold',
    },

    item_selected_class: {
      type: String,
      default: 'bg-blue-100',
    },
    full_row_click: {
      type: Boolean,
      default: false,
      required: false,
    },
    get_children_func: {
      type: Function,
      default: null,
    },
    move: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['itemClick', 'selectItem', 'openContextMenu', 'closeContextMenu', 'selectContextMenu'],
  data() {
    return {
      expanded_items_data: [],
      is_context_menu_open: false,
      children_data_key: {},
      is_loading_children: {},
    };
  },
  computed: {

    visible_items() {
      return this.items;
    },
    computed_tree_class() {
      return 'pl-6';
    },
    computed_item_class() {
      return `border-t border-gray-200 ${this.item_class} ${this.computed_tree_class}`;
    },
    computed_item_content_class() {
      return ['pl-4 py-2', this.item_content_class];
    },
  },
  watch: {
    // initialize and watch the expanded_items array with the prop value

    expanded_items: {
      handler() {
        this.expanded_items_data = this.expanded_items;
        if (this.get_children_func) {
          this.items.forEach((item) => {
            if (this.expanded_items.includes(item[this.expanded_items_key]))
              this.children_data_key[item[this.expanded_items_key]] = item[this.children_key];
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleItemClick(item, e) {
      if (this.is_context_menu_open || item?.disabled)
        return;

      else
        this.$emit('selectItem', { item, shiftKey: e.shiftKey });

      if (this.full_row_click)
        this.toggleExpand(item);
    },
    async toggleExpand(item) {
      if (!this.expanded_items_data.includes(item[this.expanded_items_key])) {
        if (!this.expanded_items_data.includes(item[this.expanded_items_key]))
          this.expanded_items_data.push(item[this.expanded_items_key]);
        if (this.get_children_func) {
          if (this.children_data_key[item[this.expanded_items_key]])
            this.children_data_key[item[this.expanded_items_key]] = [];
          this.is_loading_children[item[this.expanded_items_key]] = true;
          const children = await this.get_children_func(item);
          this.is_loading_children[item[this.expanded_items_key]] = false;
          this.children_data_key[item[this.expanded_items_key]] = children;
        }
      }
      else {
        const index = this.expanded_items_data.indexOf(item[this.expanded_items_key]);
        if (index !== -1)
          this.expanded_items_data.splice(index, 1);
      }
    },
    onOpenContextMenu(item) {
      this.is_context_menu_open = true;
      this.$emit('openContextMenu', item);
    },
    onCloseContextMenu(item) {
      this.is_context_menu_open = false;
      this.$emit('closeContextMenu', item);
    },
  },
};
</script>

<template>
  <div>
    <DynamicScroller
      :items="visible_items"
      :min-item-size="40"
      class="scroller scrollbar"
      :key-field="key_field"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          :data-active="active"
          :size-dependencies="[
            item[dynamic_size_key],
          ]"
        >
          <div
            :key="item.uid"
            class="flex items-center cursor-pointer py-2 px-4 group"
            :class="[
              item_class,
              expand_icon_on_right && 'justify-between',
              (selected_item && selected_item[selected_item_key] === item[selected_item_key]) ? item_selected_class : '',
              item?.disabled && '!pointer-events-none opacity-50',
            ]"
            @click="handleItemClick(item, $event)"
          >
            <div :class="[expand_icon_on_right ? 'order-last' : '']" class="flex-shrink-0">
              <div
                v-if="item[children_key] && item[children_key].length"
                class="text-gray-500 mr-1"
                @click.stop.prevent="toggleExpand(item)"
              >
                <div v-if="expanded_items_data.includes(item[expanded_items_key])">
                  <slot name="expand-icon">
                    <HawkLoader v-if="is_loading_children[item[expanded_items_key]]" :height="4" :width="4" container_class="mx-0.5" />
                    <IconHawkChevronDown v-else />
                  </slot>
                </div>
                <div v-else>
                  <slot name="collapse-icon">
                    <IconHawkChevronRight />
                  </slot>
                </div>
              </div>
            </div>

            <slot name="item" :data="item">
              <div class="flex-grow {{ computed_item_content_class }} ">
                {{ item[value_key] }}
              </div>
            </slot>

            <div v-if="context_menu.length" class="h-5">
              <hawk-menu
                :items="context_menu"
                class="h-5 pt-[2px] tree-item-context-menu items-center flex"
                v-bind="context_menu_options"
                @open="onOpenContextMenu(item)"
                @close="onCloseContextMenu(item)"
                @select="$emit('selectContextMenu', $event, item)"
              >
                <template #trigger>
                  <IconHawkDotsVertical />
                </template>
              </hawk-menu>
            </div>
          </div>
          <Tree
            v-if="item[children_key] && item[children_key].length && (expanded_items_data.includes(item[expanded_items_key]))"
            :key="item.uid"
            :children_key="children_key"
            :items="get_children_func ? (children_data_key[item[expanded_items_key]] || []) : item[children_key]"
            :expanded_items="expanded_items"
            :selected_item="selected_item"
            :value_key="value_key"
            :key_field="key_field"
            :expanded_items_key="expanded_items_key"
            :selected_item_key="selected_item_key"
            :item_class="item_class"
            :class="computed_tree_class"
            :item_selected_class="item_selected_class"
            :expand_icon_on_right="expand_icon_on_right"
            :full_row_click="full_row_click"
            :get_children_func="get_children_func"
            @select-item="$emit('selectItem', $event)"
          >
            <template #item="{ data }">
              <slot name="item" :data="data" />
            </template>
            <template #expand-icon>
              <slot name="expand-icon" />
            </template>
            <template #collapse-icon>
              <slot name="collapse-icon" />
            </template>
          </Tree>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>

<style scoped lang="scss">
.scroller {
  height: 100%;
  overflow: auto;
}
.hawk-hover {
  z-index: 2;
  .tree-item-context-menu {
    display: none;
  }
  &:hover {
    color: white !important;
    svg {
      color: white;
    }
    #asset, #organization {
      color: white;
    }
    &::before {
      display: block;
    }
    .tree-item-context-menu {
      display: block;
    }
  }
  &::before {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 34px;
    background-color: #344054;
    z-index: -1;
    border-radius: 0.375rem;
  }
}
.hawk-hover.active {
  color: white;
  svg {
    color: white;
  }
  &::before {
    display: block;
  }
  #asset, #organization {
    color: white;
  }
}
</style>
