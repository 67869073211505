<script>
import { IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

export default {
  name: 'YieldEstimator',
  props: {
    coordinates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: null,

      base_url: 'https://developer.nrel.gov/api/pvwatts',
      api_key: import.meta.env.VITE_APP_PVWATTS_API_KEY,

      system_capacity: 1,
      azimuth: 180,
      losses: 10,
      gcr: 0.5,

      module_types: [0, 1, 2],
      array_types: [0, 3],
      module_mapping: {
        0: 'Standard',
        1: 'Premium',
        2: 'Thin Film',
      },
      array_mapping: {
        0: 'Fixed Tilt',
        3: 'Single Axis',
      },

      yield_estimate: {
        'Standard': {
          'Fixed Tilt': {
            ac_annual: '',
            solrad_annual: '',
          },
          'Single Axis': {
            ac_annual: '',
            solrad_annual: '',
          },
        },
        'Premium': {
          'Fixed Tilt': {
            ac_annual: '',
            solrad_annual: '',
          },
          'Single Axis': {
            ac_annual: '',
            solrad_annual: '',
          },
        },
        'Thin Film': {
          'Fixed Tilt': {
            ac_annual: '',
            solrad_annual: '',
          },
          'Single Axis': {
            ac_annual: '',
            solrad_annual: '',
          },
        },
      },
    };
  },
  watch: {
    coordinates: {
      async handler() {
        await this.init();
      },
      deep: true,
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;

      const promiseCollection = [];
      for (const module_type of this.module_types) {
        for (const array_type of this.array_types) {
          const queries = this.getQueryStr(module_type, array_type);
          promiseCollection.push(
            fetch(`${this.base_url}/v8.json?${queries}`),
          );
        }
      }

      const responses = await Promise.all(promiseCollection);
      for (const res of responses) {
        const data = await res.json();
        const module = this.module_mapping[
          Number.parseInt(data.inputs.module_type, 10)
        ];
        const arr = this.array_mapping[Number.parseInt(data.inputs.array_type, 10)];
        this.yield_estimate[module][arr].ac_annual = data.outputs.ac_annual;
        this.yield_estimate[module][arr].solrad_annual = data.outputs.solrad_annual;
      }

      this.loading = false;
    },
    getQueryStr(module_type, array_type) {
      const tilt = this.getTilt(array_type);

      const obj = {
        api_key: this.api_key,
        lat: this.coordinates[1],
        lon: this.coordinates[0],
        system_capacity: this.system_capacity,
        azimuth: this.azimuth,
        module_type: Number.parseInt(module_type, 10),
        array_type: Number.parseInt(array_type, 10),
        losses: this.losses,
        gcr: this.gcr,
        tilt,
        dataset: 'intl',
      };
      let query = '';
      for (const key in obj)
        query += `${key}=${obj[key]}&`;

      return query;
    },
    getTilt(array_type) {
      if (this.coordinates[1] < 25)
        return this.coordinates[1] * 0.87;
      else if (this.coordinates[1] >= 25 && this.coordinates[1] < 50)
        return this.coordinates[1] * 0.76 + 3.1;

      return 0;
    },
  },
};
</script>

<template>
  <div class="bg-white rounded-lg">
    <header class="flex items-center justify-between p-3 border-b">
      <p class="text-[16px] font-semibold">
        {{ $t('Yield Estimator') }}
      </p>
      <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center" @click="$emit('close')">
        <IconHawkX class="text-lg" />
      </div>
    </header>
    <div class="p-3 min-w-[620px]">
      <div v-if="loading" class="min-h-[190px] flex items-center justify-center">
        <HawkLoader />
      </div>
      <table
        v-else
        class="border-collapse border border-slate-500 w-full text-center !text-[13px]"
      >
        <thead>
          <tr>
            <th rowspan="3" scope="row" class="border border-slate-300 p-2">
              {{ $t('Module Type') }}
            </th>
            <th colspan="5" scope="colgroup" class="border border-slate-300 p-2">
              {{ $t('Tracker') }}
            </th>
          </tr>
          <tr>
            <th colspan="2" scope="colgroup" class="border border-slate-300 p-2">
              {{ $t('Fixed Tilt') }}
            </th>
            <th colspan="2" scope="colgroup" class="border border-slate-300 p-2">
              {{ $t('Single Axis') }}
            </th>
          </tr>
          <tr>
            <th scope="col" class="border border-slate-300 p-2">
              {{ $t('AC Annual') }}
            </th>
            <th scope="col" class="border border-slate-300 p-2">
              {{ $t('Solar Irradiance Annual') }}
            </th>
            <th scope="col" class="border border-slate-300 p-2">
              {{ $t('AC Annual') }}
            </th>
            <th scope="col" class="border border-slate-300 p-2">
              {{ $t('Solar Irradiance Annual') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="key in Object.keys(yield_estimate)"
            :key="key"
          >
            <td class="border border-slate-300 p-2">
              {{ key }}
            </td>
            <td class="border border-slate-300 p-2">
              {{ yield_estimate[key]["Fixed Tilt"].ac_annual || $t('NA') }}
            </td>
            <td class="border border-slate-300 p-2">
              {{ yield_estimate[key]["Fixed Tilt"].solrad_annual || $t('NA') }}
            </td>
            <td class="border border-slate-300 p-2">
              {{ yield_estimate[key]["Single Axis"].ac_annual || $t('NA') }}
            </td>
            <td class="border border-slate-300 p-2">
              {{ yield_estimate[key]["Single Axis"].solrad_annual || $t('NA') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
