<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { IconHawkXClose } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['closed']);
</script>

<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-[1000]" @close="emit('closed')">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen lg:max-w-3xl md:max-w-2xl sm:max-w-full"
              >
                <div
                  class="flex h-full flex-col bg-white shadow-xl"
                >
                  <div class="px-4 sm:px-6">
                    <slot name="header" />
                    <div class="absolute top-0 right-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <HawkButton icon type="text" size="md" @click="emit('closed')">
                        <IconHawkXClose />
                      </HawkButton>
                    </div>
                  </div>
                  <div class="flex flex-col overflow-auto">
                    <slot name="content" />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
