<script setup>
import { storeToRefs } from 'pinia';
import { IconHawkDotsVertical } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import useEmitter from '~/common/composables/useEmitter';
import SmTemplateContextMenu from '~/system-model/molecules/sm-template-context-menu.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const system_model_store = useSystemModelStore();
const emitter = useEmitter();

const state = reactive({
  is_loading: false,
  template_uid_for_version: null,
  is_syncing_change: false,
});

const { auth_store, router, route, $t } = useCommonImports();
const { can_modify_resources } = storeToRefs(system_model_store);

emitter.on('template_version_syncing', (payload) => {
  state.template_uid_for_version = payload?.template_uid || null;
  state.is_syncing_change = payload?.is_sync || false;
});

watch(() => route.params.asset_id, async (value) => {
  if (!value)
    return;
  if (route.name !== 'sm-templates') {
    router.push({
      name: 'sm-templates',
      params: {
        asset_id: value,
      },
    });
  }
  await getData();
});

onMounted(async () => {
  await getData();
});

onBeforeUnmount(() => {
  emitter.off('template_version_syncing');
  system_model_store.$reset();
});

async function getData() {
  state.is_loading = true;
  await system_model_store.set_system_model_initial_data({ asset_id: route?.params?.asset_id });
  await setCurrentPage();
  state.is_loading = false;
}

async function setCurrentPage() {
  const { component, instance, active } = route.query;
  if (component) {
    if (active) {
      system_model_store.$patch({
        active_tab: active === 'details' ? 'SmComponentDetails' : 'SmInstances',
      });
    }
    system_model_store.$patch({
      active_component: system_model_store.templates_components_map[component],
    });
  }
  if (instance) {
    await system_model_store.set_active_component_details({ component_id: component });
    system_model_store.$patch({
      active_tab: 'SmInstances',
      active_instance: { uid: instance },
    });
  }
}

async function markAsCurrentVersion() {
  await system_model_store.set_as_current_version({
    template_uid: state.template_uid_for_version,
  });
  await system_model_store.set_templates({ asset_id: route.params.asset_id });
}

function getCurrentTemplate() {
  return system_model_store?.templates_map[route.params.template_id];
}

function versioningCompleted() {
  setTimeout(() => {
    state.template_uid_for_version = null;
    state.is_syncing_change = false;
  }, 2000);
}
</script>

<template>
  <HawkExportToast
    v-if="state.template_uid_for_version"
    :submit="markAsCurrentVersion"
    :progress_text="state.is_syncing_change ? $t('Syncing changes') : $t('Updating version')"
    :completed_text="state.is_syncing_change ? $t('Successfully synced the changes') : $t('Successfully updated the version')"
    subtitle=""
    :show_cancel_button="false"
    :show_subtitle="false"
    @complete="versioningCompleted"
    @close="state.template_uid_for_version = null; state.is_syncing_change = false;"
  />

  <HawkPageHeader class="h-[70px]" :title="$t('System model')">
    <template #right>
      <SmTemplateContextMenu
        v-if="route.name === 'sm-template-details' && can_modify_resources"
        :template="getCurrentTemplate()"
        additional_trigger_classes="!ring-0"
      >
        <template #trigger_button>
          <hawk-button icon type="outlined">
            <IconHawkDotsVertical class="w-5 h-5" />
          </hawk-button>
        </template>
      </SmTemplateContextMenu>
    </template>
  </HawkPageHeader>
  <div class="px-4 pb-4">
    <router-view :is_loading="state.is_loading" />
  </div>
</template>
