<script setup>
import DOMPurify from 'dompurify';
import { VueFinalModal } from 'vue-final-modal';
import { IconHawkPlus } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  el$: {
    type: Object,
    default: () => ({}),
  },
});

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const state = reactive({
  open_information_popup: false,
  current_html: props.el$.form$.data.config.placeholder,
});

function onSave() {
  // eslint-disable-next-line vue/no-mutating-props
  props.el$.form$.modelValue.config.placeholder = state.current_html;
  state.open_information_popup = false;
}

function onInput(html) {
  state.current_html = DOMPurify.sanitize(html);
}
</script>

<template>
  <div>
    <div class="flex cursor-pointer" @click="state.open_information_popup = true">
      <IconHawkPlus class="!text-primary-700 mr-2" />
      <span v-if="el$.form$.data.config.placeholder && el$.form$.data.config.placeholder.length > 0" class="text-sm font-semibold text-primary-700">
        {{ $t('Edit Information') }}
      </span>
      <span v-else class="text-sm font-semibold text-primary-700">
        {{ $t('Add Information') }}
      </span>
    </div>
    <VueFinalModal
      v-model="state.open_information_popup"
      class="flex justify-center items-center"
      content-class="bg-white border space-t-2 rounded-xl h-[calc(100vh-64px)] w-[calc(100vw-64px)]"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
      @click-outside="state.open_information_popup = false"
      @closed="state.open_information_popup = false"
      @click.stop
    >
      <HawkModalHeader @close="state.open_information_popup = false" @cancel="state.open_information_popup = false">
        <template #title>
          {{ $t('Information') }}
        </template>
      </HawkModalHeader>
      <HawkModalContent class="!p-0 h-[calc(100%-160px)]">
        <HawkWysiwygEditorComponent
          :plugins="['tables', 'images']"
          :initial_content="el$.form$.data.config.placeholder"
          :menu_enabled="true"
          placeholder_text="Add information here"
          @input="onInput"
        />
      </HawkModalContent>
      <HawkModalFooter>
        <template #right>
          <div class="flex justify-end">
            <HawkButton type="outlined" class="mr-3" @click="state.open_information_popup = false">
              {{ $t('Cancel') }}
            </HawkButton>
            <HawkButton @click="onSave">
              {{ $t('Save') }}
            </HawkButton>
          </div>
        </template>
      </HawkModalFooter>
    </VueFinalModal>
  </div>
</template>
