<script setup>
import { IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  asset_uid: {
    type: String,
  },
  show_asset: {
    type: Boolean,
    default: false,
  },
  task: {
    type: Object,
  },
  hide_breadcrumbs: {
    type: Boolean,
    default: false,
  },
  show_location: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['onNameClicked']);

const $t = inject('$t');
const common_store = useCommonStore();
const { checkTaskPermission } = useTasksPermissions();

const location_name = computed(() => {
  let location_name = [];
  if (
    props.task?.target_element?.asset && common_store.get_asset(props.task.target_element.asset)
  ) {
    if (props.task.target_element.asset)
      location_name = [common_store.get_asset(props.task.target_element.asset).name];
    if (props.task.properties?.reference_name)
      location_name.push(props.task.properties?.reference_name);
    return location_name;
  }
  return [];
});

const breadcrumb_items = computed(() => {
  return props.task.breadcrumbs?.length
    ? [...props.task.breadcrumbs.map(b => ({ uid: b.uid, label: b.name })), { uid: 'tasks', label: $t('tasks') }]
    : [];
});
</script>

<template>
  <div class="flex items-center">
    <div>
      <nav v-if="!props.hide_breadcrumbs" class="flex text-xs items-center" aria-label="Breadcrumb">
        <ol v-if="show_location">
          <li
            v-for="(item, index) in location_name"
            :key="item"
            class="inline mr-0.5"
          >
            <HawkText :length="15" :content="item" class="inline mr-0.5" />
            <IconHawkChevronRight v-if="index !== location_name.length - 1" aria-hidden="true" class="inline w-[10px] h-[10px]" />
          </li>
        </ol>
        <hawk-breadcrumbs
          v-else
          :items="breadcrumb_items"
          :show_active_color="false"
        />
      </nav>
      <p
        class="text-sm font-medium text-gray-900 group-hover/task-name:text-primary-700"
        @click="checkTaskPermission({ permission: 'can_modify', instance: task }) ? emit('onNameClicked') : null"
      >
        {{ task.name }}
      </p>
    </div>
    <div class="ml-4">
      <slot name="right" />
    </div>
  </div>
</template>
