<script setup>
// --------------------------------- Imports -------------------------------- //
import { find } from 'lodash-es';
import { IconHawkDragIcon } from '~/common/components/molecules/hawk-icons/icons.js';
import ChecklistInput from '~/common/components/organisms/hawk-checklist-fields/checklist-input.vue';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  subitem_level: {
    type: Number,
    default: 0,
  },
  dropdown_items: {
    type: Array,
    required: true,
  },
  sortable: {
    type: Boolean,
    default: true,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['addSubItem', 'updateStatus', 'updateChecklist', 'deleteItem', 'renameItem', 'updateItemPosition']);

const Draggable = defineAsyncComponent(() => import('vuedraggable'));

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //
const show_input = ref(0);
const can_rename = ref(0);
const open_dropdown = ref(null);
// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //

function handleChange(event) {
  emit('updateChecklist', props.items, { type: 'move', data: event });
}

function addChecklistSubItems(uid, children) {
  const item = find(props.items, o => o.uid === uid);
  if (item.children)
    item.children = [...item.children, ...children];
  else item.children = children;
  emit('updateChecklist', props.items);
  show_input.value = 0;
}

function handleDelete(uid) {
  emit('updateChecklist', props.items, { type: 'delete', data: uid });
}

function handleNameChange(uid, value) {
  emit('updateChecklist', props.items, { type: 'rename', data: { uid, value } });
  can_rename.value = false;
}

function selectHandler(selectedObject, element_uid) {
  if (selectedObject.emit === 'addSubItem')
    show_input.value = element_uid;
  else if (selectedObject.emit === 'deleteItem')
    handleDelete(element_uid);
  else if (selectedObject.emit === 'renameItem')
    can_rename.value = element_uid;
}
// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div>
    <Draggable
      tag="div"
      :list="items"
      :animation="0"
      :sort="props.sortable"
      item-key="item"
      group="checklist"
      ghost-class="ghost"
      @change="handleChange"
    >
      <template #item="{ element }">
        <div>
          <div>
            <div v-if="can_rename === element.uid" class=" hover:bg-gray-50 rounded-lg">
              <ChecklistInput
                class="mb-2 py-2 ml-5 mt-4 w-[97%]"
                :name="element.name"
                @close="can_rename = false"
                @add="handleNameChange(element.uid, $event)"
              />
            </div>
            <div
              v-else
              class="hover:bg-gray-50 rounded-lg px-1 py-2 group"
            >
              <div class="grid grid-cols-12">
                <div class="col-span-8 text-sm text-gray-700 flex items-center">
                  <div class="flex items-center">
                    <div>
                      <IconHawkDragIcon :class="{ '!invisible': !props.sortable }" class="cursor-move mr-1.5 h-4 invisible group-hover:visible text-gray-300" />
                    </div>
                    <p class="item-counter" />
                    <p>
                      {{ element.name }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="dropdown_items.length" class="col-span-4 flex justify-end items-center invisible group-hover:visible"
                  :class="{ '!visible': open_dropdown === element.uid }"
                >
                  <hawk-menu
                    position="fixed"
                    :items="dropdown_items"
                    @select="$event => selectHandler($event, element.uid)"
                    @open="open_dropdown = element.uid"
                    @close="open_dropdown = null"
                  />
                </div>
              </div>
            </div>
          </div>
          <ChecklistInput
            v-if="show_input === element.uid"
            class="mx-5 my-4 w-[87%]"
            placeholder="Enter subitem"
            :allow_multiple="true"
            @close="show_input = false"
            @add="addChecklistSubItems(element.uid, $event)"
          />
          <div v-if="subitem_level === 0" class="border-b" />
        </div>
      </template>
    </Draggable>
  </div>
</template>

<style scoped lang="scss">
OL { counter-reset: item }
LI { display: block; position: relative }

.item-counter:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
}
</style>
