<script setup>
import { IconHawkBellOne, IconHawkLockOne, IconHawkSettingsOne } from '~/common/components/molecules/hawk-icons/icons.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t } = useCommonImports();

const sidebar_top_items = computed(() => ([
  { uid: 'profile-settings-general', label: $t('General'), to: { name: 'profile-settings-general' }, icon: IconHawkSettingsOne },
  { uid: 'profile-settings-security', label: $t('Security'), to: { name: 'profile-settings-security' }, icon: IconHawkLockOne },
  { uid: 'profile-settings-notifications', label: $t('Notifications'), to: { name: 'profile-settings-notifications' }, icon: IconHawkBellOne },
]));
</script>

<template>
  <div class="flex">
    <HawkSidebarNavigation :sidebar_top_items="sidebar_top_items" />
    <div class="w-[calc(100vw-80px)] flex-1 pl-20 relative mx-4">
      <div class="py-5">
        <RouterView />
      </div>
    </div>
  </div>
</template>
