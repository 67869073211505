<script>
import { IconHawkUploadCloudTwoGrayRipple, IconHawkUploadCloudTwoInlineGrayRipple } from '~/common/components/molecules/hawk-icons/icons.js';

export default {
  name: 'DragAndDrop',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        icon: '',
        title: '',
        description: '',
      },
    };
  },
};
</script>

<template>
  <div
    ref="area"
    :class="[
      {
        '!flex-row !justify-start !h-5': options?.inline,
        'mt-2': !options?.inline,
      },
      classes.container,
    ]"
    style="border-style: solid !important;cursor: default;"
  >
    <IconHawkUploadCloudTwoInlineGrayRipple v-if="options?.inline" class="w-9 h-9 inline mr-3" />
    <IconHawkUploadCloudTwoGrayRipple v-else class="w-12 h-12 mb-3" />
    <div class="text-center">
      <span v-if="options" class="text-gray-600 text-sm font-regular">
        <span
          v-if="options?.clickable_text"
          class="text-primary-700 text-sm font-semibold cursor-pointer"
          @click.prevent="handleClick"
        >
          {{ options.clickable_text }}
        </span>
        {{ options.text }}
      </span>
      <br>
      <span v-if="options?.description" class="text-gray-600 text-xs font-regular">{{ options.description }}</span>
    </div>
    <div class="dnd_slot">
      <slot />
    </div>
  </div>
</template>
