<script>
import { VueFinalModal } from 'vue-final-modal';
import { IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';

export default {
  components: {
    VueFinalModal,
  },
  props: {
    footer_buttons: {
      type: Array,
      default: () => [],
    },
    disable_header: {
      type: Boolean,
      default: false,
    },
    disable_footer: {
      type: Boolean,
      default: false,
    },
    content_class: {
      type: String,
      default: 'rounded-lg',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'cancel'],
};
</script>

<template>
  <VueFinalModal
    v-bind="options"
    :content-class="`bg-white border space-t-2 ${content_class}`"
    class="flex justify-center items-center z-[1003]"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    @scroll.stop
    @wheel.stop
  >
    <div v-if="!disable_header && $slots.header">
      <slot name="header" />
    </div>
    <div v-else class="flex items-center px-6 py-4 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800">
      <slot v-if="$slots.header_left" name="header_left" />
      <div v-else class="flex items-center">
        <div v-if="$slots.title_icon" class="flex items-center border rounded-xl p-3 mr-4 border-gray-200 text-xl">
          <slot name="title_icon" />
        </div>
        <div class="flex flex-col justify-start">
          <slot name="title_text" />
          <span class="font-normal text-sm text-gray-600">
            <slot name="subtitle_text" />
          </span>
        </div>
      </div>
      <div class="flex font-normal items-center justify-center">
        <slot name="header_right" />
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="$emit('close')">
          <IconHawkX class="w-6 h-6" />
        </div>
      </div>
    </div>
    <div class="p-6 ">
      <slot />
    </div>
    <div v-if="!disable_footer" class="p-6 border-t border-t-gray-200">
      <slot v-if="$slots.footer" name="footer" />
      <div v-else-if="footer_buttons?.length" class="flex justify-end w-full">
        <template v-for="button of footer_buttons" :key="button.text">
          <HawkButton v-bind="button" @click="$emit(button.emit || 'btnClick')">
            {{ button.text }}
          </HawkButton>
        </template>
      </div>
      <div v-else class="flex justify-end w-full">
        <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
          {{ $t('Cancel') }}
        </HawkButton>
        <HawkButton color="primary">
          {{ $t('Okay') }}
        </HawkButton>
      </div>
    </div>
  </VueFinalModal>
</template>
