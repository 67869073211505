<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { IconHawkArrowRight, IconHawkGlobeTwo, IconHawkLockOne, IconHawkPlus, IconHawkTrophyFilled, IconHawkUserGroup } from '~/common/components/molecules/hawk-icons/icons.js';
import PmGraphWidget from '~/dashboard/components/widgets/graph-widgets/pm-graph.widget.vue';
import PmIndexWidget from '~/dashboard/components/widgets/pm-widgets/pm-index.widget.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import PmUploadModal from '~/project-management/components/pm-upload-modal.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const router = useRouter();
const route = useRoute();
const auth_store = useAuthStore();
const dashboard_store = useDashboardStore();
const project_management_store = useProjectManagementStore();

const { create_schedule, set_schedules } = project_management_store;
const { schedules } = storeToRefs(project_management_store);
const { current_organization } = storeToRefs(auth_store);

const state = reactive({
  is_loading: false,
  search: '',
});

const form$ = ref(null);

watch(() => [current_organization.value?.uid, router?.currentRoute.value.params?.asset_id], async () => {
  if (!current_organization.value?.uid || state.is_loading)
    return;
  try {
    state.is_loading = true;
    await dashboard_store.set_fusion_chart();
    await set_schedules({
      asset: router?.currentRoute.value.params?.asset_id,
    });
    state.is_loading = false;
  }
  catch (error) {
    logger.error(error);
  }
}, { immediate: true });

async function onFormUpdated(event) {
  if (event.type !== 'upload_completed')
    return;

  const file_data = form$.value.data.file;
  const service_object = {
    fileName: file_data.name,
    fileSize: file_data.size,
    service: file_data.service_object,
  };
  const body = {
    service_object,
    name: service_object.fileName,
    asset: route.params.asset_id,
    imported: true,
  };
  const schedule = await create_schedule(body);

  router.push({
    name: 'schedule-details',
    params: { schedule_uid: schedule.uid },
  });
}

const filtered_schedules = computed(() => {
  const searched_schedules = [];
  schedules.value.forEach((x) => {
    if (x.name.toLowerCase().includes(state.search.toLowerCase()))
      searched_schedules.push(x);
  });
  return searched_schedules;
});

function progress_success_width(planned, current) {
  return {
    width:
      planned < current
        ? `${planned * 100}%`
        : `${current * 100}%`,
  };
}

function progress_width(planned, current) {
  return {
    width:
      planned > current
        ? `${planned * 100 - current * 100}%`
        : `${current * 100 - planned * 100}%`,
  };
}

const upload_modal = useModal({
  component: PmUploadModal,
  attrs: {
    onClose() {
      upload_modal.close();
    },
  },
});

function getScheduleData(uid) {
  return {
    data: {
      name: 'S curve',
      type: 's_curve',
      schedule: uid,
      s_curve_type: 'progress',
      s_curve_interval: 'month',
      chart_type: null,
      chart_field: null,
      chart_value: null,
      chart_breakdown: null,
      number_chart_field: null,
      module: 'project management',
    },
  };
}

function getPIData(uid, type) {
  return {
    data: {
      name: null,
      type,
      schedule: uid,
      s_curve_type: null,
      s_curve_interval: null,
      chart_type: null,
      chart_field: null,
      chart_value: null,
      chart_breakdown: null,
      history_compare: null,
      history_duration: 1,
      number_chart_field: null,
      module: 'project management',
    },
  };
}

const is_boarding = computed(() => !schedules.value.length && !filtered_schedules.value?.length);
</script>

<template>
  <div>
    <h1 class="text-lg font-semibold text-gray-900 px-4 py-5">
      {{ `${$t("Project management")} - ${$t("Schedules")}` }}
    </h1>
    <Vueform ref="form$" size="sm" class="px-4 pb-5" @updated="onFormUpdated">
      <div class="flex justify-between w-full col-span-full">
        <HawkSearchInput v-if="!is_boarding" v-model="state.search" :placeholder="$t('Search')" />
        <div v-else />
        <ButtonElement
          name="upload_schedule"
          @click="upload_modal.open"
        >
          <IconHawkPlus class="w-5 h-5 text-white inline -mt-0.5 mr-2" />
          <span class="w-full">{{ $t('New Schedule') }}</span>
        </ButtonElement>
      </div>
    </Vueform>
    <HawkLoader v-if="state.is_loading" />
    <div v-else-if="is_boarding">
      <hawk-illustrations type="on-boarding" for="project-management" />
    </div>
    <div v-else-if="!filtered_schedules?.length">
      <hawk-illustrations type="no-results" />
    </div>
    <div v-else-if="filtered_schedules?.length">
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-2 px-4 pb-14">
        <div
          v-for="schedule in filtered_schedules"
          :key="schedule.uid"
          class="w-50 cursor-pointer"
          @click="router.push({ name: 'schedule-details', params: { schedule_uid: schedule.uid } })"
        >
          <div class="text-gray-900 border border-gray-300 rounded-lg w-full">
            <div class="bg-white rounded-lg py-4 px-6">
              <h1 class="text-md font-semibold mb-2">
                {{ schedule.name }}
              </h1>

              <span v-if="schedule.public">
                <IconHawkGlobeTwo class="inline text-gray-600" />
              </span>
              <span v-else-if="Object.keys(schedule?.members)?.length ?? 0">
                <IconHawkUserGroup class="inline text-gray-600" />
              </span>
              <span v-else>
                <IconHawkLockOne class="inline text-gray-600" />
              </span>

              <span class="border-l border-gray-200 mx-4" />

              <IconHawkTrophyFilled class="inline mr-1 text-warning-400" />
              <span class="text-sm font-semibold">
                {{ schedule.scheduled_milestones || 0 }}/{{ schedule.total_milestones || 0 }}
              </span>
              <div class="float-right relative flex items-center">
                <div
                  class="absolute text-xs font-regular text-gray-700 text-end right-0 -top-2.5"
                >
                  {{ Math.round((schedule.progress || 0) * 100) }}%
                </div>
                <HawkBadge v-if="schedule.progress < schedule.percent_schedule_complete" color="yellow">
                  {{ $t("Delayed by") }} {{ Math.round((schedule.percent_schedule_complete - schedule.progress || 0) * 100) }}%
                </HawkBadge>
                <HawkBadge v-if="schedule.progress >= schedule.percent_schedule_complete" color="blue">
                  {{ $t("On track") }}
                </HawkBadge>
                <div class="inline-flex items-start bg-gray-200 rounded w-60 ml-3">
                  <div
                    class="h-[8px] bg-primary-500 rounded-l"
                    :style="progress_success_width(schedule.percent_schedule_complete, schedule.progress)"
                  />
                  <div
                    class="h-[8px] rounded-r"
                    :class="{
                      'rounded-l': progress_success_width(schedule.percent_schedule_complete, schedule.progress).width === '0%',
                      'bg-error-500': schedule.percent_schedule_complete > schedule.progress,
                      'bg-primary-500': schedule.percent_schedule_complete <= schedule.progress,
                    }"
                    :style="progress_width(schedule.percent_schedule_complete, schedule.progress)"
                  />
                </div>
              </div>
            </div>
            <hr>
            <div class="h-[400px] bg-[#FCFCFD] pt-4 px-6 w-full">
              <div class="bg-white border border-gray-300 rounded p-4 cursor-auto w-full h-[380px]" @click.stop>
                <div class="h-[300px]">
                  <PmGraphWidget :id="schedule.uid" :data="getScheduleData(schedule.uid)" />
                </div>
                <div class="flex justify-evenly h-[50px]">
                  <div class="flex flex-col">
                    <div class="text-xs font-medium text-gray-600">
                      {{ $t('Schedule Performance Index (SPI)') }}
                    </div>
                    <PmIndexWidget :id="schedule.uid" :data="getPIData(schedule.uid, 'spi')" class="text-center" />
                  </div>
                  <div class="flex flex-col">
                    <div class="text-xs font-medium text-gray-600">
                      {{ $t('Cost Performance Index (CPI)') }}
                    </div>
                    <PmIndexWidget :id="schedule.uid" :data="getPIData(schedule.uid, 'cpi')" class="text-center" />
                  </div>
                </div>
              </div>
            </div>
            <div class="my-2 mx-6">
              <h1 class="text-xs font-regular text-gray-700 inline mr-2">
                {{ $t("Last updated") }}:
              </h1>
              <h1 class="text-xs font-regular text-gray-900 inline">
                {{ $date(schedule.updated_at, 'Do MMMM YYYY hh:mma') }}
              </h1>
            </div>
            <hr>
            <div class="bg-white cursor-pointer hover:bg-gray-50 rounded-bl-lg rounded-br-lg">
              <h1 class="text-sm font-semibold text-gray-700 text-center py-2">
                {{ $t("View Details") }}
                <IconHawkArrowRight class="inline -mt-0.5 ml-2" />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
