<script setup>
import { onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import AccountSettingsInvitations from '~/acct-settings/components/acct-settings-invitation-popup/acct-settings-invitation-popup.vue';
import { IconHawkAnnotation, IconHawkBuildingThree, IconHawkChevronDown, IconHawkChevronUp, IconHawkGlobeOne, IconHawkLogOutOne, IconHawkMessageQuestionCircle, IconHawkSettingsOne, IconHawkUserOne, IconHawkUserPlusOne } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkMenu from '~/common/components/molecules/hawk-menu.vue';
import OrganizationPopup from '~/common/components/organisms/hawk-navbar/hawk-organization-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['close']);
const { route, auth_store, common_store } = useCommonImports();
async function logout() {
  const { OktaAuth } = await import('@okta/okta-auth-js');
  window.OktaAuth = OktaAuth;
  await auth_store.sign_out();
}

const state = reactive({
  is_feedback_visible: false,
  is_marker_available: false,
  is_this_localhost: window.location.hostname === 'localhost',
});

const {
  open: openOrganizationModal,
  close: closeOrganizationModal,
  patchOptions,
} = useModal({
  component: OrganizationPopup,
});

function changeOrganizationHandler() {
  patchOptions({
    attrs: {
      onClose() {
        closeOrganizationModal();
      },
    },
  });
  openOrganizationModal();
}

const { open: openUserInvitePopup, close: closeUserInvitePopup, patchOptions: invitePatchOptions } = useModal({
  component: AccountSettingsInvitations,
});

function invitePopupHandler() {
  invitePatchOptions(
    {
      attrs: {
        onClose() {
          closeUserInvitePopup();
        },
        onSave() {
          getData();
          closeUserInvitePopup();
        },
        async onUpdate() {
          if (route.params.asset_id)
            await common_store.update_global_data({ assets: true });
          state.invitation_update_key += 1;
        },
      },
    },
  );
  openUserInvitePopup();
}

function onSupportClick() {
  window.open('https://support.taskmapper.com/customer-portal', '_newtab', 'noopener');
}

function toggleMarkerFeedbackVisibility() {
  const new_bool = !window.Marker.isVisible();

  if (window.Marker.isVisible())
    window.Marker.hide();
  else
    window.Marker.show();

  state.is_feedback_visible = new_bool;
  localStorage.setItem('marker_feedback_visible', new_bool.toString());
}

onMounted(() => {
  if (state.is_this_localhost || !auth_store.is_internal_user || !window?.Marker?.show)
    return;

  state.is_marker_available = true;

  if (JSON.parse(localStorage.getItem('marker_feedback_visible'))) {
    window.Marker.show();
    state.is_feedback_visible = true;
  }
  else {
    window.Marker.hide();
    state.is_feedback_visible = false;
  }
});
</script>

<template>
  <HawkMenu class="hidden md:flex" additional_trigger_classes="!ring-0 ml-3">
    <template #trigger="{ open }">
      <div class="flex cursor-pointer items-center rounded py-1.25 hover:text-white">
        <HawkMembers :members="auth_store?.logged_in_user_details?.user_id" />
        <IconHawkChevronUp v-if="open" class="text-lg" />
        <IconHawkChevronDown v-else class="text-lg" />
      </div>
    </template>
    <template #content="{ close }">
      <div class="w-60" @click="close()">
        <div class="px-4 py-3 h-1s6">
          <HawkMembers
            type="label"
            has_email
            size="md"
            :members="auth_store?.logged_in_user_details?.user_id"
            :name_truncate_length="20"
            :email_truncate_length="18"
          />
        </div>
        <hr>
        <div v-if="auth_store.current_organization" class="m-1.5">
          <div class="text-xs text-gray-600 pl-2.5">
            {{ $t("Organization") }}
          </div>
          <div
            class="flex text-sm font-medium items-center justify-between px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
            @click="changeOrganizationHandler();"
          >
            <div class="flex items-center">
              <IconHawkBuildingThree class="w-4 h-4 mr-2" />
              <HawkText :content="auth_store.current_organization?.name" :length="15" />
            </div>
            <IconHawkChevronDown />
          </div>
        </div>
        <hr v-if="common_store?.organizations?.length >= 1">
        <div class="m-1.5">
          <div
            class="flex text-sm font-medium items-center px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
            @click="$router.push({ name: 'profile-settings-general' });"
          >
            <IconHawkUserOne class="w-4 h-4 mr-2" /> {{ $t("Profile Settings") }}
          </div>
          <div
            class="flex items-center px-2 py-2 mb-2 text-sm font-medium text-gray-700 rounded-lg cursor-pointer hover:bg-gray-50"
            @click="$router.push({ name: 'account-settings-general', params: { asset_id: null } });"
          >
            <IconHawkSettingsOne class="w-4 h-4 mr-2" /> {{ $t("Account Settings") }}
          </div>
        </div>
        <hr>
        <div v-if="auth_store.check_permission('invite_users', $route.params.asset_id)" class="m-1.5">
          <div
            class="flex text-sm font-medium items-center px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
            @click="invitePopupHandler();"
          >
            <IconHawkUserPlusOne class="w-4 h-4 mr-2" /> {{ $t("Invite your team") }}
          </div>
        </div>
        <hr v-if="auth_store.check_permission('invite_users', $route.params.asset_id)">
        <div class="m-1.5">
          <div
            v-if="auth_store.is_internal_user"
            id="whats-new-link"
            class="flex text-sm font-medium items-center px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
          >
            <IconHawkMessageQuestionCircle class="w-4 h-4 mr-2" /> {{ $t("What's New") }}
          </div>
          <div
            v-if="!common_store.is_ril"
            class="flex items-center px-2 py-2 mb-2 text-sm font-medium text-gray-700 rounded-lg cursor-pointer hover:bg-gray-50"
            @click="onSupportClick();"
          >
            <IconHawkGlobeOne class="w-4 h-4 mr-2" /> {{ $t("Support") }}
          </div>
          <div
            v-if="auth_store.is_internal_user && state.is_marker_available"
            class="flex items-center px-2 py-2 mb-2 text-sm font-medium text-gray-700 rounded-lg cursor-pointer hover:bg-gray-50"
            @click="toggleMarkerFeedbackVisibility"
          >
            <IconHawkAnnotation class="w-4 h-4 mr-2" /> {{ state.is_feedback_visible ? 'Hide feedback' : 'Show feedback' }}
          </div>
        </div>
        <hr>
        <div class="m-1.5">
          <div

            class="flex text-sm font-medium items-center px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
            @click="
              logout();
              emit('close');
            "
          >
            <IconHawkLogOutOne class="w-4 h-4 mr-2" /> {{ $t("Logout") }}
          </div>
        </div>
      </div>
    </template>
  </HawkMenu>
  <div class="block md:hidden">
    <div
      class="sm:block flex justify-between text-base font-semibold items-center px-3 -mx-3 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-100"
    >
      <div class="sm:pb-2">
        {{ $t("Change Organization") }}
      </div>
      <div
        class="flex text-sm font-medium items-center justify-between px-2 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-50 text-gray-700"
        @click="changeOrganizationHandler();"
      >
        <div class="flex items-center">
          <IconHawkBuildingThree class="w-4 h-4 mr-2" />
          <HawkText :content="auth_store.current_organization?.name" :length="15" />
        </div>
        <IconHawkChevronDown />
      </div>
    </div>
    <div
      class="flex text-base font-semibold items-center px-3 -mx-3 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-100"
      @click="
        $router.push({ name: 'profile-settings-general' });
        emit('close');
      "
    >
      {{ $t("Profile Settings") }}
    </div>
    <div
      class="flex text-base font-semibold items-center px-3 -mx-3 rounded-lg py-2.5 mb-2 cursor-pointer hover:bg-gray-100"
      @click="
        $router.push({ name: 'account-settings-general' });
        emit('close');
      "
    >
      {{ $t("Account Settings") }}
    </div>
    <div
      class="flex text-base font-semibold items-center px-3 -mx-3 rounded-lg py-2.5 cursor-pointer hover:bg-gray-100"
      @click="
        logout();
        emit('close');
      "
    >
      {{ $t("Logout") }}
    </div>
  </div>
</template>
