<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { Validator } from '@vueform/vueform';
import { IconHawkCheck, IconHawkPencilOne, IconHawkX } from '~/common/components/molecules/hawk-icons/icons.js';
import ApproversField from '~/forms/components/form-workflows/sidebar/approval-block/approvers-input-field.vue';
import EmailPopupButton from '~/forms/components/form-workflows/sidebar/email-block/email-popup-button.vue';

const props = defineProps({
  form$: {
    type: Object,
  },
  form_data: {
    type: Object,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  email_forms: {
    type: Object,
  },
  step_number: {
    type: Number,
    default: -1,
  },
  default_data: {
    type: Object,
  },
});

const emit = defineEmits(['updateConfigureButtons', 'reset', 'validateNumberInput', 'changeMember', 'updateEmail']);

const $t = inject('$t');
const uppercaseRule = class extends Validator {
  get message() {
    return 'Only characters (A-Z, a-z) are allowed';
  }

  check(value) {
    return /^[a-z_ ]*$/i.test(value);
  }
};

const uniqueRule = class extends Validator {
  get message() {
    return 'The button labels should be unique';
  }

  check(value) {
    return !(value === props.form_data.configure_buttons.approve && value === props.form_data.configure_buttons.reject);
  }
};

const configure_buttons_input_status = ref({ approve: false, reject: false });

function updateConfigureButtons(action, type) {
  if (props.form$.elements$.configure_buttons.children$[type].error)
    return;
  const data = props.form_data.configure_buttons;
  if (action === 'save')
    data[`${type}_text`] = data[type];
  else
    data[type] = data[`${type}_text`];
  emit('updateConfigureButtons', data);
  configure_buttons_input_status.value[type] = false;
}

const date_dropdown_options = [
  { value: 'due_date', label: $t('Due date') },
];
</script>

<template>
  <div>
    <div class="flex items-start justify-between mb-5 w-full">
      <div>
        <div class="text-sm font-semibold text-gray-700">
          {{ $t('Require comments') }}
        </div>
        <div class="text-xs text-gray-700">
          {{ $t('The members should add a note/comment while reviewing, approving/rejecting the request.') }}
        </div>
      </div>
      <ToggleElement
        name="comments_required"
        class="ml-2"
      />
    </div>
    <hr class="mb-5">
    <ObjectElement name="escalations">
      <div class="col-span-12">
        <div class="flex items-start justify-between mb-5 w-full">
          <div>
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Escalate') }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $t('Escalate to other members if there is no outcome within the defined time period.') }}
            </div>
          </div>
          <ToggleElement
            name="is_enabled"
            class="ml-2"
            @change="emit('reset', 'escalations')"
          />
        </div>
        <div v-show="form_data.escalations.is_enabled">
          <ApproversField
            :members="form_data.escalations.members"
            :condition="form_data.escalations.condition"
            :rules="form_data.escalations.is_enabled ? ['required', 'min:1'] : []"
            @validate-number-input="emit('validateNumberInput', { ...$event, key: 'escalations' })"
            @change="emit('changeMember', $event)"
          />

          <TextElement
            name="wait"
            input-type="number"
            class="mb-5"
            :default="1"
            :rules="[
              'required',
              'integer',
              'min:1',
            ]"
            autocomplete="off"
            :label="$t('Escalate after')"
            @input="$event => emit('validateNumberInput', { name: 'wait', e: $event, key: 'escalations' })"
          >
            <template #addon-after>
              {{ $t('days') }}
            </template>
          </TextElement>

          <EmailPopupButton
            class="mt-3"
            :form="email_forms.escalations"
            :step_number="step_number"
            :is_disabled="is_disabled"
            @update-email="emit('updateEmail', { key: 'escalations', value: $event })"
          />
        </div>
      </div>
    </ObjectElement>
    <hr class="mb-5">
    <ObjectElement name="reminders">
      <div class="col-span-12">
        <div class="flex items-start justify-between mb-5 w-full">
          <div>
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Reminders') }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $t('Configure reminders to send email/push notifications for the pending members to take an action.') }}
            </div>
          </div>
          <ToggleElement
            name="is_enabled"
            class="ml-2"
            @change="emit('reset', 'reminders')"
          />
        </div>
        <RadiogroupElement
          v-show="form_data?.reminders?.is_enabled"
          name="is_once"
          :default="false"
          class="mb-2"
          :remove-class="{
            wrapper: 'flex-col',
          }"
          :add-classes="{
            RadiogroupRadio: { container: 'mr-4' },
          }"
          :items="[{
                     value: true,
                     label: $t('Once'),
                   },
                   {
                     value: false,
                     label: $t('Multiple times'),
                   }]"
        />
        <div v-show="form_data.reminders.is_enabled" class="mb-4">
          <div>
            <FormWorkflowBlockReminders :form-data="form_data" :payload-data="default_data" :date-dropdown-options="date_dropdown_options" />
          </div>
          <EmailPopupButton
            class="mt-3"
            :form="email_forms.reminders"
            :step_number="step_number"
            :is_disabled="is_disabled"
            @update-email="emit('updateEmail', { key: 'reminders', value: $event })"
          />
        </div>
      </div>
    </ObjectElement>
    <hr class="mb-4">
    <ObjectElement name="auto_closing">
      <div class="col-span-12">
        <div class="flex items-start justify-between mb-4 w-full">
          <div>
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Automatic Closing') }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $t('Automatically approve/reject if there is no outcome within the defined time period.') }}
            </div>
          </div>
          <ToggleElement
            class="ml-2"
            name="is_enabled"
            @change="emit('reset', 'auto_closing')"
          />
        </div>
        <div v-show="form_data.auto_closing.is_enabled">
          <div class="text-sm text-gray-700 font-medium mb-2">
            {{ $t('Close after') }}
          </div>
          <div class="grid grid-cols-12 h-28">
            <div class="col-span-3">
              <TextElement
                name="wait"
                input-type="number"
                class="mb-3"
                :default="1"
                :native="false"
                :rules="[
                  'required',
                  'integer',
                  'min:1',
                ]"
                autocomplete="off"
                @input="$event => emit('validateNumberInput', { name: 'wait', e: $event, key: 'auto_closing' })"
              >
                <template #addon-after>
                  {{ $t('days') }}
                </template>
              </TextElement>
            </div>
            <div class="text-sm font-medium px-4 mt-2 col-span-2">
              {{ $t('and') }}
            </div>
            <div class="col-span-7">
              <SelectElement
                class="mb-3"
                name="outcome"
                :native="false"
                :can-deselect="false"
                :can-clear="false"
                :items="[
                  { label: $t('Approve'), value: 'APPROVE' },
                  { label: $t('Reject'), value: 'DENY' },
                ]"
                default="APPROVE"
              />
            </div>
          </div>
        </div>
      </div>
    </ObjectElement>
    <hr class="mb-4">
    <ObjectElement name="configure_buttons">
      <div class="col-span-12">
        <div class="flex items-start justify-between mb-4 w-full">
          <div>
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Configure buttons') }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $t('Customize approval buttons to align with your specific workflow needs.') }}
            </div>
          </div>
          <ToggleElement
            class="ml-2"
            name="is_enabled"
            @change="emit('reset', 'configure_buttons')"
          />
        </div>
        <div v-show="form_data.configure_buttons.is_enabled">
          <div class="flex items-center justify-between mb-4">
            <div class="text-xs text-gray-600">
              {{ $t('Button label') }}
            </div>
            <div class="text-xs text-gray-600">
              {{ $t('Preview') }}
            </div>
          </div>
          <div class="grid grid-cols-12 mb-4">
            <div class="col-span-8">
              <div v-show="configure_buttons_input_status.approve" class="flex">
                <TextElement
                  name="approve"
                  class="mb-3"
                  default="Approved"
                  :native="false"
                  :messages="{
                    max: 'This field cannot have more than 10 characters',
                  }"
                  :rules="[
                    'required',
                    'max:10',
                    uppercaseRule,
                    uniqueRule,
                  ]"
                  autocomplete="off"
                  @keypress.enter="updateConfigureButtons('save', 'approve')"
                />
                <div class="flex mt-1.5">
                  <div class="cursor-pointer mx-2.5" @click="updateConfigureButtons('save', 'approve')">
                    <IconHawkCheck class="text-blue-500 cursor-pointer w-4.5" />
                  </div>
                  <div class="cursor-pointer" @click="updateConfigureButtons('cancel', 'approve')">
                    <IconHawkX class="text-gray-500 cursor-pointer w-5 h-5" />
                  </div>
                </div>
              </div>
              <div v-show="!configure_buttons_input_status.approve">
                <div class="flex items-center">
                  <div class="text-sm font-medium">
                    {{ form_data.configure_buttons.approve_text }}
                  </div>
                  <div class="cursor-pointer ml-2" @click="configure_buttons_input_status.approve = true">
                    <IconHawkPencilOne class="text-gray-500 h-5 w-5" />
                  </div>
                </div>
                <span class="italic text-xs text-gray-600">{{ $t('*To approve') }}</span>
              </div>
            </div>
            <div class="col-span-1" />
            <div class="col-span-3 flex justify-end">
              <ButtonElement secondary :button-label="form_data.configure_buttons.approve_text" />
            </div>
          </div>

          <div class="grid grid-cols-12 mb-4">
            <div class="col-span-8">
              <div v-show="configure_buttons_input_status.reject" class="flex">
                <TextElement
                  name="reject"
                  class="mb-3"
                  default="Rejected"
                  :native="false"
                  :messages="{
                    max: 'This field cannot have more than 10 characters',
                  }"
                  :rules="[
                    'required',
                    'max:10',
                    uppercaseRule,
                    uniqueRule,
                  ]"
                  autocomplete="off"
                  @keypress.enter="updateConfigureButtons('save', 'reject')"
                />
                <div class="flex mt-1.5">
                  <div class="cursor-pointer mx-2.5" @click="updateConfigureButtons('save', 'reject')">
                    <IconHawkCheck class="text-blue-500 cursor-pointer w-4.5" />
                  </div>
                  <div class="cursor-pointer" @click="updateConfigureButtons('cancel', 'reject')">
                    <IconHawkX class="text-gray-500 cursor-pointer w-5 h-5" />
                  </div>
                </div>
              </div>
              <div v-show="!configure_buttons_input_status.reject">
                <div class="flex items-center">
                  <div class="text-sm font-medium">
                    {{ form_data.configure_buttons.reject_text }}
                  </div>
                  <div class="cursor-pointer ml-2" @click="configure_buttons_input_status.reject = true">
                    <IconHawkPencilOne class="text-gray-500 h-5 w-5" />
                  </div>
                </div>
                <span class="italic text-xs text-gray-600">{{ $t('*To reject') }}</span>
              </div>
            </div>
            <div class="col-span-1" />
            <div class="col-span-3 flex justify-end">
              <ButtonElement secondary :button-label="form_data.configure_buttons.reject_text" />
            </div>
          </div>
        </div>
      </div>
    </ObjectElement>
  </div>
</template>
