<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { onMounted, ref, watch } from 'vue';
import { IconHawkDownloadOne, IconHawkInfoCircle, IconHawkMaximizeTwo } from '~/common/components/molecules/hawk-icons/icons.js';
import { import_fusioncharts } from '~/common/utils/package.utils.js';

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  description: {
    type: String,
    default: '',
  },
  graph_details: {
    type: Object,
    default: () => {},
  },
  height: {
    type: String,
    default: '300',
  },
  has_borders: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['chart']);

const $date = inject('$date');
const chart = ref(null);
const is_fullscreen = ref(false);
loadChart();

watch(() => props.data, () => {
  loadChart();
  document
    .getElementById(props.graph_details.id)
    .addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        is_fullscreen.value = false;
        loadChart();
      }
    });
});

async function loadChart() {
  try {
    const payload = {
      renderAt: props.graph_details?.id,
      type: props.graph_details?.type,
      width: is_fullscreen.value ? `${window.screen.width}` : '100%',
      height: is_fullscreen.value ? `${window.screen.height}` : props.height,
      dataFormat: 'json',
      dataSource: {
        ...props.data,
        chart: {
          numbersuffix: '',
          theme: 'fusion',
          xAxisName: props.graph_details?.xAxisName,
          yAxisName: props.graph_details?.yAxisName,
          labelDisplay: 'Auto',
          useEllipsesWhenOverflow: '0',
          yAxisValuesStep: '2',
          exportEnabled: '1',
          exportShowMenuItem: '0',
          plotToolText: props.graph_details?.plotToolText,
          showValues: props.graph_details?.showValues,
          ...(props.graph_details?.additional_config || {}),
          ...(props.graph_details?.type === 'pie2d' && {
            showValues: '0',
            showPercentValues: '0',
            ...(is_fullscreen.value || !props.graph_details.is_dashboard
              ? {}
              : {
                  showLabels: '0',
                  pieRadius: '50',
                  labelFontSize: '12',
                  legendItemFontSize: '12',
                  legendIconWidth: '12',
                  legendIconHeight: '12',
                }),
          }),
        },
      },
    };

    const { VueFusionChartsComponent, FusionCharts, Charts, FusionTheme, ExcelExport } = await import_fusioncharts();
    VueFusionChartsComponent(FusionCharts, Charts, FusionTheme, ExcelExport);
    chart.value = new FusionCharts(payload);
    chart.value.render();
    emit('chart', chart.value);
  }
  catch (error) {
    logger.log('Error while loading chart', error);
  }
}

function exportPng() {
  const now = new Date();
  const export_file_name = `${props.graph_details.title} ${$date(now, 'DD MMMM YYYY hh:mm')}`;
  chart.value.exportChart({
    exportFormat: 'png',
    exportFileName: export_file_name,
  });
}

function openFullscreen() {
  const elem = document.getElementById(props.graph_details?.id);
  is_fullscreen.value = true;
  loadChart();
  if (elem.requestFullscreen)
    elem.requestFullscreen();
  else if (elem.mozRequestFullScreen)
    elem.mozRequestFullScreen();
  else if (elem.webkitRequestFullscreen)
    elem.webkitRequestFullscreen();
  else if (elem.msRequestFullscreen)
    elem.msRequestFullscreen();
}

function handleEsc() {
  if (!document.fullscreenElement) {
    is_fullscreen.value = false;
    loadChart();
  }
}

onMounted(() => {
  document.getElementById(props.graph_details.id).addEventListener('fullscreenchange', handleEsc);
});

onBeforeUnmount(() => {
  document.getElementById(props.graph_details.id).removeEventListener('fullscreenchange', handleEsc);
});
</script>

<template>
  <div class="w-full">
    <div v-if="!graph_details.only_chart" class="flex justify-between items-center mb-[14px]">
      <div class="flex flex-col">
        <h2 class="text-md font-semibold text-gray-900">
          {{ graph_details.title }}
        </h2>
        <div v-if="graph_details.description" class="text-xs text-gray-600 mt-0.5">
          {{ graph_details.description }}
        </div>
      </div>
      <div class="flex items-center gap-6 mr-3">
        <tippy v-if="data.description && data.type === 'severity'" arrow interactive :hide-on-click="false" @state="onStateChange">
          <template #default>
            <IconHawkInfoCircle class="text-xl text-gray-400 cursor-pointer" />
          </template>
          <template #content>
            <ul>
              <li class="text-sm">
                {{ $t('Severity 1') }}: {{ $t('The anomaly is not severe enough to warrant immediate action. For example - Dirt, Shadow, Vegetation, Suspected Soiling.') }}
              </li> <br>
              <li class="text-sm">
                {{ $t('Severity 2') }}: {{ $t(`These are anomalies whose temperature difference < 40°C and not under severity level 1. It is necessary to conduct an inspection to identify root cause and rectify the anomaly within a short period of time.`) }}
              </li>  <br>
              <li class="text-sm">
                {{ $t('Severity 3') }}: {{ $t('These are anomalies which are') }} &gt; 40<sup>o</sup>C {{ $t('and are safety hazards. These need to be addressed immediately.') }}
              </li>
            </ul>
          </template>
        </tippy>

        <slot name="header-right" />
        <IconHawkDownloadOne v-if="!graph_details?.hide_download" class="text-xl text-gray-400 cursor-pointer" @click="exportPng" />
        <IconHawkMaximizeTwo class="text-xl text-gray-400 cursor-pointer" @click="openFullscreen" />
      </div>
    </div>

    <div :id="graph_details.id" :class="{ 'w-[100vh] box-border': is_fullscreen, 'border border-gray-300 shadow-md rounded-lg': has_borders }" />
  </div>
</template>
