<script setup>
import DOMPurify from 'dompurify';
import { IconHawkChevronLeft, IconHawkChevronRight } from '~/common/components/molecules/hawk-icons/icons.js';
import { load_js_css_file } from '~/common/utils/load-script.util.js';
import customHtmlGraph from '~/system-model/components/sm-graph/sm-graph-custom-html';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  items: { type: Array },
  active: { type: Object },
});

const emit = defineEmits(['element-dbl-clicked', 'element-clicked']);
const system_model_store = useSystemModelStore();
const active_instance = computed(() => system_model_store.active_instance);

const canvas = ref(null);

const state = reactive({
  pan_zoom: null,
  graph: null,
  paper: null,
  loading: false,
  is_full: false,
  tree: null,
  paperScroller: null,
  current_node: null,
});

watch(() => props.items, async () => {
  if (active_instance.value)
    graphInit();
}, { deep: true });

onMounted(async () => {
  await load_static_files();
  graphInit();
});

async function load_static_files() {
  state.loading = true;
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/lodash@4.17.21/lodash.min.js',
    'lodash',
    'js',
  );
  await load_js_css_file(
    'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.js',
    'jquery',
    'js',
  );

  await load_js_css_file(
    'https://cdnjs.cloudflare.com/ajax/libs/backbone.js/1.4.1/backbone.js',
    'backbone',
    'js',
  );
  await load_js_css_file(
    'https://dagrejs.github.io/project/dagre/latest/dagre.min.js',
    'dagre',
    'js',
  );
  await load_js_css_file(
    'https://dagrejs.github.io/project/graphlib/latest/graphlib.min.js',
    'graphlib',
    'js',
  );

  await load_js_css_file(
    'https://cdn.jsdelivr.net/gh/sensehawk/cdn/jointJs+/rappid.js',
    'joint',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/gh/sensehawk/cdn/jointJs+/rappid.css',
    'jointJsCss',
    'css',
  );
  state.loading = false;
}

function graphInit() {
  createGraphInstances();
  state.graph.addCells(createLayoutData());
  joint.layout.DirectedGraph.layout(state.graph, {
    marginX: 10,
    marginY: 10,
    nodeSep: 150,
    edgeSep: 100,
    rankSep: 100,
  });
  state.graph.on('change:position', (cell) => {
    cell.set('position', cell.previous('position'));
  });
  state.paper.fitToContent();
  addPaperEvents();
  markCellAsActive();
  centerActiveElement();
}

function centerActiveElement() {
  if (state.current_node)
    state.paperScroller.centerElement(state.current_node.model);
}
function addPaperEvents() {
  let is_dbl_click = false;
  let click_timeout = null;
  state.paper.on({
    'blank:pointerdown': state.paperScroller.startPanning,
    'element:pointerdblclick': (view) => {
      is_dbl_click = true;
      clearTimeout(click_timeout);
      emit(
      // eslint-disable-next-line vue/custom-event-name-casing
        'element-dbl-clicked',
        view.model.attributes.data,
      );
    },
    'element:pointerclick': (view) => {
      is_dbl_click = false;
      click_timeout = setTimeout(() => {
        if (is_dbl_click)
          return;
        emit(
        // eslint-disable-next-line vue/custom-event-name-casing
          'element-clicked',
          view.model.attributes.data,
        );
        highlightLinks(view);
      }, 200);
    },
    'element:mouseenter': (view) => {
      view.$box.addClass('hover');
      view.$box.find('div.popup').addClass('active');
    },
    'element:mouseleave': (view) => {
      view.$box.removeClass('hover');
      view.$box.find('div.popup').removeClass('active');
    },
    'paper:pinch': (evt, ox, oy, scale) => {
      evt.preventDefault();
      state.paperScroller.zoom((scale - 1) * 2, { min: 0.2, max: 2, oy, ox });
    },
  });
}
function highlightLinks(elementView) {
  state.graph.getLinks().forEach((link) => {
    const is_red = link.attributes.source.id === elementView.model.attributes.data.id;
    if (is_red)
      link.toFront();
    link.attr(['line', 'stroke'], is_red ? 'red' : 'black');
  });
}

function getFieldValues(value) {
  if (Array.isArray(value)) {
    let str = '';
    value.forEach(
      option =>
        (str += `<div>${option?.name || option || ''}</div>`),
    );
    return DOMPurify.sanitize(str);
  }
  else { return DOMPurify.sanitize(value); }
}

function construct_popup_data(items, name) {
  let str = `<b class='heading'>${DOMPurify.sanitize(name)}</b>`;
  if (items) {
    items.forEach((item) => {
      Object.entries(item).forEach((entry) => {
        const [key, value] = entry;
        str += `<div><b>${DOMPurify.sanitize(key, { ALLOWED_TAGS: [] })}</b></div><div>${getFieldValues(
          value,
        )}</div>`;
      });
    });
  }
  return str;
}
function createRectangle(data = {}) {
  const popup_data = construct_popup_data(
    data.field_values,
    data.name,
  );
  const rect = new joint.shapes.html.Element({
    id: data.id,
    size: { width: 120, height: 50 },
    label: data.name,
    data,
    select: data.type,
    popup: popup_data.length > 0 ? popup_data : undefined,
  });
  rect.attr({
    body: {
      fill: 'white',
    },
    label: {
      text: 'Hello',
      fill: 'black',
      fontSize: 12,
    },
  });
  if (data.pos)
    rect.position(...data.pos);
  if (data.resize)
    rect.resize(...data.resize);
  if (data.text) {
    rect.attr({
      label: {
        text: data.text,
      },
    });
  }
  return rect;
}

function createLink(from, to) {
  return new joint.shapes.standard.Link({
    source: {
      id: from.id,
    },
    target: { id: to.id },
    attrs: {
      line: {
        sourceMarker: {
          d: 'M 4 -4 0 0 4 4',
        },
        targetMarker: {
          d: '',
        },
      },
    },
  });
}
function flatElementsAndLinks(data, root, elements = [], links = [], depth = 0) {
  _.each(data, (element) => {
    element.id = element.uid;
    elements.push(element);
    if (root)
      links.push([root, element]);
    if (
      element.children
      && (depth < 1
        || element.children.length < 100000
        || props.active?.uid === element.uid)
    ) {
      flatElementsAndLinks(
        element.children,
        element,
        elements,
        links,
        ++depth,
      );
    }
  });
  return { elements, links };
}

function createLayoutData() {
  const cells = [];
  const { elements, links } = flatElementsAndLinks(props.items);
  elements.forEach(element => cells.push(createRectangle(element)));
  links.forEach(element =>
    cells.push(createLink(element[0], element[1])),
  );
  return cells;
}

function markCellAsActive() {
  _.each(state.graph.getElements(), (el) => {
    const view = el.findView(state.paper);
    if (props.active.uid === view?.model?.attributes?.data?.uid) {
      state.current_node = view;
      view.$box.css({
        background: '#F7D060',
      });
    }
  });
}

function createGraphInstances() {
  if (state.paperScroller)
    state.paperScroller.render().$el.remove();

  state.graph = new joint.dia.Graph({}, { cellNamespace: joint.shapes });
  customHtmlGraph();
  joint.routers.custom = function (vertices, args, linkView) {
    const sourceCorner = linkView.sourceBBox.center();
    const targetCorner = linkView.targetBBox.center();
    const point0 = g.Point(targetCorner.x, targetCorner.y - 60);
    const point1 = g.Point(sourceCorner.x, targetCorner.y - 60);
    const point2 = g.Point(sourceCorner.x, sourceCorner.y + 60);
    return [point2, point1, point0];
  };
  state.paper = new joint.dia.Paper({
    cellViewNamespace: joint.shapes,
    model: state.graph,
    gridSize: 1,
    interactive: false,
    width: '100%',
    height: '100%',
  });
  state.paper.options.defaultRouter = {
    name: 'custom',
  };
  state.paperScroller = new joint.ui.PaperScroller({
    paper: state.paper,
    cursor: 'grab',
    baseWidth: 1,
    baseHeight: 1,
    contentOptions: {
      padding: 10,
      allowNewOrigin: 'any',
    },
  });
  state.paperScroller.render().$el.appendTo(canvas.value);
  state.paperScroller.render().$el.addClass('scrollbar');
}

function showFullGraph() {
  const properties_section = document.getElementById('sm_properties_detail');
  state.is_full = !state.is_full;
  properties_section.classList.toggle('hidden');
  centerActiveElement();
}
</script>

<template>
  <div class="py-5 border-l border-gray-200 h-full relative graph_border">
    <div class="border border-gray-300 p-2 h-8 w-8 flex items-center rounded-full absolute left-[-20px] bg-white top-[31px] cursor-pointer" @click="showFullGraph">
      <IconHawkChevronLeft v-if="!state.is_full" class="w-4 h-4" />
      <IconHawkChevronRight v-else class="w-5 h-5" />
    </div>
    <div class="system-model w-full">
      <hawk-loader v-if="state.loading" />
      <div v-else ref="canvas" class="w-full h-[calc(100vh-15rem)] px-6" />
    </div>
  </div>
</template>

<style>
  .joint-type-html-element {
    cursor: pointer !important;
    pointer-events: all;
  }
  #paper-html-elements {
    position: relative;
    border: 1px solid gray;
    display: inline-block;
    background: transparent;
    overflow: hidden;
    cursor: pointer;
  }
  #paper-html-elements svg {
    background: transparent;
  }
  #paper-html-elements svg .link {
    z-index: 2;
  }
  .html-element {
    position: absolute;
    background: white;
    color: #000;
    border: 0.5px solid #384d6380;
    pointer-events: none;
    -webkit-user-select: none;
    padding: 5px;
    text-align: center;
    box-sizing: border-box;
  }
  .html-element.hover {
    z-index: 100;
  }
  .html-element .popup {
    display: none;
    position: absolute;
    color: black;
    top: 50px;
    left: 0px;
    min-width: 150px;
    overflow: auto;
    background: white;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
    border: 1px solid #ccc;
    z-index: 1000;
  }
  .html-element .popup > div {
    padding-bottom: 5px;
  }
  .html-element .popup.active {
    display: grid;
    gap: 5px;
    grid-template-columns: auto auto;
    text-align: left;
  }
  .html-element .popup .heading {
    grid-column: 1 / span 2;
    text-align: center;
  }
  .html-element select,
  .html-element input,
  .html-element button {
    /* Enable interacting with inputs only. */
    pointer-events: auto;
  }
  .html-element button.delete {
    color: white;
    border: none;
    background-color: #c0392b;
    border-radius: 20px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: middle;
    position: absolute;
    top: -10px;
    left: -10px;
    padding: 0;
    margin: 0;
    font-weight: bold;
    cursor: pointer;
  }
  .html-element button.delete:hover {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .html-element select {
    position: absolute;
    right: 2px;
    bottom: 28px;
  }
  .html-element input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    color: #333;
    padding: 5px;
    height: 16px;
  }
  .html-element .element {
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
  }
  .html-element .category {
    color: #737891;
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }
  .html-element div {
    font-size: 10px;
  }

  .system-model .joint-paper{
    background: transparent !important;
    pointer-events:none;
  }
</style>

<style scoped>
  #graphHolder {
    position: relative;
    pointer-events: none;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
