<script setup>
import { IconHawkFileFour, IconHawkImageThree, IconHawkPlaySquare } from '~/common/components/molecules/hawk-icons/icons.js';

const props = defineProps({
  width: {
    type: Number,
    default: 7,
  },
  height: {
    type: Number,
    default: 7,
  },
  type: {
    type: String,
    default: '',
  },
});

const video_supported_format = ['avi', 'mkv', 'mov', 'mp4', 'webm', 'wmv', 'wtv'];

const icon_classes = computed(() => {
  return props.height >= 10 ? 'h-5 w-5' : 'h-4 w-4';
});
</script>

<template>
  <div class="bg-blue-200 grid place-items-center text-blue-600" :class="`h-${height} w-${width}`">
    <IconHawkImageThree v-if="type === 'jpg'" :class="icon_classes" />
    <IconHawkPlaySquare v-else-if="video_supported_format.includes(type)" :class="icon_classes" />
    <IconHawkFileFour v-else :class="icon_classes" />
  </div>
</template>
