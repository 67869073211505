<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { orderBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { IconHawkDownloadTwo } from '~/common/components/molecules/hawk-icons/icons.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { useTerraStore } from '~/terra/store/terra.store';

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  projects: {
    type: Array,
    default: () => [],
  },
  can_modify_features: {
    type: Boolean,
    default: false,
  },
  filename_options: {
    type: Object,
    default: () => {},
  },
  saveFile: {
    type: Function,
  },
});

const emit = defineEmits(['clearDuplicates']);
const terra_store = useTerraStore();
const delete_popup = useModal({
  component: HawkDeletePopup,
});

const $t = inject('$t');
const $services = inject('$services');
const route = useRoute();

const state = reactive({
  is_exporting_data: false,
});

const columns = computed(() => ([
  {
    id: 'project_name',
    header: $t('Inverter name'),
    accessorKey: 'project_name',
  },
  {
    id: 'feature_name',
    header: $t('Tracker name'),
    accessorKey: 'feature_name',
  },
  {
    id: 'serial_number',
    header: $t('Serial number'),
    accessorKey: 'serial_number',
  },
  {
    id: 'module(x,y)',
    header: $t('Module(X,Y)'),
    accessorKey: 'module(x,y)',
    size: 120,
  },
  {
    id: 'module_number',
    header: $t('Module number'),
    accessorKey: 'module_number',
    size: 120,
  },
  {
    id: 'duplicated_at',
    header: $t('Duplicated at'),
    accessorKey: 'duplicated_at',
    size: 300,
  },
]));

async function downloadDuplicates() {
  try {
    const export_data = props.data.map(item => ({
      'Block': item.project_name,
      'Tracker/Table': item.feature_name,
      'Module number': item.module_number,
      'Serial number': item.serial_number,
      'Module(X,Y)': item['module(x,y)'],
      'Duplicate At': item.duplicated_at,
    }));
    const filename = `Duplicates_${props.filename_options.asset_name}_${props.filename_options.current_date}.xlsx`;
    await props.saveFile(orderBy(export_data, ['Block', 'Tracker/Table', 'Module number']), filename);
  }
  catch (error) {
    logger.log(error);
  }
  state.is_exporting_data = false;
}

function clearDuplicates() {
  delete_popup.patchOptions({
    attrs: {
      header: $t('Clear duplicates'),
      content: $t('Are you sure you want to clear duplicates? This action cannot be undone.'),
      button_text: $t('Confirm'),
      confirm: async () => {
        try {
          await $services.terra.post({
            url: `/features/container/${route.params.id}/barcode/remove-duplicate`,
            body: {
              features: props.data.map(val => val.feature_uid),
            },
          });
          terra_store.terra_track_events('Scan - clear duplicates');
          emit('clearDuplicates');
        }
        catch (error) {
          logger.log(error);
        }
      },
      onClose() {
        delete_popup.close();
      },
    },
  });
  delete_popup.open();
}
</script>

<template>
  <div class="py-3">
    <HawkExportToast v-if=" state.is_exporting_data" :submit="downloadDuplicates" progress_text="Exporting to XLSX" completed_text="Exported XLSX" @cancel=" state.is_exporting_data = false" @close=" state.is_exporting_data = false" />

    <div class="flex items-center justify-between py-3">
      <div class="text-lg font-semibold">
        {{ $t('Duplicates found') }}
      </div>
      <div v-if="data.length" class="flex items-center gap-x-2">
        <HawkButton type="plain" icon @click="state.is_exporting_data = true">
          <IconHawkDownloadTwo />
        </HawkButton>
        <HawkButton v-if="can_modify_features && terra_store.check_permission('modify_scan_data')" type="outlined" @click="clearDuplicates">
          {{ $t('Clear duplicates') }}
        </HawkButton>
      </div>
    </div>
    <HawkIllustrations v-if="!data.length" type="no-data" for="terra_scan_duplicate" />
    <div v-else class="max-h-[60vh] w-full overflow-auto scrollbar border rounded-lg">
      <HawkTable
        is_gapless
        :pagination_config="{ totalRows: data?.length, pageSize: 25 }"
        :data="data"
        :columns="columns"
        :show_menu_header="false"
        :disable_resize="true"
        show_column_borders
      />
    </div>
  </div>
</template>
