<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { IconHawkAlertTriangle, IconHawkCheckCircleFilled, IconHawkChevronDown, IconHawkChevronUp, IconHawkClock, IconHawkInfoCircle, IconHawkPencilOne, IconHawkPmProgressPointer } from '~/common/components/molecules/hawk-icons/icons.js';
import PmUpdateAutoProgress from '~/project-management/components/activity-details/pm-update-auto-progress.vue';
import PmUpdateProgress from '~/project-management/components/activity-details/pm-update-progress.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_task, active_schedule } = storeToRefs(project_management_store);

const state = reactive({
  update_menu_open: false,
});

const update_progress_modal = useModal({
  component: PmUpdateProgress,
  attrs: {
    onClose() {
      update_progress_modal.close();
    },
  },
});

const update_progress_auto_modal = useModal({
  component: PmUpdateAutoProgress,
  attrs: {
    onClose() {
      update_progress_auto_modal.close();
    },
  },
});

const percent_progress = computed(() => {
  return Math.round(active_task.value.progress * 100);
});

const percent_schedule_complete = computed(() => {
  return Math.round(active_task.value.percent_schedule_complete * 100);
});

const progress_activity_position = computed(() => {
  let margin_left_value;
  if (percent_progress.value >= 96)
    margin_left_value = 80;
  else if (percent_progress.value >= 13)
    margin_left_value = percent_progress.value - 13;
  else
    margin_left_value = 0;

  return {
    'margin-left': `${margin_left_value}%`,
  };
});

const progress_schedule_position = computed(() => {
  let margin_left_value;
  if (percent_schedule_complete.value >= 90)
    margin_left_value = 80;
  else if (percent_schedule_complete.value >= 15)
    margin_left_value = percent_schedule_complete.value - 15;
  else
    margin_left_value = 0;

  return {
    'margin-left': `${margin_left_value}%`,
  };
});

const progress_success_width = computed(() => {
  return {
    width:
      percent_schedule_complete.value < percent_progress.value
        ? `${percent_schedule_complete.value}%`
        : `${percent_progress.value}%`,
  };
});

const progress_width = computed(() => {
  return {
    width:
      percent_schedule_complete.value > percent_progress.value
        ? `${percent_schedule_complete.value - percent_progress.value}%`
        : `${percent_progress.value - percent_schedule_complete.value}%`,
  };
});

const progress_info_scheduled_to_start = computed(() => {
  const a = dayjs(active_task.value.start_date);
  const b = dayjs(new Date());
  const diff = a.diff(b, 'days');

  if (diff < 0) {
    return {
      icon: 'warning',
      text: `${$t('The activity was scheduled to start')} ${a.fromNow()} ${$t('i.e.')} ${a.format('ll')}`,
    };
  }

  else {
    return {
      icon: 'info',
      text: `${$t('The activity is scheduled to start')} ${a.fromNow()} ${$t('i.e.')} ${a.format('ll')}`,
    };
  }
});

const progress_info_completed = computed(() => {
  if (
    active_task.value.actual_finish > active_task.value.planned_finish
  ) {
    const a = dayjs(active_task.value.actual_finish);
    const b = dayjs(active_task.value.planned_finish);
    const diff = a.diff(b, 'days');
    return {
      icon: 'checked',
      text: `${$t('The activity was completed with a delay of')} ${diff} ${
        diff === 1
          ? $t('day')
          : $t('days')
      }`,
    };
  }
  else {
    return {
      icon: 'checked',
      text: $t('The activity is complete'),
    };
  }
});

const progress_info_lagging_behind = computed(() => {
  const x
            = active_task.value.percent_schedule_complete
            - active_task.value.progress;
  const y = Math.round(active_task.value.total_duration * x);
  return {
    icon: 'warning',
    text: `${$t('The activity is lagging behind the schedule by')} ${Math.round(x * 100)}% (${y} ${
      y === 1
        ? $t('day')
        : $t('days')
    })`,
  };
});

const progress_info_ahead_of_schedule = computed(() => {
  const x
            = active_task.value.progress
            - active_task.value.percent_schedule_complete;
  const y = Math.round(active_task.value.total_duration * x);
  return {
    icon: 'info',
    text: `${$t('The activity is ahead of schedule by')} ${Math.round(x * 100)}% (${y} ${
      y === 1
        ? $t('day')
        : $t('days')
    })`,
  };
});

const progress_info = computed(() => {
  if (
    active_task.value.progress === 0
    && active_task.value.progress
    === active_task.value.percent_schedule_complete
  ) {
    return progress_info_scheduled_to_start.value;
  }

  else if (active_task.value.progress === 1) {
    return progress_info_completed.value;
  }

  else if (
    active_task.value.progress < active_task.value.percent_schedule_complete
  ) {
    return progress_info_lagging_behind.value;
  }

  else if (
    active_task.value.progress > active_task.value.percent_schedule_complete
  ) {
    return progress_info_ahead_of_schedule.value;
  }

  else if (
    active_task.value.progress
    === active_task.value.percent_schedule_complete
  ) {
    return {
      icon: 'info',
      text: $t('The activity is on schedule'),
    };
  }
});
</script>

<template>
  <div class="ml-6 sticky top-0 pr-5">
    <div class="w-full flex justify-between mb-6">
      <div>
        <div class="text-lg font-semibold text-gray-900">
          {{ $t('Progress') }}
        </div>
        <div v-if="active_task" class="text-xs text-gray-600">
          {{ $t('The progress is indicated as of') }} {{ $date(active_task.data_date, 'DATE_MED') }}
        </div>
      </div>
      <div v-if="active_task.type === 'task' && active_schedule.can_modify" class="flex justify-center items-center w-60">
        <HawkMenu
          position="bottom-left"
          @close="state.update_menu_open = false"
          @open="state.update_menu_open = true"
        >
          <template #trigger>
            <HawkButton color="primary">
              {{ $t('Update progress') }}
              <IconHawkChevronDown v-if="!state.update_menu_open" class="text-white" />
              <IconHawkChevronUp v-else />
            </HawkButton>
          </template>
          <template #content="{ close }">
            <div class="p-1">
              <div
                v-if="!active_task?.auto_progress_sync?.is_enabled"
                class="relative flex items-center px-3 h py-3 text-sm font-medium whitespace-nowrap cursor-pointer rounded-lg text-gray-700 hover:bg-gray-50"
                @click="update_progress_modal.open(); close()"
              >
                <IconHawkPencilOne class="w-4 h-4" />
                <span class="ml-2">{{ $t('Manually update progress') }}</span>
              </div>
              <div
                class="relative flex items-center px-3 h py-3 text-sm font-medium whitespace-nowrap cursor-pointer rounded-lg text-gray-700 hover:bg-gray-50"
                @click="update_progress_auto_modal.open(); close()"
              >
                <IconHawkClock class="w-4 h-4" />
                <span class="ml-2">{{ $t('Configure auto update') }}</span>
              </div>
            </div>
          </template>
        </HawkMenu>
      </div>
    </div>
    <div class="mb-4 h-[180px]">
      <div
        class="text-xs font-semibold text-gray-900 mb-1"
        :style="progress_activity_position"
      >
        {{ $t('Activity') }} {{ percent_progress }}%
      </div>
      <IconHawkPmProgressPointer
        class="block relative h-10 w-10 translate-x-[-21px]"
        :style="{
          left: `${percent_progress}%`,
          transform: `translateX(-21px)`,
        }"
      />
      <div class="flex items-start bg-gray-200 ml-[-1px]">
        <div
          class="h-[30px] transition-all duration-300 bg-success-500"
          :style="progress_success_width"
        />
        <div
          class="h-[30px] transition-all duration-300"
          :class="percent_schedule_complete > percent_progress ? 'bg-error-400' : 'bg-primary-700'"
          :style="progress_width"
        />
      </div>
      <IconHawkPmProgressPointer
        class="block relative h-10 w-10 translate-x-[-21px]"
        :style="{
          left: `${percent_schedule_complete}%`,
          transform: `translateX(-21px) rotate(-180deg)`,
        }"
      />
      <div
        class="text-xs font-semibold text-gray-900 mt-1"
        :style="progress_schedule_position"
      >
        {{ $t('Schedule') }} {{ percent_schedule_complete }}%
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mb-4">
      <div class="p-2 border border-solid border-gray-200 rounded-lg">
        <div class="text-xs text-gray-500 mb-1">
          {{ $t('Schedule Performance Index (SPI)') }}
        </div>
        <div
          class="text-sm font-semibold"
          :class="{
            'text-error-700': active_task.spi < 1,
            'text-success-700': active_task.spi >= 1,
          }"
        >
          {{ active_task.spi.toFixed(2) }}
        </div>
      </div>
      <div class="p-2 border border-solid border-gray-200 rounded-lg">
        <div class="text-xs text-gray-500 mb-1">
          {{ $t('Cost Performance Index (CPI)') }}
        </div>
        <div
          class="text-sm font-semibold"
          :class="{
            'text-error-700': active_task.cpi < 1,
            'text-success-700': active_task.cpi >= 1,
          }"
        >
          {{ active_task.cpi?.toFixed?.(2) }}
        </div>
      </div>
    </div>
    <div
      class="flex p-2 border border-solid rounded-lg items-center"
      :class="{
        'border-primary-300 text-primary-700 bg-primary-25': progress_info.icon === 'info',
        'border-success-300 text-success-700 bg-success-25': progress_info.icon === 'checked',
        'border-warning-300 text-warning-700 bg-warning-25': progress_info.icon === 'warning',
      }"
    >
      <IconHawkInfoCircle v-if="progress_info.icon === 'info'" class="text-primary-500 flex-shrink-0" />
      <IconHawkCheckCircleFilled v-else-if="progress_info.icon === 'checked'" class="text-success-500 w-6 h-6 flex-shrink-0" />
      <IconHawkAlertTriangle v-else-if="progress_info.icon === 'warning'" class="text-warning-600 flex-shrink-0" />
      <div
        class="text-sm font-semibold ml-3"
      >
        {{ progress_info.text }}
      </div>
    </div>
  </div>
</template>
