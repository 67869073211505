<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';

import { IconHawkPlusWhite } from '~/common/components/molecules/hawk-icons/icons.js';
import SearchBar from '~/common/components/molecules/hawk-search-input.vue';

import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

import SecondaryPageHeader from '~/common/components/organisms/hawk-page-secondary-header.vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';
import ChecklistForm from '~/tasks/pages/task-details-new/left-section/task-checklists/checklist-form.vue';
import ChecklistFormSkeleton from '~/tasks/pages/task-details-new/left-section/task-checklists/checklist-form-skeleton.vue';
import { useChecklistStore } from '~/tasks/store/checklists.store.js';

const $t = inject('$t');
const $toast = inject('$toast');
const $track_event = inject('$track_event');

const { copy } = useClipboard();
const route = useRoute();
const router = useRouter();

const auth_store = useAuthStore();
const checklist_store = useChecklistStore('checklist-templates');

const { checkTaskPermission } = useTasksPermissions();

const current_organization = auth_store.current_organization;

const state = reactive({
  is_loading: false,
  page_size: 25,
  freeze_table: '-1',
});

onMounted(async () => {
  await getChecklists({});
});

async function getChecklists({ sort_by, pagination_state }) {
  state.is_loading = true;

  state.page_size = pagination_state?.pageSize || 25;
  const query = {
    page_number: 1,
    page_size: state.page_size,
    is_template: true,
    ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
  };
  if (pagination_state?.pageIndex && pagination_state?.pageSize) {
    query.page_number = pagination_state.pageIndex;
    query.page_size = pagination_state.pageSize;
  }
  if (sort_by)
    query.sort = sort_by.desc ? `-${sort_by.id}` : sort_by.id;
  await checklist_store.set_checklists(query);

  state.is_loading = false;
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function checklistDeleteHandler({ name, uid }) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete checklist'),
        content: `Are you sure you want to delete ${name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await checklist_store.delete_template(uid);
            $track_event('Checklist removed', { view: 'Checklist List' });
            closeDeletePopup();
          }
          catch {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

function viewChecklistDetailsHandler(id) {
  router.push({ name: 'checklistDetails', params: { checklist_id: id } });
}

const checklist_path = ref('hello');

function copyChecklistURLHandler(checklist_uid) {
  checklist_path.value = `${window.location.origin}/tasks/templates/checklist/${checklist_uid}/details?organization=${current_organization.uid}`;
  copy(checklist_path.value);
}
const { open: openChecklistFormSkeleton, close: closeChecklistFormSkeleton, patchOptions: patchSkeletonOptions } = useModal({
  component: ChecklistFormSkeleton,
});
function checklistRenameHandler(checklist) {
  patchSkeletonOptions(
    {
      attrs: {
        type: 'Rename Checklist',
        checklist,
        onClose() {
          closeChecklistFormSkeleton();
        },
        async on_submit(data) {
          await checklist_store.update_template({ ...data, uid: checklist.uid });
        },
      },
    },
  );
  openChecklistFormSkeleton();
}

async function searchChecklists(value) {
  state.is_loading = true;
  checklist_store.set_search_key(value);
  await checklist_store.set_checklists({ page_number: 1, page_size: 25, is_template: true });
  state.is_loading = false;
}

const { open: openChecklistForm, close: closeChecklistForm } = useModal({
  component: ChecklistForm,
  attrs: {
    type: 'New Checklist Template',
    onClose() {
      closeChecklistForm();
    },
    async on_submit(data) {
      $track_event('Checklist created', { view: 'Checklist List' });
      await checklist_store.create_template(data);
    },
  },
});

const menu_items = ref([
  {
    uid: 'details',
    label: 'View details',
  },
  {
    uid: 'rename',
    label: 'Rename',
    disabled: !checkTaskPermission({ permission: 'can_modify_templates' }),
  },
  {
    uid: 'copy_url',
    label: 'Copy URL',
  },
  {
    uid: 'delete',
    label: 'Delete',
    disabled: !checkTaskPermission({ permission: 'can_modify_templates' }),
  },
]);

const columns = ref(
  [
    {
      accessorKey: 'name',
      header: $t('Checklist Name'),
      id: 'name',
      render_as: {
        field_type: 'text',
        options: {
          text_wrap: true,
        },
      },
    },
    {
      accessorKey: 'total_items',
      header: $t('# of tasks'),
      id: 'total_items',
      render_as: {
        field_type: 'number',
      },
    },
    {
      accessorKey: 'created_at',
      header: $t('Created on'),
      id: 'created_at',
      render_as: {
        field_type: 'date',
        options: {
          format: 'DATETIME_LONG_WITH_SECONDS',
        },
      },
    },
    {
      accessorKey: 'context_menu',
      header: '',
      id: 'context_menu',
      size: '5',
      show_on_hover: 'true',
    },
  ],
);

function selectHandler(selectedObject, element) {
  if (selectedObject.uid === 'details')
    viewChecklistDetailsHandler(element?.uid);
  else if (selectedObject.uid === 'rename')
    checklistRenameHandler(element);
  else if (selectedObject.uid === 'copy_url')
    copyChecklistURLHandler(element?.uid);
  else if (selectedObject.uid === 'delete')
    checklistDeleteHandler(element);
}
</script>

<template>
  <div>
    <SecondaryPageHeader class="py-4 mb-4">
      <template #left>
        <SearchBar
          :model-value="checklist_store.search_key"
          :debounce_time="700"
          @update:model-value="searchChecklists"
        />
      </template>
      <template #right>
        <hawkButton
          v-if="checkTaskPermission({ permission: 'can_modify_templates' })"
          color="primary"
          @click="() => openChecklistForm()"
        >
          <IconHawkPlusWhite class="text-white h-3" />
          {{ $t('New checklist') }}
        </hawkButton>
      </template>
    </SecondaryPageHeader>
    <TableWrapperVue v-if="checklist_store.checklists.length || state.is_loading" container_class="border-0">
      <HawkTable
        :pagination_config="{ totalRows: checklist_store.total_checklist_count, pageSize: 25 }"
        :data="checklist_store.checklists"
        :is_loading="state.is_loading"
        :columns="columns"
        :non_sortable_columns="['context_menu']"
        :manual_pagination="true"
        :manual_sorting="true"
        :freeze_table="state.freeze_table"
        is_gapless
        @pagination="getChecklists($event)"
        @sort="getChecklists($event)"
        @row-clicked="viewChecklistDetailsHandler($event.uid)"
      >
        <template #context_menu="checklist">
          <hawk-menu
            position="fixed"
            additional_trigger_classes="!ring-0 hover:bg-gray-50"
            :items="menu_items"
            @select="$event => selectHandler($event, checklist.data.row.original)"
            @click.stop=""
            @open="state.freeze_table = checklist?.data?.row?.id"
            @close="state.freeze_table = '-1'"
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
