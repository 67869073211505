<script setup>
import { find, remove, uniq } from 'lodash-es';
import { inject } from 'vue';
import { useModal } from 'vue-final-modal';
import { IconHawkDragIcon } from '~/common/components/molecules/hawk-icons/icons.js';
import ChecklistInput from '~/common/components/organisms/hawk-checklist-fields/checklist-input.vue';
import ChecklistItem from '~/common/components/organisms/hawk-checklist-fields/checklist-item.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DocumentUploadForm from '~/dms/components/documents/forms/document-upload-form.vue';
import ChecklistNote from '~/tasks/pages/task-details-new/left-section/task-checklists/checklist-note.vue';
import { useChecklistStore } from '~/tasks/store/checklists.store.js';

const props = defineProps({
  checklist_uid: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  subitem_level: {
    type: Number,
    default: 0,
  },
  dropdown_items: {
    type: Array,
  },
  button_items: {
    type: Array,
  },
  sortable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['addSubItem', 'updateStatus', 'updateChecklist']);

const Draggable = defineAsyncComponent(() => import('vuedraggable'));

const { task_uid } = inject('task-uid', {});
const $toast = inject('$toast');
const $t = inject('$t');
const $track_event = inject('$track_event');
const is_template = inject('is-template', false);
const is_checklist_template = inject('is-checklist-template', false);
const task_store = inject('task_store', {});

const checklist_store = is_checklist_template ? useChecklistStore('checklist-templates') : useChecklistStore();
const show_input = ref(0);
const can_rename = ref(0);
const add_notes = ref(0);
const open_dropdown = ref(null);

function handleChange() {
  emit('updateChecklist', props.items);
}
function addChecklistSubItems(uid, children) {
  const item = find(props.items, o => o.uid === uid);
  if (item.children)
    item.children = [...item.children, ...children];
  else item.children = children;
  emit('updateChecklist', props.items);
  show_input.value = 0;
}

function addChecklistNotes(uid, note) {
  const item = find(props.items, o => o.uid === uid);
  item.note = note;
  $track_event('update_checklist_item', { type: 'Note', where: is_checklist_template ? 'Template' : 'Task' });
  emit('updateChecklist', props.items);
  show_input.value = 0;
  add_notes.value = 0;
}

function handleDelete(uid) {
  remove(props.items, item => item.uid === uid);
  emit('updateChecklist', props.items);
}
function updateStatus(uid, status) {
  const item = find(props.items, o => o.uid === uid);
  item.status = status;
  emit('updateChecklist', props.items);
}
function handleNameChange(uid, value) {
  const item = find(props.items, o => o.uid === uid);
  item.name = value.name;
  emit('updateChecklist', props.items);
  can_rename.value = false;
}

// Checklist Attachments
const file_element = { name: 'File', component: 'MultifileElement', auto: false };
const attachment_upload_modal = useModal({
  component: DocumentUploadForm,
});
function openAttachmentUploadModal(item_uid) {
  attachment_upload_modal.patchOptions({
    attrs: {
      fields_list: [
        file_element,
      ],
      attachment_config: { meta: { service: 'tickets', id: task_uid.value } },
      submit: async (form) => {
        attachment_upload_modal.close();
        try {
          const payload_arr = form.data?.File?.map((item) => {
            const request_payload = {
              name: item.name || '',
              location: item.location || null,
              file_name: item.name || '',
              file_size: item.size || 0,
              file_type: item.type || null,
              service: item.service_object,
            };
            return request_payload;
          });
          if (is_checklist_template) {
            await checklist_store.set_checklist_template_attachments({
              item_uid,
              checklist_uid: props.checklist_uid,
            }, { attachments: payload_arr });
          }
          else {
            await checklist_store.set_checklist_attachments({
              task_uid: task_uid.value,
              item_uid,
              checklist_uid: props.checklist_uid,
              store_key: task_store.$id,
            }, { attachments: payload_arr });
          }
          const item = find(props.items, o => o.uid === item_uid);
          item.attachments = item?.attachments ? item.attachments + payload_arr.length : payload_arr.length;
          $track_event('update_checklist_item', { type: 'Attachment', where: is_checklist_template ? 'Template' : 'Task' });
          if (payload_arr?.length) {
            task_store.task_track_events('Attachments added', {
              count: payload_arr.length,
              filetype: uniq(payload_arr.map(val => val.file_type)).join(', '),
              filesize: payload_arr.map(val => val.file_size).reduce((acc, val) => acc + val, 0),
              location: 'Checklist',
            }, task_uid.value);
          }

          emit('updateChecklist', props.items);
        }
        catch (error) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
            position: 'bottom-right',
          });
          logger.error(error);
        }
      },
      onClose() {
        attachment_upload_modal.close();
      },
    },
  });
  attachment_upload_modal.open();
}
function attachmentDownloadHandler(file) {
  task_store.task_track_events('Attachments downloaded', { filesize: file.file_size, filetype: file.mime_type || file.extension, location: 'Checklist' }, task_uid.value);
}
const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function attachmentDeleteHandler(item_uid, file) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Attachment'),
        content: `Are you sure you want to delete ${file.file_name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            if (is_checklist_template) {
              await checklist_store.delete_checklist_template_attachments({
                checklist_uid: props.checklist_uid,
                item_uid,
                attachment_uid: file.uid,
              });
            }
            else {
              await checklist_store.delete_checklist_attachments({
                task_uid: task_uid.value,
                checklist_uid: props.checklist_uid,
                item_uid,
                attachment_uid: file.uid,
                store_key: task_store.$id,
              });
            }
            const item = find(props.items, o => o.uid === item_uid);
            item.attachments = item.attachments - 1;
            $track_event('update_checklist_item', { type: 'Attachment', where: is_checklist_template ? 'Template' : 'Task' });
            task_store.task_track_events('Attachments removed', {
              count: 1,
              filetype: file.type || file.mime_type,
              filesize: file.file_size,
              location: 'Checklist',
            }, task_uid.value);

            emit('updateChecklist', props.items);
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

const dropdown_items = props.dropdown_items || [
  {
    label: 'Add sub item',
    emit: 'addSubItem',
  },
  {
    label: 'Add notes',
    emit: 'addNotes',
  },
  {
    label: 'Add Attachments',
    emit: 'addAttachments',
  },
  {
    label: 'Rename',
    emit: 'renameItem',
  },
  {
    label: 'Delete',
    emit: 'deleteItem',
  },
];

const button_items = props.button_items || [
  {
    uid: 0,
    label: 'Yes',
    action: 'onResolve',
  },
  {
    uid: 1,
    label: 'No',
    action: 'onReject',
  },
  {
    uid: 2,
    label: 'N/A',
    action: 'onHold',
  },
];
function active_state(status) {
  if (status === 'resolved')
    return 0;
  else if (status === 'rejected')
    return 1;
  else if (status === 'onHold')
    return 2;
  else return -1;
}
function item_attachments(item_uid) {
  if (is_checklist_template)
    return checklist_store.checklist_attachments(item_uid);
  return task_store.checklist_attachments(item_uid);
}
function selectHandler(selectedObject, element_uid) {
  if (selectedObject.emit === 'addSubItem')
    show_input.value = element_uid;
  else if (selectedObject.emit === 'deleteItem')
    handleDelete(element_uid);
  else if (selectedObject.emit === 'renameItem')
    can_rename.value = element_uid;
  else if (selectedObject.emit === 'addNotes')
    add_notes.value = element_uid;
  else if (selectedObject.emit === 'addAttachments')
    openAttachmentUploadModal(element_uid);
}
</script>

<script>
export default {
  name: 'ChecklistItem',
};
</script>

<template>
  <div class="">
    <Draggable
      tag="div"
      :list="items"
      :animation="0"
      :sort="props.sortable"
      item-key="item"
      group="checklist"
      ghost-class="ghost"
      @change="handleChange"
    >
      <template #item="{ element }">
        <div>
          <li>
            <div v-if="can_rename === element.uid" class=" hover:bg-gray-50 rounded-lg">
              <ChecklistInput
                class="mb-2 py-2 ml-5 mt-4 w-[97%]"
                :name="element.name"
                @close="can_rename = false"
                @add="handleNameChange(element.uid, $event)"
              />
            </div>
            <div
              v-else
              class="hover:bg-gray-50 rounded-lg px-1 py-2 group"
            >
              <div class="grid grid-cols-12">
                <div class="col-span-8 text-sm text-gray-700 flex items-center">
                  <div class="flex items-center">
                    <div>
                      <IconHawkDragIcon :class="{ '!invisible': !props.sortable }" class="cursor-move mr-1.5 h-4 invisible group-hover:visible text-gray-300" />
                    </div>
                    <p class="item-counter" />
                    <p>
                      {{ element.name }}
                    </p>
                  </div>
                </div>
                <div class="col-span-3 flex justify-center items-center">
                  <hawk-button-group
                    v-if="button_items.length > 0"
                    v-show="!(is_checklist_template || is_template)"
                    :items="button_items"
                    :active_item="active_state(element.status)"
                    @on-resolve="updateStatus(element.uid, 'resolved')"
                    @on-reject="updateStatus(element.uid, 'rejected')"
                    @on-hold="updateStatus(element.uid, 'onHold')"
                  />
                </div>
                <div
                  v-if="dropdown_items.length" class="col-span-1 flex justify-center items-center invisible group-hover:visible"
                  :class="{ '!visible': open_dropdown === element.uid }"
                >
                  <hawk-menu
                    position="fixed"
                    :items="dropdown_items"
                    @select="$event => selectHandler($event, element.uid)"
                    @open="open_dropdown = element.uid"
                    @close="open_dropdown = null"
                  />
                </div>
              </div>
              <div v-if="element.attachments || add_notes === element.uid || element.note" class="py-8 px-11">
                <div
                  v-if="element.attachments && item_attachments(element.uid).length"
                  class="text-sm text-[#344054] grid grid-cols-4 mb-4"
                  :class="{ 'grid-cols-6': is_checklist_template }"
                >
                  <p class="col-span-1">
                    {{ $t('Attachments') }}
                  </p>
                  <div class="col-span-2 w-[373px]">
                    <hawk-attachments-grid
                      v-if="item_attachments(element.uid).length"
                      :items="item_attachments(element.uid)"
                      @delete="$event => attachmentDeleteHandler(element.uid, $event)"
                      @view="task_store.task_track_events('Attachments viewed', { filesize: $event.file_size, filetype: $event.mime_type || $event.extension, location: 'Checklist' }, task_uid)"
                    />
                  </div>
                </div>
                <div
                  v-if="element.note || add_notes === element.uid"
                  class="text-sm text-[#344054] grid grid-cols-4"
                  :class="{ 'grid-cols-6': is_checklist_template }"
                >
                  <p class="col-span-1">
                    {{ $t('Notes') }}
                  </p>
                  <div v-if="add_notes === element.uid" class="col-span-2">
                    <ChecklistNote :item="element" :focus="true" @add-notes="addChecklistNotes(element.uid, $event)" />
                  </div>
                  <div v-else class="col-span-2">
                    <ChecklistNote :item="element" @add-notes="addChecklistNotes(element.uid, $event)" />
                  </div>
                </div>
              </div>
            </div>
            <ol>
              <ChecklistItem
                v-if="element.children"
                :checklist_uid="checklist_uid"
                :items="element.children"
                :subitem_level="subitem_level + 1"
                :dropdown_items="props.dropdown_items"
                :button_items="props.button_items"
                class="pb-2 pt-1 last:p-0"
                @update-checklist="handleChange"
              />
            </ol>
          </li>
          <ChecklistInput
            v-if="show_input === element.uid"
            class="mx-5 my-4 w-[87%]"
            placeholder="Enter subitem"
            :allow_multiple="true"
            @close="show_input = false"
            @add="addChecklistSubItems(element.uid, $event)"
          />
          <div v-if="subitem_level === 0" class="border-b" />
        </div>
      </template>
    </Draggable>
  </div>
</template>

<style lang="scss">
OL { counter-reset: item }
LI { display: block; position: relative }
.item-counter:before { content: counters(item, ".") " "; counter-increment: item ; margin-right: 12px;
font-weight: 500;
font-size: 14px;
}
</style>
